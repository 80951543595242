/** @format */

import * as _ from 'underscore';
import { PatientRuleConditionOperatorOptions, PatientRuleConditionRelationOptions, PatientRuleConditionSymptomsOperatorOptions, PatientRuleConditionVitalsOptions } from '../constants/PatientRules';
import { PatientRuleItemConditionType } from '../types/PatientRule.types';

export const getConditionRelationLabel = (conditionRelation: string) => {
  return PatientRuleConditionRelationOptions.find((item) => conditionRelation === item.value)?.label || '';
};

export const getVitalDisaplyLabel = (vitalsValue: string) => {
  return PatientRuleConditionVitalsOptions.find((item) => vitalsValue === item.value)?.label || '';
};

export const getOperatorLabel = (operator: string) => {
  return [...PatientRuleConditionOperatorOptions, ...PatientRuleConditionSymptomsOperatorOptions].find((item) => operator === item.value)?.label || '';
};

export const getValueLabel = (value: number | number[]) => {
  if (Array.isArray(value)) {
    const result = value.map((e: any) => {
      if (!_.isNaN(parseFloat(e))) {
        return e.toString().toFixed(1);
      } else {
        return e;
      }
    });
    return result.join(' - ');
  }

  if (value) {
    return value.toFixed(1);
  }
};

export const formatPatientRuleConditions = (conditionsType: string, conditions: PatientRuleItemConditionType[], addConditionType = true): string[] => {
  return conditions?.reduce((acc: string[], condition: PatientRuleItemConditionType, index: number) => {
    acc.push(`${condition.fact} ${condition.operator} ${Array.isArray(condition.value) ? condition.value.join(' - ') : condition.value}`);

    if (index !== conditions.length - 1 && addConditionType) {
      acc.push(conditionsType);
    }
    return acc;
  }, [] as string[]);
};
