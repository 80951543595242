/** @format */

import axios, { CancelTokenSource } from 'axios';
import * as React from 'react';
import { DateTime } from 'luxon';
import { useNavigate } from 'react-router-dom';
import { useMutation, useQuery } from '@tanstack/react-query';
import { ErrorBoundary } from 'react-error-boundary';

import Box from '@mui/material/Box';
// import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';

import CustomTable from '../../components/common/custom-table/CustomTable';
import CustomLoading from '../../components/common/custom-loading/CustomLoading';
import { PageErrorBoundary } from '../../components/common/error/PageErrorBoundary';
import PatientNameIdCell from '../../components/table-cell-components/PatientNameIdCell';
import { ComponentErrorBoundary } from '../../components/common/error/ComponentErrorBoundary';
import SearchSchedulePatientAppointmentModal from '../../components/appointments/SearchSchedulePatientAppointmentModal';

import { AppointmentsType } from '../../types/Appointments';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import useSocket, { useSocketProps } from '../../hooks/useSocket';
import { CustomTableColumnType } from '../../types/CustomTable.types';
import { PatientListNewType } from '../../types/PatientListPage.types';

import { setToast } from '../../reducers/toast-reducer/ToastReducer';
import { setActivePatientList, setLimit, setPageOffset, updateActivePatientInfo } from '../../reducers/patient-list-reducer/ActivePatientListSlice';

import { fetchPatientsList } from '../../service/patient-list';
import { addPatientAppointment } from '../../service/appointments';

interface ActivePatientListProps {}
const customStyles = {
  backgroundColor: '#F1F2F3',
  color: '#667185',
};

const ActivePatientList: React.FunctionComponent<ActivePatientListProps> = (props) => {
  const navigate = useNavigate();

  const reduxDispatch = useAppDispatch();

  const axiosApiRef = React.useRef<CancelTokenSource | null>(null);

  const doctors = useAppSelector((state) => state.userRegion.orgUsers);

  const state = useAppSelector((state) => state.activePatientList);
  const cardiologistId = useAppSelector((state) => state.patientList.doctorName || 'ALL');

  const [isOpenAppointmentRescheduleModal, setIsOpenAppointmentRescheduleModal] = React.useState<boolean>(false);
  const [appointmentRescheduleInfo, setAppointmentRescheduleInfo] = React.useState<AppointmentsType | null>(null);

  const activePatientQuery = useQuery({
    queryKey: ['fetchActivePatientsList', state.limit, state.pageOffset, cardiologistId],
    queryFn: async ({ signal }) => {
      const CancelToken = axios.CancelToken;

      if (axiosApiRef.current) {
        axiosApiRef.current.cancel();
      }

      axiosApiRef.current = CancelToken.source();

      signal?.addEventListener('abort', () => {
        axiosApiRef.current?.cancel('Query was cancelled by TanStack Query');
      });

      const payload = await fetchPatientsList(
        {
          status: 'ACTIVE',
          limit: state.limit,
          offset: state.pageOffset * state.limit,
          flag: true,
          startDate: DateTime.now().minus({ days: 7 }).toFormat('yyyy-MM-dd'),
          endDate: DateTime.now().toFormat('yyyy-MM-dd'),
          cardiologistId,

          isEnrolmentDetails: 'false',
          isMedicalAdhrence: 'false',
          isPatientSymtoms: 'false',
          isPatientVitals: 'false',
        },
        axiosApiRef.current.token,
      );

      const newData = payload.data.filter((ele: any) => ele !== null);
      const newPayload = {
        count: payload.count,
        data: newData,
      };

      reduxDispatch(setActivePatientList(newPayload));

      return payload;
    },
    refetchIntervalInBackground: false,
    refetchOnWindowFocus: false,
  });

  const addPatientAppointmentMutation = useMutation({
    mutationFn: async ({ patientSourceId, payload }: { patientSourceId: string; payload: AppointmentsType }) => {
      const newPayload = { ...payload };

      if (newPayload?.patientDetails) {
        newPayload.patientDetails = {} as any;
      }

      await addPatientAppointment(patientSourceId, newPayload);
      return { patientSourceId, payload };
    },
    onSuccess: () => {
      reduxDispatch(setToast({ message: 'Successfully added appointments', code: 'success' }));
    },
    onError: () => {
      reduxDispatch(setToast({ message: 'Failed to add the appointments', code: 'error' }));
    },
  });

  const handleLabReports = React.useCallback(
    (patientSourceId: string, labReports: any[]) => {
      const patientInfo = state.listData.find((item: any) => item?.basicDetails?.patientSourceId === patientSourceId);
      if (patientInfo) {
        patientInfo.latestVitals = labReports.concat(patientInfo?.latestVitals || []);
        reduxDispatch(updateActivePatientInfo(patientInfo));
      }
    },
    [state.listData],
  );

  const handlePatientSymptoms = React.useCallback(
    (patientSourceId: string, symptoms: any) => {
      const patientInfo = state.listData.find((item: any) => item?.basicDetails?.patientSourceId === patientSourceId);
      if (patientInfo) {
        patientInfo.titrationCycleSymptoms = [symptoms]?.concat(patientInfo.titrationCycleSymptoms || []);
        patientInfo.symptoms = { ...patientInfo.symptoms, symptoms: symptoms.symptoms.concat(patientInfo.symptoms?.symptoms || []) } as any;
        reduxDispatch(updateActivePatientInfo(patientInfo));
      }
    },
    [state.listData],
  );

  const socketConnection: useSocketProps = {
    handleLabReports,
    handlePatientVitals: handleLabReports,
    handlePatientSymptoms,
  };

  useSocket(socketConnection);

  const handleChangePageOffset = (event: React.MouseEvent<HTMLButtonElement> | null, newPage: number) => {
    reduxDispatch(setPageOffset(Number(newPage)));
  };

  const handleChangeLimit = (event: React.ChangeEvent<HTMLInputElement>) => {
    reduxDispatch(setLimit(Number(event.target.value)));
  };

  const handleCloseRescheduleModel = () => {
    setIsOpenAppointmentRescheduleModal(false);
    setAppointmentRescheduleInfo(null);
  };

  const handleClickSubmitUpdateAppointment = async (appointment: AppointmentsType) => {
    await addPatientAppointmentMutation.mutateAsync({ patientSourceId: appointment.patientDetails.patientSourceId!, payload: appointment });
    handleCloseRescheduleModel();
  };

  if (activePatientQuery.isPending) {
    return <CustomLoading />;
  }

  const columnDefs: CustomTableColumnType[] = [
    {
      minWidth: 250,
      width: '25%',
      id: 'patientId',
      label: 'Patient Details',
      cellFormatter: (data: PatientListNewType, index: number) => {
        const handleClickPatientId = () => {
          navigate(`/patients/${data?.basicDetails?.patientSourceId}`);
        };

        return (
          <ErrorBoundary key={index} fallbackRender={ComponentErrorBoundary}>
            <PatientNameIdCell data={data} handleClickPatientId={handleClickPatientId} />
          </ErrorBoundary>
        );
      },
    },

    {
      id: 'hf-type',
      // minWidth: 150,
      // width: '15%',
      label: 'Hf-Type',
      cellFormatter: (data: PatientListNewType, index: number) => (
        <Typography variant='fontSemiBold14' color='#33425B' component='div' sx={{ maxWidth: 'fit-content' }}>
          {data?.medicalHistory?.heartFailureMedicalHistory?.hfType
            ? `${data?.medicalHistory?.heartFailureMedicalHistory?.hfType} (${data?.medicalHistory?.heartFailureMedicalHistory?.nyhaClass})`
            : 'NA'}
        </Typography>
      ),
    },

    {
      id: 'doctor-name',
      label: 'Cardiologist',
      minWidth: 180,
      width: '18%',
      cellFormatter: (columnValue: PatientListNewType, index: number) => {
        const patientDoctor = doctors?.find((e: any) => e.value === columnValue.basicDetails?.cardiologistId!);
        if (doctors && patientDoctor) {
          return (
            <Typography
              key={index}
              textTransform='capitalize'
              color='#5C6A90'
              variant='fontSemiBold14'
              component='div'
              sx={{
                marginRight: '5px',
                display: 'inline-block',
                maxWidth: '200px',
                whiteSpace: 'nowrap',
                overflow: 'hidden !important',
                textOverflow: 'ellipsis',
                padding: '0 10px',
              }}>
              {patientDoctor.label.toLowerCase()}
            </Typography>
          );
        } else {
          return null;
        }
      },
    },
    {
      id: 'enrolment-date',
      minWidth: 150,
      width: '15%',
      label: 'Enrolment Date',
      cellFormatter: (data: PatientListNewType, index: number) => (
        <Typography variant='fontSemiBold14' color='#33425B' component='div' sx={{ maxWidth: 'fit-content' }}>
          {data?.basicDetails?.enrollmentDate ? DateTime.fromJSDate(new Date(data?.basicDetails?.enrollmentDate)).toFormat('MMM dd yyyy') : 'NA'}
        </Typography>
      ),
    },
    {
      id: 'last-review',
      // minWidth: 150,
      // width: '15%',
      label: 'Last Review',
      cellFormatter: (data: PatientListNewType, index: number) => (
        <Typography variant='fontSemiBold14' color='#33425B' component='div' sx={{ maxWidth: 'fit-content' }}>
          {data?.prescriptions?.prescriptions?.prescriptionDate ? DateTime.fromJSDate(new Date(data?.prescriptions?.prescriptions?.prescriptionDate)).toFormat('MMM dd yyyy') : 'NA'}
        </Typography>
      ),
    },
    {
      id: 'action',
      // minWidth: 220,
      hiddenHeader: true,
      // width: '20%',
      label: '',
      cellFormatter: (data: PatientListNewType, index: number) => {
        const handleClickScheduleReview = () => {
          setIsOpenAppointmentRescheduleModal(true);
          setAppointmentRescheduleInfo({ patientDetails: data.basicDetails as any } as AppointmentsType);
        };

        const handleClickStartReview = () => {
          navigate(`/patients/${data.basicDetails?.patientSourceId}`, { state: { isPatientReview: true } });
        };

        return (
          <Box className='patient-list-actions' sx={{ textAlign: 'right' }}>
            <Button size='small' variant='outlined' sx={{ borderColor: '#3E4685', py: 1, mr: '16px', minWidth: '120px' }} onClick={handleClickScheduleReview}>
              <Typography variant='fontSemiBold14' color='#3E4685'>
                Schedule
              </Typography>
            </Button>
            <Button size='small' variant='outlined' sx={{ borderColor: '#3E4685', py: 1, minWidth: '120px' }} onClick={handleClickStartReview}>
              <Typography variant='fontSemiBold14' color='#3E4685'>
                Start Review
              </Typography>
            </Button>
          </Box>
        );
      },
    },
  ];

  return (
    <ErrorBoundary FallbackComponent={PageErrorBoundary}>
      <Box id='active-patient-list' padding='0px 15px 0px 15px'>
        <CustomTable
          customStyles={customStyles}
          count={state.count}
          data={state.listData}
          columns={columnDefs}
          tablePagination
          rowsPerPage={state.limit}
          handleChangeRowsPerPage={handleChangeLimit}
          page={state.pageOffset}
          handleChangePage={handleChangePageOffset}
          hoverEffect={true}
        />

        {isOpenAppointmentRescheduleModal && (
          <SearchSchedulePatientAppointmentModal
            open={isOpenAppointmentRescheduleModal}
            disableButtons={false}
            isForceEdit
            isNewAppointment
            handleClose={handleCloseRescheduleModel}
            appointment={appointmentRescheduleInfo!}
            handleSubmitAppointment={handleClickSubmitUpdateAppointment}
            handleDeleteAppointment={console.info}
            isUpdatingAppointment={false}
            selectedPatientInfo={appointmentRescheduleInfo?.patientDetails}
          />
        )}
      </Box>
    </ErrorBoundary>
  );
};

export default ActivePatientList;
