/** @format */

import * as React from 'react';

import Box from '@mui/material/Box';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';

interface PDFViewerProps {
  handleClose: () => void;
  pdfUrl: string;

  isOpen: boolean;
}

const PDFViewer: React.FunctionComponent<PDFViewerProps> = (props) => {
  return (
    <Dialog maxWidth='md' fullWidth open={props.isOpen} onClose={props.handleClose} sx={{ p: 0 }}>
      <DialogContent sx={{ p: 0 }}>
        <Box style={{ maxWidth: '100%' }}>
          <iframe width='100%' height='800px' src={props.pdfUrl} title={props.pdfUrl}></iframe>
        </Box>
      </DialogContent>
    </Dialog>
  );
};

export default PDFViewer;
