/** @format */

import * as Joi from 'joi';

export const emailRegex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
export const phoneNumberRegex = /^((\+\d{1,3}(-| )?\(?\d\)?(-| )?\d{1,5})|(\(?\d{2,6}\)?))(-| )?(\d{3,4})(-| )?(\d{4})(( x| ext)\d{1,5}){0,1}$/;
export const countryCodeRegex = /^(\+?\d{1,3}|\d{1,4})$/;

export const PatientCareGiverPostSchema = Joi.object({
  name: Joi.string()
    .min(3)
    .max(100)
    .messages({
      'string.max': 'Name should be less than equal to 100 Chars',
      'string.min': 'Name should be more than equal to 3 Chars',
      'any.required': 'Name is required',
      'string.empty': 'Name is required',
      'string.base': 'Name is required',
    })
    .required(),
  email: Joi.string()
    .min(2)
    .pattern(emailRegex)
    .max(200)
    .messages({
      'string.max': 'Email should be less than 100 characters',
      'string.min': 'Email should be more than 2 characters',
      'any.required': 'Email is required',
      'string.empty': 'Email is required',
      'string.pattern.base': 'Invalid Email',
    })
    .required(),
  phoneNumber: Joi.string()
    .pattern(phoneNumberRegex)
    .messages({
      'string.pattern.base': 'Invalid Phone Number',
      'any.required': 'Phone Number is required',
      'string.empty': 'Phone Number is required',
    })
    .required(),
  countryCode: Joi.string()
    .pattern(countryCodeRegex)
    .messages({
      'string.pattern.base': 'Invalid Country Code',
      'any.required': 'Country Code is required',
      'string.empty': 'Country Code is required',
    })
    .allow(null, '')
    .optional(),
}).required();
