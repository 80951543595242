/** @format */

import React from 'react';
import { Form } from '@rjsf/mui';
import { RJSFSchema } from '@rjsf/utils';
import validator from '@rjsf/validator-ajv8';

import Dialog from '@mui/material/Dialog';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import DialogTitle from '@mui/material/DialogTitle';
// import DialogContent from '@mui/material/DialogContent';

import CloseIcon from '@mui/icons-material/Close';

// Types.
import { PatientInfoType } from '../../../types/Patients.types';
import { EditOtherMedicalHistoryFormDataPayloadType, OtherHistoryLifeStyleType, PatientMedicalHistoryPayloadType } from '../../../types/PatientMedicalHistory.types';

// Components
import { GridObjectFieldTemplateWithoutCard } from '../../common/react-json-schema/GridObjectFieldTemplate';
import { GridSelectInputField, TitleFieldTemplate, FieldErrorTemplate, GridInputField } from '../../common/react-json-schema';
import { Box } from '@mui/material';

// Component props.
interface EditOtherMedicalHistoryProps {
  isOpen: boolean;
  isUpdatingAPICalling: boolean;

  handleClose: () => void;
  handleSubmit: (payload: Partial<PatientMedicalHistoryPayloadType>) => void;

  patientInfo: PatientInfoType;
}

const EditOtherMedicalHistory: React.FunctionComponent<EditOtherMedicalHistoryProps> = (props) => {
  const deactiveStatus = props.patientInfo.basicDetails?.patientStatus === 'DEACTIVE' || false;

  const [formData, setFormData] = React.useState<EditOtherMedicalHistoryFormDataPayloadType>({} as EditOtherMedicalHistoryFormDataPayloadType);

  React.useEffect(() => {
    const medicalHistory: PatientMedicalHistoryPayloadType = props.patientInfo?.medicalHistory || ({} as PatientMedicalHistoryPayloadType);

    const formData: EditOtherMedicalHistoryFormDataPayloadType = {
      otherHistoryLifeStyle: {
        otherIllnesses: medicalHistory?.otherHistoryLifeStyle?.otherIllnesses,
        familyHistory: medicalHistory?.otherHistoryLifeStyle?.familyHistory,
        foodAllergies: medicalHistory?.otherHistoryLifeStyle?.foodAllergies,
        drugAllergiesContraindications: medicalHistory?.otherHistoryLifeStyle?.drugAllergiesContraindications,
        diet: medicalHistory?.otherHistoryLifeStyle?.diet,
        smoking: medicalHistory?.otherHistoryLifeStyle?.smoking,
        alcohol: medicalHistory?.otherHistoryLifeStyle?.alcohol,
        sleep: medicalHistory?.otherHistoryLifeStyle?.sleep,
        physicalActivity: medicalHistory?.otherHistoryLifeStyle?.physicalActivity,
      } as OtherHistoryLifeStyleType,
    };

    setFormData(formData);
  }, [props.patientInfo]);

  const schema: RJSFSchema = {
    title: 'Other history and Lifestyle',
    type: 'object',
    properties: {
      otherHistoryLifeStyle: {
        type: 'object',
        properties: {
          otherIllnesses: {
            title: 'Other illnesses',
            type: 'string',
          },

          familyHistory: {
            title: 'Family History',
            type: 'string',
          },

          foodAllergies: {
            title: 'Food Allergies',
            type: 'string',
          },

          drugAllergiesContraindications: {
            title: 'Drug Allergies / Contraindications',
            type: 'string',
          },

          diet: {
            title: 'Diet',
            type: 'string',
          },

          smoking: {
            title: 'Smoking',
            type: 'string',
            enum: ['Non-smoker', 'Occasional smoker'],
          },

          alcohol: {
            title: 'Alcohol',
            type: 'string',
            enum: ['Non-drinker', 'Social drinker', 'Heavy drinker'],
          },

          sleep: {
            title: 'Sleep',
            type: 'string',
          },

          physicalActivity: {
            title: 'Physical Activity',
            type: 'string',
          },
        },
      },
    },
  };

  const uiSchema: any = {
    'ui:ObjectFieldTemplate': GridObjectFieldTemplateWithoutCard,
    'ui:noErrors': true,

    otherHistoryLifeStyle: {
      'ui:ObjectFieldTemplate': GridObjectFieldTemplateWithoutCard,
      'ui:noErrors': true,

      otherIllnesses: {
        'ui:widget': GridInputField,
        'ui:disabled': false,
        'ui:grid': {
          xs: 4,
        },
        'ui:mandatory': false,
        'ui:grid-area': 'otherIllnesses',
      },

      familyHistory: {
        'ui:widget': GridInputField,
        'ui:disabled': false,
        'ui:grid': {
          xs: 4,
        },
        'ui:mandatory': false,
        'ui:grid-area': 'familyHistory',
      },

      foodAllergies: {
        'ui:widget': GridInputField,
        'ui:disabled': false,
        'ui:grid': {
          xs: 4,
        },
        'ui:mandatory': false,
        'ui:grid-area': 'foodAllergies',
      },

      drugAllergiesContraindications: {
        'ui:widget': GridInputField,
        'ui:disabled': false,
        'ui:grid': {
          xs: 4,
        },
        'ui:mandatory': false,
        'ui:grid-area': 'drugAllergiesContraindications',
      },

      diet: {
        'ui:widget': GridInputField,
        'ui:disabled': false,
        'ui:grid': {
          xs: 4,
        },
        'ui:mandatory': false,
        'ui:grid-area': 'drugAllergiesContraindications',
      },

      smoking: {
        'ui:widget': GridSelectInputField,
        'ui:disabled': false,
        'ui:grid': {
          xs: 4,
        },
        'ui:mandatory': false,
        'ui:grid-area': 'drugAllergiesContraindications',
      },

      alcohol: {
        'ui:widget': GridSelectInputField,
        'ui:disabled': false,
        'ui:grid': {
          xs: 4,
        },
        'ui:mandatory': false,
        'ui:grid-area': 'drugAllergiesContraindications',
      },

      sleep: {
        'ui:widget': GridInputField,
        'ui:disabled': false,
        'ui:grid': {
          xs: 4,
        },
        'ui:mandatory': false,
        'ui:grid-area': 'drugAllergiesContraindications',
      },

      physicalActivity: {
        'ui:widget': GridInputField,
        'ui:disabled': false,
        'ui:grid': {
          xs: 4,
        },
        'ui:mandatory': false,
        'ui:grid-area': 'drugAllergiesContraindications',
      },
    },
  };

  const transformErrors = (errors: any[]) => {
    return errors.map((error) => {
      const path = error.property.split('.');
      const fieldName = path[path?.length - 1] || '';

      const formatFieldName: string = fieldName.replace(/([A-Z])/g, ' $1').replace(/^./, (str: string) => str.toUpperCase());

      if (error.name === 'type') {
        error.message = `${formatFieldName} cannot be empty`;
      }

      if (error.name === 'pattern') {
        error.message = `Please enter valid ${formatFieldName}`;
      }

      if (error.name === 'minLength') {
        error.message = `${formatFieldName} should be minimum ${error?.params?.limit} char`;
      }

      if (error.name === 'maxLength') {
        error.message = `${formatFieldName} should not exceed ${error?.params?.limit} char`;
      }

      if (error.name === 'maximum') {
        error.message = `${formatFieldName} should be less or equal to ${error?.params?.limit}`;
      }

      if (error.name === 'minimum') {
        error.message = `${formatFieldName} should be  greater or equal to ${error?.params?.limit}`;
      }

      if (error.name === 'required') {
        error.message = `Other ${formatFieldName} is required`;
      }

      return error;
    });
  };

  const customValidate = (formData: EditOtherMedicalHistoryFormDataPayloadType, errors: any) => {
    return errors;
  };

  const handleClickUpdatePatientMedicalHistory = async (formData: any) => {
    const medicalHistory: PatientMedicalHistoryPayloadType = props.patientInfo?.medicalHistory || ({} as PatientMedicalHistoryPayloadType);

    const otherHistoryLifeStyle: OtherHistoryLifeStyleType = {
      ...medicalHistory.otherHistoryLifeStyle,
      ...formData.otherHistoryLifeStyle,
    };

    if (otherHistoryLifeStyle && otherHistoryLifeStyle.otherIllnesses && otherHistoryLifeStyle.otherIllnesses !== medicalHistory?.otherHistoryLifeStyle?.otherIllnesses) {
      otherHistoryLifeStyle.otherIllnessesHistory = (medicalHistory?.otherHistoryLifeStyle?.otherIllnessesHistory || []).concat([
        { otherIllnesses: otherHistoryLifeStyle?.otherIllnesses, date: new Date().toISOString() },
      ]);
    }

    if (otherHistoryLifeStyle && otherHistoryLifeStyle.familyHistory && otherHistoryLifeStyle.familyHistory !== medicalHistory?.otherHistoryLifeStyle?.familyHistory) {
      otherHistoryLifeStyle.familyHistoryHistory = (medicalHistory?.otherHistoryLifeStyle?.familyHistoryHistory || []).concat([
        { familyHistory: otherHistoryLifeStyle?.familyHistory, date: new Date().toISOString() },
      ]);
    }

    if (otherHistoryLifeStyle && otherHistoryLifeStyle.foodAllergies && otherHistoryLifeStyle.foodAllergies !== medicalHistory?.otherHistoryLifeStyle?.foodAllergies) {
      otherHistoryLifeStyle.foodAllergiesHistory = (medicalHistory?.otherHistoryLifeStyle?.foodAllergiesHistory || []).concat([
        { foodAllergies: otherHistoryLifeStyle?.foodAllergies, date: new Date().toISOString() },
      ]);
    }

    if (
      otherHistoryLifeStyle &&
      otherHistoryLifeStyle.drugAllergiesContraindications &&
      otherHistoryLifeStyle.drugAllergiesContraindications !== medicalHistory?.otherHistoryLifeStyle?.drugAllergiesContraindications
    ) {
      otherHistoryLifeStyle.drugAllergiesContraindicationsHistory = (medicalHistory?.otherHistoryLifeStyle?.drugAllergiesContraindicationsHistory || []).concat([
        { drugAllergiesContraindications: otherHistoryLifeStyle?.drugAllergiesContraindications, date: new Date().toISOString() },
      ]);
    }

    if (otherHistoryLifeStyle && otherHistoryLifeStyle.diet && otherHistoryLifeStyle.diet !== medicalHistory?.otherHistoryLifeStyle?.diet) {
      otherHistoryLifeStyle.dietHistory = (medicalHistory?.otherHistoryLifeStyle?.dietHistory || []).concat([{ diet: otherHistoryLifeStyle?.diet, date: new Date().toISOString() }]);
    }

    if (otherHistoryLifeStyle && otherHistoryLifeStyle.smoking && otherHistoryLifeStyle.smoking !== medicalHistory?.otherHistoryLifeStyle?.smoking) {
      otherHistoryLifeStyle.smokingHistory = (medicalHistory?.otherHistoryLifeStyle?.smokingHistory || []).concat([{ smoking: otherHistoryLifeStyle?.smoking, date: new Date().toISOString() }]);
    }

    if (otherHistoryLifeStyle && otherHistoryLifeStyle.alcohol && otherHistoryLifeStyle.alcohol !== medicalHistory?.otherHistoryLifeStyle?.alcohol) {
      otherHistoryLifeStyle.alcoholHistory = (medicalHistory?.otherHistoryLifeStyle?.alcoholHistory || []).concat([{ alcohol: otherHistoryLifeStyle?.alcohol, date: new Date().toISOString() }]);
    }

    if (otherHistoryLifeStyle && otherHistoryLifeStyle.sleep && otherHistoryLifeStyle.sleep !== medicalHistory?.otherHistoryLifeStyle?.sleep) {
      otherHistoryLifeStyle.sleepHistory = (medicalHistory?.otherHistoryLifeStyle?.sleepHistory || []).concat([{ sleep: otherHistoryLifeStyle?.sleep, date: new Date().toISOString() }]);
    }

    if (otherHistoryLifeStyle && otherHistoryLifeStyle.physicalActivity && otherHistoryLifeStyle.physicalActivity !== medicalHistory?.otherHistoryLifeStyle?.physicalActivity) {
      otherHistoryLifeStyle.physicalActivityHistory = (medicalHistory?.otherHistoryLifeStyle?.physicalActivityHistory || []).concat([
        { physicalActivity: otherHistoryLifeStyle?.physicalActivity, date: new Date().toISOString() },
      ]);
    }

    const payload: Partial<PatientMedicalHistoryPayloadType> = {
      otherHistoryLifeStyle,
    };

    props.handleSubmit(payload);
  };

  return (
    <Dialog onClose={props.handleClose} open={props.isOpen} maxWidth='lg' fullWidth>
      <DialogTitle sx={{ display: 'flex' }}>
        <Typography color='primary' variant='fontSemiBold18' sx={{ flex: 1 }}>
          Edit Other History and Life Style
        </Typography>

        <IconButton onClick={props.handleClose}>
          <CloseIcon />
        </IconButton>
      </DialogTitle>

      <Box id='medical-history-edit-profile' sx={{ mt: 4, padding: '10px', minWidth: '500px', 'div:has(> button[type=submit])': { textAlign: 'right' }, '.MuiGrid-root': { pt: '0px !important' } }}>
        <Form
          liveValidate
          formData={formData}
          onError={console.error}
          validator={validator}
          onSubmit={(formData) => handleClickUpdatePatientMedicalHistory(formData.formData)}
          templates={{ TitleFieldTemplate, FieldErrorTemplate }}
          uiSchema={uiSchema}
          schema={schema}
          showErrorList={false}
          disabled={props.isUpdatingAPICalling || deactiveStatus}
          transformErrors={transformErrors}
          customValidate={customValidate}
        />
      </Box>
    </Dialog>
  );
};

export default EditOtherMedicalHistory;
