/** @format */

import React, { FC } from 'react';

import { Divider, Grid, IconButton, Menu, Switch, Typography, useTheme } from '@mui/material';
// import MoreVertIcon from '@mui/icons-material/MoreVert';
import SettingsIcon from '@mui/icons-material/Settings';

interface ShowGraphMenuProps {
  initalState: {};
  toggleGraphs: {
    sbp: boolean;
    dbp: boolean;
    // hr: boolean;
    hrApple: boolean;
    hrOmron: boolean;
    weight: boolean;
    spo2: boolean;
    steps: boolean;
    medicalEvents: boolean;
    // labVitals: boolean;
  };
  // setToggleGraphs?: React.Dispatch<
  //   React.SetStateAction<{
  //     sbp: boolean;
  //     dbp: boolean;
  //     // hr: boolean;
  //     hrApple: boolean;
  //     hrOmron: boolean;
  //     weight: boolean;
  //     spo2: boolean;
  //     steps: boolean;
  //     medicalEvents: boolean;
  //     // labVitals: boolean;
  //   }>
  // >;

  handleChangeToggleGraphs: (payload: {
    sbp: boolean;
    dbp: boolean;
    // hr: boolean;
    hrApple: boolean;
    hrOmron: boolean;
    weight: boolean;
    spo2: boolean;
    steps: boolean;
    medicalEvents: boolean;
    // labVitals: boolean;
  }) => void;

  toggleMedianLine: boolean;
  handleToggleMedianLine: (a: boolean) => void;
}

const ShowGraphsMenu: FC<ShowGraphMenuProps> = (props) => {
  const { initalState, toggleGraphs, handleChangeToggleGraphs } = props;

  const appTheme = useTheme();

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const graphNamesText = ['Medical Event', 'SBP', 'DBP', 'Pulse Rate Watch', 'Pulse Rate OMRON', 'Weight', 'SPO2', 'STEPS'];

  const graphNamesMap: { [m: string]: string } = {
    SBP: 'sbp',
    DBP: 'dbp',
    // HR: 'hr',
    'Pulse Rate Watch': 'hrApple',
    'Pulse Rate OMRON': 'hrOmron',
    Weight: 'weight',
    SPO2: 'spo2',
    STEPS: 'steps',
    'Medical Event': 'medicalEvents',
    // 'Lab Vitals': 'labVitals',
  };

  return (
    <>
      {/* <IconButton aria-label='more' className='vital-history-header-button-icon3' onClick={handleClick}>
        <SettingsIcon sx={{ mr: '18px' }} />
      </IconButton> */}

      <IconButton
        disableRipple
        disableTouchRipple
        sx={{ '&:hover': { backgroundColor: 'inherit', cursor: 'pointer' }, border: '0.6px solid #b1b4ce', borderRadius: '5px', padding: '5px 10px' }}
        onClick={handleClick}>
        <SettingsIcon />
      </IconButton>

      <Menu
        id='basic-menu'
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        // disableScrollLock={true}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}>
        <Grid container direction={'column'}>
          <Grid item p={1}>
            <Typography variant='fontSemiBold18' color={appTheme.palette.customColor.primary}>
              Chart Settings
            </Typography>
          </Grid>

          <Divider />

          <Grid item p={1}>
            <Grid item xs={12} container alignItems='center'>
              <Grid item xs={9}>
                <Typography>Median</Typography>
              </Grid>
              <Grid item xs={3}>
                <Switch defaultChecked={props.toggleMedianLine} onChange={() => props.handleToggleMedianLine(!props.toggleMedianLine)}></Switch>
              </Grid>
            </Grid>
          </Grid>

          <Divider />

          <Grid item p={1}>
            {graphNamesText.map((ele: any, index: number) => (
              <Grid key={index} item xs={12} container alignItems='center'>
                <Grid item xs={9}>
                  <Typography sx={{ flex: 1, marginRight: '10px' }}>{ele.toUpperCase()}</Typography>
                </Grid>
                <Grid item xs={3}>
                  {/* {toggleGraphs[graphNamesMap[ele] as keyof typeof initalState] ? <DoneIcon /> : 'X'} */}

                  <Switch
                    checked={toggleGraphs[graphNamesMap[ele] as keyof typeof initalState]}
                    defaultChecked={toggleGraphs[graphNamesMap[ele] as keyof typeof initalState]}
                    onClick={() => handleChangeToggleGraphs({ ...toggleGraphs, [graphNamesMap[ele]]: !toggleGraphs[graphNamesMap[ele] as keyof typeof initalState] })}></Switch>
                </Grid>
              </Grid>
            ))}
          </Grid>
        </Grid>
      </Menu>
    </>
  );
};

export default ShowGraphsMenu;
