/** @format */

import * as React from 'react';
import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';

// Css
import './CustomTabs.scss';

interface TabPanelProps {
  className: string;
  children?: React.ReactNode;
  index: number;
  value: number;
}

function TabPanel (props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div role='tabpanel' hidden={value !== index} id={`simple-tabpanel-${index}`} aria-labelledby={`simple-tab-${index}`} {...other}>
      {value === index && children}
    </div>
  );
}

function a11yProps (index: number) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

export type CustomTabsPanelType = {
  label: string;
  value?: string;
  panelComponent: JSX.Element;
  hideTab?: boolean;
};

interface CustomTabsPanelProps {
  tabs: CustomTabsPanelType[];

  setValue?: (value: number) => void;
}

const CustomTabsPanel: React.FunctionComponent<CustomTabsPanelProps> = (props) => {
  const [value, setValue] = React.useState(0);

  React.useEffect(() => {
    if (value > props.tabs.length - 1) {
      setValue(0);
    }
  }, [props.tabs, value]);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
    if (props.setValue) {
      props.setValue(newValue);
    }
  };

  const _renderTabs = (tabItem: CustomTabsPanelType, index: number) => {
    if (tabItem.hideTab) {
      return null;
    }

    return <Tab className={`tab-label ${index !== value && 'tab-inactive'}`} key={index} label={tabItem.label} {...a11yProps(index)} />;
  };

  const _renderTabPanel = (tabItem: CustomTabsPanelType, index: number) => {
    if (tabItem.hideTab) {
      return null;
    }

    return (
      <TabPanel value={value} index={index} key={index} className='tabpanel'>
        {tabItem.panelComponent}
      </TabPanel>
    );
  };

  return (
    <Box className='custom-tabs' sx={{ width: '100%', mt: '17px' }} component='div'>
      <Tabs
        value={value}
        onChange={handleChange}
        aria-label='basic tabs example'
        classes={{
          root: 'tabs-root',
          indicator: 'tab-indicator',
          scroller: 'tab-scroller',
        }}
        variant='scrollable'
        scrollButtons='auto'>
        {props.tabs.map(_renderTabs)}
      </Tabs>
      {props.tabs.map(_renderTabPanel)}
    </Box>
  );
};

export default CustomTabsPanel;
