/** @format */

import * as React from 'react';
import { DateTime } from 'luxon';

import Typography from '@mui/material/Typography';

import { PatientPrescriptionReviewListType } from '../../types/PrescriptionsGenerator.types';

interface PatientReviewStatusCellProps {
  rowData: PatientPrescriptionReviewListType;
}

const PatientReviewStatusCell: React.FunctionComponent<PatientReviewStatusCellProps> = (props) => {
  const rowData = props.rowData;
  let status = 'Upcoming';
  let statusColor = '#33425B';

  if (rowData?.status === 'APPROVED') {
    status = 'Complete';
  } else if (rowData?.status === 'SUBMITTED_APPROVAL') {
    status = 'Pending';
    statusColor = '#9B9A9A';
  } else if (rowData?.status === 'DRAFTED') {
    status = 'In Progress';
  } else if (rowData?.status === 'REJECTED') {
    status = 'Re-Opened';
  } else if (rowData?.status === 'UPCOMING' && DateTime.fromJSDate(new Date(rowData.timestamp)).startOf('day').toMillis() < DateTime.local().startOf('day').toMillis()) {
    status = 'Overdue';
    statusColor = '#D8930E';
  }

  return (
    <Typography variant='fontSemiBold14' color={statusColor} sx={{ textTransform: 'capitalize' }}>
      {status}
    </Typography>
  );
};

export default PatientReviewStatusCell;
