/** @format */

export const PATIENT_ENROLLMENT_TABS = [
  {
    id: 'personal-detail',
    label: 'Personal Detail',
    value: 'PERSONAL_DETAIL',
  },
  {
    id: 'hf-medical-history',
    label: 'HF Medical History',
    value: 'HF_MEDICAL_HISTORY',
  },
  {
    id: 'care-plan',
    label: 'Care Plan',
    value: 'CARE_PLAN',
  },
];

export const HEADER_OFFSET = 200;
