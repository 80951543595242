/** @format */

import AxiosInstance from './index';
import CONFIG from '../config/config';

export const addPatientAppointment = async (sourceId: string, appointmentPayload: any) => {
  const res = await AxiosInstance({
    method: 'POST',
    baseURL: CONFIG.careWebAppApiUrl,
    url: `appointments/patients/${sourceId}`,
    data: {
      ...appointmentPayload,
    },
  });

  return res.data || {};
};

export const updatePatientAppointment = async (patientSourceId: string, appointmentId: string, appointmentPayload: any) => {
  const res = await AxiosInstance({
    method: 'PUT',
    baseURL: CONFIG.careWebAppApiUrl,
    url: `appointments/${appointmentId}/patients/${patientSourceId}`,
    data: {
      ...appointmentPayload,
    },
  });

  return res.data || {};
};

export const updatePatinetAppointmentStatus = async (sourceId: string, appointmentId: string, appointmentStatus: string) => {
  const res = await AxiosInstance({
    method: 'PUT',
    baseURL: CONFIG.careWebAppApiUrl,
    url: `appointments/${appointmentId}/patients/${sourceId}`,
    data: {
      status: appointmentStatus,
    },
  });

  return res.data || {};
};

export const deletePatientAppointment = async (sourceId: string, appointmentId: string) => {
  const res = await AxiosInstance({
    method: 'DELETE',
    baseURL: CONFIG.careWebAppApiUrl,
    url: `appointments/${appointmentId}/patients/${sourceId}/`,
  });

  return res.data || {};
};

export const getAllAppointments = async (patientSourceId?: string, startDate?: string, endDate?: string, limit?: number, offset?: number, cardiologistId?: string, status?: string) => {
  const res = await AxiosInstance({
    method: 'GET',
    baseURL: CONFIG.careWebAppApiUrl,
    url: 'appointments',
    params: {
      startDate,
      endDate,
      limit,
      offset,
      patientSourceId: patientSourceId?.length !== 0 ? patientSourceId : undefined,
      status,
      cardiologistId,
    },
  });

  return res.data || {};
};
