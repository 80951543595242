/** @format */

import AxiosInstance from './index';

import { PatientCareGiverStatusTypeDto, PatientCareGiverType } from '../types/PatientCareGiver';

import CONFIG from '../config/config';

export const fetchPatientTreatmentDevicesPreferences = async (sourceId: string) => {
  const res = await AxiosInstance({
    method: 'GET',
    baseURL: CONFIG.patientApiUrl,
    url: `patients/${sourceId}/preference/TREATMENT_DEVICE_PREFERENCES`,
  });
  return res.data || {};
};

export const createPatientTreatmentDevicesPreferences = async (sourceId: string, preferences: any) => {
  const res = await AxiosInstance({
    method: 'POST',
    baseURL: CONFIG.patientApiUrl,
    url: `patients/${sourceId}/preference/TREATMENT_DEVICE_PREFERENCES`,
    data: { preferences },
  });
  return res.data || {};
};

export const updatePatientTreatmentDevicesPreferences = async (sourceId: string, preferences: any) => {
  const res = await AxiosInstance({
    method: 'PATCH',
    baseURL: CONFIG.patientApiUrl,
    url: `patients/${sourceId}/preference/TREATMENT_DEVICE_PREFERENCES`,
    data: { preferences },
  });
  return res.data || {};
};

export const generatePatientAppLoginResetAppCredsLink = async (patientSourceId: string) => {
  const res = await AxiosInstance({
    method: 'POST',
    baseURL: CONFIG.patientApiUrl,
    url: `patients/${patientSourceId}/reset/app/credentials`,
  });

  return res.data || '';
};

export const resetpasswordThroughCurentPassword = async (userId: string, data: any) => {
  const res = await AxiosInstance({
    method: 'PATCH',
    baseURL: CONFIG.saasAdminUrl,
    url: `organizations/users/${userId}/reset/password`,
    data,
  });

  return res.data || {};
};

export const resetPin = async (userId: string, pin: any) => {
  const res = await AxiosInstance({
    method: 'PATCH',
    baseURL: CONFIG.saasAdminUrl,
    url: `organizations/users/${userId}/pin/reset`,
    data: { ...pin },
  });
  return res.data || {};
};

export const udpatePatientAppCredsByResetToken = async (resetToken: string, password: string) => {
  const res = await AxiosInstance({
    method: 'PATCH',
    baseURL: CONFIG.patientApiUrl,
    url: `patients/reset/${resetToken}/app/credentials`,
    data: {
      password,
    },
  });

  return res.data || '';
};

export const verifyPatientResetAppCredLink = async (resetToken: string) => {
  const res = await AxiosInstance({
    baseURL: CONFIG.patientApiUrl,
    url: `patients/reset/${resetToken}/verify`,
  });

  return res.data || '';
};

export const getPatientAppCreds = async (patientSourceId: string) => {
  const res = await AxiosInstance({
    baseURL: CONFIG.patientApiUrl,
    url: `patients/${patientSourceId}/app/credentials`,
  });

  return res.data || '';
};

/*

POST 'https://patients-dev.tricare.tricogdev.net/api/patients/15f7ea31-652b-41fb-919d-e2986f26c59b/tasks/care-team?date=2022-09-19'

*/
export const createTasksCareTeamApi = async (sourceId: string, date?: string) => {
  const res = await AxiosInstance({
    method: 'POST',
    baseURL: CONFIG.patientApiUrl,
    url: `patients/${sourceId}/tasks/care-team`,
    params: {
      date,
    },
  });

  return res.data || [];
};

/*

  POST 'https://patients-dev.tricare.tricogdev.net/api/patients/74dfab8a-8e19-48f3-9a46-1086ad50d1c7/tasks/medication-change?lastTitrationDate=2022-09-18&currentDate=2022-09-25'

*/
export const createTasksMedicationChangeApi = async (sourceId: string, lastTitrationDate: string, currentDate: string) => {
  const res = await AxiosInstance({
    method: 'POST',
    baseURL: CONFIG.patientApiUrl,
    url: `patients/${sourceId}/tasks/medication-change?lastTitrationDate=${lastTitrationDate}&currentDate=${currentDate}`,
  });

  return res.data || [];
};

/*

  POST 'https://patients-dev.tricare.tricogdev.net/api/patients/74dfab8a-8e19-48f3-9a46-1086ad50d1c7/tasks/daily?date=2022-09-20'

*/
export const createPatientDailyTasksApi = async (sourceId: string, date: string) => {
  const res = await AxiosInstance({
    method: 'POST',
    baseURL: CONFIG.patientApiUrl,
    url: `patients/${sourceId}/tasks/patients-daily?date=${date}`,
  });

  return res.data || [];
};

export const uplaodPatientFile = async (sourceId: string, formData: any) => {
  const res = await AxiosInstance({
    method: 'POST',
    baseURL: CONFIG.careWebAppApiUrl,
    url: `patients/${sourceId}/history`,
    data: formData,
  });

  return res.data || [];
};

export const fetchPatientOmronDetails = async (sourceId: string) => {
  const res = await AxiosInstance({
    method: 'GET',
    baseURL: CONFIG.patientApiUrl,
    url: `patients/${sourceId}/omron`,
  });
  return res.data || {};
};

export const updatePatientOmronDetails = async (sourceId: string, data: any) => {
  const res = await AxiosInstance({
    method: 'POST',
    baseURL: CONFIG.patientApiUrl,
    url: `patients/${sourceId}/omron`,
    data,
  });
  return res.data || {};
};

export const deletePatientOmronDetails = async (sourceId: string) => {
  const res = await AxiosInstance({
    method: 'DELETE',
    baseURL: CONFIG.patientApiUrl,
    url: `patients/${sourceId}/omron`,
  });
  return res.data || {};
};

export const updatePatientSubscription = async (sourceId: string, data: any) => {
  const res = await AxiosInstance({
    method: 'PATCH',
    baseURL: CONFIG.patientApiUrl,
    url: `patients/${sourceId}/preference/subscription`,
    data,
  });
  return res.data || {};
};

export const getPatientCareGiverDetails = async (patientSourceId: string, queryParams: any = {}) => {
  const res = await AxiosInstance({
    baseURL: CONFIG.patientApiUrl,
    url: `patients/${patientSourceId}/caregiver`,
    params: queryParams,
  });
  return res.data || [];
};

export const createNewPatientCareGiverDetails = async (patientSourceId: string, data: Partial<PatientCareGiverType>) => {
  const res = await AxiosInstance({
    method: 'POST',
    baseURL: CONFIG.patientApiUrl,
    url: `patients/${patientSourceId}/caregiver`,
    data,
  });
  return res.data || [];
};

export const updatePatientCareGiverDetails = async (patientSourceId: string, careGiverId: string, data: Partial<PatientCareGiverType>) => {
  const res = await AxiosInstance({
    method: 'PATCH',
    baseURL: CONFIG.patientApiUrl,
    url: `patients/${patientSourceId}/caregiver/${careGiverId}`,
    data,
  });
  return res.data || [];
};

export const deletePatientCareGiverDetails = async (patientSourceId: string, careGiverId: string) => {
  const res = await AxiosInstance({
    method: 'DELETE',
    baseURL: CONFIG.patientApiUrl,
    url: `patients/${patientSourceId}/caregiver/${careGiverId}`,
  });
  return res.data || [];
};

export const updatePatientCareGiverApproval = async (patientSourceId: string, careGiverId: string, status: PatientCareGiverStatusTypeDto) => {
  const res = await AxiosInstance({
    method: 'PATCH',
    baseURL: CONFIG.patientApiUrl,
    url: `patients/${patientSourceId}/caregiver/${careGiverId}/approval`,
    data: {
      status,
    },
  });
  return res.data || [];
};

export const generatePatientCareGiverLoginResetAppCredsLink = async (patientSourceId: string, careGiverId: string) => {
  const res = await AxiosInstance({
    method: 'POST',
    baseURL: CONFIG.patientApiUrl,
    url: `patients/${patientSourceId}/caregiver/${careGiverId}/reset/app/credentials`,
  });

  return res.data || '';
};
