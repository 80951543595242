/** @format */

import { createSlice, PayloadAction } from '@reduxjs/toolkit';

// Types and interfaces.
import { RootState } from '../../app/store';
import { PatientListNewType } from '../../types/PatientListPage.types';

export interface ActivePatientListState {
  limit: number;
  pageOffset: number;

  count: number;
  listData: PatientListNewType[];
}

export const activeInitialState: ActivePatientListState = {
  count: 0,
  listData: [],

  limit: 20,
  pageOffset: 0,
};

export const activePatientListReducer = createSlice({
  name: 'Active patient list reducer',
  initialState: activeInitialState,
  reducers: {
    setPageOffset: (state, { payload }: PayloadAction<number>) => {
      state.pageOffset = Number(payload);
    },

    setLimit: (state, { payload }: PayloadAction<number>) => {
      state.pageOffset = 0;
      state.limit = Number(payload);
    },

    changeDoctorFilters: (state) => {
      state.listData = [];
      state.count = 0;
      state.pageOffset = 0;
    },

    setActivePatientList: (state, { payload }: PayloadAction<{ data: PatientListNewType[]; count: number }>) => {
      state.listData = payload.data;
      state.listData.sort((a, b) => parseInt(a.basicDetails?.recordId!) - parseInt(b.basicDetails?.recordId!));
      state.count = payload.count;
    },

    updateActivePatientInfo: (state, { payload }: PayloadAction<PatientListNewType>) => {
      state.listData = state.listData.map((item) => {
        if (item?.basicDetails?.patientSourceId === payload?.basicDetails?.patientSourceId) {
          return { ...payload };
        }
        return item;
      });
    },

    updateDeactivePatient: (state, { payload }: PayloadAction<PatientListNewType>) => {
      state.listData = state.listData.filter((e) => e.basicDetails?.patientSourceId !== payload.basicDetails?.patientSourceId);
    },

    clearPatientListStore: (state) => {
      state = {
        count: 0,
        listData: [],

        limit: 20,
        pageOffset: 0,
      };
      return state;
    },
  },
});

export const {
  clearPatientListStore,

  setActivePatientList,
  updateActivePatientInfo,

  setPageOffset,
  setLimit,

  changeDoctorFilters,
  updateDeactivePatient,
} = activePatientListReducer.actions;

export const activePatientListSelector = (state: RootState) => state.activePatientList;
