/** @format */

export const DATE_FORMAT = 'yyyy-MM-dd';

export const PATIENT_BASIC_DETAILS_KEYS = [
  'patientId',
  'firstName',
  'lastName',
  'email',
  'phoneNumber',
  'ethnicity',
  'gender',
  'age',
  'address',
  'phoneNumberAlt',
  'relationshipAltContact',
  'relationshipAltContactName',
];

export const GENDER_GENDER_KEY_MAPPING: { [key: string]: string } = {
  MALE: 'Male',
  FEMALE: 'Female',
  OTHER: 'Other',
  PREFER_NOT_TO_SAY: 'Prefer not to say',
};
