/** @format */

export const convertToPascalCase = (string: string, separator = ' ', joinBy = ' ') => {
  return string
    .trim()
    .split(separator)
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
    .join(joinBy);
};

export const capitalizeFirstLetter = (string: string) => {
  const stringToConvert = string.trim();
  return stringToConvert.charAt(0).toUpperCase() + stringToConvert.slice(1);
};
