/** @format */

import _ from 'underscore';
import * as React from 'react';

import { ChartClass, DotsAndTooltipsPluginClass } from 'tricog-d3';

// Types
import { ViewBoxType, ChartDimensions, ChartMarginType, DotsAndTooltipsPluginOptionsDataType } from 'tricog-d3/dist/types/LineChart.types';

interface LineChartProps {
  id: string;
  vitalName: string;
  data: Array<any>;
  dimensions?: ChartDimensions;
  margin?: ChartMarginType;
  viewBox?: ViewBoxType;
  range: boolean;
  lowerBound: string;
  upperBound: string;
  continuousLine: boolean;
  color: string;
  toggleDots: boolean;
  toggleTooltip: boolean;
  toggleVerticalPipe: boolean;
  toggleXAxisGrid: boolean;
  toggleYAxisGrid: boolean;
  tickSize: number;
  toggleMedianLine: boolean;
  onClickSetContinuousLine: () => void;
  yAxisTicks: number;
  xAxisTicks: number;
  xAxisTickSize: number;
  yAxisTickSize: number;
  toggleSVGIcon: boolean;
  toggleSVGIconTooltip: boolean;
  timeFormat: string;
  xAxisNice: number;
  yAxisNice: number;
  xAxisPosition: string;
  rangeXAxisOffset: number;
  rangeYAxisOffset: number;
  scale?: 'SCALE_TIME' | 'SCALE_POINT';
}

const TrendLineChart: React.FunctionComponent<LineChartProps> = (props) => {
  React.useEffect(() => {
    // set the dimensions and margins of the graph
    const width = props.dimensions!.width - props.margin!.left - props.margin!.right;
    const height = props.dimensions!.height - props.margin!.top - props.margin!.bottom;

    const options = {
      yAxisRange: {
        type: props.range ? 'MANUAL' : 'AUTO',
        max: props.upperBound,
        min: props.lowerBound,
      },
      yAxisTicks: props.yAxisTicks,
      xAxisTickSize: props.xAxisTickSize,
      yAxisTickSize: props.yAxisTickSize,
      timeFormat: props.timeFormat,
      xAxisNice: props.xAxisNice,
      yAxisNice: props.yAxisNice,
      xAxisPosition: props.xAxisPosition,
      rangeXAxisOffset: props.rangeXAxisOffset,
      rangeYAxisOffset: props.rangeYAxisOffset,
      scale: props.scale,
    };

    const lineChart = new ChartClass(`#${props.id}`, width, height, props.margin!, props.viewBox!, props.data, options as any);

    // Creating Base Elements for Line Chart
    lineChart.core.deleteSvgElements();
    lineChart.core.createSvgElement();
    lineChart.core.createFocusElement({ className: 'spbYAxis', marginTop: 0 });

    // Drawing xAxis Scale
    lineChart.core.drawXAxisTimeScale();

    // Drawing yAxis Scale
    lineChart.core.drawYAxisValue();

    // Register a Plugin
    lineChart.registerPlugin(
      'yAxisDotsAndTooltipsPlugin',
      new DotsAndTooltipsPluginClass({
        rootElement: props.id,
        focusElement: lineChart.core.focusElement,
        chartData: props.data,
        xAxisScale: lineChart.core.xAxisScale as any,
        yAxisScale: lineChart.core.yAxisScale,
        fillDotCustomFunc: (ele: any) => {
          // ['CRITICAL_LOW', 'CRITICAL_HIGH', 'NORMAL', 'WARNING_LOW', 'WARNING_HIGH']
          if (ele.value) {
            if (ele?.severity === 'CRITICAL_LOW' || ele?.severity === 'CRITICAL_HIGH') return 'red';
            if (ele?.severity === 'WARNING_LOW' || ele?.severity === 'WARNING_HIGH') return 'orange';
          }

          return 'black';
        },
        tooltipCustomFunc (event: any) {
          const pageX = event.offsetX;
          const pageY = event.offsetY;

          return lineChart?.plugins?.yAxisDotsAndTooltipsPlugin?.dotTooltip
            ?.html(lineChart.plugins.yAxisDotsAndTooltipsPlugin.tooltipHTMLElement(event))
            .style('left', `${pageX + 40}px`)
            .style('top', `${pageY + 60}px`)
            .style('min-width', '100px')
            .style('background-color', 'white')
            .style('border-radius', '4px')
            .style('box-shadow', '1px 1px 10px 1px gray')
            .style('position', 'absolute');
        },
        rDotCustomFunc (d: any) {
          return _.isNaN(parseInt(d.value)) ? 0 : 4;
        },
        appendTextDot (d: any) {
          return _.isNaN(parseInt(d.value)) ? '' : d.value;
        },
      } as DotsAndTooltipsPluginOptionsDataType),
    );
    lineChart.plugins.yAxisDotsAndTooltipsPlugin.addDots();
    lineChart.plugins.yAxisDotsAndTooltipsPlugin.appendTextMinMax();
    lineChart.plugins.yAxisDotsAndTooltipsPlugin.addDotsLabels();
    lineChart.plugins.yAxisDotsAndTooltipsPlugin.addTooltip();

    lineChart.core.createClipPath();
  }, [props.continuousLine, props.data, props.id, props.margin, props.toggleMedianLine]);

  return <div id={props.id}></div>;
};

TrendLineChart.defaultProps = {
  margin: { top: 10, right: 30, bottom: 30, left: 60 },
};

export default TrendLineChart;
