/** @format */

import CONFIG from '../config/config';
import AxiosInstance from './index';

export const createPatientVitalsGraphPreference = async (sourceId: string, preferences: any) => {
  const res = await AxiosInstance({
    method: 'POST',
    baseURL: CONFIG.patientApiUrl,
    url: `patients/${sourceId}/preference/GRAPH_VITALS_HISTORY_PREFERENCES`,
    data: { ...preferences },
  });

  return res.data || {};
};

export const updatePatientVitalsGraphPreference = async (sourceId: string, preferences: any) => {
  const res = await AxiosInstance({
    method: 'PATCH',
    baseURL: CONFIG.patientApiUrl,
    url: `patients/${sourceId}/preference/GRAPH_VITALS_HISTORY_PREFERENCES`,
    data: { ...preferences },
  });

  return res.data || {};
};

export const getPatientVitalsGraphPreference = async (sourceId: string) => {
  const res = await AxiosInstance({
    method: 'GET',
    baseURL: CONFIG.patientApiUrl,
    url: `patients/${sourceId}/preference/GRAPH_VITALS_HISTORY_PREFERENCES`,
  });

  return res.data || {};
};
