/** @format */

import React, { useContext } from 'react';

import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
// Components.
import CustomLoading from '../../../components/common/custom-loading/CustomLoading';

// Types

import { PatientProfileContext } from '../../../pages/patient-profile/PatientProfileContext';
import CustomTable from '../../common/custom-table/CustomTable';
import { CustomTableColumnType } from '../../../types/CustomTable.types';
import { Typography } from '@mui/material';
import { LOGS_CATEGORY_MAPPING } from '../../../constants/logs';
import { formatDateTime } from '../../../utils/functions/convert-date';

const PatientLogs: React.FC = () => {
  const { isPatinetProfileLogsLoading, patientLogsData, handleChangeLimit, handleChangePageOffset, patientLogsFilter } = useContext(PatientProfileContext);
  if (isPatinetProfileLogsLoading) return <CustomLoading />;

  const columns: CustomTableColumnType[] = [
    {
      id: 'Category',
      label: 'Category',
      minWidth: '8rem',
      width: '22%',
      cellFormatter: (columnValue: any, index: number) => {
        return (
          <Typography key={index} variant='fontReg14' color='#33425B'>
            {LOGS_CATEGORY_MAPPING[columnValue.category]}
          </Typography>
        );
      },
    },
    {
      id: 'Date & TimeStap',
      label: 'Date & TimeStap',
      minWidth: '8rem',
      width: '22%',
      cellFormatter: (columnValue: any, index: number) => {
        const { date, time } = formatDateTime(columnValue.createdAt);
        return (
          <Typography key={index} variant='fontReg14' color='#33425B' sx={{ textTransform: 'capitalize' }}>
            {date} - {time}
          </Typography>
        );
      },
    },
    {
      id: 'Activity',
      label: 'Activity',
      minWidth: '8rem',
      width: '22%',
      cellFormatter: (columnValue: any, index: number) => {
        return (
          <Typography key={index} variant='fontReg14' color='#33425B'>
            {columnValue?.message}
          </Typography>
        );
      },
    },
  ];

  return (
    <Box sx={{ marginTop: 3, height: 'calc(100vh - 110px)', display: 'flex', flexDirection: 'column' }}>
      <Paper id='logs-table' sx={{ width: '100%', mb: 2 }}>
        <CustomTable
          tablePagination
          columns={columns}
          data={patientLogsData?.logsData || []}
          count={patientLogsData?.count || 0}
          rowsPerPage={patientLogsFilter.pageLimit}
          page={patientLogsFilter.pageOffset}
          handleChangeRowsPerPage={handleChangeLimit}
          handleChangePage={handleChangePageOffset}
        />
      </Paper>
    </Box>
  );
};

export default PatientLogs;
