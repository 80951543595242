/** @format */

import { Box, Typography, useTheme } from '@mui/material';
import { FieldErrorProps } from '@rjsf/utils';
import _ from 'underscore';

const FieldErrorTemplate = (props: FieldErrorProps) => {
  const appTheme = useTheme();
  let maxErrors = 1;

  if (props.uiSchema && props.uiSchema['ui:noErrors']) {
    return null;
  }

  if (props.uiSchema && !_.isNull(props.uiSchema['ui:maxErrors']) && !_.isUndefined(props.uiSchema['ui:maxErrors'])) {
    maxErrors = props.uiSchema['ui:maxErrors'];
  }

  let errors = props.errors;

  if (errors && errors?.length > maxErrors) {
    errors = errors?.slice(0, maxErrors);
  }

  if (maxErrors <= 0) {
    return null;
  }

  return (
    <Box id='error-field-template' sx={{ minHeight: '30px', width: 'inhert' }}>
      <Box sx={{ marginTop: '5px', marginLeft: '5px', display: 'flex', flexDirection: 'column', marginRight: '5px' }}>
        {Array.isArray(errors) &&
          errors.length > 0 &&
          errors.map((message, index: number) => {
            return (
              <Typography key={index} variant='fontSemiBold12' color={appTheme.palette.customColor.danger}>
                {message}
              </Typography>
            );
          })}
      </Box>
    </Box>
  );
};

export default FieldErrorTemplate;
