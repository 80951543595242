/** @format */

export const VITALS_HEART_RATE_READING = 'HEART_RATE';
export const VITALS_POTTASSIUM_READING = 'POTASSIUM';
export const VITALS_SCR_READING = 'SCR';
export const VITALS_EGFR_READING = 'EGFR';
export const VITALS_BLOOD_PRESSURE_READING = 'BLOOD_PRESSURE';
export const VITALS_WEIGHT_READING = 'WEIGHT';

export const VITALS_SYSTOLIC_BLOOD_PRESSURE_READING = 'SYSTOLIC';
export const VITALS_DIASTOLIC_BLOOD_PRESSURE_READING = 'DIASTOLIC';

export const MAPPING_ALGO_VITALS: { [key: string]: string } = {
  heartRate: VITALS_HEART_RATE_READING,
  systolicBloodPressure: VITALS_SYSTOLIC_BLOOD_PRESSURE_READING,
  serumPotassium: VITALS_POTTASSIUM_READING,
  serumCreatinine: VITALS_SCR_READING,
  eGFR: VITALS_EGFR_READING,
  weight: VITALS_WEIGHT_READING,
};

export const MAPPING_ALGO_FORMATTED_VITALS_TEXT: { [key: string]: string } = {
  [VITALS_HEART_RATE_READING]: 'Pulse Rate',
  [VITALS_SYSTOLIC_BLOOD_PRESSURE_READING]: 'Systolic',
  [VITALS_POTTASSIUM_READING]: 'Potassium',
  [VITALS_SCR_READING]: 'sCr',
  [VITALS_EGFR_READING]: 'eGFR',
  [VITALS_WEIGHT_READING]: 'Weight',
};
