/** @format */

import { Button, Typography } from '@mui/material';
import React from 'react';
import Grid from '@mui/material/Grid';
import WarningAmberIcon from '@mui/icons-material/WarningAmber';

// Component props.
interface PageErrorProps {
  error: any;
}

export const PageErrorBoundary: React.FunctionComponent<PageErrorProps> = ({ error }) => {
  console.error(error.message);
  return (
    <Grid container flexDirection='column' gap={5} justifyContent='center' alignItems='center' mt={5}>
      <Typography variant='h1' color='primary'>
        <WarningAmberIcon color='error' fontSize='inherit' />
      </Typography>
      <Typography variant='fontBold28' color='primary'>
        Unexpected Error
      </Typography>
      <Typography variant='fontReg16' color='primary' textAlign='center' lineHeight='unset'>
        Sorry, Something went wrong
        <br />
        Please try again after sometime
      </Typography>
      <Button variant='contained' onClick={() => window.location.reload()}>
        Refresh Page
      </Button>
    </Grid>
  );
};
