/** @format */

import * as React from 'react';
import * as _ from 'underscore';
import { DateTime } from 'luxon';
import { useSelector } from 'react-redux';
import { ErrorBoundary } from 'react-error-boundary';

import Box from '@mui/material/Box';
import Chip from '@mui/material/Chip';
import Grid from '@mui/material/Grid';
import { useTheme } from '@mui/material/styles';
import Typography from '@mui/material/Typography';

import { RootState } from '../../../app/store';

// Icons
import InfoIcon from '@mui/icons-material/Info';
import CircleIcon from '@mui/icons-material/Circle';

import CustomPopper from '../../common/custom-popper';
import { ComponentErrorBoundary } from '../../common/error/ComponentErrorBoundary';

import { PatientSymptomPayloadItemType, PatientSymptomsPayloadType } from '../../../types/PatientSymptoms.types';

interface PatientReportedSymptomsItem extends PatientSymptomPayloadItemType {
  timestamp: string;
  description?: string;
  severity?: string;
}

interface ViewPatientReportedSymptomsProps {
  id?: string;

  symptoms: PatientSymptomsPayloadType[];

  showDots?: boolean;

  title?: string;
  showTitle?: boolean;

  showDateBelowSymptoms?: boolean;
  showDateAlongWithTitle?: boolean;

  activePatientSymptoms?: string;
  handleClickShowMore?: () => void;

  alignContent?: 'start' | 'center' | 'end';
}

const ViewPatientReportedSymptoms: React.FunctionComponent<ViewPatientReportedSymptomsProps> = (props) => {
  const appTheme = useTheme();

  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);

  const symptomsMasterConfig = useSelector((state: RootState) => state.patientSymptoms);

  const { reportedSymptoms, latestSymptoms }: { reportedSymptoms: PatientReportedSymptomsItem[]; latestSymptoms?: PatientReportedSymptomsItem } = React.useMemo(() => {
    let symptoms: PatientReportedSymptomsItem[] = [];

    props.symptoms?.forEach((e: PatientSymptomsPayloadType) => {
      return e.symptoms.forEach((ele: PatientSymptomPayloadItemType) => {
        let description: string | undefined = 'NA';
        let severity = 'low';

        if (ele?.category !== 'OTHERS') {
          const symptomData = symptomsMasterConfig?.patientSymptoms?.find((dt: any) => {
            return dt?.shortCode === ele?.shortcode;
          });

          if (symptomData) {
            description = symptomData?.description!;
            severity = symptomData?.severity!;
          }
        } else if (ele?.category === 'OTHERS') {
          severity = 'high';
          description = ele?.description;
        }

        symptoms.push({
          ...ele,
          timestamp: e.timestamp,
          description,
          severity,
        });
      });
    });

    symptoms = symptoms.sort((a: any, b: any) => new Date(b.timestamp).getTime() - new Date(a.timestamp).getTime());

    let latestSymptoms: PatientReportedSymptomsItem | undefined = symptoms.find((item) => item.category !== 'NO_SYMPTOMS');

    if (_.isEmpty(latestSymptoms)) {
      latestSymptoms = symptoms[0];
    }

    return { latestSymptoms, reportedSymptoms: symptoms };
  }, [props.symptoms, symptomsMasterConfig]);

  const SEVERITY_COLOR: any = {
    high: appTheme.palette.customColor.danger,
    medium: appTheme.palette.customColor.danger,
    low: appTheme.palette.customColor.success,
  };

  const handleClickMore = (event: React.MouseEvent<HTMLButtonElement>) => {
    if (props.handleClickShowMore) {
      props.handleClickShowMore();
    }
    setAnchorEl(event.currentTarget);
  };

  const handleClosePopover = () => {
    setAnchorEl(null);
  };

  const _renderSymptomsText = (symptom: PatientReportedSymptomsItem | undefined) => {
    return (
      <Typography variant='fontReg14' color={appTheme.palette.customColor.text} component='div' sx={{ wordBreak: 'break-word', whiteSpace: 'normal' }}>
        {symptom?.description || symptom?.shortcode || 'NA'}
      </Typography>
    );
  };

  const _renderDeleteChipAction = () => {
    if (!latestSymptoms) {
      return <div />;
    }
    return <InfoIcon fontSize='small' htmlColor={appTheme.palette.customColor.secondary} />;
  };

  const latestSymptomsDate = !_.isEmpty(latestSymptoms) && DateTime.fromJSDate(new Date(latestSymptoms?.timestamp!)).toRelativeCalendar();

  return (
    <ErrorBoundary fallbackRender={ComponentErrorBoundary}>
      <Grid item xs={12} container direction='column' gap={0.3} sx={{ alignContent: props.alignContent }}>
        {props.showTitle && (
          <Typography component='div' color='#3E4685' variant='fontReg14' sx={{ pb: 1, textTransform: 'capitalize', whiteSpace: 'nowrap' }}>
            {props.title}
            {props.showDateAlongWithTitle && latestSymptomsDate && (
              <Box sx={{ pl: 1 }} component='small'>
                - ({latestSymptomsDate})
              </Box>
            )}
          </Typography>
        )}

        <Chip
          sx={{
            display: 'flex',
            width: !latestSymptoms ? 'fit-content' : '100%',
            // ml: !latestSymptoms ? 'auto' : 'unset',
            // mr: !latestSymptoms ? 'auto' : 'unset',
            background: '#ECEFF4',
            height: 'auto',
            border: '0.5px solid #C2D3EF',
            borderRadius: '5px',
            overflow: 'hidden',
            padding: '2.5px 5px',
            '& .MuiChip-deleteIcon': {
              ml: 'auto',
            },
            '& .MuiChip-label': {
              ml: !latestSymptoms ? 'auto' : 'unset',
            },
          }}
          label={
            <Typography
              className={'custom-text-wrap'}
              variant='fontReg14'
              color={appTheme.palette.customColor.text}
              display='inline-block;'
              component='div'
              sx={{
                wordBreak: 'break-word',
                whiteSpace: 'normal',
              }}>
              {_renderSymptomsText(latestSymptoms)}
            </Typography>
          }
          avatar={latestSymptoms && <CircleIcon sx={{ fill: SEVERITY_COLOR[latestSymptoms?.severity || 'low'], width: '6px !important', height: '6px !important' }} />}
          deleteIcon={_renderDeleteChipAction()}
          onDelete={handleClickMore}
        />

        {props.showDateBelowSymptoms && (
          <Typography component='div' color='#3E4685' variant='fontReg12' sx={{ pb: 1, textTransform: 'capitalize', mx: 'auto' }}>
            {latestSymptomsDate}
          </Typography>
        )}
      </Grid>

      <CustomPopper
        popperProps={{
          open: Boolean(Boolean(anchorEl) && (!props.activePatientSymptoms || (props.activePatientSymptoms && props.activePatientSymptoms === props.id))),
          anchorEl,
          placement: 'bottom-end',
          sx: { zIndex: 2 },
        }}
        paperProps={{
          elevation: 5,
          sx: {
            minWidth: '22rem',
            p: 1.5,
          },
        }}
        onClose={handleClosePopover}>
        <Typography variant='fontSemiBold14' sx={{ color: appTheme.palette.customColor.text }}>
          Symptoms Reported
        </Typography>
        <Box
          component='div'
          sx={{
            background: '#ECEFF4',
            border: '0.5px solid #C2D3EF',
            borderRadius: '5px',
            overflow: 'auto',
            maxHeight: '10rem',
            p: 1,
          }}>
          {reportedSymptoms?.map((symptom: PatientReportedSymptomsItem, index: number) => {
            const relativeDate = symptom?.timestamp && DateTime.fromJSDate(new Date(symptom.timestamp)).toFormat('dd MMM yy');
            return (
              <Grid key={index} mt={1} item xs={12} container alignItems='baseline' gap={0.5}>
                <Grid item xs='auto'>
                  <CircleIcon sx={{ fill: 'black', width: '8px !important', height: '8px !important' }} />
                </Grid>

                <Grid item xs={3}>
                  <Typography variant='fontReg14' color={appTheme.palette.customColor.text} component='div'>
                    {relativeDate}
                  </Typography>
                </Grid>

                <Grid item xs>
                  {_renderSymptomsText(symptom)}
                </Grid>
              </Grid>
            );
          })}
        </Box>
      </CustomPopper>
    </ErrorBoundary>
  );
};

export default ViewPatientReportedSymptoms;
