/** @format */

import * as React from 'react';
import { Box } from '@mui/system';
import { Accordion, AccordionDetails, AccordionSummary, Button, Typography, useTheme } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

interface CustomCardProps {
  title: string;
  children: JSX.Element;
  bgColor?: string;
  editMode?: boolean;
  handleClickEditCard?: () => void;
  approveMode?: boolean;
}

const CustomCard: React.FunctionComponent<CustomCardProps> = (props) => {
  const appTheme = useTheme();
  const [expanded, setExpanded] = React.useState<boolean>(true);
  const handleChange = (expanded: boolean) => {
    setExpanded(expanded);
  };

  const handleClickEdit = (event: MouseEvent | any) => {
    event.stopPropagation();
    if (props.handleClickEditCard) {
      props.handleClickEditCard();
    }
  };

  return (
    <Accordion
      expanded={expanded}
      onChange={() => handleChange(!expanded)}
      id={props.title}
      sx={{
        // bgcolor: props.editMode ? '#FAFBFC' : appTheme.palette.customColor.card,
        // borderColor: appTheme.palette.customColor.stroke,
        borderColor: '#D5D5D6',
        borderWidth: '0.6px',
        borderStyle: 'solid',
        borderRadius: '5px',
        boxShadow: 'none',
        // p: '10.5px 11px 20px 16px',
      }}>
      <AccordionSummary
        expandIcon={
          <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
            <ExpandMoreIcon color='primary' />
          </Box>
        }
        sx={{
          backgroundColor: '#F5F9FE',
          minHeight: '60px',
          maxHeight: '60px',
          borderRadius: '5px',
          borderBottomLeftRadius: expanded ? '0px' : '5px',
          borderBottomRightRadius: expanded ? '0px' : '5px',
        }}>
        <Box>
          <Typography variant='fontBold20' color={appTheme.palette.customColor.primary}>
            {props.title}
          </Typography>

          {props.editMode && !props.approveMode && (
            <Button variant='text' onClick={handleClickEdit} sx={{ textTransform: 'lowercase', textDecoration: 'underline', marginLeft: '10px', color: 'skyblue' }}>
              <Typography variant='fontSemiBold16'>Edit</Typography>
            </Button>
          )}
        </Box>
      </AccordionSummary>

      <AccordionDetails>
        <Box component='div' sx={{ pt: '20px', pb: '10px' }}>
          {props.children}
        </Box>
      </AccordionDetails>
    </Accordion>
  );
};

export default CustomCard;
