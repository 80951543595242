/** @format */

import { Grid, Typography, useTheme } from '@mui/material';
import { ObjectFieldTemplateProps } from 'react-jsonschema-form';

export const FlexVitalsObjectFieldTemplate = (props: ObjectFieldTemplateProps) => {
  return (
    <Grid id={props.uiSchema['ui:fields']?.id} sx={{ border: props.uiSchema['ui:withBorder'] ? '1px solid #D5D5D6' : 'none', borderRadius: '5px', padding: '25px 0 0 0' }}>
      <Typography variant='fontSemiBold14' color='info' mb='6px'>
        {props.title}
      </Typography>

      <Grid id={`grid-template-areas-${props.uiSchema['ui:fields']?.className}`} sx={{ display: 'grid', gridColumnGap: '0.625rem', gridRowGap: '0rem' }}>
        {props.properties.map((e, idx) => {
          return (
            <Grid item xs={3} sx={{ margin: '5px', gridArea: e.content?.props?.uiSchema?.['ui:grid-area'] }} {...e.content?.props?.uiSchema?.['ui:grid']} key={idx} mr={2}>
              {e.content}
            </Grid>
          );
        })}
      </Grid>
    </Grid>
  );
};

export const FlexVitalsMultiInput = (props: ObjectFieldTemplateProps) => {
  const appTheme = useTheme();

  const required = props.uiSchema['ui:required'] || false;
  const mandatory = props.uiSchema['ui:mandatory'] || false;

  return (
    <Grid sx={{ display: 'flex', alignItems: 'center', marginLeft: '5px' }}>
      <Typography variant='fontSemiBold16' color='#33425B' sx={{ marginRight: '10px', minWidth: '150px', textAlign: 'start' }}>
        {props.title} {mandatory && <span style={{ marginLeft: '3px' }}>*</span>}
        {required && <span style={{ color: 'red', marginLeft: '3px' }}>*</span>}
        {props.uiSchema['ui:unit'] && (
          <Typography variant='fontReg14' color={appTheme.palette.customColor.pageTitle} component='div' sx={{ minWidth: '150px', textAlign: 'start', marginTop: '4px' }}>
            ({props.uiSchema['ui:unit']})
          </Typography>
        )}
      </Typography>

      <Grid display='flex'>
        {props.properties.map((e, idx) => {
          return (
            <Grid item {...e.content?.props?.uiSchema?.['ui:grid']} key={idx} sx={{ '&:first-of-type': { marginRight: '10px' } }}>
              {e.content}
            </Grid>
          );
        })}
      </Grid>
    </Grid>
  );
};
