/** @format */

import * as React from 'react';
import { io } from 'socket.io-client';
import { getUserSession } from '../utils/user-session';
import config from '../config/config';

export interface useSocketProps {
  handleLabReports?: (patientSourceId: string, labReports: any[]) => void;
  handleMedicalEvents?: (patientSourceId: string, medicalEvents: any[]) => void;

  handlePatientVitals?: (patientSourceId: string, patientVitals: any[]) => void;
  handleMedicationChange?: (patientSourceId: string, prescriptions: any[]) => void;
  handlePatientSymptoms?: (patientSourceId: string, patientSymptoms: any[]) => void;
  handlePatientEcg?: (patientSourceId: string, patientEcgs: any[]) => void;

  handleLevePatientVideoCall?: (patientSourceId: string) => void;
}

const useSocket = (props: useSocketProps) => {
  const [isConnected, setIsConnected] = React.useState(false);

  const [socket, setSocket] = React.useState<any>(null);

  const handleLabReports = React.useCallback(
    (data: any) => {
      console.debug('SOCKET handleLabReports >>>', data);
      if (props.handleLabReports) {
        props.handleLabReports(data.patientSourceId, data.labReports);
      }
    },
    [props.handleLabReports],
  );

  const handleLevePatientVideoCall = React.useCallback(
    (data: any) => {
      console.debug('SOCKET handleLevePatientVideoCall >>>', data);
      if (props.handleLevePatientVideoCall) {
        props.handleLevePatientVideoCall(data.patientSourceId);
      }
    },
    [props.handleLevePatientVideoCall],
  );

  const handleMedicalEvents = React.useCallback(
    (data: any) => {
      console.debug('SOCKET handleMedicalEvents >>>', data);
      if (props.handleMedicalEvents) {
        props.handleMedicalEvents(data.patientSourceId, data.medicalEvents);
      }
    },
    [props.handleMedicalEvents],
  );

  const handlePatientVitals = React.useCallback(
    (data: any) => {
      console.debug('SOCKET handlePatientVitals >>>', data);
      if (props.handlePatientVitals) {
        props.handlePatientVitals(data.patientSourceId, data.patientVitals);
      }
    },
    [props.handlePatientVitals],
  );

  const handleMedicationChange = React.useCallback(
    (data: any) => {
      console.debug('SOCKET handleMedicationChange >>>', data);
      if (props.handleMedicationChange) {
        props.handleMedicationChange(data.patientSourceId, data.prescriptions);
      }
    },
    [props.handleMedicationChange],
  );

  const handlePatientSymptoms = React.useCallback(
    (data: any) => {
      console.debug('SOCKET handlePatientSymptoms >>>', data);
      if (props.handlePatientSymptoms) {
        props.handlePatientSymptoms(data.patientSourceId, data.patientSymptoms);
      }
    },
    [props.handlePatientSymptoms],
  );

  const handlePatientEcgs = React.useCallback(
    (data: any) => {
      console.debug('SOCKET handlePatientEcgs >>>', data);
      if (props.handlePatientEcg) {
        props.handlePatientEcg(data.patientSourceId, data.patientEcgs);
      }
    },
    [props.handlePatientEcg],
  );

  React.useEffect(() => {
    if (!isConnected) {
      return;
    }
    console.debug('JOIN ROMMENNRNRNNR');
    socket.emit('join');

    socket.on('labReports', handleLabReports);

    socket.on('medicalEvents', handleMedicalEvents);

    socket.on('patientVitals', handlePatientVitals);

    socket.on('medicationChange', handleMedicationChange);

    socket.on('patientSymptoms', handlePatientSymptoms);

    socket.on('patientEcgs', handlePatientEcgs);

    socket.on('patientVideoCallEnd', handleLevePatientVideoCall);

    return () => {
      socket.off('labReports', handleLabReports);

      socket.off('medicalEvents', handleMedicalEvents);

      socket.off('patientVitals', handlePatientVitals);

      socket.off('medicationChange', handleMedicationChange);

      socket.off('patientSymptoms', handlePatientSymptoms);

      socket.off('patientEcgs', handlePatientEcgs);

      socket.off('patientVideoCallEnd', handleLevePatientVideoCall);
    };
  }, [isConnected, socket, handleLabReports, handleMedicalEvents, handlePatientVitals, handleMedicationChange, handlePatientSymptoms, handlePatientEcgs, handleLevePatientVideoCall]);

  React.useEffect(() => {
    const onConnect = () => {
      console.debug('SOCKET CONNECTED ');
      setIsConnected(true);
    };

    const onError = (error: any) => {
      console.debug('SOCKET >>><< <<< SOCKET error', error);
    };

    const onDisconnect = () => {
      console.debug('SOCKET DS"C<DSCJDNCJKDSC SOCKET DISCONNECTED');
      setIsConnected(false);
    };

    const onConnectionFailed = (err: any) => {
      console.debug('SOCKET DS"C<DSCJDNCJKDSC CONNECTION FAILED', err);
    };

    const userSession = getUserSession();

    const socket = io(config.patientSocketApiUrlPath, {
      path: config.patientApiUrlPath + 'socket.io/',
      autoConnect: false,
      transports: ['websocket'],
      query: {
        token: userSession.sessionId,
      },
    });

    socket.on('connect', onConnect);
    socket.on('connect_failed', onConnectionFailed);
    socket.on('connect_error', onConnectionFailed);
    socket.on('error', onError);
    socket.on('disconnect', onDisconnect);

    socket.connect();

    setSocket(socket);

    return () => {
      socket.disconnect();

      socket.off('connect', onConnect);
      socket.off('disconnect', onDisconnect);
      socket.off('connect_failed', onConnectionFailed);
      socket.off('connect_error', onConnectionFailed);
      socket.off('error', onError);
    };
  }, []);

  return [isConnected];
};

export default useSocket;
