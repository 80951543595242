/** @format */

import _ from 'underscore';
import { UptitratePatientMedicaitonPayloadType } from '../types/PatientTCPMedications.types';

export const getMedicationAcceptanceTextFormat = (medication: UptitratePatientMedicaitonPayloadType, comparingText = true): string => {
  let text = '';
  if (medication) {
    text += `${medication.medicationGroup}: ${medication.medicationName} ${medication.dosage}`;

    if (medication.replaces && comparingText) {
      text += `  replaces ${medication?.replaces?.medicationName || ''} ${medication?.replaces?.dosage}`;
    }
  }
  return text;
};

export const getMedicalChangeTextFormat = (medication: UptitratePatientMedicaitonPayloadType, comparingText = true, index?: number): string => {
  let suggestionMessage = '';
  const prevMedication = medication?.replaces?.dosage;
  const currentMedication = medication?.dosage;

  if (_.isNaN(parseInt(prevMedication as string)) && prevMedication === 0) suggestionMessage = 'Initiate';
  else if (currentMedication === prevMedication || (currentMedication && _.isNaN(parseInt(prevMedication as string)))) suggestionMessage = 'Continue';
  else if (currentMedication && currentMedication > prevMedication!) suggestionMessage = 'Increase';
  else if (currentMedication && currentMedication < prevMedication!) suggestionMessage = 'Decrease';
  else if (currentMedication === 0) suggestionMessage = 'Stop';

  let text = '';
  if (medication) {
    if (currentMedication === prevMedication) {
      // prefix
      text += `<span style="margin-right: 5px;">${String.fromCharCode(97 + index!)}.</span>`;

      // Current Medication
      text += `${medication.medicationGroup}: ${medication.medicationName} ${medication.dosage || ''} mg`;

      // Suggestion Message
      if (suggestionMessage) {
        text += ` <span style='margin-left: 10px; color: black; font-style: normal; font-weight: 600; font-size: 0.75rem; line-height: 0.875rem; letter-spacing: 0.01em;'>
                  (${suggestionMessage})
                </span>`;
      }
    }

    if (currentMedication !== prevMedication) {
      // prefix
      text += `<span style="margin-right: 5px;">${String.fromCharCode(97 + index!)}.</span>`;

      // Prev Medication
      text += `${medication.medicationGroup}: ${medication.medicationName} ${medication?.replaces?.dosage || ''} mg`;

      text += `<svg style="margin-left: 20px;" color="#667185" width="24" height="24" viewBox="0 0 24 24">
                <path d="m12 4-1.41 1.41L16.17 11H4v2h12.17l-5.58 5.59L12 20l8-8z"></path>
              </svg>`;

      text += '<span style="margin-left: 20px">:</span>';

      // Current Medication
      text += `<span style="margin-left: 20px">${medication.dosage || ''} mg</span>`;

      // Suggestion Message
      if (suggestionMessage) {
        text += `<span style='margin-left: 7px; color: black; font-style: normal; font-weight: 600; font-size: 0.75rem; line-height: 0.875rem; letter-spacing: 0.01em;'>
                  (${suggestionMessage})
                </span>`;
      }
    }
  }

  return text;
};

export const getSymptomsTextFormat = (patientSymptom: any, getmasterData: any) => {
  let description = 'No Symptoms Reported';
  // let severity = 'high';
  if (!_.isEmpty(patientSymptom)) {
    description = patientSymptom.description || patientSymptom?.shortcode;
    if (patientSymptom?.category !== 'OTHERS') {
      const symptomData = getmasterData?.patientSymptoms?.find((dt: any) => {
        return dt?.shortCode === patientSymptom?.shortcode;
      });
      if (symptomData) {
        description = symptomData?.description!;
        // severity = symptomData?.severity!;
      }
    }
  }
  return description;
};
