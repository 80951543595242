/** @format */

import * as React from 'react';

import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import FormGroup from '@mui/material/FormGroup';
import Typography from '@mui/material/Typography';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import InputBase, { InputBaseProps } from '@mui/material/InputBase';

import AddIcon from '@mui/icons-material/Add';

import CustomSelect, { CustomSelectProps } from '../common/custom-select/CustomSelect';

import { PrescriptionsGeneratorMedicationsCommonItemType } from '../../types/PrescriptionsGenerator.types';

import { DIRECTIONS, MEDICATION_FREQUENCIES } from '../../constants/PrescriptionGenerator';

interface CustomTableSelectCellProps extends CustomSelectProps {
  inputMinWidth?: number;
  inputMinHeight?: number;
}

export const CustomTableSelectCell: React.FunctionComponent<CustomTableSelectCellProps> = (props) => {
  return (
    <Box
      sx={{
        width: '100%',
        '&  div[class*="-control"]': {
          width: '100%',
          minWidth: props.inputMinWidth || 50,
          border: !props.isError ? 'none' : '1px solid',
        },
        '&  div[class*="-ValueContainer"]': {
          p: '3px !important',
          minHeight: props.inputMinHeight || 49,
        },
        '& div[class*="-indicatorContainer"]': {
          padding: 0.2,
          margin: 0,
          '& svg': {
            width: '12px',
            height: '12px',
          },
        },
      }}>
      <CustomSelect {...props} value={props.value} menuPortalTarget={document.body} onChange={props.onChange} />
    </Box>
  );
};

type CustomTableInputBaseCellProps = InputBaseProps;

export const CustomTableInputBaseCell: React.FunctionComponent<CustomTableInputBaseCellProps> = (props) => {
  return (
    <InputBase
      {...props}
      sx={{
        '&.MuiInputBase-root': {
          height: '100%',
        },
        '& .MuiInputBase-input': { p: '6px', height: '100%' },

        '&.Mui-disabled': {
          '& .MuiInputBase-input': {
            cursor: 'no-drop',
          },
        },
        ...props.sx,
      }}
    />
  );
};

type CustomTableInputMultilineBaseCellProps = InputBaseProps;

export const CustomTableInputMultilineBaseCell: React.FunctionComponent<CustomTableInputMultilineBaseCellProps> = (props) => {
  return (
    <InputBase
      {...props}
      multiline
      fullWidth
      minRows={1}
      maxRows={2}
      sx={{
        '&.MuiInputBase-root': {
          p: 0.6,
        },
      }}
    />
  );
};

interface CustomAddRowComponentProps {
  node: any;
}

export const CustomAddRowComponent: React.FunctionComponent<CustomAddRowComponentProps> = (props) => {
  const [cssClass] = React.useState(props.node.rowPinned ? 'example-full-width-pinned-row' : 'example-full-width-row');

  return (
    <Box sx={{ px: 2 }} className={cssClass}>
      <Button startIcon={<AddIcon />} onClick={props.node?.data?.onClick}>
        {props.node?.data?.addBtnLabel}
      </Button>
    </Box>
  );
};

interface CustomFrequnceyTableCellProps {
  value?: string;
  onChange: (value: string) => void;

  data: PrescriptionsGeneratorMedicationsCommonItemType;

  isError?: boolean;

  isDisabled?: boolean;

  isOpenFrequencyDropdown: boolean;
  handleOpenFrequencyDropdown: () => void;
  handleCloseFrequencyDropdown: () => void;
}

export const CustomFrequnceyTableCell: React.FunctionComponent<CustomFrequnceyTableCellProps> = (props) => {
  const [frequencyDropdownOptions, setFrequencyDropdownOptions] = React.useState<{ label: string; value: string }[]>(MEDICATION_FREQUENCIES);

  const handleClickOpenOptions = () => {
    if (props.isDisabled) {
      return;
    }

    props.handleOpenFrequencyDropdown();
  };

  const handleFocusOnFirstTime = () => {
    if (props.isDisabled) {
      return;
    }
    props.handleOpenFrequencyDropdown();
  };

  const handleCloseOptions = () => {
    props.handleCloseFrequencyDropdown();
  };

  const handleChangeInput = (value: string, index: number) => {
    if (parseFloat(value) > 10) {
      return;
    }

    if (props.value) {
      values = props.value?.split(' - ');
    }

    if (index === 0) {
      const options = MEDICATION_FREQUENCIES.map((item) => ({
        label: value ? item.label?.replaceAll('1', value) : item.label,
        value: value ? item.value.replaceAll('1', value) : item.value,
      }));
      setFrequencyDropdownOptions(options);
    }

    values[index] = value;

    props.onChange(values.join(' - '));
  };

  let values: string[] = [];

  if (props.value) {
    values = props.value?.split(' - ');
  }

  return (
    <ClickAwayListener onClickAway={handleCloseOptions}>
      <FormGroup onFocus={handleFocusOnFirstTime} onDoubleClick={handleClickOpenOptions} sx={{ pl: 0.5 }}>
        <Box sx={{ '& div': { visibility: 'hidden', height: '0.5px' } }}>
          <CustomTableSelectCell
            menuPortalTarget={document.body}
            placeholder=''
            menuIsOpen={props.isOpenFrequencyDropdown}
            options={frequencyDropdownOptions}
            onChange={(value: any) => {
              props.onChange(value.value);
              handleCloseOptions();
            }}
            isDisabled={props.isDisabled}
          />
        </Box>

        <Grid item xs={12} container gap={0.2} alignItems='center' justifyContent='space-between'>
          {[1, 2, 3].map((item, index) => {
            return (
              <>
                <Grid item xs={3} sx={{ input: { py: '0px !important', px: '4px !important', height: '49px !important', textAlign: 'center' } }}>
                  <CustomTableInputBaseCell
                    key={index}
                    type='number'
                    onFocus={(event: any) => {
                      event.target.select();
                      handleFocusOnFirstTime();
                    }}
                    disabled={props.isDisabled}
                    onMouseOver={handleFocusOnFirstTime}
                    value={values[index] || ''}
                    onChange={(event) => handleChangeInput(event.target.value, index)}
                    error={props.isError}
                  />
                </Grid>
                {index !== 2 && (
                  <Grid item xs='auto'>
                    <Typography>-</Typography>
                  </Grid>
                )}
              </>
            );
          })}
        </Grid>
      </FormGroup>
    </ClickAwayListener>
  );
};

interface CustomDirectionTableCellProps {
  value?: string;
  onChange: (value: string) => void;

  data: PrescriptionsGeneratorMedicationsCommonItemType;

  isError?: boolean;
}

export const CustomDirectionTableCell: React.FunctionComponent<CustomDirectionTableCellProps> = (props) => {
  const [isOpenModal, setIsOpenModal] = React.useState<boolean>(false);

  const handleClickOpenOptions = () => {
    setIsOpenModal(true);
  };

  const handleFocusOnFirstTime = () => {
    setIsOpenModal(true);
  };

  const handleCloseOptions = () => {
    setIsOpenModal(false);
  };

  return (
    <ClickAwayListener onClickAway={handleCloseOptions}>
      <FormGroup onFocus={handleFocusOnFirstTime} onDoubleClick={handleClickOpenOptions} sx={{ pl: 0.5 }}>
        <Box sx={{ '& div': { visibility: 'hidden', height: '0.5px' } }}>
          <CustomTableSelectCell
            menuPortalTarget={document.body}
            placeholder=''
            value={{ label: props.data.directionOfUse, value: props.data.directionOfUse, _isNew_: true }}
            isCreateableSelect
            menuIsOpen={isOpenModal}
            options={DIRECTIONS}
            onChange={(value: any) => {
              props.onChange(value.value);
              handleCloseOptions();
            }}
          />
        </Box>

        <Grid item xs={12} container gap={0.2} alignItems='center' justifyContent='space-between'>
          <CustomTableInputMultilineBaseCell onChange={(event) => props.onChange(event.target.value)} value={props.value} minRows={1} maxRows={3} error={props.isError} />
        </Grid>
      </FormGroup>
    </ClickAwayListener>
  );
};
