/** @format */

import { Box, IconButton, Typography } from '@mui/material';
import React from 'react';
import { NOTE_INITIAL_STATE } from '../../../constants/notes';
import { DateTime } from 'luxon';

import { NotesDeleteIcon } from '../../../assets/NoteIcons';
import { Notes } from './Notes';
import { Descendant } from 'slate';
import { AllSavedNotesType } from '../../../types/Notes';

// Component props.
interface PatientNoteItemProps {
  handleClickShowDeleteConfirmationModal: () => void;
  note: AllSavedNotesType;
  handleClickSaveGeneralNote: (paylaod: { data: any; alignment: string }) => void;
  handleClickEditPatientNote: (payload: AllSavedNotesType) => void;
}

const PatientNoteItemComponent: React.FunctionComponent<PatientNoteItemProps> = (props) => {
  const [editorState, setEditorState] = React.useState<Descendant[]>(NOTE_INITIAL_STATE);
  const [isEdit, setIsEdit] = React.useState<boolean>(true);

  const handleClickSetEdit = () => {
    // setIsEdit(false);
    props.handleClickEditPatientNote(props.note);
  };

  const handleClickCancel = () => {
    setIsEdit(true);
  };

  return (
    <>
      <Box
        onClick={handleClickSetEdit}
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          padding: '5px',
          margin: '5px',
          // borderRadius: '10px',
          '&:last-of-type': {
            marginBottom: 0,
          },
          cursor: 'pointer',
          // borderBottom: '1px solid #b4c7d9'
          '&:hover': { backgroundColor: '#EFF1FF' },
        }}
        key={props.note?.noteId}>
        <Box sx={{ display: 'flex', alignItems: 'baseline', marginBottom: '5px' }} id='printable-div'>
          <Typography
            variant='fontReg16'
            sx={{
              marginRight: '5px',
              textTransform: 'capitalize',
              color: '#424B56',
              flex: 1,
              display: 'inline-block',
              // maxWidth: '90px',
              whiteSpace: 'nowrap',
              overflow: 'hidden !important',
              textOverflow: 'ellipsis',
            }}>
            {props.note?.createdBy.name}
          </Typography>
          <Typography variant='fontReg14' color='secondary'>
            {DateTime.fromISO(props.note?.createdAt).toFormat('hh:mm a')}
          </Typography>
        </Box>

        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <Box sx={{ flex: 1, overflowX: 'hidden' }}>
            <Notes
              readonly={isEdit}
              showToolbar={!isEdit}
              handleClickSaveNote={props.handleClickSaveGeneralNote}
              updatedNote={props.note}
              handleClickCancel={handleClickCancel}
              editorState={editorState}
              setEditorState={setEditorState}
              isGeneralNote={true}
            />
          </Box>

          <IconButton
            onClick={(e) => {
              e.stopPropagation();
              props.handleClickShowDeleteConfirmationModal();
            }}
            data-testid='delete-note'>
            <NotesDeleteIcon />
          </IconButton>
        </Box>
      </Box>
      {/* </Tooltip> */}
    </>
  );
};

export const PatientNoteItem = PatientNoteItemComponent;
