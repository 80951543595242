/** @format */

import * as Joi from 'joi';
import { DateTime } from 'luxon';
export const VITALS_TYPE_SYSTOLIC_BP = 'SYSTOLIC';
export const VITALS_TYPE_DIASTOLIC_BP = 'DIASTOLIC';
export const VITALS_TYPE_HEART_RATE = 'HEART_RATE';
export const VITALS_TYPE_BLOOD_PRESSURE = 'BLOOD_PRESSURE';
export const VITALS_TYPE_HEIGHT = 'HEIGHT';
export const VITALS_TYPE_FEET_HEIGHT = 'FEET';
export const VITALS_TYPE_INCHES_HEIGHT = 'inches';
export const VITALS_TYPE_WEIGHT = 'WEIGHT';
export const VITALS_TYPE_SCR = 'SCR';
export const VITALS_TYPE_EGFR = 'EGFR';
export const VITALS_TYPE_POTASSIUM = 'POTASSIUM';
export const VITALS_TYPE_NTPROBNP = 'NTPROBNP';
export const VITALS_TYPE_GLUCOSE = 'GLUCOSE';
export const VITALS_TYPE_CHLORIDE = 'CHLORIDE';
export const VITALS_TYPE_CALCIUM = 'CALCIUM';
export const VITALS_TYPE_BLOOD_UREA_NITROGEN = 'BLOOD_UREA_NITROGEN';
export const VITALS_TYPE_SODIUM = 'SODIUM';
export const VITALS_TYPE_BICARBONATE = 'BICARBONATE';
export const VITALS_TYPE_HEMOGLOBIN = 'HEMOGLOBIN';
export const VITALS_TYPE_HEMATOCRIT = 'HEMATOCRIT';
export const VITALS_TYPE_HBA1C = 'HBA1C';
export const VITALS_TYPE_BMI = 'BMI';
export const VITALS_TYPE_BNP = 'BNP';

const emailRegex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
const phoneNumberRegex = /^((\+\d{1,3}(-| )?\(?\d\)?(-| )?\d{1,5})|(\(?\d{2,6}\)?))(-| )?(\d{3,4})(-| )?(\d{4})(( x| ext)\d{1,5}){0,1}$/;

// const patientRuleConditionSchema = Joi.array().items(
//   Joi.object({
//     fact: Joi.string().required(),
//     operator: Joi.string().required(),
//     value: Joi.any().required(),
//   }).label('Condition'),
// );

// const patientThresholdSchema = Joi.array().items(
//   Joi.object({
//     vitalsType: Joi.string().required(),
//     warningLow: Joi.number().optional(),
//     warningHigh: Joi.number().optional(),
//     criticalLow: Joi.number().optional(),
//     criticalHigh: Joi.number().optional(),
//     notificationPreferences: Joi.object({
//       sms: Joi.boolean().optional(),
//       email: Joi.boolean().optional(),
//     })
//       .label('Threshold')
//       .optional(),
//   }),
// );

// const patientMedicationRulesSchema = Joi.array().items(
//   Joi.object({
//     suggestionType: Joi.string().valid('IMMEDIATE', 'ON_REVIEW').required(),
//     conditions: Joi.object().pattern(Joi.string(), patientRuleConditionSchema).required(),
//     ruleAction: Joi.string().required(),
//     ruleKind: Joi.string().valid('MEDICATION_CHANGE', 'ALERTS').required(),
//     notificationPreferences: Joi.object({
//       sms: Joi.boolean().required(),
//       email: Joi.boolean().required(),
//     }).required(),
//     medications: Joi.array().items(Joi.string().required()).optional(),
//     ruleId: Joi.string().optional(),
//   }).label('Medication Rules'),
// );

const MAX_SUPPORTED_VALUES: any = {
  [VITALS_TYPE_SYSTOLIC_BP]: {
    errorMessageName: 'Systolic',
    value: 'SYSTOLIC',
    range: {
      max: 240,
      min: 0,
    },
    unit: ['mmhg', 'mm/hg'],
  },
  [VITALS_TYPE_DIASTOLIC_BP]: {
    errorMessageName: 'Diastolic',
    value: 'DIASTOLIC',
    range: {
      max: 240,
      min: 0,
    },
    unit: ['mmhg', 'mm/hg'],
  },
  [VITALS_TYPE_HEART_RATE]: {
    errorMessageName: 'Pulse Rate',
    value: 'HEART_RATE',
    range: {
      max: 240,
      min: 1,
    },
    unit: ['bpm'],
  },
  [VITALS_TYPE_WEIGHT]: {
    errorMessageName: 'Weight',
    value: 'WEIGHT',
    range: {
      max: 1000,
      min: 10,
    },
    unit: ['lbs', 'kgs'],
  },
  [VITALS_TYPE_SCR]: {
    errorMessageName: 'sCr',
    value: 'SCR',
    range: {
      max: 74,
      min: 0,
    },
    unit: ['mg/dl'],
  },
  [VITALS_TYPE_EGFR]: {
    errorMessageName: 'eGFR',
    value: 'EGFR',
    range: {
      max: 400,
      min: 10,
    },
    unit: ['ml/min/1.73 m2'],
  },
  [VITALS_TYPE_POTASSIUM]: {
    errorMessageName: 'Potassium',
    value: 'POTASSIUM',
    range: {
      max: 14,
      min: 0,
    },
    unit: ['meq/l'],
  },
  [VITALS_TYPE_NTPROBNP]: {
    errorMessageName: 'NTproBNP',
    value: 'NTPROBNP',
    range: {
      max: 30000,
      min: 0,
    },
    unit: ['pg/ml'],
  },
  [VITALS_TYPE_BNP]: {
    errorMessageName: 'BNP',
    value: 'BNP',
    range: {
      max: 20000,
      min: 0,
    },
    unit: ['pg/ml'],
  },
  [VITALS_TYPE_GLUCOSE]: {
    errorMessageName: 'Glucose',
    value: 'GLUCOSE',
    range: {
      max: 500,
      min: 0,
    },
    unit: ['mg/dl'],
  },
  [VITALS_TYPE_HEMOGLOBIN]: {
    errorMessageName: 'Hemoglobin',
    value: 'HEMOGLOBIN',
    range: {
      max: 500,
      min: 0,
    },
    unit: ['mg/dl'],
  },
  [VITALS_TYPE_HEMATOCRIT]: {
    errorMessageName: 'Hematocrit',
    value: 'HEMATOCRIT',
    range: {
      max: 100,
      min: 1,
    },
    unit: ['%'],
  },
  [VITALS_TYPE_HBA1C]: {
    errorMessageName: 'HbA1c',
    value: 'HBA1C',
    range: {
      max: 500,
      min: 0,
    },
    unit: ['mmol/mol'],
  },
  [VITALS_TYPE_BMI]: {
    errorMessageName: 'BMI',
    value: 'BMI',
    range: {
      max: 500,
      min: 0,
    },
    unit: ['kg/m2'],
  },
  [VITALS_TYPE_CHLORIDE]: {
    errorMessageName: 'Chloride',
    value: 'CHLORIDE',
    range: {
      max: 150,
      min: 80,
    },
    unit: ['meq/l'],
  },
  [VITALS_TYPE_CALCIUM]: {
    errorMessageName: 'Calcium',
    value: 'CALCIUM',
    range: {
      max: 31,
      min: 0,
    },
    unit: ['mg/dl'],
  },
  [VITALS_TYPE_BLOOD_UREA_NITROGEN]: {
    errorMessageName: 'Blood Urea Nitrogen',
    value: 'BLOOD_UREA_NITROGEN',
    range: {
      max: 200,
      min: 0,
    },
    unit: ['mg/dl'],
  },
  [VITALS_TYPE_SODIUM]: {
    errorMessageName: 'Sodium',
    value: 'SODIUM',
    range: {
      max: 255,
      min: 50,
    },
    unit: ['meq/l'],
  },
  [VITALS_TYPE_BICARBONATE]: {
    errorMessageName: 'Bicarbonate',
    value: 'BICARBONATE',
    range: {
      max: 50,
      min: 0,
    },
    unit: ['meq/l'],
  },
  [VITALS_TYPE_INCHES_HEIGHT]: {
    errorMessageName: 'Inches',
    value: 'INCHES',
    range: {
      max: 12,
      min: -1,
    },
    unit: ['meq/l'],
  },
  [VITALS_TYPE_FEET_HEIGHT]: {
    errorMessageName: 'Feet',
    value: 'FEET',
    range: {
      max: 12,
      min: 0,
    },
    unit: ['meq/l'],
  },
};

export function generateSchemaForVitalsMeasurementName (vitalsType: string) {
  return Joi.string()
    .valid(MAX_SUPPORTED_VALUES[vitalsType].value)
    .required()
    .messages({
      'any.only': `Invalid the name for ${MAX_SUPPORTED_VALUES[vitalsType].errorMessageName} measurement`,
      'any.required': `Name is required ${MAX_SUPPORTED_VALUES[vitalsType].errorMessageName} measurement`,
    });
}

export const getBirtDateSchema = (errorMessageName: string) => {
  const maxDate = DateTime.local().minus({ years: 150 }).toFormat('yyyy-MM-dd');

  const minDate = DateTime.local().minus({ years: 3 }).toFormat('yyyy-MM-dd');

  return Joi.date()
    .min(maxDate)
    .max(minDate)
    .messages({
      'date.min': `${errorMessageName} should be younger than 150 years`,
      'date.max': `${errorMessageName} should be older than 3 years`,
      'date.base': `Invalid Date for ${errorMessageName}`,
      'any.required': `${errorMessageName} is required`,
    });
};

export function generateSchemaForVitalsMeasurementValue (vitalsType: string) {
  return Joi.number()
    .precision(6)
    .greater(MAX_SUPPORTED_VALUES[vitalsType].range.min)
    .less(MAX_SUPPORTED_VALUES[vitalsType].range.max)
    .messages({
      'number.max': `${MAX_SUPPORTED_VALUES[vitalsType].errorMessageName} value must be less than ${MAX_SUPPORTED_VALUES[vitalsType].range.max}`,
      'number.min': `${MAX_SUPPORTED_VALUES[vitalsType].errorMessageName} value must be greater than ${MAX_SUPPORTED_VALUES[vitalsType].range.min}`,
      'any.required': `${MAX_SUPPORTED_VALUES[vitalsType].errorMessageName} measurement required`,
    })
    .options({ convert: true });
}

export function generateSchemaForVitalsMeasurementUnit (vitalsType: string) {
  return Joi.string()
    .lowercase()
    .valid(...MAX_SUPPORTED_VALUES[vitalsType].unit)
    .messages({
      'any.only': `Only ${MAX_SUPPORTED_VALUES[vitalsType].unit.join(', ')} is supported for ${MAX_SUPPORTED_VALUES[vitalsType].errorMessageName} measurement`,
      'any.required': `Unit is required ${MAX_SUPPORTED_VALUES[vitalsType].errorMessageName} measurement `,
    })
    .required();
}

export function generateSchemaForVitals (vitalsType: string) {
  return Joi.object({
    name: generateSchemaForVitalsMeasurementName(vitalsType),
    value: generateSchemaForVitalsMeasurementValue(vitalsType),
    unit: generateSchemaForVitalsMeasurementUnit(vitalsType),
  }).messages({
    'object.base': `${MAX_SUPPORTED_VALUES[vitalsType].errorMessageName} measurement is required`,
  });
}

export const bloodPressureMeasurementSchema = Joi.array()
  .items(
    Joi.object({
      name: Joi.string()
        .valid(VITALS_TYPE_SYSTOLIC_BP, VITALS_TYPE_DIASTOLIC_BP)
        .required()
        .messages({
          'any.only': `Invalid measurment name for Blood Pressure (${VITALS_TYPE_SYSTOLIC_BP}, ${VITALS_TYPE_DIASTOLIC_BP})`,
        }),
      unit: Joi.when('name', {
        is: VITALS_TYPE_SYSTOLIC_BP,
        then: generateSchemaForVitalsMeasurementUnit(VITALS_TYPE_SYSTOLIC_BP),
        otherwise: generateSchemaForVitalsMeasurementUnit(VITALS_TYPE_DIASTOLIC_BP),
      }),
      value: Joi.when('name', {
        is: VITALS_TYPE_SYSTOLIC_BP,
        then: generateSchemaForVitalsMeasurementValue(VITALS_TYPE_SYSTOLIC_BP),
        otherwise: generateSchemaForVitalsMeasurementValue(VITALS_TYPE_DIASTOLIC_BP),
      }),
    }),
  )
  .min(2)
  .max(2)
  .messages({
    'array.base': 'Blood Pressure measurement should be an array',
    'array.min': 'Blood Pressure measurement should have 2 values',
    'array.max': 'Blood Pressure measurement should have 2 values',
  });

export const heightMeasurementSchema = Joi.array().items(Joi.object()).min(2).max(2);

export function generateSchemaForOtherVitals () {
  const otherVitals = [
    VITALS_TYPE_HEART_RATE,
    VITALS_TYPE_WEIGHT,
    VITALS_TYPE_SCR,
    VITALS_TYPE_EGFR,
    VITALS_TYPE_POTASSIUM,
    VITALS_TYPE_NTPROBNP,
    VITALS_TYPE_GLUCOSE,
    VITALS_TYPE_CHLORIDE,
    VITALS_TYPE_CALCIUM,
    VITALS_TYPE_BLOOD_UREA_NITROGEN,
    VITALS_TYPE_SODIUM,
    VITALS_TYPE_BICARBONATE,
  ];

  let schema;

  for (let i = 0; i < otherVitals.length; i++) {
    if (i !== 0) {
      schema = Joi.when('type', {
        is: otherVitals[i],
        then: Joi.array().items(generateSchemaForVitals(otherVitals[i])).min(1),
        otherwise: schema,
      });
    } else {
      schema = Joi.when('type', {
        is: otherVitals[i],
        then: Joi.array().items(generateSchemaForVitals(otherVitals[i])).min(1),
        otherwise: Joi.array().items(Joi.object()),
      });
    }
  }

  return schema;
}

export const PatientSingleVitalDeviceReadingSchema = Joi.object({
  type: Joi.string().required(),
  timestamp: Joi.date().timestamp('unix').required(),
  sourceType: Joi.string().required(),
  sourceInfo: Joi.object().allow(null).optional(),
  measurements: Joi.when('type', {
    is: Joi.string().valid(VITALS_TYPE_BLOOD_PRESSURE),
    then: bloodPressureMeasurementSchema,
    otherwise: Joi.when('type', {
      is: Joi.string().valid(VITALS_TYPE_HEIGHT),
      then: heightMeasurementSchema,
      otherwise: generateSchemaForOtherVitals(),
    }),
  }),
}).messages({
  'object.base': 'Vitals is required',
});

export const hfTypeSchema = Joi.string().valid('HFrEF', 'HFpEF', 'HFmrEF', 'HFimpEF').messages({
  'any.only': 'Only HFrEF, HFpEF, HFmrEF, HFimpEF are supported for HF-Type',
  'any.required': 'Type of HF is required',
});

export const nyhaSchema = Joi.string().valid('I', 'II', 'III', 'IV').messages({
  'any.only': 'Only I, II, III, IV are supported for NYHA Class',
  'any.required': 'NYHA Class is required',
});

export const lastHospitalizationDateSchema = Joi.date().messages({
  'date.less': 'Last hospitalization should be less than current date',
  'date.base': 'Invalid Date for Last hospitalization',
  'any.required': 'Last hospitalization is required',
});

export const DateSchema = Joi.date().less('now').messages({
  'date.less': 'Date should be less than current date',
  'date.base': 'Invalid Date for Last hospitalization',
  'any.required': 'Date is required',
});

export const getStringRangeSchema = (errorMesgName: string, min: number, max: number, hasAlphabets = false, hasNumbers = false) => {
  let JoiValidations = Joi.string();

  if (hasAlphabets && hasNumbers) {
    JoiValidations = JoiValidations.pattern(/^[a-zA-Z0-9\s]+$/);
  } else if (hasAlphabets) {
    JoiValidations = JoiValidations.pattern(/^[a-zA-Z\s]+$/);
  } else if (hasNumbers) {
    JoiValidations = JoiValidations.pattern(/^\d+$/);
  }

  JoiValidations = JoiValidations.min(min).max(max).label(errorMesgName);

  JoiValidations = JoiValidations.messages({
    'string.max': `${errorMesgName} should be maximum of ${max} characters`,
    'string.min': `${errorMesgName} should be minimiuum of ${min} characters`,
    'any.required': `${errorMesgName} is required`,
    'string.empty': `${errorMesgName} is required`,
    'string.base': `${errorMesgName} is required`,

    'string.pattern.base': `Enter ${(hasAlphabets && 'alphabetic characters') || ''} ${(hasNumbers && 'and numbers') || ''}`,
  });

  return JoiValidations;
};

export const getDateSchema = (errorMesgName: string) => {
  return Joi.date()
    .less('now')
    .messages({
      'date.less': `${errorMesgName} should be less than or equal to current date`,
      'date.base': `Invalid Date for ${errorMesgName}`,
      'any.required': `${errorMesgName} is required`,
    });
};

const lvefSchema = Joi.number()
  .precision(6)
  .greater(0)
  .less(100)
  .messages({
    'number.max': 'LVEF value must be less than 100',
    'number.min': 'LVEF value must be greater 0',
    'any.required': 'LVEF is required',
  })
  .options({ convert: true });

// const durationSchema = Joi.number()
//   .precision(6)
//   .greater(1)
//   .less(24)
//   .messages({
//     'number.max': 'Duration value must be less than 100',
//     'number.min': 'Duration value must be greater 0',
//     'any.required': 'Duration is required',
//   })
//   .options({ convert: true });

export const SymptomsSchema = Joi.array()
  .items(
    Joi.object({
      category: Joi.string().required().error(new Error('Category cannot be empty')),
      shortcode: Joi.when('category', {
        is: Joi.string().valid('OTHERS'),
        then: Joi.string().optional(),
        otherwise: Joi.string().required(),
      }),
      description: Joi.when('category', {
        is: Joi.string().valid('OTHERS'),
        then: getStringRangeSchema('Descriptions', 3, 100).required(),
        otherwise: Joi.string().optional(),
      }),
    }),
  )
  .messages({
    'any.required': 'Symptoms is required',
    'array.min': 'Alteast one symptoms is required',
  });

export const getStringArraySchema = (errorMesgName: string, mode: 'EDIT' | 'DRAFT') => {
  const schmea = Joi.array()
    .items(Joi.string())

    .messages({
      'any.required': `${errorMesgName} is required`,
      'array.min': `Alteast one ${errorMesgName} is require`,
    });

  if (mode.includes('EDIT') || mode.includes('DRAFT')) {
    schmea.allow({}, null, undefined);
  }
  return schmea;
};

export const getAgeSchema = (errorMessageName: string, max: number, min: number) => {
  return Joi.number()
    .min(min)
    .max(max)
    .messages({
      'string.max': `${errorMessageName} should be less than equal to ${max} years`,
      'string.min': `${errorMessageName} should be more than equal to ${min} years`,
      'any.required': `${errorMessageName} is required`,
      'string.empty': `${errorMessageName} is required`,
      'string.base': `${errorMessageName} is required`,
    });
};

export const patientSaveSchema = (mode: 'EDIT' | 'DRAFT' | 'APPROVE', payload: any, isMedicationAdded = false): Joi.Schema => {
  let customHfTypeSchema = hfTypeSchema;
  let customNyhaSchema = nyhaSchema;

  if (mode) {
    customHfTypeSchema = customHfTypeSchema.allow(null, '');
    customNyhaSchema = customNyhaSchema.allow(null, '');
  }

  return Joi.object({
    physician: Joi.object({
      assignPhysician: Joi.string()
        .allow('')
        .label('Physician')
        .options({ presence: mode === 'EDIT' ? 'optional' : 'required' }),
    }).required(),

    patientBasic: Joi.object({
      patientId: getStringRangeSchema('Patient ID', 3, 20, true, true)
        .label('Patient ID')
        .options({ presence: mode === 'EDIT' ? 'optional' : 'required' }),

      firstName: getStringRangeSchema('Given Name / First Name', 3, 15, true)
        .label('Given Name / First Name')
        .options({
          presence: mode === 'EDIT' || !payload.patientBasic?.firstName ? 'optional' : 'required',
        }),

      lastName: getStringRangeSchema('Surname / Last Name', 3, 15, true)
        .label('Surname / Last Name')
        .options({
          presence: mode === 'EDIT' || !payload.patientBasic?.lastName ? 'optional' : 'required',
        }),

      gender: getStringRangeSchema('Gender', 2, 20)
        .label('Gender')
        .options({ presence: mode === 'EDIT' || !payload.patientBasic?.gender ? 'optional' : 'required' }),

      ethnicity: getStringRangeSchema('Ethnicity', 2, 20)
        .label('Ethnicity')
        .options({ presence: mode === 'EDIT' || !payload.patientBasic?.ethnicity ? 'optional' : 'required' }),

      address: getStringRangeSchema('Address', 3, 500)
        .label('Address')
        .options({ presence: mode === 'EDIT' || !payload.patientBasic?.address ? 'optional' : 'required' }),

      phoneNumber: Joi.string()
        .pattern(phoneNumberRegex)
        .options({ presence: mode === 'EDIT' || !payload.patientBasic?.phoneNumber ? 'optional' : 'required' })
        .messages({
          'string.pattern.base': 'Invalid Phone Number',
          'any.required': 'Phone Number is required',
          'string.empty': 'Phone Number is required',
        }),

      phoneNumberAlt: Joi.string().pattern(phoneNumberRegex).optional().messages({
        'string.pattern.base': 'Invalid ALT Phone Number',
        'any.required': 'Alt Phone Number is required',
        'string.empty': 'ALT Phone Number is required',
      }),

      email: Joi.string()
        .pattern(emailRegex)
        .max(100)
        .options({ presence: mode === 'EDIT' || !payload.patientBasic?.email ? 'optional' : 'required' })
        .messages({
          'string.max': 'Email should be less than 100 characters',
          'any.required': 'Email is required',
          'string.empty': 'Email is required',
          'string.pattern.base': 'Invalid Email',
        }),

      relationshipAltContact: Joi.string().allow(null, '').optional(),
      relationshipAltContactName: Joi.string()
        .pattern(/^[a-zA-Z\s]+$/)
        .allow(null, '')
        .max(50)
        .optional()
        .messages({
          'string.pattern.base': 'Name must only contain alphabetic characters',
        }),

      age: getAgeSchema('Age', 150, 5).label('Age'),

      enrollmentDate: Joi.date()
        .max(DateTime.now().endOf('day').toISO())
        .min(DateTime.now().minus({ month: 3 }).startOf('day').toISO())
        .messages({
          'date.min': `Date should be greater than ${DateTime.local().minus({ month: 3 }).startOf('day').toISODate()}`,
          'date.max': `Date should be less than  ${DateTime.local().startOf('day').toISODate()}`,
          'date.base': 'Invalid Date',
          'any.required': 'Date is required',
        })
        .options({ presence: mode === 'EDIT' || !payload.patientBasic?.enrollmentDate ? 'optional' : 'required' }),

      createdAt: Joi.string().optional(),

      patientStatus: Joi.string().optional(),

      patientSourceId: Joi.string().optional(),
    })
      .options({ presence: mode === 'EDIT' ? 'optional' : 'required' })
      .messages({
        'any.require': 'Heart Failure Medical History is required',
      }),

    medicalHistory: Joi.object({
      dateOfHFDiagnosis: Joi.date()
        .max(DateTime.now().endOf('day').toISO())
        .min(DateTime.now().minus({ month: 3 }).startOf('day').toISO())
        .messages({
          'date.min': `Date should be greater than ${DateTime.local().minus({ month: 3 }).startOf('day').toISODate()}`,
          'date.max': `Date should be less than  ${DateTime.local().startOf('day').toISODate()}`,
          'date.base': 'Invalid Date',
          'any.required': 'Date is required',
        })
        .label('Date of HF Diagnosis')
        .options({
          presence: mode === 'EDIT' ? 'optional' : mode === 'DRAFT' ? 'optional' : mode === 'APPROVE' ? 'required' : 'forbidden',
        }),

      hfType: customHfTypeSchema
        .allow(null, '')
        .label('HF Type')
        .options({
          presence: mode === 'EDIT' ? 'optional' : mode === 'DRAFT' ? 'optional' : mode === 'APPROVE' ? 'required' : 'forbidden',
        }),

      nyhaClass: customNyhaSchema
        .allow(null, '')
        .label('NYHA Class')
        .options({
          presence: mode === 'EDIT' ? 'optional' : mode === 'DRAFT' ? 'optional' : mode === 'APPROVE' ? 'required' : 'forbidden',
        }),

      lastHospitalizationDate: Joi.date()
        .max(DateTime.now().endOf('day').toISO())
        .messages({
          'date.max': `Date should be less than  ${DateTime.local().startOf('day').toISODate()}`,
          'date.base': 'Invalid Date',
          'any.required': 'Date is required',
        })
        .label('Last Hospitalization Date')
        .options({
          presence: mode === 'EDIT' ? 'optional' : mode === 'DRAFT' ? 'optional' : mode === 'APPROVE' ? 'required' : 'forbidden',
        }),

      lvef: lvefSchema
        .allow(null)
        .label('LVEF')
        .options({
          presence: mode === 'EDIT' ? 'optional' : mode === 'DRAFT' ? 'optional' : mode === 'APPROVE' ? 'required' : 'forbidden',
        }),

      diseasesAndSymptoms: Joi.any(),
      allergies: Joi.string().label('Allergies').allow('').optional(),
      vitalsReference: Joi.object({
        vitalsReferenceDate: Joi.date()
          .max(DateTime.now().endOf('day').toISO())
          .min(DateTime.now().minus({ month: 3 }).startOf('day').toISO())
          .messages({
            'date.min': `Date should be greater than ${DateTime.local().minus({ month: 3 }).startOf('day').toISODate()}`,
            'date.max': `Date should be less than  ${DateTime.local().startOf('day').toISODate()}`,
            'date.base': 'Invalid Date',
            'any.required': 'Date is required',
          })
          .options({ presence: mode === 'EDIT' ? 'optional' : mode === 'DRAFT' ? 'optional' : mode === 'APPROVE' ? 'required' : 'forbidden' })
          .label('Date'),
        vitals: Joi.object({
          bp: Joi.object({
            sbp: generateSchemaForVitalsMeasurementValue(VITALS_TYPE_SYSTOLIC_BP)
              .label('SBP')
              .options({
                presence: mode === 'EDIT' ? 'optional' : mode === 'DRAFT' ? 'optional' : mode === 'APPROVE' ? 'required' : 'forbidden',
              }),
            dbp: generateSchemaForVitalsMeasurementValue(VITALS_TYPE_DIASTOLIC_BP)
              .label('DBP')
              .options({
                presence: mode === 'EDIT' ? 'optional' : mode === 'DRAFT' ? 'optional' : mode === 'APPROVE' ? 'required' : 'forbidden',
              }),
          }).options({ presence: mode === 'EDIT' ? 'optional' : mode === 'DRAFT' ? 'optional' : mode === 'APPROVE' ? 'required' : 'forbidden' }),
          height: Joi.object({
            feet: generateSchemaForVitalsMeasurementValue(VITALS_TYPE_FEET_HEIGHT)
              .label('Feet')
              .options({
                presence: mode === 'EDIT' ? 'optional' : mode === 'DRAFT' ? 'optional' : mode === 'APPROVE' ? 'required' : 'forbidden',
              }),
            inches: generateSchemaForVitalsMeasurementValue(VITALS_TYPE_INCHES_HEIGHT)
              .label('Inches')
              .options({
                presence: mode === 'EDIT' ? 'optional' : mode === 'DRAFT' ? 'optional' : mode === 'APPROVE' ? 'required' : 'forbidden',
              }),
          })
            .options({
              presence: mode === 'EDIT' ? 'optional' : mode === 'DRAFT' ? 'optional' : mode === 'APPROVE' ? 'required' : 'forbidden',
            })
            .label('Height'),

          hr: generateSchemaForVitalsMeasurementValue(VITALS_TYPE_HEART_RATE)
            .label('Pulse Rate')
            .options({
              presence: mode === 'EDIT' ? 'optional' : mode === 'DRAFT' ? 'optional' : mode === 'APPROVE' ? 'required' : 'forbidden',
            }),

          weight: generateSchemaForVitalsMeasurementValue(VITALS_TYPE_WEIGHT)
            .label('Weight')
            .options({
              presence: mode === 'EDIT' ? 'optional' : mode === 'DRAFT' ? 'optional' : mode === 'APPROVE' ? 'required' : 'forbidden',
            }),

          ntprobnp: generateSchemaForVitalsMeasurementValue(VITALS_TYPE_NTPROBNP).label('NTproBNP').options({
            presence: 'optional',
          }),
          scr: generateSchemaForVitalsMeasurementValue(VITALS_TYPE_SCR).label('sCr').options({
            presence: 'optional',
          }),
          egfr: generateSchemaForVitalsMeasurementValue(VITALS_TYPE_EGFR).label('eGFR').options({
            presence: 'optional',
          }),
          potassium: generateSchemaForVitalsMeasurementValue(VITALS_TYPE_POTASSIUM).label('Potassium').options({
            presence: 'optional',
          }),
          glucose: generateSchemaForVitalsMeasurementValue(VITALS_TYPE_GLUCOSE).label('Glucose').options({
            presence: 'optional',
          }),

          chloride: generateSchemaForVitalsMeasurementValue(VITALS_TYPE_CHLORIDE).label('Chloride').options({
            presence: 'optional',
          }),
          calcium: generateSchemaForVitalsMeasurementValue(VITALS_TYPE_CALCIUM).label('Calcium').options({
            presence: 'optional',
          }),
          bloodUreaNitrogen: generateSchemaForVitalsMeasurementValue(VITALS_TYPE_BLOOD_UREA_NITROGEN).label('Blood Urea Nitrogen').options({
            presence: 'optional',
          }),

          sodium: generateSchemaForVitalsMeasurementValue(VITALS_TYPE_SODIUM).label('Sodium').options({
            presence: 'optional',
          }),
          bicarbonate: generateSchemaForVitalsMeasurementValue(VITALS_TYPE_BICARBONATE).label('Bicarbonate').options({
            presence: 'optional',
          }),

          bnp: generateSchemaForVitalsMeasurementValue(VITALS_TYPE_BNP).label('BNP').options({
            presence: 'optional',
          }),

          bmi: generateSchemaForVitalsMeasurementValue(VITALS_TYPE_BMI).label('BMI').options({
            presence: 'optional',
          }),

          hba1c: generateSchemaForVitalsMeasurementValue(VITALS_TYPE_HBA1C).label('HbA1c').options({
            presence: 'optional',
          }),

          hematocrit: generateSchemaForVitalsMeasurementValue(VITALS_TYPE_HEMATOCRIT).label('Hematocrit').options({
            presence: 'optional',
          }),

          hemoglobin: generateSchemaForVitalsMeasurementValue(VITALS_TYPE_HEMOGLOBIN).label('Hemaglobin').options({
            presence: 'optional',
          }),
        }).options({ presence: mode === 'EDIT' ? 'optional' : mode === 'DRAFT' ? 'optional' : mode === 'APPROVE' ? 'required' : 'forbidden' }),
      }).options({ presence: mode === 'EDIT' ? 'optional' : mode === 'DRAFT' ? 'optional' : mode === 'APPROVE' ? 'required' : 'forbidden' }),
    })
      .options({ presence: mode === 'EDIT' ? 'optional' : mode === 'DRAFT' ? 'optional' : mode === 'APPROVE' ? 'required' : 'forbidden' })
      .messages({
        'any.require': 'Heart Failure Medical History is required',
      }),

    previousMedications: Joi.array()
      .items(
        Joi.object({
          medicationBrandNameObject: Joi.any(),

          brandName: Joi.string().required(),

          medicationName: Joi.string().when('isCustomMedications', {
            switch: [{ is: true, then: Joi.optional() }],
            otherwise: Joi.required(),
          }),

          dosage: Joi.any().when('isCustomMedications', {
            switch: [{ is: true, then: Joi.optional() }],
            otherwise: Joi.required(),
          }),

          dosageUnit: Joi.any().required(),

          dosageQuantity: Joi.any().optional(),

          duration: Joi.any().required(),
          durationUnits: Joi.any().required(),

          frequencyRegime: Joi.string().required(),

          isCustomMedications: Joi.boolean().optional(),

          directionOfUse: Joi.string().optional(),
          reasonForChange: Joi.string().optional(),
        }).with('duration', 'durationUnits'),
      )
      .options({ presence: isMedicationAdded ? 'required' : 'optional' }),

    hospitalization: Joi.object({
      summary: Joi.string().optional().allow(''),
      attachments: Joi.array().items(Joi.object().unknown()).optional(),
      payload: Joi.object({
        cardiacAdmit: Joi.boolean().optional(),
        hospitalizationDate: Joi.any().optional(),
        dischargeDate: Joi.when('hospitalizationDate', {
          is: Joi.string(),
          then: Joi.date().iso().min(Joi.ref('hospitalizationDate')),
          otherwise: Joi.date().optional(),
        }).optional(),
      }).optional(),
    })
      .allow({}, null)
      .optional(),
  });
};
