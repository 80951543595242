/** @format */

import { createSlice, PayloadAction } from '@reduxjs/toolkit';

// Types and interfaces.
import { RootState } from '../../app/store';

export interface PatientListState {
  doctorName: string;
}

export const initialState: PatientListState = {
  doctorName: 'ALL',
};

export const patientListReducer = createSlice({
  name: 'patient list reducer',
  initialState,
  reducers: {
    setDoctorName: (state, { payload }: PayloadAction<string>) => {
      state.doctorName = payload;
    },

    clearPatientListStore: (state) => {
      state = {
        doctorName: 'ALL',
      };

      return state;
    },
  },
});

export const {
  clearPatientListStore,

  setDoctorName,
} = patientListReducer.actions;

export const patientListSelector = (state: RootState) => state.patientList;
