/** @format */

import * as React from 'react';

import Grid from '@mui/material/Grid';
import { useTheme } from '@mui/material';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';

// Components
import FormFields, { FormFieldsProps } from '../../common/form-fields/FormFields';

// Type
import { UptitratePatientMedicaitonPayloadType } from '../../../types/PatientTCPMedications.types';

// Constants
import { TEXT_INPUT_COMPONENT } from '../../../constants/PatientEnrollment';
import { DateTime } from 'luxon';
import PatientTasksCreation from './PatientTasksCreation';

interface TestingConfigProps {
  patientMedicationSuggestions?: UptitratePatientMedicaitonPayloadType;

  handleOnClickCreateCareTreamTasks: (date: string) => void;
  handleOnClickCreateTasksMedicationChange: (startDate: string, endDate: string) => void;
  handleOnClickCreatePatientDailyTasks: (date: string) => void;

  setPatientUploadFile: (file: any) => void;
  onFileUpload: () => void;

  handleSubmitOmronSync: (startDate: any) => void;
  handleSubmitFitbitSync: (startDate: any) => void;
}

const TestingConfig: React.FunctionComponent<TestingConfigProps> = (props) => {
  const appTheme = useTheme();

  const [fitbitStartDate, setfitbitStartDate] = React.useState<string>(DateTime.local().toFormat('yyyy-MM-dd'));
  const [omronSyncStartDate, setOmronSyncStartDate] = React.useState<string>(DateTime.local().toFormat('yyyy-MM-dd'));

  const uploadPatientHistory: FormFieldsProps = {
    dataTestIdPrefix: 'patient-history',
    id: 'patient-history',
    componentType: TEXT_INPUT_COMPONENT,
    label: '',
    input: {
      value: null,
      onChange: (event: any) => props.setPatientUploadFile(event.target.files[0]),
      type: 'file',
      placeholder: 'Select CSV File',
    },
  };

  return (
    <Grid container mt={1} direction='column' gap={2}>
      <PatientTasksCreation
        medicationTeamTaskDate={{ startDate: props.patientMedicationSuggestions?.lastTitrationDate as string, endDate: DateTime.local().toFormat('yyyy-MM-dd') }}
        handleOnClickCreateCareTreamTasks={props.handleOnClickCreateCareTreamTasks}
        handleOnClickCreateTasksMedicationChange={props.handleOnClickCreateTasksMedicationChange}
        handleOnClickCreatePatientDailyTasks={props.handleOnClickCreatePatientDailyTasks}
      />

      <Grid item container alignItems='center' justifyContent='space-between' my={3}>
        <Grid item xs={9}>
          <Typography mb={2} component='div' variant='fontSemiBold16' color={appTheme.palette.customColor.primary}>
            Omron Data Sync
          </Typography>
          <Grid container justifyContent='space-between' alignItems='center'>
            <Grid item xs={5.9} sx={{ '& .MuiFormLabel-root': { display: 'none' } }}>
              <FormFields
                label=''
                id='startDate'
                input={{
                  value: omronSyncStartDate,
                  onChange: (event: any) => setOmronSyncStartDate(event.target.value),
                  type: 'date',
                }}
                componentType={TEXT_INPUT_COMPONENT}
              />
            </Grid>
          </Grid>
        </Grid>

        <Button variant='contained' onClick={() => props.handleSubmitOmronSync(omronSyncStartDate)}>
          Submit
        </Button>
      </Grid>

      <Grid item container alignItems='center' justifyContent='space-between' my={3}>
        <Grid item xs={9}>
          <Typography mb={2} component='div' variant='fontSemiBold16' color={appTheme.palette.customColor.primary}>
            Fit Bit Sync
          </Typography>
          <Grid container justifyContent='space-between' alignItems='center'>
            <Grid item xs={5.9} sx={{ '& .MuiFormLabel-root': { display: 'none' } }}>
              <FormFields
                label=''
                id='startDate'
                input={{
                  value: fitbitStartDate,
                  onChange: (event: any) => setfitbitStartDate(event.target.value),
                  type: 'date',
                }}
                componentType={TEXT_INPUT_COMPONENT}
              />
            </Grid>
          </Grid>
        </Grid>

        <Button variant='contained' onClick={() => props.handleSubmitFitbitSync(fitbitStartDate)}>
          Submit
        </Button>
      </Grid>

      <Grid item container alignItems='center' justifyContent='space-between'>
        <Grid item xs={9}>
          <Typography mb={2} component='div' variant='fontSemiBold16' color={appTheme.palette.customColor.primary}>
            Patient History Upload
          </Typography>
          <Grid container justifyContent='space-between' alignItems='center' sx={{ '& .MuiFormLabel-root': { display: 'none' } }}>
            <FormFields {...uploadPatientHistory} />
          </Grid>
        </Grid>

        <Button variant='contained' onClick={props.onFileUpload}>
          Submit
        </Button>
      </Grid>
    </Grid>
  );
};

export default TestingConfig;
