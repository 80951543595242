/** @format */

import _, { isEmpty } from 'underscore';
import { DateTime } from 'luxon';
import React, { useContext, useState } from 'react';
import { Box, Grid, Button, InputBase, Typography } from '@mui/material';
import { CustomTableColumnType } from '../../../types/CustomTable.types';
import CustomTable from '../../common/custom-table/CustomTable';

import './index.scss';

import { PatientMedicalHistoryPayloadType, WalkTestTimeHistoryType } from '../../../types/PatientMedicalHistory.types';
import { PatientProfileContext } from '../../../pages/patient-profile/PatientProfileContext';

export const WalkTest = () => {
  const [date, setDate] = useState<string>(DateTime.now().toFormat('yyyy-MM-dd'));
  const [dateError, setDateError] = useState<string>('');

  const [sixMinWalkTest, setSixMinWalkTest] = React.useState<string>('');
  const [sixMinWalkTestError, setSixMinWalkTestError] = React.useState<string>('');

  const [initialSPO2, setInitialSPO2] = React.useState<string>('');
  const [initialSPO2Errors, setInitialSPO2Errors] = React.useState<string>('');

  const [finalSPO2, setFinalSPO2] = React.useState<string>('');
  const [finalSPO2Errors, setFinalSPO2Errors] = React.useState<string>('');

  const [data, setData] = useState<WalkTestTimeHistoryType[]>([{} as WalkTestTimeHistoryType]);

  const { patientInfo, handleSubmitWalkTest } = useContext(PatientProfileContext);

  React.useEffect(() => {
    if (patientInfo.medicalHistory?.walkTestTimeHistory) {
      setData([...patientInfo.medicalHistory.walkTestTimeHistory, patientInfo.medicalHistory.walkTestTime, {} as WalkTestTimeHistoryType]);
    }
  }, [patientInfo?.medicalHistory?.walkTestTime, patientInfo?.medicalHistory?.walkTestTimeHistory]);

  const handleSubmit = async () => {
    let isError = false;

    if (_.isEmpty(date)) {
      setDateError('Date cannot be empty');
      isError = true;
    } else if (!(new Date(date).getTime() < DateTime.local().plus({ days: 0 }).toMillis())) {
      setDateError('Date cannot be future date');
      isError = true;
    } else if (!(new Date(date).getTime() > DateTime.local().minus({ months: 3 }).toMillis())) {
      setDateError('Date cannot be less than 3 months');
      isError = true;
    } else {
      setDateError('');
    }

    if (parseInt(initialSPO2) < 0 || parseInt(initialSPO2) > 200) {
      isError = true;
      setInitialSPO2Errors('Please enter valid SPO2 value');
    } else {
      setInitialSPO2Errors('');
    }

    if (parseInt(sixMinWalkTest) < 0 || parseInt(sixMinWalkTest) > 200) {
      isError = true;
      setSixMinWalkTestError('Please enter valid value');
    } else {
      setSixMinWalkTestError('');
    }

    if (parseInt(finalSPO2) < 0 || parseInt(finalSPO2) > 200) {
      isError = true;
      setFinalSPO2Errors('Please enter valid SPO2 value');
    } else {
      setFinalSPO2Errors('');
    }

    if (isError) {
      return;
    }

    let medicalHistory: Partial<PatientMedicalHistoryPayloadType> = {
      walkTestTime: {
        date,
        value: sixMinWalkTest,
        finalSPO2,
        initialSPO2,
      },
      walkTestTimeHistory: [],
    };

    if (!isEmpty(patientInfo.medicalHistory!.walkTestTime)) {
      medicalHistory = {
        ...medicalHistory,
        walkTestTimeHistory: (patientInfo?.medicalHistory?.walkTestTimeHistory || []).concat({
          date: patientInfo.medicalHistory!.walkTestTime.date,
          value: patientInfo.medicalHistory!.walkTestTime.value,
          finalSPO2: patientInfo.medicalHistory!.walkTestTime.finalSPO2,
          initialSPO2: patientInfo.medicalHistory!.walkTestTime.initialSPO2,
        }),
      };
    }

    const response: any = await handleSubmitWalkTest(medicalHistory);

    if (response.message.includes('Success')) {
      handleClickCancel();
    }
  };

  const handleClickCancel = () => {
    setDate(DateTime.now().toFormat('yyyy-MM-dd'));
    setSixMinWalkTest('');
    setInitialSPO2('');
    setFinalSPO2('');
  };

  const inputStyles = {
    fontSize: '14px',
    width: '100%',
    '.MuiInputBase': {
      padding: 0,
    },

    '.MuiInputBase-input': {
      padding: 0,
    },
  };

  const columnDefs: CustomTableColumnType[] = [
    {
      id: 'date',
      label: 'Date',
      cellAlignment: 'left',
      cellFormatter: (data) => {
        if (!isEmpty(data)) {
          return <Box sx={{ width: 'max-content' }}>{data.date}</Box>;
        } else {
          return <InputBase sx={inputStyles} type='date' value={date} error={!_.isEmpty(dateError)} onChange={({ currentTarget }) => setDate(currentTarget.value)} />;
        }
      },
    },

    {
      minWidth: 100,
      width: '30%',
      id: 'six-min-walk-test',
      label: 'Six Minute Walk Test (m)',
      cellAlignment: 'left',
      cellFormatter: (data) => {
        if (!isEmpty(data)) {
          return <Box>{data.value}</Box>;
        } else {
          return <InputBase sx={inputStyles} type='number' value={sixMinWalkTest} onChange={({ currentTarget }) => setSixMinWalkTest(currentTarget.value)} />;
        }
      },
    },

    {
      minWidth: 100,
      width: '30%',
      id: 'initial-spo2',
      label: 'Inital SPO2 (%)',
      cellAlignment: 'left',
      cellFormatter: (data) => {
        if (!isEmpty(data)) {
          return <Box>{data.initialSPO2}</Box>;
        } else {
          return <InputBase sx={inputStyles} type='number' value={initialSPO2} onChange={({ currentTarget }) => setInitialSPO2(currentTarget.value)} />;
        }
      },
    },

    {
      minWidth: 100,
      width: '30%',
      id: 'final-spo2',
      label: 'Final SPO2 (%)',
      cellAlignment: 'left',
      cellFormatter: (data) => {
        if (!isEmpty(data)) {
          return <Box>{data.finalSPO2}</Box>;
        } else {
          return <InputBase sx={inputStyles} type='number' value={finalSPO2} onChange={({ currentTarget }) => setFinalSPO2(currentTarget.value)} />;
        }
      },
    },
  ];

  return (
    <Box className='kccq-main'>
      <CustomTable
        count={1}
        columns={columnDefs}
        showAddRow
        addRowBtnLabel='Submit Walk Test'
        data={data}
        noDataMessage='No Prescription'
        dataRowHover={false}
        stickyHeader
        tableSize='small'
        customActions={
          <Grid item xs={12} container justifyContent='space-between' m={2} mr={5}>
            <Grid item xs>
              <Typography variant='fontReg12' color='#33425B' component='div'>
                {dateError}
              </Typography>
              <Typography variant='fontReg12' color='#33425B' component='div'>
                {sixMinWalkTestError}
              </Typography>
              <Typography variant='fontReg12' color='#33425B' component='div'>
                {initialSPO2Errors}
              </Typography>
              <Typography variant='fontReg12' color='#33425B' component='div'>
                {finalSPO2Errors}
              </Typography>
            </Grid>
            <Grid item xs gap={2} sx={{ textAlign: 'right', mr: 5 }}>
              <Button variant='outlined' color='error' sx={{ marginRight: '10px' }} onClick={handleClickCancel}>
                Cancel
              </Button>

              <Button variant='contained' color='primary' onClick={handleSubmit} disabled={isEmpty(date) || isEmpty(sixMinWalkTest)}>
                Submit
              </Button>
            </Grid>
          </Grid>
        }
      />
    </Box>
  );
};
