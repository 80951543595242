/** @format */

import * as React from 'react';
import * as _ from 'underscore';
import { DateTime } from 'luxon';
import { ErrorBoundary } from 'react-error-boundary';

import Box from '@mui/material/Box';
import Chip from '@mui/material/Chip';
import Grid from '@mui/material/Grid';
import { useTheme } from '@mui/material/styles';
import Typography from '@mui/material/Typography';

import CustomPopper from '../common/custom-popper';
import { ComponentErrorBoundary } from '../common/error/ComponentErrorBoundary';

import { PatientTasksItemType } from '../../types/PatientTasks.type';

import WarningAmberIcon from '@mui/icons-material/WarningAmber';

interface ViewPatientReportedSymptomsProps {
  id?: string;

  showDots?: boolean;

  title?: string;
  showTitle?: boolean;

  showDateBelowSymptoms?: boolean;
  showDateAlongWithTitle?: boolean;

  handleClickShowMore?: () => void;

  alerts: PatientTasksItemType<any, any>[];
}

const ViewPatientAlerts: React.FunctionComponent<ViewPatientReportedSymptomsProps> = (props) => {
  const appTheme = useTheme();

  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);

  const handleClickMore = (event: any) => {
    if (props.handleClickShowMore) {
      props.handleClickShowMore();
    }

    if (props.alerts.length <= 1) return;
    setAnchorEl(event.currentTarget);
  };

  const handleClosePopover = () => {
    setAnchorEl(null);
  };

  const _renderAlertsText = (alert: PatientTasksItemType<any, any>) => {
    const alertReason =
      alert?.payload && alert?.payload.inputPayload && alert?.payload?.inputPayload?.reason
        ? alert.payload.inputPayload.reason.charAt(0).toUpperCase() + alert.payload.inputPayload.reason.slice(1)
        : '';
    return (
      <Typography variant='fontReg14' color={appTheme.palette.customColor.text} component='div' sx={{ wordBreak: 'break-word', whiteSpace: 'normal' }}>
        {alertReason || 'NA'}
      </Typography>
    );
  };

  const _renderDeleteChipAction = () => {
    if (props.alerts && props.alerts.length === 0) {
      return <div />;
    }
    return <Chip label={props.alerts.length} size='small' variant='outlined' />;
  };

  const latestAlertsDate = !_.isEmpty(props.alerts[0]) && DateTime.fromJSDate(new Date(props.alerts[0].timestamp)).toRelative();

  return (
    <ErrorBoundary fallbackRender={ComponentErrorBoundary}>
      <Grid item xs={12} container direction='column' gap={0.3}>
        {props.showTitle && (
          <Typography component='div' color='#3E4685' variant='fontReg14' sx={{ pb: 1, textTransform: 'capitalize' }}>
            {props.title} {'- ' + props.showDateAlongWithTitle && latestAlertsDate}
          </Typography>
        )}

        <Chip
          sx={{
            display: 'flex',
            width: 'fit-content',
            // ml: 'auto',
            // mr: 'auto',
            background: '#ECEFF4',
            height: 'auto',
            border: '0.5px solid #C2D3EF',
            borderRadius: '5px',
            overflow: 'hidden',
            padding: '2.5px 5px',
            '& .MuiChip-deleteIcon': {
              ml: 'auto',
            },
            '& .MuiChip-label': {
              ml: 'auto',
            },
          }}
          label={
            <Typography
              className={'custom-text-wrap'}
              variant='fontReg14'
              color={appTheme.palette.customColor.text}
              display='inline-block;'
              component='div'
              sx={{
                wordBreak: 'break-word',
                whiteSpace: 'normal',
              }}>
              {_renderAlertsText(props.alerts[0])}
            </Typography>
          }
          avatar={props?.alerts[0] && <WarningAmberIcon sx={{ color: props?.alerts[0]?.type?.match('CRITICAL_ALERTS') ? 'red !important' : 'orange !important', fontSize: '25px' }} />}
          deleteIcon={_renderDeleteChipAction()}
          onMouseEnter={handleClickMore}
          // onMouseLeave={handleClosePopover}
        />

        {props.showDateBelowSymptoms && (
          <Typography component='div' color='#3E4685' variant='fontReg12' sx={{ pb: 1, textTransform: 'capitalize', mx: 'auto' }}>
            {latestAlertsDate}
          </Typography>
        )}
      </Grid>

      <CustomPopper
        popperProps={{
          sx: { zIndex: 2 },
          open: Boolean(anchorEl),
          anchorEl,
          placement: 'bottom-end',
        }}
        paperProps={{
          elevation: 5,
          sx: {
            minWidth: '22rem',
            p: 1.5,
          },
        }}
        onClose={handleClosePopover}>
        <Typography variant='fontSemiBold14' sx={{ color: appTheme.palette.customColor.text }}>
          Alerts
        </Typography>
        <Box
          component='div'
          sx={{
            background: '#ECEFF4',
            border: '0.5px solid #C2D3EF',
            borderRadius: '5px',
            overflow: 'auto',
            maxHeight: '10rem',
            p: 1,
          }}>
          {props.alerts?.map((alert: PatientTasksItemType<any, any>, index: number) => {
            const relativeDate = alert?.timestamp && DateTime.fromJSDate(new Date(alert.timestamp)).toFormat('dd MMM yy');
            return (
              <Grid key={index} mt={1} item xs={12} container alignItems='baseline' gap={0.5}>
                <Grid item xs='auto'>
                  {/* <WarningAmberIcon sx={{ color: alert.type.match('CRITICAL_ALERTS') ? 'red !important' : 'orange !important', fontSize: '25px' }} /> */}
                </Grid>

                <Grid item xs={3}>
                  <Typography variant='fontReg14' color={appTheme.palette.customColor.text} component='div'>
                    {relativeDate}
                  </Typography>
                </Grid>

                <Grid item xs>
                  {_renderAlertsText(alert)}
                </Grid>
              </Grid>
            );
          })}
        </Box>
      </CustomPopper>
    </ErrorBoundary>
  );
};

export default ViewPatientAlerts;
