/** @format */

import React, { FunctionComponent } from 'react';
import * as _ from 'underscore';
import { Navigate, useLocation } from 'react-router-dom';
import CustomLoading from './components/common/custom-loading/CustomLoading';
import { getUserSession } from './utils/user-session';

import { UserSessionType } from './types/OrganizationUsers';

export interface PrivateAuthProps {
  children: JSX.Element;
}

const PrivateAuth: FunctionComponent<PrivateAuthProps> = (props) => {
  const location = useLocation();

  const [isMount, setIsMount] = React.useState<boolean>(false);
  const [auth, setAuth] = React.useState<UserSessionType>({} as UserSessionType);

  React.useEffect(() => {
    const userSession = getUserSession();
    setAuth(userSession);
    setIsMount(true);
  }, []);

  if (!isMount) {
    return <CustomLoading />;
  }

  if (_.isEmpty(auth.sessionId)) {
    return <Navigate to='/login' state={{ from: location }} replace />;
  }

  return props.children;
};

PrivateAuth.defaultProps = {};

export default PrivateAuth;
