/** @format */

import { initializeApp } from 'firebase/app';
import { getFirestore } from 'firebase/firestore';

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional

const firebaseConfig = {
  apiKey: 'AIzaSyCbAODpM1k1hMTLyZAwumruMG09uEOe9K0',
  authDomain: 'tricog-care-in-chat.firebaseapp.com',
  projectId: 'tricog-care-in-chat',
  storageBucket: 'tricog-care-in-chat.appspot.com',
  messagingSenderId: '988852697829',
  appId: '1:988852697829:web:73b89d004782ce9ba0720c',
  measurementId: 'G-F8T1XLNJPH',
};

// Initialize Firebase
export const firebaseApp = initializeApp(firebaseConfig);
export const firebaseDB = getFirestore(firebaseApp);
