/** @format */

import axios from 'axios';
import React from 'react';
import CustomAsyncSelect from '../common/custom-select/CustomAsyncSelect';
import SearchIcon from '@mui/icons-material/Search';
import { searchPatient } from '../../service/patients';
import { Typography } from '@mui/material';
import _ from 'underscore';
import ClearIcon from '@mui/icons-material/Clear';

// Component props.
interface PatientSearchProps {
  type?: string;
  error?: string;
  handleChange?: (payload: any) => void;
  userDetails?: {
    firstName: string;
    lastName: string;
    patientId: string;
    patientSourceId: string;
  } | null;
  disabled: boolean;
  handleClickClearSearchFilter?: () => void;
}

const PatientSearch: React.FunctionComponent<PatientSearchProps> = (props) => {
  const { error, handleChange, userDetails, disabled, handleClickClearSearchFilter, type } = props;

  const [inputvalue, setInputvalue] = React.useState<string>('');
  const axiosSourceRef: any = React.useRef();

  let name = userDetails?.patientId;

  if (userDetails && userDetails?.firstName && userDetails?.lastName) {
    name = userDetails.firstName + ' ' + userDetails.lastName;
  }

  const handleInputChange = (value: string) => {
    setInputvalue(value);
  };

  const handleClickClearSearch = () => {
    setInputvalue('');
    if (handleClickClearSearchFilter) {
      handleClickClearSearchFilter();
    }
  };

  const fetchPatients = (inputValue: string, cb: any) => {
    if (axiosSourceRef.current && axiosSourceRef.current.token) {
      axiosSourceRef.current.cancel();
    }
    axiosSourceRef.current = axios.CancelToken.source();
    if (inputValue && !_.isEmpty(inputValue.trim())) {
      searchPatient(inputValue, axiosSourceRef.current, type)
        .then((data) => {
          cb(data);
        })
        .catch((err) => {
          console.error('err', err);
        });
    } else {
      cb();
    }
  };

  return (
    <>
      <CustomAsyncSelect
        value={inputvalue}
        placeholder={userDetails ? `${name}` : 'Search'}
        loadOptions={fetchPatients}
        components={{
          DropdownIndicator: () => null,
          IndicatorSeparator: () => (userDetails && handleClickClearSearchFilter ? <ClearIcon color='secondary' onClick={handleClickClearSearch} /> : <SearchIcon color='secondary' />),
        }}
        onInputChange={handleInputChange}
        onChange={handleChange}
        getOptionLabel={(e: any) => (e.firstName ? `${e.firstName} ${e.lastName}` + ' - ' + e.patientId : e.patientId)}
        getOptionValue={(e: any) => e.patientSourceId}
        isDisabled={disabled}
      />

      <Typography>{error}</Typography>
    </>
  );
};

export default PatientSearch;
