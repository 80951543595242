/** @format */

import { env } from './../env';

const config = {
  patientApiUrl: env.REACT_APP_PATIENT_API_URL,
  patientSocketApiUrlPath: env.REACT_APP_PATIENT_API_SOCKET_URL,
  patientApiUrlPath: env.REACT_APP_PATIENT_API_URL_BASE_PATH || '/api/',
  careWebAppApiUrl: env.REACT_APP_CARE_WEB_APP_API_URL,
  analyticsApiUrl: env.REACT_APP_ANALYTICS_API_URL,
  UISentryUrl: env.REACT_APP_SENTRY_DSN,
  logsApiUrl: env.REACT_APP_LOGS_API_URL,
  userPolicyURL: env.REACT_APP_USER_POLICY_URL,
  apiTimeout: 30000,
  saasAdminUrl: env.REACT_APP_TRICOG_SASS_SERVER,
};

export default config;
