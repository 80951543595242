/** @format */

import AxiosInstance from './index';
import CONFIG from '../config/config';

export const approvePatient = async (sourceId: string) => {
  const res = await AxiosInstance({
    method: 'PATCH',
    baseURL: CONFIG.patientApiUrl,
    url: `patients/${sourceId}/enrollment/approval`,
  });

  return res;
};

export const enrollNewPatient = async (patientData: any, status: string) => {
  const res = await AxiosInstance({
    method: 'POST',
    baseURL: CONFIG.patientApiUrl,
    url: `patients/enrollment/${status}`,
    data: patientData,
  });

  return res;
};

export const updateEnrolledPatient = async (patientData: any, sourceId: string, status: string) => {
  const res = await AxiosInstance({
    method: 'PUT',
    baseURL: CONFIG.patientApiUrl,
    url: `patients/${sourceId}/enrollment/${status}`,
    data: patientData,
  });

  return res;
};

export const fetchPatientsList = async (
  params: {
    status: string;
    limit: number;
    offset: number;
    cardiologistId: any;
    flag?: boolean;
    startDate?: string;
    endDate?: string;
    isEnrolmentDetails?: 'true' | 'false';
    isPatientSymtoms?: 'true' | 'false';
    isPatientVitals?: 'true' | 'false';
    isMedicalAdhrence?: 'true' | 'false';
  },
  requestCancelToken?: any,
) => {
  const res = await AxiosInstance({
    method: 'GET',
    baseURL: CONFIG.patientApiUrl,
    url: 'patients/all',
    params,
    cancelToken: requestCancelToken,
  });

  return res.data || {};
};

export const fetchPatientsApprovalsList = async (params: { limit: number; offset: number; cardiologistId: any; flag?: boolean; startDate?: string; endDate?: string }, requestCancelToken?: any) => {
  const res = await AxiosInstance({
    method: 'GET',
    baseURL: CONFIG.patientApiUrl,
    url: 'patients/list/approval',
    params,
    cancelToken: requestCancelToken,
  });

  return res.data || {};
};
