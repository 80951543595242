/** @format */

import * as React from 'react';
import { StylesConfig } from 'react-select';
import AsyncSelect from 'react-select/async';

type ViewProps = React.ComponentProps<typeof AsyncSelect>;

export interface CustomAsyncSelectProps extends ViewProps {
  isError?: boolean;
  isDisabled?: boolean;
  maxMenuHeight?: number;

  width?: number;
  height?: number;

  minWidth?: number;
  minHeight?: number;

  maxWidth?: number;
  maxHeight?: number;
}

const CustomAsyncSelectWithDynamicDimensions: React.FunctionComponent<CustomAsyncSelectProps> = (props) => {
  const colourStyles: StylesConfig<any> = {
    control: (styles, state) => ({
      ...styles,

      background: state.isDisabled ? '#F4F4F4' : '#FFFFFF',
      border: state.isDisabled ? '1px solid #9BB4CC' : '1px solid #9BB4CC',
      borderRadius: '5px',
      transform: 'rotate(-0.06deg)',
      // minWidth: '300px',
      cursor: 'pointer',

      minWidth: props.minWidth,
      minHeight: props.minHeight,

      maxWidth: props.maxWidth,
      maxHeight: props.maxHeight,

      width: props.width,
      height: props.height,

      ':hover': {
        ...styles[':hover'],
        borderColor: 'rgba(0, 0, 0, 0.87)',
      },
    }),
    indicatorSeparator: (styles) => ({ display: 'flex' }),
    input: (styles) => ({
      ...styles,

      cursor: 'pointer',

      fontFamily: "'DM Sans', sans-serif;",
      fontStyle: 'normal',
      fontWeight: '400',
      fontSize: '16px',
      lineHeight: '19px',
      letterSpacing: '0.01em',
      color: '#33425B',
      transform: 'rotate(-0.06deg)',
    }),
    valueContainer: (styles) => ({
      ...styles,
      // padding: '10px 10px 11.24px 10px',

      cursor: 'pointer',

      fontFamily: "'DM Sans', sans-serif;",
      fontStyle: 'normal',
      fontWeight: '700',
      fontSize: '14px',
      lineHeight: '16.8px',
      letterSpacing: '1%',
      color: '#33425B',
      transform: 'rotate(-0.06deg)',
    }),
    singleValue: (styles) => ({
      ...styles,

      cursor: 'pointer',

      fontFamily: "'DM Sans', sans-serif;",
      fontStyle: 'normal',
      fontWeight: '400',
      fontSize: '16px',
      lineHeight: '19px',
      letterSpacing: '0.01em',
      color: '#33425B',
      transform: 'rotate(-0.06deg)',
    }),
    clearIndicator: (styles) => ({ ...styles, paddingRight: 0 }),
    menu: (styles) => ({ ...styles, zIndex: 9999999 }),
    menuPortal: (base) => ({ ...base, zIndex: 9999999 }),
    dropdownIndicator: (styles) => ({
      ...styles,
      cursor: 'pointer',

      color: '#33425B',
    }),
    indicatorsContainer: (styles) => ({
      ...styles,
      paddingInline: '10px',
      borderLeft: '1px solid #9BB4CC',

      // backgroundColor: 'red',
    }),
    container: (styles) => ({ ...styles, minWidth: props.minWidth, minHeight: props.minHeight, maxWidth: props.maxWidth, maxHeight: props.maxHeight }),
  };

  return <AsyncSelect {...props} options={props.options} styles={colourStyles} isDisabled={props.isDisabled} maxMenuHeight={props.maxMenuHeight} />;
};

export default CustomAsyncSelectWithDynamicDimensions;

CustomAsyncSelectWithDynamicDimensions.defaultProps = {
  width: 300,
  height: 50,
};
