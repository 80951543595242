/** @format */

import React from 'react';
import AddBoxIcon from '@mui/icons-material/AddBox';
import { Grid, Tooltip, TooltipProps, Typography, styled, tooltipClasses } from '@mui/material';

const CustomWidthTooltip = styled(({ className, ...props }: TooltipProps) => <Tooltip {...props} classes={{ popper: className }} />)({
  [`& .${tooltipClasses.tooltip}`]: {
    border: '1px solid #D6D6D6',
    maxWidth: 'none',
    backgroundColor: 'white',
    fontSize: '16px',
    overflow: 'hidden',
  },
});

interface CustomTooltipInterface {
  open: boolean;
  onClose: () => void;
  onOpen: () => void;
  tooltipTitle: string;
  timestamp: string;
  customFormatTimestamp: JSX.Element;
  title: string;
  data: any[];
  tooltipIcon: JSX.Element;
  showAddButton?: boolean;
  totalCount: number;
  handleClickButton?: () => void;
  children: JSX.Element;
}

const CustomTooltip: React.FC<CustomTooltipInterface> = ({
  open,
  onClose,
  onOpen,
  tooltipTitle,
  data,
  tooltipIcon,
  showAddButton,
  totalCount,
  title,
  handleClickButton,
  timestamp,
  children,
  customFormatTimestamp,
}) => {
  const _renderToolTipTimestamp = () => {
    if (customFormatTimestamp) {
      return customFormatTimestamp;
    }

    return <>({timestamp})</>;
  };

  return (
    <CustomWidthTooltip
      open={open}
      onClose={onClose}
      onOpen={onOpen}
      title={
        <Grid sx={{ maxWidth: 'none' }}>
          <Typography sx={{ color: '#585858' }}>{tooltipTitle}</Typography>

          <Grid sx={{ border: '1px solid #D1D1D1', width: '100%', backgroundColor: '#F8F8F8' }}>
            <Grid
              item
              sx={{
                textOverflow: 'ellipsis',
                whiteSpace: 'nowrap',
                overflow: 'hidden',
                marginRight: '5px',
                maxWidth: '500px',
                color: '#646464',
                marginLeft: '5px',
              }}>
              {children}
            </Grid>
          </Grid>
        </Grid>
      }
      placement='bottom'>
      <Grid className='custom-tooltop-container' item xs={12} container alignItems='center' gap={0.5} sx={{ border: '1px solid #e7e7e7', borderRadius: '5px', padding: '8px 5px' }}>
        <Grid item xs container alignItems='center' gap={0.8}>
          <Grid item xs={0.5} sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
            {tooltipIcon}
          </Grid>

          <Grid item xs={10.5} className='custom-tooltip-title'>
            <Typography
              sx={{
                whiteSpace: 'break-spaces',
                wordBreak: 'break-word',
                overflow: 'auto',
              }}>
              {title}
              {data.length !== 0 && (
                <Typography className='custom-tooltip-timestamp' sx={{ color: '#a7a7a7', pl: 0.1 }} component='span'>
                  {_renderToolTipTimestamp()}
                </Typography>
              )}
            </Typography>
          </Grid>
        </Grid>

        {showAddButton && (
          <Grid item xs='auto' sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', cursor: 'pointer', marginLeft: 'auto' }} onClick={handleClickButton}>
            <AddBoxIcon sx={{ color: '#8e8e8e', marginBottom: '2px' }} />
          </Grid>
        )}

        <Grid item xs='auto' className='custom-tooltip-count' sx={{ padding: '2px 5px', borderRadius: '5px', marginLeft: 'auto', backgroundColor: '#e7e7e7' }}>
          {totalCount}
        </Grid>
      </Grid>
    </CustomWidthTooltip>
  );
};

export default CustomTooltip;
