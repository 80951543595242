/** @format */

import React from 'react';
import { DateTime } from 'luxon';
import DatePicker from 'react-datepicker';

import SettingsIcon from '../../../assets/SettingsIcon';

import CalendarTodayIcon from '@mui/icons-material/CalendarToday';

import './DateRangePicker.scss';

// Component props.

type Placement = 'top-end' | 'top-start' | 'right-end' | 'right-start' | 'bottom-end' | 'bottom-start' | 'left-end' | 'left-start';
interface DateRangePickerProps {
  handleChangeStartDate: (a: string) => void;
  handleChangeEndDate: (a: string) => void;
  startDate?: string;
  endDate?: string;
  showIcon: boolean;
  showCalendarIcon?: boolean;
  minDate?: Date;
  maxDate?: Date;
  selectDaysRange?: number;

  selectsRange?: boolean;
  dateFormat?: string;

  preSelectedDate?: string;
  placement?: Placement | undefined;
  label?: string;

  disabled?: boolean;
  datePickerInput?: string;
  handleChangeDate?: (a: string) => void;
  clearValue?: boolean;
  isEdit?: boolean;
}

const DateRangePicker: React.FunctionComponent<DateRangePickerProps> = (props) => {
  const {
    showCalendarIcon,
    showIcon,
    handleChangeStartDate,
    handleChangeEndDate,
    startDate: prevDate,
    endDate: currentDate,
    maxDate,
    minDate,
    placement,
    selectDaysRange = 30,
    selectsRange = true,
    dateFormat = 'LLL-dd',
    preSelectedDate,
    label,
    disabled,
    handleChangeDate,
    datePickerInput,
    clearValue,
  } = props;

  const [startDate, setStartDate] = React.useState<Date | null>(DateTime.fromJSDate(new Date(prevDate!)).toJSDate());
  const [endDate, setEndDate] = React.useState<Date | null>(DateTime.fromJSDate(new Date(currentDate!)).toJSDate());

  const [selectedDate, setSelectedDate] = React.useState<Date | null>(null);

  const [datePickerCorrectRange, setDatePickerCorrectRange] = React.useState(true);

  React.useEffect(() => {
    setStartDate(DateTime.fromJSDate(new Date(prevDate!)).toJSDate());
    setEndDate(DateTime.fromJSDate(new Date(currentDate!)).toJSDate());
  }, [prevDate, currentDate]);

  React.useEffect(() => {
    if (preSelectedDate) {
      setSelectedDate(DateTime.fromJSDate(new Date(preSelectedDate)).toJSDate());
    } else {
      setSelectedDate(null);
    }
  }, [preSelectedDate]);

  const onChangeDate = (dates: [Date, Date]) => {
    if (Array.isArray(dates)) {
      const [start, end] = dates;

      const { days } = DateTime.fromJSDate(start).diff(DateTime.fromJSDate(end), ['days']).toObject();

      if (start !== null && end !== null && Math.abs(days!) < selectDaysRange) {
        const prevDate = DateTime.fromJSDate(new Date(start)).toFormat('yyyy-MM-dd');
        const currentDate = DateTime.fromJSDate(new Date(end)).toFormat('yyyy-MM-dd');

        setDatePickerCorrectRange(true);
        handleChangeStartDate(prevDate);
        handleChangeEndDate(currentDate);
      }

      setStartDate(start);
      setEndDate(end);

      if (Math.abs(days!) >= selectDaysRange) {
        setDatePickerCorrectRange(false);
      }
    } else if (!Array.isArray(dates)) {
      setStartDate(null);
      setEndDate(null);
      setSelectedDate(dates);

      handleChangeDate && handleChangeDate(dates);
    }
  };
  React.useEffect(() => {
    if (clearValue) {
      setSelectedDate(null);
    }
  }, [clearValue, selectedDate]);

  return (
    <>
      <div className={'datepicker-main'}>
        <div className={datePickerCorrectRange ? 'datepicker-item' : 'datepicker-item-error'}>
          <DatePicker
            minDate={minDate}
            maxDate={maxDate}
            popperPlacement={placement}
            selectsRange={selectsRange}
            startDate={startDate}
            endDate={endDate}
            onChange={onChangeDate}
            isClearable={true}
            className={datePickerInput || 'datepicker-input'}
            dateFormat={dateFormat}
            selected={selectedDate}
            disabled={props.isEdit ? props.isEdit : disabled}
            placeholderText={label}
          />

          {showCalendarIcon && <CalendarTodayIcon />}
        </div>

        {showIcon ? (
          <div className='datepicker-icon'>
            <SettingsIcon />
          </div>
        ) : null}
      </div>
    </>
  );
};

export default DateRangePicker;
