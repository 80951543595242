/** @format */

import { DateTime } from 'luxon';
import _, { isEmpty } from 'underscore';
import React, { useContext, useState } from 'react';
import { Box, Button, InputBase, Typography, Grid } from '@mui/material';
import { CustomTableColumnType } from '../../../types/CustomTable.types';
import CustomTable from '../../common/custom-table/CustomTable';

import './index.scss';
import { KCQQCScoreInterface, PatientMedicalHistoryPayloadType } from '../../../types/PatientMedicalHistory.types';
import { PatientProfileContext } from '../../../pages/patient-profile/PatientProfileContext';

export const Kccq = () => {
  const [date, setDate] = useState<string>(DateTime.now().toFormat('yyyy-MM-dd'));
  const [dateError, setDateError] = useState<string>('');

  const [clinicalSummaryScore, setClinicalSummaryScore] = React.useState<string>('');
  const [clinicalSummaryScoreError, setClinicalSummaryScoreError] = React.useState<string>('');

  const [totalSymptomsScore, setTotalSymptomsScore] = React.useState<string>('');
  const [totalSymptomsScoreError, setTotalSymptomsScoreError] = React.useState<string>('');

  const [overallSummaryScore, setOverallSummaryScore] = React.useState<string>('');
  const [overallSummaryScoreError, setOverallSummaryScoreError] = React.useState<string>('');

  const [data, setData] = useState<KCQQCScoreInterface[]>([{} as KCQQCScoreInterface]);

  const { patientInfo, handleSubmitKccq } = useContext(PatientProfileContext);

  React.useEffect(() => {
    if (patientInfo.medicalHistory?.kcqqcHistory) {
      setData([...patientInfo.medicalHistory.kcqqcHistory, patientInfo.medicalHistory.kcqqc, {} as KCQQCScoreInterface]);
    }
  }, [patientInfo.medicalHistory?.kcqqc, patientInfo.medicalHistory?.kcqqcHistory]);

  const handleSubmit = async () => {
    let isError = false;

    if (_.isEmpty(date)) {
      setDateError('Date cannot be empty');
      isError = true;
    } else if (!(new Date(date).getTime() < DateTime.local().plus({ days: 0 }).toMillis())) {
      setDateError('Date cannot be future date');
      isError = true;
    } else if (!(new Date(date).getTime() > DateTime.local().minus({ months: 3 }).toMillis())) {
      setDateError('Date cannot be less than 3 months');
      isError = true;
    } else {
      setDateError('');
    }

    if (parseInt(clinicalSummaryScore) < 0 || parseInt(clinicalSummaryScore) > 1000) {
      isError = true;
      setClinicalSummaryScoreError('Please enter valid clinical summary score');
    } else {
      setClinicalSummaryScoreError('');
    }

    if (parseInt(totalSymptomsScore) < 0 || parseInt(totalSymptomsScore) > 1000) {
      isError = true;
      setTotalSymptomsScoreError('Please enter valid total symptoms score');
    } else {
      setTotalSymptomsScoreError('');
    }

    if (parseInt(overallSummaryScore) < 0 || parseInt(overallSummaryScore) > 1000) {
      isError = true;
      setOverallSummaryScoreError('Please enter valid overall summary score');
    } else {
      setOverallSummaryScoreError('');
    }

    if (isError) {
      return;
    }

    let medicalHistory: Partial<PatientMedicalHistoryPayloadType> = {
      kcqqc: {
        date,
        clinicalSummaryScore,
        overallSummaryScore,
        totalSymptomScore: totalSymptomsScore,
      },

      kcqqcHistory: [],
    };

    if (!isEmpty(patientInfo.medicalHistory!.kcqqc)) {
      medicalHistory = {
        ...medicalHistory,
        kcqqcHistory: (patientInfo?.medicalHistory?.kcqqcHistory || []).concat({
          date: patientInfo.medicalHistory!.kcqqc.date,
          clinicalSummaryScore: patientInfo.medicalHistory!.kcqqc.clinicalSummaryScore,
          overallSummaryScore: patientInfo.medicalHistory!.kcqqc.overallSummaryScore,
          totalSymptomScore: patientInfo.medicalHistory!.kcqqc.totalSymptomScore,
        }),
      };
    }

    const response: any = await handleSubmitKccq(medicalHistory);

    if (response.message.includes('Success')) {
      handleClickCancel();
    }
  };

  const handleClickCancel = () => {
    setDate(DateTime.now().toFormat('yyyy-MM-dd'));
    setClinicalSummaryScore('');
    setOverallSummaryScore('');
    setTotalSymptomsScore('');
  };

  const inputStyles = {
    fontSize: '14px',
    width: '100%',
    '.MuiInputBase': {
      padding: 0,
    },

    '.MuiInputBase-input': {
      padding: 0,
    },
  };

  const columnDefs: CustomTableColumnType[] = [
    {
      id: 'date',
      label: 'Date',
      cellAlignment: 'left',
      cellFormatter: (data) => {
        if (!isEmpty(data)) {
          return <Box sx={{ width: 'max-content' }}>{data.date}</Box>;
        } else {
          return <InputBase error={!_.isEmpty(dateError)} sx={inputStyles} type='date' value={date} onChange={({ currentTarget }) => setDate(currentTarget.value)} />;
        }
      },
    },

    {
      minWidth: 100,
      width: '30%',
      id: 'clinical-summary-score',
      label: 'Clinical Summary Score',
      cellAlignment: 'left',
      cellFormatter: (data) => {
        if (!isEmpty(data)) {
          return <Box>{data.clinicalSummaryScore}</Box>;
        } else {
          return (
            <InputBase
              sx={inputStyles}
              type='number'
              error={!_.isEmpty(clinicalSummaryScoreError)}
              value={clinicalSummaryScore}
              onChange={({ currentTarget }) => setClinicalSummaryScore(currentTarget.value)}
            />
          );
        }
      },
    },

    {
      minWidth: 100,
      width: '30%',
      id: 'total-symptoms-score',
      label: 'Total Symptoms Score',
      cellAlignment: 'left',
      cellFormatter: (data) => {
        if (!isEmpty(data)) {
          return <Box>{data.totalSymptomScore}</Box>;
        } else {
          return (
            <InputBase
              sx={inputStyles}
              type='number'
              error={!_.isEmpty(totalSymptomsScoreError)}
              value={totalSymptomsScore}
              onChange={({ currentTarget }) => setTotalSymptomsScore(currentTarget.value)}
            />
          );
        }
      },
    },

    {
      minWidth: 100,
      width: '30%',
      id: 'overall-summary-score',
      label: 'Overall Summary Score',
      cellAlignment: 'left',
      cellFormatter: (data) => {
        if (!isEmpty(data)) {
          return <Box>{data.overallSummaryScore}</Box>;
        } else {
          return (
            <InputBase
              sx={inputStyles}
              type='number'
              error={!_.isEmpty(overallSummaryScoreError)}
              value={overallSummaryScore}
              onChange={({ currentTarget }) => setOverallSummaryScore(currentTarget.value)}
            />
          );
        }
      },
    },
  ];

  return (
    <Box className='kccq-main'>
      <CustomTable
        count={1}
        columns={columnDefs}
        showAddRow
        addRowBtnLabel='Submit KCCQ Score'
        // handleAddNewRow={() => setData([...data, {} as KccqInterface])}
        data={data}
        noDataMessage='No Prescription'
        dataRowHover={false}
        stickyHeader
        tableSize='small'
        customActions={
          <Grid item xs={12} container justifyContent='space-between' m={2} mr={5}>
            <Grid item xs>
              <Typography variant='fontReg12' color='#33425B' component='div'>
                {dateError}
              </Typography>
              <Typography variant='fontReg12' color='#33425B' component='div'>
                {clinicalSummaryScoreError}
              </Typography>
              <Typography variant='fontReg12' color='#33425B' component='div'>
                {totalSymptomsScoreError}
              </Typography>
              <Typography variant='fontReg12' color='#33425B' component='div'>
                {overallSummaryScoreError}
              </Typography>
            </Grid>
            <Grid item xs gap={2} sx={{ textAlign: 'right', mr: 5 }}>
              <Button variant='outlined' color='error' sx={{ marginRight: '10px' }} onClick={handleClickCancel}>
                Cancel
              </Button>

              <Button
                variant='contained'
                color='primary'
                onClick={handleSubmit}
                disabled={isEmpty(date) || isEmpty(totalSymptomsScore) || isEmpty(clinicalSummaryScore) || isEmpty(overallSummaryScore)}>
                Submit
              </Button>
            </Grid>
          </Grid>
        }
      />
    </Box>
  );
};
