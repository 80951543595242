/** @format */

import * as Joi from 'joi';
import { VITALS_TYPE_DIASTOLIC_BP, VITALS_TYPE_SYSTOLIC_BP, VITALS_TYPE_HEART_RATE, generateSchemaForVitalsMeasurementValue } from './enrolment-validation.joi';
import { DateTime } from 'luxon';

export const ObservationGeneralSchema = Joi.array().items(
  Joi.object({
    id: Joi.string().required(),
    mainText: Joi.string().required(),
    subText: Joi.string().optional(),
  }).required(),
);

export const complaintsSchema = Joi.array().items(
  Joi.object({
    complaints: Joi.array().items(Joi.string().required()).min(1).required().messages({
      'any.required': 'Complaints is required',
      'array.min': 'Alteast one Complaints is require',
    }),

    observedFrom: Joi.any().required().messages({
      'any.required': 'Since is required',
    }),

    observedFromUnits: Joi.any().optional(),
  }).required(),
);

export const examinationsSchema = Joi.array().items(
  Joi.object({
    examinations: Joi.string().required(),
  }).required(),
);

export const diagnosisSchema = Joi.array().items(
  Joi.object({
    diagnosis: Joi.string().required(),
  }),
);

export const notesSchema = Joi.array().items(
  Joi.object({
    notes: Joi.string().required(),
    medicationName: Joi.string().optional(),
    dosage: Joi.any().optional(),
  }),
);

export const vitalsSchema = Joi.object({
  bloodPressure: Joi.object({
    sbp: generateSchemaForVitalsMeasurementValue(VITALS_TYPE_SYSTOLIC_BP).label('Systolic').required(),
    dbp: generateSchemaForVitalsMeasurementValue(VITALS_TYPE_DIASTOLIC_BP).label('Diastolic').required(),
  }),
  pulseRate: generateSchemaForVitalsMeasurementValue(VITALS_TYPE_HEART_RATE).label('Pulse Rate').options({
    presence: 'optional',
  }),
  respirationRate: Joi.number().precision(3).greater(0).less(120).options({
    presence: 'optional',
  }),
  bodyTemperature: Joi.number().precision(3).greater(0).less(200).options({
    presence: 'optional',
  }),
});

export const investigationTestSchema = (prescriptionDate: string, nextReviewDate: string) => {
  return Joi.array().items(
    Joi.object({
      investigationTests: Joi.array().items(Joi.string()).min(1).required(),

      date: Joi.date()
        .allow(null, '')
        .min(DateTime.fromJSDate(new Date(prescriptionDate)).toISODate()!)
        .max(DateTime.fromJSDate(new Date(nextReviewDate)).toISODate()!)
        .default(null),

      dayBeforeReviw: Joi.string().when('date', {
        switch: [{ is: Joi.date(), then: Joi.optional() }],
        otherwise: Joi.required(),
      }),

      notes: Joi.string().max(100),
    }),
  );
};

export const proceduresSchema = Joi.array().items(
  Joi.object({
    procedures: Joi.string().required(),
  }).required(),
);

export const patientAppointmentSchema = Joi.object({
  scheduleAppointment: Joi.boolean().required(),
  appointmentPlace: Joi.string().required(),
  appointmentTime: Joi.string().required(),
  appointmentDuration: Joi.number().min(1).max(400).required(),
});

export const patientPrescriptionMedicationSchema = Joi.array()
  .items(
    Joi.object({
      medicationBrandNameObject: Joi.any(),

      brandName: Joi.string().required(),

      medicationName: Joi.string().when('isCustomMedications', {
        switch: [{ is: true, then: Joi.optional() }],
        otherwise: Joi.required(),
      }),

      dosage: Joi.any().when('isCustomMedications', {
        switch: [{ is: true, then: Joi.optional() }],
        otherwise: Joi.required(),
      }),

      dosageUnit: Joi.any().required(),

      dosageQuantity: Joi.any().optional(),

      duration: Joi.any().required(),
      durationUnits: Joi.any().required(),

      frequencyRegime: Joi.string().required(),

      isCustomMedications: Joi.boolean().optional(),

      directionOfUse: Joi.string().optional(),
      reasonForChange: Joi.string().optional(),
    }).with('duration', 'durationUnits'),
  )
  .min(1)
  .required();
