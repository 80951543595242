/** @format */

import * as React from 'react';
import * as _ from 'underscore';
import { DateTime } from 'luxon';
import DatePicker from 'react-datepicker';
import { ErrorBoundary } from 'react-error-boundary';

import Grid from '@mui/material/Grid';
import { Paper } from '@mui/material';
import Typography from '@mui/material/Typography';

// Components
import Ecg from '../../common/charts/Ecg';
import CustomLoading from '../../common/custom-loading/CustomLoading';
import { ComponentErrorBoundary } from '../../common/error/ComponentErrorBoundary';

// Material UI
import WatchIcon from '@mui/icons-material/Watch';
import TablePagination from '@mui/material/TablePagination';

// Context
import { PatientProfileContext } from '../../../pages/patient-profile/PatientProfileContext';

// Css
import './PatientEcg.scss';
// import { ECG_DEVICES_LIST, ECG_DIAGNOSIS_LIST } from '../../../constants/PatientEcgMapping';

const PatientEcg = () => {
  const {
    patientEcgFilter,

    isPatientEcgLoading,
    patientEcgs,

    handleChangePatientEcgFilters,
  } = React.useContext(PatientProfileContext);

  // const [ecgFilters, setEcgFilters] = React.useState({
  //   acquisitionDevice: 'All Devices',
  //   diagnosisCodes: 'All Diagnosis',
  // });

  const [startDate, setStartDate] = React.useState<null | Date>(DateTime.now().minus({ months: 1 }).toJSDate());
  const [endDate, setEndDate] = React.useState<null | Date>(DateTime.now().toJSDate());

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    handleChangePatientEcgFilters({ pageLimit: Number(event.target.value) });
  };

  const handleChangePage = (event: React.MouseEvent<HTMLButtonElement> | null, newPage: number) => {
    handleChangePatientEcgFilters({ pageOffset: newPage });
  };

  const [svgElementDynDimensions, setsvgElementDynDimensions] = React.useState<{ width: number; height: number }>({ width: 1000, height: 800 });
  const rcaChartRef = React.useRef<HTMLDivElement | null>(null);

  const getChartDimensions = () => {
    if (rcaChartRef.current) {
      const width = rcaChartRef.current?.getBoundingClientRect().width;
      const height = rcaChartRef.current?.getBoundingClientRect().height;

      if (width > 0 && height > 0) {
        setsvgElementDynDimensions((prev: { width: number; height: number }) => {
          return { ...prev, width, height };
        });
      }
    }
  };

  React.useEffect(() => {
    getChartDimensions();

    window.addEventListener('resize', getChartDimensions);

    return () => window.removeEventListener('resize', getChartDimensions);
  }, [rcaChartRef.current, patientEcgs]);

  // const maxHeight = '35rem';

  // const handleChangeEcgFilters = (event: SelectChangeEvent, filterType: 'ACQUISITION_DEVICE' | 'DIAGNOSIS_CODE') => {
  //   if (filterType === 'ACQUISITION_DEVICE') {
  //     setEcgFilters((prev) => ({ ...prev, acquisitionDevice: event.target.value }));
  //     handleChangePatientEcgFilters({ deviceType: event.target.value });
  //   }

  //   if (filterType === 'DIAGNOSIS_CODE') {
  //     setEcgFilters((prev) => ({ ...prev, diagnosisCodes: event.target.value }));
  //     handleChangePatientEcgFilters({ diagnosisType: event.target.value });
  //   }
  // };

  const _renderEcgFilters = () => {
    return (
      <Grid className='ecgs-filters' container justifyContent={'right'} gap={1.5} mb={1} alignItems={'center'} pr={1.5}>
        <Grid item>
          <DatePicker
            className='ecgs-date-picker'
            selected={new Date()}
            selectsRange={true}
            startDate={startDate}
            endDate={endDate}
            // minDate={DateTime.fromJSDate(new Date()).minus({ months: 1 }).toJSDate()}
            maxDate={new Date()}
            onWeekSelect={() => {
              // do nothing
            }}
            onChange={(dates) => {
              if (dates && Array.isArray(dates)) {
                const [start, end] = dates;
                setStartDate(start);
                setEndDate(end);

                if (start && end) {
                  const sDate = DateTime.fromJSDate(start).startOf('day').toFormat('yyyy-MM-dd');
                  const eDate = DateTime.fromJSDate(end).endOf('day').toFormat('yyyy-MM-dd');

                  // const diffDateOneMonth = DateTime.fromJSDate(end!).diff(DateTime.fromJSDate(start!), 'month').toObject().months! > 1;
                  // if (diffDateOneMonth) {
                  //   document.querySelector('.ecgs-date-picker')?.classList?.add('ecgs-date-picker-danger');
                  // } else {
                  //   document.querySelector('.ecgs-date-picker')?.classList?.remove('ecgs-date-picker-danger');
                  //   handleChangePatientEcgFilters({ startDate: sDate });
                  //   handleChangePatientEcgFilters({ endDate: eDate });
                  // }

                  handleChangePatientEcgFilters({ startDate: sDate });
                  handleChangePatientEcgFilters({ endDate: eDate });
                }
              }
            }}
            dateFormat='dd-LLL-yyyy'
            monthsShown={2}
          />
        </Grid>

        {/* Device List and Ecg Diagnosis Filters */}
        {/* <Grid item className='ecg-devices-list' sx={{ minWidth: 150 }} component={'div'}>
          <FormControl fullWidth>
            <Select
              labelId='devices-list-select-label'
              id='devices-list-select'
              value={ecgFilters.acquisitionDevice}
              label='Device'
              onChange={(event) => handleChangeEcgFilters(event, 'ACQUISITION_DEVICE')}
              size='small'
              sx={{ border: '1px solid rgba(62, 70, 133, 0.5)', ':hover': { border: '1px solid rgba(62, 70, 133, 0.5)' } }}>
              {ECG_DEVICES_LIST.map((ele, index) => {
                return (
                  <MenuItem key={index} value={ele.value}>
                    {ele.label}
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>
        </Grid>
        <Grid className='ecg-diagnosis-list' sx={{ minWidth: 150 }} component={'div'}>
          <FormControl fullWidth>
            <Select
              labelId='ecg-diagnosis-select-label'
              id='ecg-diagnosis-select'
              value={ecgFilters.diagnosisCodes}
              label='Diagnosis'
              onChange={(event) => handleChangeEcgFilters(event, 'DIAGNOSIS_CODE')}
              size='small'
              sx={{ border: '1px solid rgba(62, 70, 133, 0.5)', ':hover': { border: '1px solid rgba(62, 70, 133, 0.5)' } }}>
              {ECG_DIAGNOSIS_LIST.map((ele, index) => {
                return (
                  <MenuItem key={index} value={ele.value}>
                    {ele.label}
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>
        </Grid> */}
      </Grid>
    );
  };

  // if (isPatientEcgLoading) {
  //   return <CustomLoading />;
  // }

  return (
    <ErrorBoundary fallbackRender={ComponentErrorBoundary}>
      {isPatientEcgLoading && <CustomLoading />}

      {_renderEcgFilters()}

      {!isPatientEcgLoading && _.isEmpty(patientEcgs.data) && (
        <Typography component='div' variant='fontReg18' textAlign={'center'} color='#929292' width={'100%'} padding={'5rem'}>
          No Ecg's Found.
        </Typography>
      )}

      {!isPatientEcgLoading && !_.isEmpty(patientEcgs.data) && (
        <Paper id='patient-ecg-container'>
          <Grid className='ecgs-container' container gap={2} minHeight='calc(100vh - 390px)' maxHeight='calc(100vh - 390px)' sx={{ overflowY: 'scroll' }}>
            {patientEcgs.data?.map((ele: any) => {
              const ecgData: { date: number; value: number }[] = [];
              const apiData = ele.payload?.ecgData?.data?.ecg;

              const ecgSampleBase = ele.payload?.ecgData?.measurements?.ECGSampleBase;
              const recordingTime = apiData.length / ecgSampleBase;

              // Formatting the data to { date: number, value: number }
              for (let i = 0; i < apiData.length; i++) {
                ecgData.push({ date: apiData[i][0], value: apiData[i][1] });
              }

              return (
                <Grid key={ele.id} container item direction={'column'} p={2} gap={1}>
                  <Grid container item direction={'row'} gap={2}>
                    <Typography>{ele.payload?.ecgData?.diagnosis.join(', ')}</Typography>
                    <Typography variant='fontReg12' color='#929292' sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                      {DateTime.fromISO(ele.timestamp).toLocaleString(DateTime.DATETIME_MED)}
                    </Typography>
                  </Grid>
                  <Grid container item>
                    <Ecg
                      id={`ecg-${ele.id}`}
                      data={ecgData || []}
                      // dimensions={{ width: Math.abs(svgElementDynDimensions?.width), height: 160 }}
                      dimensions={{ width: Math.abs(svgElementDynDimensions?.width), height: 250 }}
                      margin={{ top: 0, right: 0, bottom: 0, left: 0 }}
                      xAxisPosition='BOTTOM'
                      scale='SCALE_POINT'
                      timeFormat='%d'
                      rangeXAxisOffset={0}
                      rangeYAxisOffset={0}
                      rcaChartRef={rcaChartRef}
                      ecgLineColor={'#E2536F'}
                      recordingTime={recordingTime}
                    />
                  </Grid>
                  <Grid container item direction={'row'} justifyContent={'space-between'}>
                    <Grid item gap={1} display={'flex'} alignItems={'center'} justifyContent={'center'}>
                      {/* <FavoriteIcon sx={{ color: '#E25059' }} />
                        <Typography variant='fontReg12' color='#828282'>66 BPM Average</Typography> */}
                    </Grid>
                    <Grid item gap={1} display={'flex'} alignItems={'center'} justifyContent={'center'}>
                      <WatchIcon fontSize='medium' sx={{ color: '#828282' }} />
                      <Typography variant='fontReg12' color='#828282'>
                        Recording Time: {recordingTime} Sec
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
              );
            })}
          </Grid>
          <Grid container justifyContent={'flex-end'} borderTop={'0.6px solid #d5d5d6'} maxHeight={'52px'}>
            <TablePagination
              component='div'
              className='ecg-pagination'
              count={patientEcgs?.count}
              rowsPerPageOptions={[5, 10, 15, 20]}
              rowsPerPage={patientEcgFilter.pageLimit}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
              page={patientEcgFilter.pageOffset}
            />
          </Grid>
        </Paper>
      )}
    </ErrorBoundary>
  );
};

export default PatientEcg;
