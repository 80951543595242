/** @format */

import * as React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import Typography from '@mui/material/Typography';

import { checkUserHasPermission } from '../../../utils/user-session';

import './CustomTabs.scss';

export interface CustomRoutingTabsTabsType {
  label: string | JSX.Element;
  value: string;
  path: string;
  activePathPatter?: (path: string) => boolean;
  userRoles: string[];
}

interface CustomRoutingTabsProps {
  tabs: CustomRoutingTabsTabsType[];
  style?: any;
}

const CustomRoutingTabs: React.FunctionComponent<CustomRoutingTabsProps> = (props) => {
  const navigate = useNavigate();
  const location = useLocation();

  const [activeTab, setActiveTab] = React.useState<string | null>(null);

  React.useEffect(() => {
    const activeTab = props.tabs.find((item) => {
      if (item.activePathPatter) {
        return item.activePathPatter(location.pathname);
      }
      return item.path === location.pathname;
    });
    setActiveTab(activeTab?.value!);
  }, [location]);

  const _renderTabs = (item: CustomRoutingTabsTabsType, index: number) => {
    const userHasPermission = checkUserHasPermission(item.userRoles);
    const handleChangeTabs = () => {
      navigate(item.path);
    };

    if (userHasPermission) {
      return (
        <Tab
          className={`tab-label ${activeTab !== item.value && 'tab-inactive'}`}
          key={item.value}
          value={item.value}
          onClick={handleChangeTabs}
          sx={props.style}
          label={
            <Typography variant='fontSemiBold18' sx={{ textTransform: 'none' }}>
              {item.label}
            </Typography>
          }
        />
      );
    }
    return null;
  };

  return (
    <Box className='custom-tabs' component='div'>
      <Tabs
        TabIndicatorProps={{
          sx: {
            height: '3px',
          },
        }}
        variant='scrollable'
        scrollButtons='auto'
        value={activeTab || false}
        aria-label='secondary tabs example'>
        {props.tabs.map(_renderTabs)}
      </Tabs>
    </Box>
  );
};

export default CustomRoutingTabs;
