/** @format */

import AxiosInstance from './index';
import CONFIG from '../config/config';

export const fetchPatientMedicalAdherence = async (sourceId: string, startDate: string, endDate: string) => {
  const res = await AxiosInstance({
    method: 'GET',
    baseURL: CONFIG.patientApiUrl,
    url: `patients/${sourceId}/medical-adherence?startDate=${startDate}&endDate=${endDate}`,
  });

  return res.data || {};
};

export const updatePatientMedicalAdherenceStatus = async (sourceId: string, payload: any) => {
  const res = await AxiosInstance({
    method: 'POST',
    baseURL: CONFIG.patientApiUrl,
    url: `patients/${sourceId}/medical-questionnaire/medical-adherence`,
    data: payload,
  });

  return res.data || {};
};
