/** @format */

import _ from 'underscore';
import * as React from 'react';
import { useTheme } from '@mui/material/styles';
import Menu from '@mui/material/Menu';
import Chip from '@mui/material/Chip';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Switch from '@mui/material/Switch';
import Tooltip from '@mui/material/Tooltip';
import MenuItem from '@mui/material/MenuItem';
import FormGroup from '@mui/material/FormGroup';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import FormControlLabel from '@mui/material/FormControlLabel';

import AddIcon from '@mui/icons-material/Add';
import SaveIcon from '@mui/icons-material/Save';
import CloseIcon from '@mui/icons-material/Close';
import DeleteIcon from '@mui/icons-material/Delete';
import MoreVertIcon from '@mui/icons-material/MoreVert';

import CustomSlider from './CustomSlider';
import FormFields from '../common/form-fields/FormFields';
import CustomTable from '../common/custom-table/CustomTable';
import { ConfirmationDialog } from '../common/confirmation-dialog/ConfirmationDialog';

import { useAppSelector } from '../../app/hooks';

import { getConditionRelationLabel, getOperatorLabel, getValueLabel, getVitalDisaplyLabel } from '../../utils/patient-rule';

// Types
import { CustomTableColumnType } from '../../types/CustomTable.types';
import { PatientRuleItemConditionType, PatientRuleItemErrorType, PatientRuleItemType } from '../../types/PatientRule.types';

// Constants
import {
  PatientRuleConditionOperatorOptions,
  PatientRuleConditionRelationOptions,
  PatientRuleConditionSymptomsOperatorOptions,
  PatientRuleConditionVitalsOptions,
  PATIENT_RULE_DEFAULT_RULE_ID,
} from '../../constants/PatientRules';

import './PatientRuleItem.scss';

interface PatientRuleItemProps {
  hideSaveBtnRuleItem?: boolean;

  rule: PatientRuleItemType;

  isForceEdit?: boolean;
  itemControlEdit?: boolean;

  isUpdatingPatientRule: boolean;
  handleDeleteRule: (ruleId: string) => void;
  handleSubmitDuplicateRule: (rule: PatientRuleItemType) => void;
  handleRemoveNewRule: (ruleId: string) => void;
  handleSubmitNewPatientRule: (rule: PatientRuleItemType) => void;
  handleSubmitUpdatePatientRule: (ruleId: string, rule: PatientRuleItemType) => void;

  handleChangeRules?: (ruleId: string, rule: PatientRuleItemType) => void;

  patientRulesErrors?: PatientRuleItemErrorType[];

  isRestrictColumnWidth?: boolean;
}

const PatientRuleItem: React.FunctionComponent<PatientRuleItemProps> = (props) => {
  const appTheme = useTheme();

  const organizationMedications = useAppSelector((state) => state.orgMedications?.medications);
  const organizationPatientSymptoms = useAppSelector((state) => state.patientSymptoms.patientSymptoms);

  const organizationMedicationsOptions = _.uniq(organizationMedications, 'medicationGroup')
    .filter((item) => item.isAlgoSupported === 1)
    .map((item) => ({ label: item.medicationGroup, value: item.medicationGroup }));
  const organizationSymptomsOptions = organizationPatientSymptoms.map((item) => ({ label: item.description, value: item.shortCode })).filter((e) => !['OTHERS'].includes(e.value));

  const prevIsUpdatingPatientRuleRef = React.useRef(props.isUpdatingPatientRule);

  const [rules, setRules] = React.useState<PatientRuleItemType>({} as PatientRuleItemType);
  const [medications, setMedicaitons] = React.useState<string[]>([]);
  const [notificationPreferences, setNotificationPreferences] = React.useState<{ sms: boolean; email: boolean }>({ sms: true, email: true });

  const [conditions, setConditions] = React.useState<PatientRuleItemConditionType[]>([]);
  const [conditionRelation, setConditionRelation] = React.useState<string>('');

  const [isEdit, setIsEdit] = React.useState<boolean>(false);
  const [actionMenuAnchorEl, setActionMenuAnchorEl] = React.useState<null | HTMLElement>(null);

  const [isDeleteRule, setIsDeleteRule] = React.useState<boolean>(false);

  const handleResetRules = () => {
    if (props.rule) {
      const conditionRelation = Object.keys(props.rule?.conditions)[0];
      setConditions(props.rule?.conditions[conditionRelation]);
      setConditionRelation(conditionRelation);
    }

    if (props.rule?.medications) {
      setMedicaitons(props.rule?.medications);
    }

    if (props.rule?.notificationPreferences) {
      setNotificationPreferences(props.rule?.notificationPreferences);
    }

    setRules(props.rule);
  };

  const handleClickActionMenu = (event: React.MouseEvent<HTMLButtonElement>) => {
    setActionMenuAnchorEl(event.currentTarget);
  };

  const handleChangeNotificationPreferenceInput = (variable: string, value: boolean) => {
    setNotificationPreferences((prev) => {
      prev = { ...prev, [variable]: value };
      handleChangeRules(conditions, medications, prev);
      return prev;
    });
  };

  const handleCloseActionMenu = () => {
    setActionMenuAnchorEl(null);
  };

  const toggleDeleteRule = () => {
    setIsDeleteRule((prev) => !prev);
  };

  const handleSubmitDeleteRule = () => {
    props.handleDeleteRule(rules?.ruleId!);
    setIsDeleteRule(false);
  };

  const handleClickDeleteActionMenu = () => {
    handleCloseActionMenu();
    setIsDeleteRule(true);
  };

  const handleClickDuplicateMenu = () => {
    props.handleSubmitDuplicateRule(rules);
    handleCloseActionMenu();
  };

  const handleChangeMedications = (event: any) => {
    const value = event.target?.value;
    setMedicaitons(typeof value === 'string' ? value.split(',') : value);
    handleChangeRules(conditions, value, notificationPreferences);
  };

  const toggleEditMode = () => {
    setIsEdit((prev) => !prev);
    handleCloseActionMenu();
  };

  const handleChangeConditionType = (event: any) => {
    setConditionRelation(event.target?.value);
  };

  const handleChangeConditionInputs = (value: any, variable: string, index: number) => {
    setConditions((prev) => {
      prev = prev.map((item, itemIndex) => {
        if (index === itemIndex) {
          return { ...item, [variable]: value };
        }
        return item;
      });
      handleChangeRules(prev, medications, notificationPreferences);
      return prev;
    });
  };

  const handleClickNewCondition = () => {
    setConditions((prev) => prev.concat({} as PatientRuleItemConditionType));
  };

  const handleClickRemoveCondition = (index: number) => {
    setConditions((prev) => {
      return prev.filter((item, itemIndex) => index !== itemIndex);
    });
  };

  const handleChangeRules = (newConditions: PatientRuleItemConditionType[], medications: string[], notificationPreferences: any) => {
    const rule: PatientRuleItemType = {
      ...rules,
      suggestionType: rules.suggestionType,
      conditions: {
        [conditionRelation]: newConditions,
      },
      medications,
      notificationPreferences,
    };

    if (props.handleChangeRules) {
      props.handleChangeRules(rules?.ruleId!, rule);
    }
  };

  const handleSubmitSaveRule = () => {
    const rule: PatientRuleItemType = {
      ...rules,
      suggestionType: rules.suggestionType,
      conditions: {
        [conditionRelation]: conditions,
      },
      medications,
      notificationPreferences,
    };

    if (rules.ruleId?.startsWith(PATIENT_RULE_DEFAULT_RULE_ID)) {
      props.handleSubmitNewPatientRule(rule);
    } else {
      props.handleSubmitUpdatePatientRule(rules?.ruleId!, rule);
    }
  };

  const handleClickCancelRule = () => {
    if (rules.ruleId?.startsWith(PATIENT_RULE_DEFAULT_RULE_ID)) {
      props.handleRemoveNewRule(rules.ruleId);
    }

    handleResetRules();
    toggleEditMode();
  };

  React.useEffect(() => {
    handleResetRules();
    if (props.rule?.ruleId?.startsWith(PATIENT_RULE_DEFAULT_RULE_ID) && props.isForceEdit) {
      setIsEdit(true);
    }
  }, [props.rule, props.isForceEdit]);

  React.useEffect(() => {
    if (isEdit && prevIsUpdatingPatientRuleRef.current && !props.isUpdatingPatientRule) {
      setIsEdit(false);
    }
    prevIsUpdatingPatientRuleRef.current = props.isUpdatingPatientRule;

    if (isDeleteRule && prevIsUpdatingPatientRuleRef.current && !props.isUpdatingPatientRule) {
      setIsDeleteRule(false);
    }
  }, [props.rule, props.isUpdatingPatientRule, isEdit, prevIsUpdatingPatientRuleRef, isDeleteRule]);

  React.useEffect(() => {
    if (props.isForceEdit && (!props.itemControlEdit || props.rule?.ruleId?.startsWith(PATIENT_RULE_DEFAULT_RULE_ID))) {
      setIsEdit(true);
    } else {
      setIsEdit(false);
    }
  }, [props.isForceEdit, props.itemControlEdit]);

  const column: CustomTableColumnType[] = [
    {
      hiddenHeader: !isEdit,
      hiddenColumn: !isEdit,
      id: 'add-condition',
      // label: 'Add Condition',
      label: <Typography variant='fontSemiBold14'>Condition</Typography>,
      minWidth: 10,
      width: '1%',
      cellAlignment: 'left',
      cellFormatter: (rule: PatientRuleItemConditionType, index: number) => {
        if (isEdit) {
          if (index === 0) {
            return (
              <IconButton disabled={props.isUpdatingPatientRule} onClick={handleClickNewCondition}>
                <AddIcon />
              </IconButton>
            );
          }
          return (
            <FormFields
              fullWidth
              label=''
              id='add-condition-drop'
              componentType='TEXT_SELECT_COMPONENT'
              input={{
                value: conditionRelation,
                onChange: handleChangeConditionType,
                options: PatientRuleConditionRelationOptions,
                disabled: props.isUpdatingPatientRule || index !== conditions?.length - 1,
              }}
            />
          );
        }

        return null;
      },
    },
    {
      hiddenHeader: !isEdit,
      hiddenColumn: !isEdit,
      id: 'remove',
      label: '',
      minWidth: 10,
      width: '1%',
      cellAlignment: 'left',
      cellFormatter: (rule: PatientRuleItemConditionType, index: number) => {
        if (isEdit && conditions?.length - 1 > 0) {
          return (
            <IconButton disabled={props.isUpdatingPatientRule} onClick={() => handleClickRemoveCondition(index)}>
              <DeleteIcon />
            </IconButton>
          );
        }
        return null;
      },
    },
    {
      hiddenHeader: !isEdit,
      id: 'select-vital',
      label: <Typography variant='fontSemiBold14'>Medical Parameters</Typography>,
      cellAlignment: 'left',
      minWidth: isEdit ? 100 : 250,
      width: isEdit ? '10%' : '25%',
      cellFormatter: (rule: PatientRuleItemConditionType, index: number) => {
        const options = PatientRuleConditionVitalsOptions.find((item) => item.value === rule.fact);
        if (isEdit) {
          const ruleItemError = props.patientRulesErrors?.find((item) => item.ruleId === props.rule?.ruleId && item.ruleKind && item.ruleKind);
          const conditionError = ruleItemError ? _.get(ruleItemError, ['conditions', conditionRelation! as any, index!, 'fact'], '') : '';
          return (
            <FormFields
              label=''
              fullWidth
              id='select-vitals'
              componentType='TEXT_SELECT_COMPONENT'
              input={{
                value: rule.fact,
                onChange: (event) => {
                  handleChangeConditionInputs(event?.target.value, 'fact', index);
                  handleChangeConditionInputs('', 'value', index);
                  handleChangeConditionInputs('', 'operator', index);
                },
                options: PatientRuleConditionVitalsOptions.concat(),
                disabled: props.isUpdatingPatientRule,
                errorMessage: conditionError,
                endAdornment: (
                  <Typography variant='fontReg12' sx={{ pr: 2 }}>
                    {' '}
                    {options?.unit}
                  </Typography>
                ),
              }}
            />
          );
        }
        return (
          <Typography variant='fontReg14'>
            <Grid container alignItems='center' gap={1}>
              <Grid item xs={1}>
                {index !== 0 && <Typography variant='fontReg12'>{getConditionRelationLabel(conditionRelation)}</Typography>}
              </Grid>
              <Grid item xs={5}>
                {getVitalDisaplyLabel(rule.fact)}
                <Typography variant='fontReg12' sx={{ pl: 1, wordBreak: 'break-word', whiteSpace: 'break-spaces', overflow: 'auto' }}>
                  {options?.unit && <span>({options?.unit})</span>}
                </Typography>
              </Grid>
              <Grid item xs={2}>
                {getOperatorLabel(rule.operator)}
              </Grid>
              <Grid item xs={3}>
                {rule.fact?.toLowerCase() === 'symptoms' &&
                  (rule.value as any[])?.map((item) => {
                    const symptomsLabel = organizationSymptomsOptions.find((ele) => item === ele.value)?.label;
                    return (
                      <Tooltip disableInteractive title={symptomsLabel} placement='top'>
                        <Chip sx={{ mb: 1, background: appTheme.palette.customColor.chipBgColor }} label={<Typography variant='fontReg12'>{symptomsLabel}</Typography>} />
                      </Tooltip>
                    );
                  })}
                {rule.fact?.toLowerCase() !== 'symptoms' && getValueLabel(rule.value)}
              </Grid>
            </Grid>
          </Typography>
        );
      },
    },
    {
      hiddenHeader: !isEdit,
      hiddenColumn: !isEdit,
      id: 'select-operator',
      label: <Typography variant='fontSemiBold14'>Operator</Typography>,
      minWidth: 40,
      width: '4%',
      cellAlignment: 'left',
      cellFormatter: (rule: PatientRuleItemConditionType, index: number) => {
        if (isEdit) {
          let options = PatientRuleConditionOperatorOptions;
          if (rule.fact === 'symptoms') {
            options = PatientRuleConditionSymptomsOperatorOptions;
          }
          const ruleItemError = props.patientRulesErrors?.find((item) => item.ruleId === props.rule?.ruleId && item.ruleKind && item.ruleKind);
          const conditionError = ruleItemError ? _.get(ruleItemError, ['conditions', conditionRelation! as any, index!, 'operator'], '') : '';

          return (
            <FormFields
              label=''
              fullWidth
              id='select-operator'
              componentType='TEXT_SELECT_COMPONENT'
              input={{
                errorMessage: conditionError,
                value: rule.operator,
                onChange: (event) => {
                  const value = event?.target.value;
                  handleChangeConditionInputs(event?.target.value, 'operator', index);
                  if (rule.fact === 'symptoms') {
                    handleChangeConditionInputs([], 'value', index);
                  } else {
                    const options = PatientRuleConditionVitalsOptions.find((item) => item.value === rule.fact);
                    if (value === 'between') {
                      if (!Array.isArray(rule.value)) {
                        handleChangeConditionInputs(options?.defaultRange, 'value', index);
                      } else if (_.isEmpty(rule.value)) {
                        handleChangeConditionInputs(options?.defaultRange, 'value', index);
                      }
                    } else if (value !== 'between') {
                      if (Array.isArray(rule.value)) {
                        handleChangeConditionInputs(options?.defaultValue, 'value', index);
                      } else if (_.isEmpty(rule.value)) {
                        handleChangeConditionInputs(options?.defaultValue, 'value', index);
                      }
                    }
                  }
                },
                // options: [{ label: 'Select operator', value: '' }].concat(options),
                options,
                disabled: props.isUpdatingPatientRule,
              }}
            />
          );
        }
        return null;
      },
    },
    {
      hiddenHeader: !isEdit,
      hiddenColumn: !isEdit,
      id: 'select-threshold',
      minWidth: 150,
      width: '15%',
      label: <Typography variant='fontSemiBold14'>Criteria</Typography>,
      cellAlignment: 'left',
      cellFormatter: (rule: PatientRuleItemConditionType, index: number) => {
        if (isEdit && rule.fact && rule.operator) {
          const ruleItemError = props.patientRulesErrors?.find((item) => item.ruleId === props.rule?.ruleId && item.ruleKind && item.ruleKind);
          const conditionError: any = ruleItemError ? _.get(ruleItemError, ['conditions', conditionRelation! as any, index!, 'value'], '') : '';
          if (rule.fact?.toLowerCase() === 'symptoms') {
            return (
              <FormFields
                fullWidth
                label=''
                id='select-operator'
                componentType='TEXT_SELECT_CHECKBOX_MULTI_COMPONENT'
                input={{
                  errorMessage: conditionError,
                  value: rule.value || [],
                  onChange: (event) => {
                    const value = event?.target.value;
                    handleChangeConditionInputs(typeof value === 'string' ? value.split(',') : value, 'value', index);
                  },
                  options: organizationSymptomsOptions,
                  disabled: props.isUpdatingPatientRule,
                }}
              />
            );
          }

          const options = PatientRuleConditionVitalsOptions.find((item) => item.value === rule.fact);
          const sliderProps = {
            minRange: options?.minRange!,
            maxRange: options?.maxRange!,
            defaultRange: options?.defaultRange!,
            value: rule.value,
            step: options?.step,
            handleChangeValue: (value: any) => handleChangeConditionInputs(value, 'value', index),
          };

          if (Array.isArray(rule.value)) {
            return <CustomSlider {...sliderProps} />;
          }
          return <CustomSlider {...sliderProps} />;
        }
        return null;
      },
    },
    {
      hiddenHeader: !isEdit,
      id: 'medication',
      minWidth: 100,
      width: '10%',
      label: <Typography variant='fontSemiBold14'>Medication</Typography>,
      cellAlignment: 'left',
      rowSpan: conditions.length,
      cellFormatter: (rule: PatientRuleItemConditionType, index: number) => {
        if (index === 0) {
          if (isEdit) {
            const ruleItemError = props.patientRulesErrors?.find((item) => item.ruleId === props.rule?.ruleId && item.ruleKind && item.ruleKind);
            const conditionError = ruleItemError?.medications || '';
            return (
              <FormFields
                fullWidth
                label=''
                id='select-medications'
                componentType='TEXT_SELECT_CHECKBOX_MULTI_COMPONENT'
                input={{
                  errorMessage: conditionError,
                  value: medications,
                  onChange: handleChangeMedications,
                  options: [{ value: 'ALL', label: 'ALL' }].concat(organizationMedicationsOptions),
                  disabled: props.isUpdatingPatientRule,
                }}
              />
            );
          }
          return (
            <Typography variant='fontReg12' color={appTheme.palette.customColor.text} textTransform='capitalize'>
              {rules?.ruleAction} <b>{medications.join('- ')}</b>
            </Typography>
          );
        }
        return <div className='remove-cell' />;
      },
    },
    {
      hiddenHeader: !isEdit,
      id: 'notifications',
      minWidth: 100,
      width: '10%',
      label: <Typography variant='fontSemiBold14'>Notifications</Typography>,
      cellAlignment: 'left',
      rowSpan: conditions.length,
      cellFormatter: (rule: PatientRuleItemConditionType, index: number) => {
        if (index === 0) {
          if (isEdit) {
            return (
              <FormGroup sx={{ display: 'flex', alignItems: 'flex-start', flexDirection: 'column', justifyContent: 'center' }}>
                <FormControlLabel
                  sx={{ marginLeft: 0 }}
                  control={<Switch checked={notificationPreferences.sms} onChange={(event, checked: boolean) => handleChangeNotificationPreferenceInput('sms', checked)} />}
                  label={<Typography variant='fontReg12'>SMS&nbsp;&nbsp;&nbsp;</Typography>}
                  labelPlacement='start'
                />
                <FormControlLabel
                  sx={{ marginLeft: 0 }}
                  control={<Switch checked={notificationPreferences.email} onChange={(event, checked: boolean) => handleChangeNotificationPreferenceInput('email', checked)} />}
                  label={<Typography variant='fontReg12'>EMAIL</Typography>}
                  labelPlacement='start'
                />
              </FormGroup>
            );
          }

          const notificaitons = [];

          if (notificationPreferences?.sms) {
            notificaitons.push('SMS');
          }

          if (notificationPreferences?.email) {
            notificaitons.push('EMAIL');
          }

          return (
            <Typography variant='fontReg12' color={appTheme.palette.customColor.text} textTransform='capitalize'>
              Notifications:
              <br />
              {notificaitons.length <= 0 ? 'DISABLED' : notificaitons.join(', ')}
            </Typography>
          );
        }
        return <div className='remove-cell' />;
      },
    },
    {
      hiddenHeader: !isEdit,
      id: 'Actions',
      minWidth: 30,
      width: '1%',
      rowSpan: conditions.length,
      label: (
        <>
          {isEdit && (
            <Tooltip title={rules.ruleId?.startsWith(PATIENT_RULE_DEFAULT_RULE_ID) ? 'Remove' : 'Cancel'}>
              <IconButton disabled={props.isUpdatingPatientRule} aria-label='Cancel' onClick={handleClickCancelRule}>
                <CloseIcon />
              </IconButton>
            </Tooltip>
          )}
        </>
      ),
      cellAlignment: 'right',
      cellFormatter: (rule: PatientRuleItemConditionType, index: number) => {
        if (index !== 0 || props.hideSaveBtnRuleItem) {
          return <div className='remove-cell' />;
        }

        const disableConditionButton = !conditions.every((item) => !_.isEmpty(item.fact) && !_.isEmpty(item.operator) && !_.isNull(item.value) && !_.isUndefined(item.value));
        const disableMedicationButton = _.isEmpty(medications);

        if (isEdit) {
          return (
            <Tooltip title='Save'>
              <IconButton disabled={props.isUpdatingPatientRule || disableConditionButton || disableMedicationButton} aria-label='Save' onClick={handleSubmitSaveRule}>
                <SaveIcon />
              </IconButton>
            </Tooltip>
          );
        }
        const open = Boolean(actionMenuAnchorEl);
        return (
          <>
            <IconButton disabled={props.isUpdatingPatientRule} onClick={handleClickActionMenu}>
              <MoreVertIcon />
            </IconButton>
            <Menu
              id='basic-menu'
              anchorEl={actionMenuAnchorEl}
              open={open}
              onClose={handleCloseActionMenu}
              MenuListProps={{
                'aria-labelledby': 'basic-button',
              }}>
              <MenuItem onClick={toggleEditMode}>Edit</MenuItem>
              <MenuItem onClick={handleClickDuplicateMenu}>Duplicate</MenuItem>
              <MenuItem onClick={handleClickDeleteActionMenu}>Delete</MenuItem>
            </Menu>
          </>
        );
      },
    },
  ];

  if (!props.isRestrictColumnWidth) {
    column.forEach((item) => {
      delete item.width;
      delete item.minWidth;
      delete item.maxWidth;
    });
  }

  return (
    <Paper
      elevation={0}
      id='patient-rule-item'
      className={isEdit ? 'table-edit' : 'table-read'}
      sx={{
        borderWidth: '1px',
        borderStyle: 'solid',
        borderColor: appTheme.palette.customColor.stroke,
      }}>
      <CustomTable data={conditions} columns={column} dataRowHover={false} />

      {isDeleteRule && (
        <ConfirmationDialog
          title='Are you sure you want to delete rule?'
          description='Once deleted this rule cannot be recovered'
          isOpen={isDeleteRule}
          onClose={toggleDeleteRule}
          isLoading={props.isUpdatingPatientRule}
          onClickConfirm={handleSubmitDeleteRule}
        />
      )}
    </Paper>
  );
};

PatientRuleItem.defaultProps = {
  hideSaveBtnRuleItem: false,
};

export default PatientRuleItem;
