/** @format */

import { AxiosRequestConfig, CancelToken } from 'axios';
import AxiosInstance from './index';
import config from '../config/config';

export const getSystemConfigMedications = async () => {
  const res = await AxiosInstance({
    method: 'get',
    baseURL: config.patientApiUrl,
    url: '/config/medications',
  });

  return res.data || {};
};

export const getSystemConfigMedicationsWithBrandNames = async (
  limit: number,
  offset: number,
  searchByBrandName?: string,
  searchByMedicationName?: string,
  searchByManufacturers?: string,
  cancelToken?: CancelToken,
) => {
  const options: AxiosRequestConfig = {};

  if (cancelToken) {
    options.cancelToken = cancelToken;
  }

  const res = await AxiosInstance({
    ...options,
    method: 'get',
    baseURL: config.patientApiUrl,
    url: '/config/medications/brand-name',
    params: {
      limit,
      offset,
      searchByBrandName,
      searchByMedicationName,
      searchByManufacturers,
    },
  });

  return res.data || {};
};
