/** @format */

import { PatientVitalsReadingType } from './../types/PatientVitals.types';
import { BLOOD_PRESSURE, SYSTOLIC, DIASTOLIC, MANUAL_ENTRY, HEIGHT } from '../constants/PatientVitals';
import { DateTime } from 'luxon';
export const formatPatientVitals = (type: string, value?: string, date?: string | Date | number, unit?: string, name?: string): PatientVitalsReadingType => {
  const measurements = [];

  if (type === BLOOD_PRESSURE) {
    const splitValue = value?.split('/');

    if (splitValue && splitValue.length > 0) {
      measurements.push(
        {
          name: SYSTOLIC,
          value: splitValue[0],
          unit: 'mmHg',
        },
        {
          name: DIASTOLIC,
          value: splitValue[1],
          unit: 'mmHg',
        },
      );
    }
  } else if (type === HEIGHT) {
    const splitValue = value?.split('/');

    if (splitValue && splitValue.length > 0) {
      measurements.push(
        {
          name: 'FEET',
          value: splitValue[0],
          unit: 'Feet',
        },
        {
          name: 'INCHES',
          value: splitValue[1],
          unit: 'Inches',
        },
      );
    }
  } else {
    measurements.push({
      name,
      value,
      unit,
    });
  }
  return {
    type,
    measurements,
    timestamp: date ? (DateTime.fromJSDate(new Date(date)).toISO() as string) : '',
    sourceType: MANUAL_ENTRY,
  };
};
