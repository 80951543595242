/** @format */

import CONFIG from '../config/config';
import AxiosInstance from './index';

export const fetchPatientMedicalEvents = async (sourceId: string, startTime: string, endTime: string) => {
  const res = await AxiosInstance({
    method: 'GET',
    baseURL: CONFIG.patientApiUrl,
    url: `patients/${sourceId}/medical-events`,
    params: {
      startTime,
      endTime,
    },
  });
  return res.data || {};
};
