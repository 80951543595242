/** @format */

import React from 'react';

interface VitalsGraphIconProps {}

export const VitalsGraphIcon: React.FC<VitalsGraphIconProps> = () => {
  return (
    <svg width='16' height='12' viewBox='0 0 16 12' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M6.00002 11.3327C5.78891 11.3327 5.60002 11.2716 5.43335 11.1493C5.26669 11.0271 5.14446 10.8716 5.06669 10.6827L3.53335 6.66602H0.666687V5.33268H4.46669L6.00002 9.39935L9.06669 1.31602C9.14446 1.12713 9.26669 0.971571 9.43335 0.849349C9.60002 0.727127 9.78891 0.666016 10 0.666016C10.2111 0.666016 10.4 0.727127 10.5667 0.849349C10.7334 0.971571 10.8556 1.12713 10.9334 1.31602L12.4667 5.33268H15.3334V6.66602H11.5334L10 2.59935L6.93335 10.6827C6.85558 10.8716 6.73335 11.0271 6.56669 11.1493C6.40002 11.2716 6.21113 11.3327 6.00002 11.3327Z'
        fill='black'
      />
    </svg>
  );
};
