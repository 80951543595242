/** @format */

import React from 'react';
import ReactDOM from 'react-dom/client';
import reportWebVitals from './reportWebVitals';
import * as Sentry from '@sentry/react';
import { BrowserTracing } from '@sentry/tracing';
import CONFIG from './config/config';

// App.
import App from './App';

if (CONFIG.UISentryUrl) {
  Sentry.init({
    dsn: CONFIG.UISentryUrl,
    integrations: [new BrowserTracing()],
    tracesSampleRate: 1.0,
  });
}

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
root.render(
  <React.StrictMode>
    <App />,
  </React.StrictMode>,
);

reportWebVitals();
