/** @format */

import React, { useContext } from 'react';
import { Box } from '@mui/material';
import { Kccq } from './Kccq';
import { WalkTest } from './WalkTest';
import PatientRuleThreshold from '../../patient-rule/PatientRuleThreshold';
import { PatientProfileContext } from '../../../pages/patient-profile/PatientProfileContext';
import { PATIENT_RULE_ACTION_KIND_MEDICATION_CHANGE } from '../../../constants/PatientRules';
import PatientRuleList from '../../patient-rule/PatientRuleList';

export const Assessments = () => {
  const [sidebarOption, setSidebarOption] = React.useState<string>('kccq-test');

  const {
    patientRuleThreshold,
    isUpdatingPatientRuleThreshold,
    handleSubmitUpdatePatientRuleThreshold,
    patientRules,
    isUpdatingPatientRule,
    handleSubmitChangeDefaultRules,
    handleSubmitNewPatientRule,
    handleSubmitUpdatePatientRule,
    handleSubmitDeletePatientRule,
  } = useContext(PatientProfileContext);

  const handleChangeSidebarOption = (payload: string) => {
    setSidebarOption(payload);
  };

  const _renderSidebar = () => {
    const OPTIONS = [
      {
        id: 1,
        title: 'KCCQ Test',
        value: 'kccq-test',
      },

      {
        id: 2,
        title: '6 Min. Walk Test',
        value: '6-min-walk-test',
      },

      // {
      //   id: 3,
      //   title: 'Alert Thresholds',
      //   value: 'alert-thresholds',
      // },

      // {
      //   id: 4,
      //   title: 'Patient Rules',
      //   value: 'patient-rules',
      // },
    ];

    return (
      <Box sx={{ border: '1px solid #9bb4cc', padding: '5px' }}>
        {OPTIONS.map((e) => {
          return (
            <Box
              onClick={() => handleChangeSidebarOption(e.value)}
              sx={{ padding: '10px', cursor: 'pointer', marginBottom: '10px', '&:hover': { backgroundColor: '#eff1ff' }, backgroundColor: sidebarOption === e.value ? '#eff1ff' : '' }}>
              {e.title}
            </Box>
          );
        })}
      </Box>
    );
  };

  const _renderSidebarComponent = () => {
    switch (sidebarOption) {
      case 'kccq-test':
        return <Kccq />;

      case '6-min-walk-test':
        return <WalkTest />;

      case 'alert-thresholds':
        return (
          <Box>
            <PatientRuleThreshold
              rules={patientRuleThreshold}
              isUpdatingPatientRuleThreshold={isUpdatingPatientRuleThreshold}
              handleSubmitUpdatePatientRuleThreshold={handleSubmitUpdatePatientRuleThreshold}
            />
          </Box>
        );

      case 'patient-rules':
        return (
          <PatientRuleList
            itemControlEdit
            patientRules={patientRules.filter((item) => item.ruleKind === PATIENT_RULE_ACTION_KIND_MEDICATION_CHANGE)}
            isUpdatingPatientRule={isUpdatingPatientRule}
            handleClickChangeRules={handleSubmitChangeDefaultRules}
            handleSubmitNewPatientRule={handleSubmitNewPatientRule}
            handleSubmitUpdatePatientRule={handleSubmitUpdatePatientRule}
            handleSubmitDeletePatientRule={handleSubmitDeletePatientRule}
          />
        );
    }
  };

  return (
    <Box sx={{ display: 'flex', height: '100%' }}>
      <Box sx={{ flex: 1, marginRight: '5px' }}>{_renderSidebar()}</Box>
      <Box sx={{ flex: 3, padding: '0 5px' }}>{_renderSidebarComponent()}</Box>
    </Box>
  );
};
