/** @format */

import React from 'react';

import DialogTitle from '@mui/material/DialogTitle';
import Dialog from '@mui/material/Dialog';

import { Box, Button, CircularProgress, DialogActions, DialogContent, ListItem, Typography } from '@mui/material';

// Component props.
interface ConfirmationDialogProps {
  title: string | JSX.Element;
  description: string | string[];
  isOpen: boolean;
  onClose: () => void;
  onClickConfirm?: () => void;
  isLoading?: boolean;
  disabled?: boolean;

  submitText?: string;
  cancelText?: string;
}

const ConfirmationDialogComponent: React.FC<ConfirmationDialogProps> = ({ title, description, isOpen, onClose, onClickConfirm, isLoading, disabled, ...props }) => {
  return (
    <Dialog fullWidth maxWidth='xs' onClose={onClose} open={isOpen}>
      <DialogTitle variant='fontSemiBold18'>{title}</DialogTitle>

      <DialogContent>
        {Array.isArray(description) &&
          description?.map((e) => (
            <Box key={e} sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
              <ListItem>{e}</ListItem>
            </Box>
          ))}
        {!Array.isArray(description) && <Typography sx={{ marginBottom: '20px' }}>{description}</Typography>}
      </DialogContent>

      <DialogActions>
        <Button color='primary' variant='contained' type='reset' onClick={onClickConfirm} disabled={isLoading || disabled}>
          {isLoading && <CircularProgress sx={{ mr: 1 }} />}
          {props.submitText || 'Confirm'}
        </Button>
        <Button color='error' variant='outlined' onClick={onClose} sx={{ marginRight: '10px' }}>
          {props.cancelText || 'Cancel'}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

ConfirmationDialogComponent.defaultProps = {
  submitText: 'Confirm',
  cancelText: 'Cancel',
};

export const ConfirmationDialog = ConfirmationDialogComponent;
