/** @format */

import AxiosInstance from './index';
import CONFIG from '../config/config';

export const fetchPatientSymptomsConfig = async () => {
  const res = await AxiosInstance({
    method: 'GET',
    baseURL: CONFIG.patientApiUrl,
    url: '/config/symptoms',
  });

  return res.data || {};
};

export const updatePatientSymptoms = async (sourceId: string, payload: any) => {
  const res = await AxiosInstance({
    method: 'POST',
    baseURL: CONFIG.patientApiUrl,
    url: `patients/${sourceId}/symptoms`,
    data: payload,
  });

  return res.data || {};
};
