/** @format */

import * as React from 'react';

import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';

import GroupOutlinedIcon from '@mui/icons-material/GroupOutlined';
import FolderSharedOutlinedIcon from '@mui/icons-material/FolderSharedOutlined';
import VideoCameraFrontOutlinedIcon from '@mui/icons-material/VideoCameraFrontOutlined';

import { PatientPrescriptionReviewListType } from '../../types/PrescriptionsGenerator.types';

interface PatientReviewReviewEventTypeCellProps {
  rowData: PatientPrescriptionReviewListType;
}

const PatientReviewReviewEventTypeCell: React.FunctionComponent<PatientReviewReviewEventTypeCellProps> = (props) => {
  let Icon = FolderSharedOutlinedIcon;
  let subText = 'No appointment';

  const columnValue = props.rowData;

  if (columnValue.appointmentId && columnValue.appointmentInfo) {
    if (columnValue.appointmentInfo?.appointmentPlace === 'PHYSICAL') {
      Icon = GroupOutlinedIcon;
      subText = 'In-person Appointment';
    } else {
      Icon = VideoCameraFrontOutlinedIcon;
      subText = 'Virtual appointment';
    }
  }

  return (
    <Grid item xs={12} container alignItems='center' gap={2}>
      <Grid item xs='auto'>
        <Icon htmlColor='#33425B' />
      </Grid>

      <Grid item xs='auto'>
        <Typography component='div' variant='fontSemiBold14' color='#33425B' sx={{ textTransform: 'capitalize' }}>
          Review {columnValue.appointmentId && '+'}
        </Typography>
        <Typography
          component='div'
          variant='fontReg12'
          color='#5C6A90'
          sx={{
            textTransform: 'capitalize',
            pt: 0.7,
            marginRight: '5px',
            display: 'inline-block',
            maxWidth: '90px',
            whiteSpace: 'nowrap',
            overflow: 'hidden !important',
            textOverflow: 'ellipsis',
          }}>
          {subText}
        </Typography>
      </Grid>
    </Grid>
  );
};

export default PatientReviewReviewEventTypeCell;
