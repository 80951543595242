/** @format */
import * as _ from 'underscore';
export function validateMobileNumber (value: string) {
  const pattern = /^((\+\d{1,3}(-| )?\(?\d\)?(-| )?\d{1,5})|(\(?\d{2,6}\)?))(-| )?(\d{3,4})(-| )?(\d{4})(( x| ext)\d{1,5}){0,1}$/;
  return pattern.test(value);
}

export function validateEmailAddress (value: string) {
  const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return pattern.test(value);
}

export const validateAllErrors = (arr: any[]) => {
  for (let i = 0; i < arr.length; i++) {
    if (Object.keys(arr[i]).length !== 0) {
      return false;
    }
  }

  return true;
};

export const validatePassword = (value: string) => {
  if (_.isEmpty(value) || value.length < 6) {
    return false;
  }
  return true;
  // const pattern = /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/;

  // return pattern.test(value);
};

export const calculateProgress = (obj: any) => {
  const basicDetailsValues = [
    'address',
    'age',
    'email',
    'ethnicity',
    'firstName',
    'gender',
    'lastName',
    'patientId',
    'patientStatus',
    'phoneNumber',
    // 'phoneNumberAlt',
    'patientSourceId',
    // 'relationshipAltContact',
  ];

  const medicalHistoryValues = ['heartFailureMedicalHistory', 'dateOfHFDiagnosis', 'hfType', 'lastHospitalizationDate', 'lvef', 'nyhaClass', 'symptoms', 'allergies'];

  const vitalsValues = [
    'BLOOD_PRESSURE',
    'HEART_RATE',
    'WEIGHT',
    'CALCIUM',
    'BLOOD_UREA_NITROGEN',
    'SODIUM',
    'BICARBONATE',
    'CHLODIDE',
    'NTPROBNP',
    'SCR',
    'IRON',
    'EGFR',
    'POTASSIUM',
    'HBA1C',
    'HEIGHT',
  ];

  let basic = 0;
  let medicalHistory = 0;
  let vitals = 0;

  if (obj.basicDetails) {
    for (const key in obj.basicDetails) {
      if (basicDetailsValues.includes(key) && obj.basicDetails[key] && obj.basicDetails[key].length !== 0) {
        basic += 1;
      }
    }
  }

  if (obj.medicalHistory) {
    // if (obj.medicalHistory.comorbidities && obj.medicalHistory.comorbidities.length !== 0) {
    //   medicalHistory += 1;
    // }

    if (obj.medicalHistory.allergies && obj.medicalHistory.allergies.length !== 0) {
      medicalHistory += 1;
    }

    if (obj.medicalHistory.heartFailureMedicalHistory) {
      const currentObj = obj.medicalHistory.heartFailureMedicalHistory;

      if (currentObj.dateOfHFDiagnosis && currentObj.dateOfHFDiagnosis.length !== 0) {
        medicalHistory += 1;
      }

      if (currentObj.hfType && currentObj.hfType.length !== 0) {
        medicalHistory += 1;
      }

      if (currentObj.lastHospitalizationDate && currentObj.lastHospitalizationDate.length !== 0) {
        medicalHistory += 1;
      }

      if (currentObj.lvef && currentObj.lvef.length !== 0) {
        medicalHistory += 1;
      }

      if (currentObj.nyhaClass && currentObj.nyhaClass.length !== 0) {
        medicalHistory += 1;
      }
    }

    if (obj.medicalHistory.vitalsReference && obj.medicalHistory.vitalsReference.length !== 0) {
      const vitalsArr = obj.medicalHistory.vitalsReference;

      for (let i = 0; i < vitalsArr.length; i++) {
        if (vitalsValues.includes(vitalsArr[i].type) && vitalsArr[i].measurements[0]?.value?.length !== 0) {
          vitals += 1;
        }
      }
    }
  }

  const currentTotal = basic + vitals + medicalHistory;

  const overallTotal = basicDetailsValues.length + vitalsValues.length + medicalHistoryValues.length;

  const percentage = (currentTotal / overallTotal) * 100;

  return percentage.toFixed(1);
};
