/** @format */

import { PayloadAction, createSlice } from '@reduxjs/toolkit';

// Types and interfaces.
import { RootState } from '../../app/store';
import { IDLE_TIMEOUT } from '../../constants/timeout';

export interface ConfigStateInterface {
  patientBasic: {
    address: boolean;
    patientId: boolean;
    firstName: boolean;
    lastName: boolean;
    age: boolean;
    gender: boolean;
    ethnicity: boolean;
    email: boolean;
    phoneNumber: boolean;
    phoneNumberAlt: boolean;
    relationshipAltContact: boolean;
    relationshipAltContactName: boolean;
    enrollmentDate: boolean;
  };

  vitals: {
    bp: boolean;
    hr: boolean;
    weight: boolean;
    bnp: boolean;
    glucose: boolean;
    bloodUreaNitrogen: boolean;
    ntprobnp: boolean;
    scr: boolean;
    hemoglobin: boolean;
    height: boolean;
    egfr: boolean;
    hematocrit: boolean;
    bmi: boolean;
    potassium: boolean;
    sodium: boolean;
    chloride: boolean;
    calcium: boolean;
    bicarbonate: boolean;
    hba1c: boolean;
  };

  vitalsUnit: {
    [key: string]: string;
    bmi: string;
    sCr: string;
    eGFR: string;
    hba1c: string;
    height: string;
    sodium: string;
    weight: string;
    calcium: string;
    glucose: string;
    chloride: string;
    ntprobnp: string;
    heartRate: string;
    potassium: string;
    hematocrit: string;
    hemoglobin: string;
    bicarbonate: string;
    bloodPressure: string;
    bloodUreaNitrogen: string;
  };

  isLoading: boolean;

  orgUserConfig: {
    isEnable: boolean;
    inactivityDuration: string;
  };
}

export const initialState: ConfigStateInterface = {
  patientBasic: {
    address: true,
    patientId: true,
    firstName: true,
    lastName: true,
    age: true,
    gender: true,
    ethnicity: true,
    email: true,
    phoneNumber: true,
    phoneNumberAlt: true,
    relationshipAltContact: true,
    relationshipAltContactName: true,
    enrollmentDate: true,
  },

  vitals: {
    bp: true,
    hr: true,
    weight: true,
    bnp: true,
    glucose: true,
    bloodUreaNitrogen: true,
    ntprobnp: true,
    scr: true,
    hemoglobin: true,
    height: true,
    egfr: true,
    hematocrit: true,
    bmi: true,
    potassium: true,
    sodium: true,
    chloride: true,
    calcium: true,
    bicarbonate: true,
    hba1c: true,
  },
  vitalsUnit: {
    bmi: '',
    sCr: '',
    eGFR: '',
    hba1c: '',
    height: '',
    sodium: '',
    weight: '',
    calcium: '',
    glucose: '',
    chloride: '',
    ntprobnp: '',
    heartRate: '',
    potassium: '',
    hematocrit: '',
    hemoglobin: '',
    bicarbonate: '',
    bloodPressure: '',
    bloodUreaNitrogen: '',
  },

  isLoading: false,

  orgUserConfig: {
    isEnable: false,
    inactivityDuration: IDLE_TIMEOUT,
  },
};

export const configReducer = createSlice({
  name: 'config',
  initialState,
  reducers: {
    clearConfigStore: () => {
      return initialState;
    },
    updateConfigStore: (state, { payload }: PayloadAction<any>) => {
      state.patientBasic = payload.enrolmentConfig?.patientBasic;
      state.vitals = payload.enrolmentConfig?.vitals;
      state.vitalsUnit = payload.vitalsUnitConfig?.vitalsUnit;
      state.orgUserConfig = payload.orgUserConfig;
    },
  },
});

export const configSelector = (state: RootState) => state.config;

export const { clearConfigStore, updateConfigStore } = configReducer.actions;
