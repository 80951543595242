/** @format */

import * as React from 'react';

import Menu from '@mui/material/Menu';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';
import MenuItem from '@mui/material/MenuItem';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';

import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import MoreVertOutlinedIcon from '@mui/icons-material/MoreVertOutlined';
import RemoveRedEyeOutlinedIcon from '@mui/icons-material/RemoveRedEyeOutlined';
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';

interface PatientReviewActionBtnCellProps {
  showStartReview?: boolean;
  handleClickStartReview?: () => void;

  showRescheduleOption?: boolean;
  disableRescheduleOption?: boolean;
  handleClickReschedule?: () => void;

  showDeleteOption?: boolean;
  disableDeleteOption?: boolean;
  handleClickDelete?: () => void;

  showViewReview?: boolean;
  handleClickViewReview?: () => void;

  showResume?: boolean;
  handleClickResumne?: () => void;

  showDownloadReview?: boolean;
  handleClickDownloadReview?: () => void;

  showEditReview?: boolean;
  handleClickEditReview?: () => void;

  showMarkAsAddressed?: boolean;
  handleClickMarkAsAddressed?: () => void;

  showScheduleReview?: boolean;
  handleClickScheduleReview?: () => void;
}

const PatientReviewActionBtnCell: React.FunctionComponent<PatientReviewActionBtnCellProps> = (props) => {
  const [menuAnchorEl, setMenuAnchorEl] = React.useState<null | HTMLElement>(null);

  const handleClickMenu = (event: React.MouseEvent<HTMLElement>) => {
    setMenuAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setMenuAnchorEl(null);
  };

  const ITEM_HEIGHT = 48;

  const options = [
    {
      label: 'Reschedule',
      value: 'RESCHEDULE',
      onClick: () => {
        props.handleClickReschedule?.();
        handleClose();
      },
      isDisabled: props.disableRescheduleOption,
      isVisible: props.showRescheduleOption,
    },
    {
      label: 'Delete',
      value: 'DELETE',
      onClick: () => {
        props.handleClickDelete?.();
        handleClose();
      },
      isDisabled: props.disableDeleteOption,
      isVisible: props.showDeleteOption,
    },
    {
      label: 'Mark as Addressed',
      value: 'MARK_AS_ADDRESSED',
      onClick: () => {
        props.handleClickMarkAsAddressed?.();
        handleClose();
      },
      isVisible: props.showMarkAsAddressed,
    },
  ].filter((item) => item.isVisible);

  return (
    <Grid item xs={12} container justifyContent='flex-end' gap={2}>
      {props.showScheduleReview && (
        <Button
          size='small'
          variant='outlined'
          sx={{
            borderColor: '#9BB4CC',
            py: 1,
            '&:hover, &:active, &:focus ': { borderColor: '#9BB4CC !important' },
          }}
          onClick={props.handleClickScheduleReview}>
          <Typography variant='fontSemiBold14' color='#3E4685'>
            Schedule Review
          </Typography>
        </Button>
      )}

      {props.showStartReview && (
        <Button
          size='small'
          variant='outlined'
          sx={{
            backgroundColor: '#ffff !important',
            borderColor: '#9BB4CC',
            py: 1,
            '&:hover, &:active, &:focus ': { borderColor: '#9BB4CC !important' },
          }}
          onClick={props.handleClickStartReview}>
          <Typography variant='fontSemiBold14' color='#3E4685'>
            Start Review
          </Typography>
        </Button>
      )}

      {props.showResume && (
        <Button
          size='small'
          variant='outlined'
          onClick={props.handleClickResumne}
          sx={{
            borderColor: '#9BB4CC',
            py: 1,
            '&:hover, &:active, &:focus ': { borderColor: '#9BB4CC !important' },
          }}>
          <Typography variant='fontSemiBold14' color='#3E4685'>
            Resume
          </Typography>
        </Button>
      )}

      {props.showViewReview && (
        <IconButton onClick={props.handleClickViewReview}>
          <RemoveRedEyeOutlinedIcon />
        </IconButton>
      )}

      {props.showDownloadReview && (
        <IconButton onClick={props.handleClickDownloadReview}>
          <FileDownloadOutlinedIcon />
        </IconButton>
      )}

      {props.showEditReview && (
        <IconButton onClick={props.handleClickEditReview}>
          <EditOutlinedIcon />
        </IconButton>
      )}

      {options && options.length > 0 && (
        <IconButton onClick={handleClickMenu}>
          <MoreVertOutlinedIcon htmlColor='#3E4685' />
        </IconButton>
      )}

      <Menu
        id='long-menu'
        MenuListProps={{
          'aria-labelledby': 'long-button',
        }}
        anchorEl={menuAnchorEl}
        open={Boolean(menuAnchorEl)}
        onClose={handleClose}
        elevation={0}
        sx={{
          '& ul': {
            p: 0,
            border: '1px solid #9BB4CC',
            borderRadius: 0,
          },
        }}
        PaperProps={{
          style: {
            maxHeight: ITEM_HEIGHT * 4.5,
            width: '20ch',
            padding: 0,
            marginTop: '12px',
            marginLeft: '-30px',
            borderRadius: 0,
          },
        }}>
        {options.map((option, index) => (
          <React.Fragment key={index}>
            <MenuItem key={option.value} disabled={option.isDisabled} onClick={option.onClick}>
              <Typography color='#33425B' variant='fontReg14'>
                {option.label}
              </Typography>
            </MenuItem>
            {index !== options.length - 1 && <Divider sx={{ borderColor: '#9BB4CC', m: '0px !important' }} />}
          </React.Fragment>
        ))}
      </Menu>
    </Grid>
  );
};

export default PatientReviewActionBtnCell;
