/** @format */

import * as React from 'react';
import Timeline, { TimelineHeaders, SidebarHeader, DateHeader, TimelineGroupBase } from 'react-calendar-timeline';
import { TimeplanItemsType } from '../../../types/TitrationCalendar.types';

// CSS
import { Button, Checkbox, FormControlLabel, FormGroup, Grid, Paper, Popover, Typography } from '@mui/material';
import FormFields from '../form-fields/FormFields';
import { appTheme } from '../../../context/AppTheme';
import { ErrorBoundary } from 'react-error-boundary';
import { ComponentErrorBoundary } from '../error/ComponentErrorBoundary';

import 'react-calendar-timeline/lib/Timeline.css';
import './TitrationCalendar.scss';

interface TitrationCalendarProps {
  editMode?: boolean;
  rootWidth?: string;

  itemTouchSendsClick?: boolean;
  traditionalZoom?: boolean;
  canMove?: boolean;
  canResize?: false | true | 'left' | 'right' | 'both' | undefined;

  sideBarTitle: string;

  keys?: any;
  groups: TimelineGroupBase[];
  items: TimeplanItemsType[];

  groupRenderer?: (group: any) => JSX.Element;
  itemRenderer?: (item: any) => JSX.Element;

  verticalLineClassNamesForTime?: (start: number, end: number) => string[] | undefined;

  onItemMove?: (selectedItemId: any, modifiedTimeMilli: any, newGroupOrder: any) => void;
  onItemResize?: (itemId: any, time: any, edge: any) => void;

  defaultTimeEnd: any;
  defaultTimeStart: any;

  itemHeightRatio?: number;
  lineHeight?: number;

  onChangeItemStartEndDate?: (selectedItemId: string, startMillis: number, endMillis: number) => void;
}

const TitrationCalendar: React.FunctionComponent<TitrationCalendarProps> = (props) => {
  // Making unique Keys
  /* for (let key in props.keys) {
    props.keys[key] = props.keys[key] + Math.random();
  } */

  const defaultTimeRange: any = props.defaultTimeEnd - props.defaultTimeStart;

  const [showYear, setShowYear] = React.useState<boolean>(true);
  const [showWeek, setShowWeek] = React.useState<boolean>(true);
  const [showDay, setShowDay] = React.useState<boolean>(true);
  const [itemPopover, setItemPopover] = React.useState<{ anchorEl?: HTMLButtonElement | null; selectedItemId?: any; startDate?: string; endDate?: string }>({});

  const itemRenderer = ({ item, timelineContext, itemContext, getItemProps, getResizeProps, ...restProps }: any) => {
    if (props.itemRenderer) {
      return props.itemRenderer({ item, timelineContext, itemContext, getItemProps, getResizeProps });
    }

    const { left: leftResizeProps, right: rightResizeProps } = getResizeProps();

    const backgroundColor = itemContext.selected ? 'yellow' : item.bgColor;
    const borderColor = itemContext.selected ? 'red' : item.borderColor;

    const innerProps = getItemProps({
      style: {
        background: backgroundColor,
        backgroundColor,
        color: item.textColor,
        borderColor,
        borderStyle: 'solid',
        borderWidth: itemContext.selected ? 2 : 1,
        borderLeftWidth: itemContext.selected ? 2 : 1,
        borderRightWidth: itemContext.selected ? 2 : 1,
      },
    });

    let title = itemContext.title;

    if (itemContext.title && itemContext.title.includes('\n')) {
      title = itemContext.title.split('\n').map((item: string, index: number) => (
        <div key={index} style={{ height: '12px', marginTop: index === 0 ? '-8px' : '5px' }}>
          {item}
        </div>
      ));
    }

    const handleClickOnItem = (event: React.MouseEvent<HTMLButtonElement>) => {
      if (item.id && props.onChangeItemStartEndDate) {
        setItemPopover({ selectedItemId: item.id, anchorEl: event.currentTarget });
      }
    };

    return (
      <div {...innerProps} key={item.id} onDoubleClick={handleClickOnItem}>
        {itemContext.useResizeHandle ? <div {...leftResizeProps} /> : null}

        <div
          style={{
            height: itemContext.dimensions.height,
            overflow: 'hidden',
            paddingLeft: 3,
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap',
            position: 'sticky',
            left: '0px',
            display: 'inline-block',
            borderRadius: '2px',
            padding: '0 6px',
            width: 'inherit',
          }}>
          {title}
        </div>

        {itemContext.useResizeHandle ? <div {...rightResizeProps} /> : null}
      </div>
    );
  };

  const groupRenderer = ({ group }: any) => {
    if (props.groupRenderer) {
      return props.groupRenderer({ group });
    }
    return (
      <div className='group-container' key={group}>
        <div className='group-title'>{group.title}</div>
        <div className='group-subtitle'>{group.subTitle}</div>
      </div>
    );
  };

  const handleClickSaveDate = () => {
    if (props.onChangeItemStartEndDate) {
      props.onChangeItemStartEndDate(itemPopover.selectedItemId, new Date(`${itemPopover.startDate!} 00:00:00`).getTime(), new Date(`${itemPopover.endDate!} 23:59:59`).getTime());
    }
    handleCloseItemPopover();
  };

  const handleCloseItemPopover = () => {
    setItemPopover({});
  };

  return (
    <ErrorBoundary fallbackRender={ComponentErrorBoundary}>
      <div className='custom-titration-calender' style={{ width: props.rootWidth, maxWidth: '92vw', overflow: 'auto' }}>
        <FormGroup row>
          <FormControlLabel control={<Checkbox sx={{ display: 'none' }} />} sx={{ ml: 0 }} label='Display: ' />
          <FormControlLabel control={<Checkbox checked={showDay} onChange={() => setShowDay((prev) => !prev)} name='day' />} label='Day' />
          <FormControlLabel control={<Checkbox checked={showWeek} onChange={() => setShowWeek((prev) => !prev)} name='week' />} label='Week' />
          <FormControlLabel control={<Checkbox checked={showYear} onChange={() => setShowYear((prev) => !prev)} name='year' />} label='Year' />
        </FormGroup>

        {props.editMode ? null : (
          <Popover
            open={Boolean(itemPopover.anchorEl)}
            anchorEl={itemPopover.anchorEl}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'left',
            }}
            onClose={handleCloseItemPopover}>
            <Paper elevation={10} sx={{ width: '100%', p: 3 }}>
              <Typography variant='fontSemiBold18' color={appTheme.palette.customColor.primary}>
                Please select Date Range for the Dosage
              </Typography>
              <Grid container alignItems='center' gap={2}>
                <Grid item xs={5.7}>
                  <FormFields
                    label='Start Date'
                    fullWidth
                    id='start-date'
                    componentType='TEXT_INPUT_COMPONENT'
                    input={{
                      value: itemPopover.startDate,
                      type: 'date',
                      onChange: (event: any) => setItemPopover((prev) => ({ ...prev, startDate: event.target.value })),
                    }}
                  />
                </Grid>
                <Grid item xs={5.7}>
                  <FormFields
                    label='End Date'
                    fullWidth
                    id='end-date'
                    componentType='TEXT_INPUT_COMPONENT'
                    input={{
                      value: itemPopover.endDate,
                      type: 'date',
                      onChange: (event: any) => setItemPopover((prev) => ({ ...prev, endDate: event.target.value })),
                    }}
                  />
                </Grid>
              </Grid>
              <div style={{ height: '2rem' }}>
                <Button disabled={!itemPopover.startDate || !itemPopover.endDate} variant='contained' color='primary' sx={{ float: 'right' }} onClick={handleClickSaveDate}>
                  Save
                </Button>
              </div>
            </Paper>
          </Popover>
        )}

        <Timeline
          keys={props.keys}
          groups={props.groups}
          items={props.items as any}
          itemTouchSendsClick={props.itemTouchSendsClick}
          traditionalZoom={props.traditionalZoom}
          canMove={props.canMove}
          canResize={props.canResize}
          groupRenderer={groupRenderer}
          itemRenderer={itemRenderer}
          verticalLineClassNamesForTime={props.verticalLineClassNamesForTime}
          onItemMove={props.onItemMove}
          lineHeight={props.lineHeight}
          onItemResize={props.onItemResize}
          itemHeightRatio={props.itemHeightRatio}
          minZoom={defaultTimeRange}
          maxZoom={defaultTimeRange}
          defaultTimeStart={props.defaultTimeStart}
          defaultTimeEnd={props.defaultTimeEnd}>
          <TimelineHeaders className='sticky timeline-header'>
            <SidebarHeader>
              {({ getRootProps }) => {
                return (
                  <div {...getRootProps()} className='sidebar-title'>
                    {props.sideBarTitle}
                  </div>
                );
              }}
            </SidebarHeader>
            {showYear && <DateHeader unit='month' />}
            {showWeek && <DateHeader unit='week' />}
            {showDay && <DateHeader style={{ width: '30px' }} unit='day' />}
          </TimelineHeaders>
        </Timeline>
      </div>
    </ErrorBoundary>
  );
};

TitrationCalendar.defaultProps = {
  rootWidth: '93rem',
  itemTouchSendsClick: false,
  traditionalZoom: false,
  canMove: true,
  canResize: true,
  keys: {},
  itemHeightRatio: 1,
};

export default TitrationCalendar;
