/** @format */

import React from 'react';

// SCSS.
import { Grid, Typography, useTheme } from '@mui/material';
import { ObjectFieldTemplateProps } from 'react-jsonschema-form';

export const GridColumnObjectTemplate = (props: ObjectFieldTemplateProps) => {
  const appTheme = useTheme();

  return (
    <Grid id={`grid-template-areas-${props.uiSchema['ui:fields']?.className}`} container display='flex' flexDirection='column'>
      <Typography variant='fontSemiBold16' color={appTheme.palette.customColor.pageTitle} component='div' mb={3} sx={{ marginLeft: '5px' }}>
        {props.title}
      </Typography>
      {props.properties.map((element, idx) => {
        return (
          <Grid item key={idx} {...element?.content?.props?.uiSchema?.['ui:grid']}>
            {element.content}
          </Grid>
        );
      })}
    </Grid>
  );
};
