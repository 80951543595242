/** @format */

import * as React from 'react';

interface CareEditIconProps {
  width?: number | string;
  height?: number | string;
  fill?: string;
}

const CareEditIcon: React.FunctionComponent<CareEditIconProps> = () => {
  return (
    <svg width='14' height='14' viewBox='0 0 14 14' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M1.33333 13.5C0.966667 13.5 0.652778 13.3694 0.391667 13.1083C0.130556 12.8472 0 12.5333 0 12.1667V2.83333C0 2.46666 0.130556 2.15277 0.391667 1.89166C0.652778 1.63055 0.966667 1.49999 1.33333 1.49999H7.28333L5.95 2.83333H1.33333V12.1667H10.6667V7.53333L12 6.19999V12.1667C12 12.5333 11.8694 12.8472 11.6083 13.1083C11.3472 13.3694 11.0333 13.5 10.6667 13.5H1.33333ZM4 9.49999V6.66666L10.1167 0.549994C10.25 0.416661 10.4 0.316661 10.5667 0.249994C10.7333 0.183327 10.9 0.149994 11.0667 0.149994C11.2444 0.149994 11.4139 0.183327 11.575 0.249994C11.7361 0.316661 11.8833 0.416661 12.0167 0.549994L12.95 1.49999C13.0722 1.63333 13.1667 1.78055 13.2333 1.94166C13.3 2.10277 13.3333 2.26666 13.3333 2.43333C13.3333 2.59999 13.3028 2.76388 13.2417 2.92499C13.1806 3.0861 13.0833 3.23333 12.95 3.36666L6.83333 9.49999H4ZM5.33333 8.16666H6.26667L10.1333 4.29999L9.66667 3.83333L9.18333 3.36666L5.33333 7.21666V8.16666Z'
        fill='#3E4685'
      />
    </svg>
  );
};

CareEditIcon.defaultProps = {
  width: '14',
  height: '14',
  fill: '#3E4685',
};

export default CareEditIcon;
