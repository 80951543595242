/** @format */

import { DateTime, ToRelativeOptions } from 'luxon';
import { DATE_FORMAT } from '../constants/common';

export const getRelativeDate = (date: string | number | Date, defaultValue = 'NA', overrideCurrentDate?: string | Date, relativeCalendar = false) => {
  if (date) {
    const options: ToRelativeOptions = {};

    if (overrideCurrentDate) {
      options.base = DateTime.fromJSDate(new Date(overrideCurrentDate));
    }

    if (relativeCalendar) {
      return DateTime.fromJSDate(new Date(date)).toRelativeCalendar({ base: options.base });
    }
    return DateTime.fromJSDate(new Date(date)).toRelative(options);
  }
  return defaultValue;
};

export const getDateFormat = (date?: string | number | Date, dateFormat: string = DATE_FORMAT, defaultValue = 'NA') => {
  if (date) {
    return DateTime.fromJSDate(new Date(date)).toFormat(dateFormat);
  }
  return defaultValue;
};

export const removeMonthFromDate = (date: string) => {
  const pattern = /(January|February|March|April|May|June|July|August|September|October|November|December)\s\d{1,2}\b/g;

  const result = date.replace(pattern, '').replace(',', '');

  return result;
};

export const addDatePrefixOriginal = (number: number) => {
  const suffixes = ['th', 'st', 'nd', 'rd'];
  const v = number % 100;
  return number + (suffixes[(v - 20) % 10] || suffixes[v] || suffixes[0]);
};
