/** @format */

import * as React from 'react';

import Box from '@mui/material/Box';
import Chip from '@mui/material/Chip';
import Popover from '@mui/material/Popover';
import InputBase from '@mui/material/InputBase';

import ZoomDeleteIcon from './CloseIcon';

import { V2PatientAttachmentReportsPayloadReportTestsType, V2PatientAttachmentReportsPayloadReportType } from '../../../types/PatientAttachments.types';
import { CustomTableColumnType } from '../../../types/CustomTable.types';
import CustomTable from '../../common/custom-table/CustomTable';
import { VITALS_EGFR, VITALS_POTASSIUM, VITALS_SCR } from '../../../constants/PatientVitals';

interface LabReportItemProps {
  labTestItems: V2PatientAttachmentReportsPayloadReportType;

  isOpen: boolean;

  handleDeleteSelectedValues: (payload: string) => void;

  handleAddTableColumn: () => void;

  handlClickReportChip: () => void;
  handleCloseReportChip: () => void;

  handleOnChange: (value: any, columnIndex: number, variable: string) => void;

  disabled?: boolean;
  isEdit?: boolean;
}

const LabReportItem: React.FunctionComponent<LabReportItemProps> = (props) => {
  const inputRef = React.useRef<any>(null);

  const [anchorEl, setAnchorEl] = React.useState<any>(null);

  const columns: CustomTableColumnType[] = [
    {
      id: 'investigations',
      label: 'Investigations',
      cellFormatter: (row: V2PatientAttachmentReportsPayloadReportTestsType, index: number) => {
        return (
          <InputBase
            disabled={!props.isEdit ? true : props.disabled}
            value={row.name}
            multiline
            onChange={(event) => props.handleOnChange(event.target.value, index, 'name')}
            sx={{
              width: '100%',
              border: 'none',
              '& .MuiInputBase-input': {
                color: '#3E4685',
                padding: 0,
              },
            }}
            type='text'
          />
        );
      },
    },
    {
      id: 'results',
      label: 'Results',
      headerColSpan: 2,
      cellFormatter: (row: V2PatientAttachmentReportsPayloadReportTestsType, index: number) => {
        return (
          <InputBase
            placeholder='Enter value'
            type='number'
            disabled={!props.isEdit ? true : props.disabled}
            value={row.value}
            onChange={(event) => props.handleOnChange(event.target.value, index, 'value')}
            sx={{
              width: '100%',
              border: 'none',
              '& .MuiInputBase-input': {
                color: '#000057',
                padding: 0,
              },
            }}
          />
        );
      },
    },
    {
      id: 'units',
      label: '',
      hiddenHeader: true,
      cellFormatter: (row: V2PatientAttachmentReportsPayloadReportTestsType, index: number) => {
        const isScr = row.name.toLowerCase() === 'serum creatinine' || row.name.toLowerCase() === 'creatinine' || row.name.toLowerCase() === 'scr';
        const isPotassium = row.name.toLowerCase() === 'potassium' || row.name.toLowerCase() === 'k';
        const isEGFR = row.name.toLowerCase() === 'egfr';

        let disabled = !props.isEdit ? true : props.disabled;

        if (isScr) {
          if (!row.units || row.units !== VITALS_SCR.unit) {
            props.handleOnChange(VITALS_SCR.unit, index, 'units');
          }
          disabled = true;
        }

        if (isPotassium) {
          if (!row.units || row.units !== VITALS_POTASSIUM.unit) {
            props.handleOnChange(VITALS_POTASSIUM.unit, index, 'units');
          }
          disabled = true;
        }

        if (isEGFR) {
          if (!row.units || row.units !== VITALS_EGFR.unit) {
            props.handleOnChange(VITALS_EGFR.unit, index, 'units');
          }
          disabled = true;
        }

        return (
          <Box sx={{ borderLeft: '1px solid #e0e0e0', pl: 1 }}>
            <InputBase
              placeholder='units'
              disabled={disabled}
              value={row.units}
              onChange={(event) => props.handleOnChange(event.target.value, index, 'units')}
              sx={{
                width: '100%',
                border: 'none',
                '& .MuiInputBase-input': {
                  color: '#000057',
                  padding: 0,
                },
              }}
              type='text'
            />
          </Box>
        );
      },
    },
  ];

  const _renderTootTip = () => {
    return (
      <Box sx={{ width: '500px', '& .table-container': { maxHeight: '50vh', overflow: 'auto', '& .table-body-cell-results, .table-head-cell-results': { borderLeft: '1px solid #e0e0e0' } } }}>
        <CustomTable
          data={props.labTestItems?.tests}
          columns={columns}
          tablePagination={false}
          stickyHeader
          hoverEffect={false}
          dataRowHover={false}
          tableSize='small'
          showAddRow
          addRowBtnLabel='Add investigations'
          handleAddNewRow={props.handleAddTableColumn}
        />
      </Box>
    );
  };

  React.useEffect(() => {
    const getBoundingClientRect = () => {
      return inputRef.current?.getBoundingClientRect();
    };

    setAnchorEl({ getBoundingClientRect, nodeType: 1 });
  }, [props.isOpen, inputRef]);

  return (
    <>
      <Chip
        ref={inputRef}
        label={props.labTestItems.name}
        key={props.labTestItems.id}
        disabled={!props.isEdit}
        size='medium'
        onClick={props.handlClickReportChip}
        deleteIcon={<ZoomDeleteIcon />}
        onDelete={() => {
          if (props.isEdit) {
            props.handleDeleteSelectedValues(props.labTestItems.name);
          }
        }}
      />

      <Popover
        PaperProps={{
          elevation: 0, // Remove shadow
          style: { border: '1px solid #9BB4CC', borderRadius: '8px', top: '1' }, // Add grey border
        }}
        open={props.isOpen}
        anchorEl={anchorEl}
        onClose={props.handleCloseReportChip}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}>
        {_renderTootTip()}
      </Popover>
    </>
  );
};
export default LabReportItem;
