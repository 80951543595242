/** @format */

import * as React from 'react';

interface EcgWaveIconProps {
  width?: number | string;
  height?: number | string;
  fill?: string;
}

const EcgWaveIcon: React.FunctionComponent<EcgWaveIconProps> = (props) => {
  return (
    <svg width={props.width} height={props.height} viewBox='0 0 22 16' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M8.21254 15.1252C7.9657 15.1252 7.74462 15.0526 7.54929 14.9072C7.35379 14.7617 7.21045 14.5766 7.11929 14.352L4.93479 8.75022H0.519287V7.25022H5.98854L8.21154 13.0232L12.6963 1.63872C12.7871 1.41406 12.93 1.22906 13.1248 1.08372C13.3198 0.93839 13.5407 0.865723 13.7875 0.865723C14.0344 0.865723 14.2555 0.93839 14.4508 1.08372C14.6463 1.22906 14.7896 1.41406 14.8808 1.63872L17.0845 7.25022H21.5V8.75022H16.0308L13.7885 2.96747L9.30379 14.352C9.21295 14.5766 9.07012 14.7617 8.87529 14.9072C8.68029 15.0526 8.45937 15.1252 8.21254 15.1252Z'
        fill={props.fill}
      />
    </svg>
  );
};

EcgWaveIcon.defaultProps = {
  width: '22',
  height: '16',
  fill: '#33425B',
};

export default EcgWaveIcon;
