/** @format */

import { createSlice, PayloadAction } from '@reduxjs/toolkit';

// Types and interfaces.
import { RootState } from '../../app/store';

export interface PatientSymptomsError {
  message: string;
}

export interface PatientSymptomsInterface {
  recordId: number;
  category: string;
  shortCode: string;
  description: string;
  severity: string;
  weightage: number;
  isActive: boolean;
  createdAt: string;
}

export interface PatientSymptomsState {
  isLoading: boolean;
  patientSymptoms: Array<PatientSymptomsInterface>;
  error: PatientSymptomsError;
}

export const initialState: PatientSymptomsState = {
  isLoading: false,
  patientSymptoms: [],
  error: { message: '' },
};

export const patientSymptomsReducer = createSlice({
  name: 'patient symptoms',
  initialState,
  reducers: {
    setLoading: (state, { payload }: PayloadAction<boolean>) => {
      state.isLoading = payload;
    },

    setPatientSymptoms: (state, { payload }: PayloadAction<Array<PatientSymptomsInterface>>) => {
      state.patientSymptoms = payload;
    },
  },
});

export const { setPatientSymptoms, setLoading } = patientSymptomsReducer.actions;

export const patientSymptomsSelector = (state: RootState) => state.patientSymptoms;
