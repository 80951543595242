/** @format */

// Library
import * as React from 'react';
import { Provider } from 'react-redux';
import { ThemeProvider } from '@mui/material';
import { HashRouter, useRoutes } from 'react-router-dom';
import StyledEngineProvider from '@mui/material/StyledEngineProvider';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';

// Video SDK
import AgoraRTC, { IAgoraRTCClient } from 'agora-rtc-sdk-ng';
import { AgoraRTCProvider } from 'agora-rtc-react';

// Components
import InitialComponent from './InitialComponent';
import CustomToast from './components/common/custom-toast/CustomToast';

// Css
import 'react-phone-input-2/lib/style.css';
import 'react-datepicker/dist/react-datepicker.css';
import './global.scss';

// App Routes
import { NavRoutes } from './_navigation.routes';

// Store
import { store } from './app/store';

// App Theme
// import { useAppDispatch } from './app/hooks';
import { appTheme } from './context/AppTheme';
// import { getUserRegion } from './service/user-regsion';
import UserSessionProvider from './context/UserSession';
// import { setUserRegion, UserRegionState } from './reducers/user-region-reducer/UserRegionSlice';

interface AppProps {}

const HomeApp: React.FunctionComponent<AppProps> = () => {
  return useRoutes(NavRoutes);
};

const AppWrapper: React.FunctionComponent<AppProps> = () => {
  const queryClient = new QueryClient({ defaultOptions: { queries: { retryDelay: 5000, retry: 3, refetchOnWindowFocus: false } } });

  return (
    <QueryClientProvider client={queryClient}>
      <StyledEngineProvider injectFirst>
        <Provider store={store}>
          <ThemeProvider theme={appTheme}>
            <AgoraRTCProvider client={AgoraRTC.createClient({ mode: 'rtc', codec: 'vp8' }) as IAgoraRTCClient}>
              <HashRouter>
                <UserSessionProvider>
                  <HomeApp />
                  <InitialComponent />
                  <CustomToast />
                </UserSessionProvider>
              </HashRouter>
            </AgoraRTCProvider>
          </ThemeProvider>
        </Provider>
      </StyledEngineProvider>
    </QueryClientProvider>
  );
};

export default AppWrapper;
