/** @format */

import * as React from 'react';
import * as _ from 'underscore';
import { Box, Divider, Grid, IconButton } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';

import CustomCard from '../custom-card';
import CustomTable from '../custom-table/CustomTable';
import { CustomFrequnceyTableCell, CustomTableSelectCell } from '../../prescriptions-generator/CustomTableComponent';
import PrescriptionMedicationSelectComponent from '../../prescriptions-generator/PrescriptionMedicationSelectComponent';

import { FieldTemplateProps } from '@rjsf/utils';
import { CustomTableColumnType } from '../../../types/CustomTable.types';
import { PrescriptionsGeneratorMedicationsCommonItemType } from '../../../types/PrescriptionsGenerator.types';

import { DURATION, MEDICATION_DOSAGE_UNIT_OPTIONS, MEDICATION_DUREATION_UNITS_OPTIONS } from '../../../constants/PrescriptionGenerator';

import './CustomTemplatePrevMedications.scss';
import { patientPrescriptionMedicationSchema } from '../../../validations/prescription-generator.joi';
import Joi from 'joi';

type CustomTemplatePrevMedicationsProps = FieldTemplateProps;

const CustomTemplatePrevMedications: React.FC<CustomTemplatePrevMedicationsProps> = (props) => {
  const [patientPrescriptionMedicationsErrors, setPatientPrescriptionMedicationsErrors] = React.useState<PrescriptionsGeneratorMedicationsCommonItemType[]>([]);
  const [isOpenFrequencyDropdownIndex, setIsOpenFrequencyDropdownIndex] = React.useState<number | null>(null);

  const editMode: boolean = props.uiSchema?.['ui:fields']?.disabled;

  const title = props.schema?.title || 'Medications';
  const patientPrescriptionMedications = props.formData;

  const editCard = props.uiSchema?.['ui:fields']?.handleClick;

  const onChange = props.onChange;

  const validateSanity = React.useCallback((data: any) => {
    const errors = validateJoiArraySchema(patientPrescriptionMedicationSchema, data, { allowUnknown: true });
    if (!_.isEmpty(errors)) {
      setPatientPrescriptionMedicationsErrors(errors);
    } else {
      setPatientPrescriptionMedicationsErrors([]);
    }
  }, []);

  const validateJoiArraySchema = (schema: Joi.Schema, data: any, optionsValidate: any = {}) => {
    const joiErrors = schema.validate(data, optionsValidate);

    const index: any = joiErrors.error?.details?.[0]?.path?.[0];
    const variable = joiErrors.error?.details?.[0]?.path?.[1];
    let errors: any;
    if (index >= 0 && variable) {
      errors = new Array(data.length).fill(null);
      if (!errors[index]) {
        errors[index] = {};
      }
      errors[index][variable] = joiErrors.error?.details?.[0]?.message;
    }

    return errors;
  };

  const handleNewPatientPrescriptionsMedications = React.useCallback(
    (medications: PrescriptionsGeneratorMedicationsCommonItemType) => {
      const newMedications = (props.formData || [])?.concat([medications]);
      validateSanity(newMedications);
      onChange(newMedications);
    },
    [onChange, props.formData, validateSanity],
  );

  const handleChangePatientPrescriptionMedications = React.useCallback(
    (value: any, variable: keyof PrescriptionsGeneratorMedicationsCommonItemType, rowIndex: number) => {
      const updatedMedications = (props.formData || []).map((item: PrescriptionsGeneratorMedicationsCommonItemType, index: number) => {
        if (index === rowIndex) {
          item[variable] = value;
          return { ...item };
        }
        return item;
      });

      validateSanity(updatedMedications);
      onChange(updatedMedications);
    },
    [props.formData, validateSanity, onChange],
  );

  const handleRemovePatientPrescriptionsMedications = React.useCallback(
    (medicationItem: number) => {
      setPatientPrescriptionMedicationsErrors((prev) => prev.filter((item, index: number) => index !== medicationItem));
      const removeMedications = (props.formData || []).filter((item: any, index: number) => index !== medicationItem);
      onChange(removeMedications);
    },
    [onChange, props.formData],
  );

  const columnDefs: CustomTableColumnType[] = [
    {
      minWidth: 150,
      width: '20%',
      id: 'medication-name',
      label: 'Medication Name',
      cellFormatter: (data: PrescriptionsGeneratorMedicationsCommonItemType, rowIndex: number) => {
        const error = patientPrescriptionMedicationsErrors?.find((item, index: number) => index === rowIndex);
        return (
          <PrescriptionMedicationSelectComponent
            rowIndex={rowIndex}
            data={data}
            error={error}
            patientPrescriptionMedications={patientPrescriptionMedications}
            handleChangePatientPrescriptionMedications={handleChangePatientPrescriptionMedications}
            patientInfo={{}}
            isDisabled={editMode}
          />
        );
      },
    },

    {
      id: 'Frequency',
      label: 'Frequency',
      minWidth: 150,
      width: '15%',
      cellFormatter: (data: PrescriptionsGeneratorMedicationsCommonItemType, rowIndex: number) => {
        return (
          <Grid key={rowIndex} item xs={12} container alignItems='center' gap={0.5}>
            <Grid item xs={7}>
              <CustomFrequnceyTableCell
                isOpenFrequencyDropdown={isOpenFrequencyDropdownIndex === rowIndex}
                handleCloseFrequencyDropdown={() => setIsOpenFrequencyDropdownIndex(null)}
                handleOpenFrequencyDropdown={() => setIsOpenFrequencyDropdownIndex(rowIndex)}
                value={data?.frequencyRegime}
                onChange={(value: string) => handleChangePatientPrescriptionMedications(value, 'frequencyRegime', rowIndex)}
                data={data}
                isError={!_.isEmpty(patientPrescriptionMedicationsErrors?.find((item, index: number) => index === rowIndex)?.frequencyRegime)}
                isDisabled={editMode}
              />
            </Grid>
            <Grid item xs={0.2}>
              <Divider orientation='vertical' sx={{ height: '25px', borderColor: '#9BB4CC' }} />
            </Grid>
            <Grid item xs={4}>
              <CustomTableSelectCell
                key={rowIndex}
                value={MEDICATION_DOSAGE_UNIT_OPTIONS?.find((item) => item.value === data?.dosageUnit)}
                menuPortalTarget={document.body}
                placeholder=''
                isDisabled={editMode}
                options={MEDICATION_DOSAGE_UNIT_OPTIONS}
                onChange={(value: any) => handleChangePatientPrescriptionMedications(value.value, 'dosageUnit', rowIndex)}
                isError={!_.isEmpty(patientPrescriptionMedicationsErrors?.find((item, index: number) => index === rowIndex)?.dosageUnit)}
              />
            </Grid>
          </Grid>
        );
      },
    },
    {
      id: 'duration',
      label: 'Duration',
      minWidth: 120,
      width: '5%',
      cellFormatter: (data: PrescriptionsGeneratorMedicationsCommonItemType, rowIndex: number) => (
        <Grid key={rowIndex} item xs={12} container alignItems='center'>
          <Grid item xs={4.8}>
            <CustomTableSelectCell
              key={rowIndex}
              value={DURATION?.find((item) => data?.duration === item.value)}
              menuPortalTarget={document.body}
              placeholder=''
              isDisabled={editMode}
              options={DURATION}
              onChange={(value: any) => handleChangePatientPrescriptionMedications(value?.value, 'duration', rowIndex)}
              isError={!_.isEmpty(patientPrescriptionMedicationsErrors?.find((item, index: number) => index === rowIndex)?.duration)}
            />
          </Grid>
          <Grid item xs>
            <Divider orientation='vertical' sx={{ height: '25px', borderColor: '#9BB4CC' }} />
          </Grid>
          <Grid item xs={7}>
            <CustomTableSelectCell
              key={rowIndex}
              isDisabled={editMode}
              value={MEDICATION_DUREATION_UNITS_OPTIONS?.find((item) => data?.durationUnits === item.value)}
              menuPortalTarget={document.body}
              placeholder=''
              options={MEDICATION_DUREATION_UNITS_OPTIONS}
              isCreateableSelect={true}
              onChange={(value: any) => handleChangePatientPrescriptionMedications(value?.value, 'durationUnits', rowIndex)}
              isError={!_.isEmpty(patientPrescriptionMedicationsErrors?.find((item, index: number) => index === rowIndex)?.durationUnits)}
            />
          </Grid>
        </Grid>
      ),
    },
    {
      id: 'actions',
      label: '',
      minWidth: 30,
      width: '2%',
      cellAlignment: 'center',
      cellFormatter: (data: PrescriptionsGeneratorMedicationsCommonItemType, rowIndex: number) => (
        <IconButton key={rowIndex} onClick={() => handleRemovePatientPrescriptionsMedications(rowIndex)} disabled={editMode}>
          <DeleteIcon />
        </IconButton>
      ),
    },
  ];

  return (
    <Box sx={{ paddingBottom: '30px' }} id='custom-prescription-sections-form'>
      <CustomCard title={title} editMode={editMode} handleClickEditCard={editCard}>
        <Grid id={`grid-template-areas-${props.uiSchema?.['ui:fields']?.className}`} container sx={{ display: 'grid', gridColumnGap: '0.625rem', gridRowGap: '0rem' }}>
          <CustomTable
            count={patientPrescriptionMedications.length}
            columns={columnDefs}
            showAddRow
            addRowBtnLabel='Add Medications'
            handleAddNewRow={() => handleNewPatientPrescriptionsMedications({ durationUnits: 'Days' } as PrescriptionsGeneratorMedicationsCommonItemType)}
            data={patientPrescriptionMedications}
            noDataMessage='No Prescription'
            dataRowHover={false}
            stickyHeader
            tableSize='small'
            isDisabled={editMode}
          />
        </Grid>
      </CustomCard>
    </Box>
  );
};

export default CustomTemplatePrevMedications;
