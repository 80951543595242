/** @format */

import React, { useEffect } from 'react';
import isHotkey from 'is-hotkey';
import { Box, IconButton } from '@mui/material';
import { Editable, useSlate, Slate, withReact } from 'slate-react';
import { Editor, Transforms, Element as SlateElement, BaseEditor, Descendant, createEditor } from 'slate';
import SaveIcon from '@mui/icons-material/Save';
// Icons.
import CancelIcon from '@mui/icons-material/Cancel';
import CodeIcon from '@mui/icons-material/Code';
import FormatBoldIcon from '@mui/icons-material/FormatBold';
import FormatItalicIcon from '@mui/icons-material/FormatItalic';
import FormatUnderlinedIcon from '@mui/icons-material/FormatUnderlined';
import FormatAlignLeftIcon from '@mui/icons-material/FormatAlignLeft';
import FormatAlignRightIcon from '@mui/icons-material/FormatAlignRight';
import FormatAlignCenterIcon from '@mui/icons-material/FormatAlignCenter';
import FormatListBulletedIcon from '@mui/icons-material/FormatListBulleted';
import FormatListNumberedIcon from '@mui/icons-material/FormatListNumbered';

// Types.
import { AllSavedNotesType } from '../../../types/Notes';
import { NOTE_INITIAL_STATE } from '../../../constants/notes';
import { ErrorBoundary } from 'react-error-boundary';
import { ComponentErrorBoundary } from '../../common/error/ComponentErrorBoundary';
import { PatientProfileContext } from '../../../pages/patient-profile/PatientProfileContext';

// Constants.
const HOTKEYS = {
  'mod+b': 'bold',
  'mod+i': 'italic',
  'mod+u': 'underline',
  'mod+`': 'code',
};
const LIST_TYPES = ['numbered-list', 'bulleted-list'];
const TEXT_ALIGN_TYPES = ['left', 'center', 'right', 'justify'];

interface NotesInterface {
  readonly: boolean;
  handleClickSaveNote?: (payload: AllSavedNotesType | any) => void;
  updatedNote?: AllSavedNotesType | null;
  handleClickCancel?: (payload: any) => void;
  isGeneralNote?: boolean;
  showToolbar: boolean;
  editorState: Descendant[];
  autoFocus?: boolean;
  setEditorState: React.Dispatch<React.SetStateAction<Descendant[]>>;
  margin?: string | number;
}

export const Notes: React.FC<NotesInterface> = ({
  handleClickSaveNote,
  handleClickCancel,
  updatedNote,
  readonly,
  showToolbar,
  isGeneralNote,
  editorState,
  setEditorState,
  autoFocus,
  margin = '20px',
}) => {
  const noteRef = React.useRef<HTMLDivElement | null>(null);
  const [editor] = React.useState(() => withReact(createEditor()));

  const { patientInfo } = React.useContext(PatientProfileContext);
  const deactiveStatus = patientInfo.basicDetails?.patientStatus === 'DEACTIVE' || false;

  useEffect(() => {
    if (editorState) {
      editor.children = editorState;
    }
  }, [editor, editorState]);

  useEffect(() => {
    if (updatedNote) {
      editor.children = updatedNote?.notesPayload;
    }
  }, [editor, updatedNote]);

  const renderElement = (props: any) => <Element {...props} />;
  const renderLeaf = (props: any) => <Leaf {...props} />;

  const handleEditorChange = (newValue: Descendant[]) => {
    setEditorState(newValue);
  };

  const saveNote = (payload: any) => {
    if (JSON.stringify(payload) === JSON.stringify(NOTE_INITIAL_STATE)) {
      return;
    }

    if (updatedNote) {
      handleClickSaveNote?.({
        ...updatedNote,
        notesPayload: payload,
      });
      editor.children = NOTE_INITIAL_STATE;
      Transforms.select(editor, {
        anchor: { path: [0, 0], offset: 0 },
        focus: { path: [0, 0], offset: 0 },
      });
      editor.onChange();
    } else {
      handleClickSaveNote?.(payload);
      editor.children = NOTE_INITIAL_STATE;
      Transforms.select(editor, {
        anchor: { path: [0, 0], offset: 0 },
        focus: { path: [0, 0], offset: 0 },
      });
      editor.onChange();
    }
  };

  const cancelNote = () => {
    if (updatedNote) {
      handleClickCancel?.(updatedNote.noteId);

      try {
        editor.children = NOTE_INITIAL_STATE;
        editor.onChange();
      } catch (error) {
        console.error(error);
      }
    } else {
      try {
        editor.children = NOTE_INITIAL_STATE;
        Transforms.select(editor, {
          anchor: { path: [0, 0], offset: 0 },
          focus: { path: [0, 0], offset: 0 },
        });
        editor.onChange();
      } catch (error) {
        console.error(error);
      }
    }
  };

  return (
    <ErrorBoundary fallbackRender={ComponentErrorBoundary}>
      <Slate editor={editor} initialValue={editorState} onChange={handleEditorChange}>
        <Box
          sx={{
            backgroundColor: readonly ? 'inherit' : 'white',
            padding: readonly ? 0 : '5px 5px 0 5px',
          }}
          ref={noteRef}>
          {showToolbar && (
            <Box sx={{ display: 'flex', alignItems: 'center', flexWrap: 'wrap', justifyContent: 'flex-start' }}>
              <>
                <MarkButton format='bold' icon={<FormatBoldIcon color='action' />} />
                <MarkButton format='italic' icon={<FormatItalicIcon color='action' />} />
                <MarkButton format='underline' icon={<FormatUnderlinedIcon color='action' />} />
                <MarkButton format='code' icon={<CodeIcon color='action' />} />

                <BlockButton format='numbered-list' icon={<FormatListNumberedIcon color='action' />} />
                <BlockButton format='bulleted-list' icon={<FormatListBulletedIcon color='action' />} />

                <BlockButton format='left' icon={<FormatAlignLeftIcon color='action' />} />
                <BlockButton format='center' icon={<FormatAlignCenterIcon color='action' />} />
                <BlockButton format='right' icon={<FormatAlignRightIcon color='action' />} />

                {isGeneralNote && (
                  <Box sx={{ margin: '5px 0 5px auto' }}>
                    <IconButton
                      color='primary'
                      sx={{ borderRadius: 0 }}
                      onClick={() => saveNote(editorState)}
                      disabled={deactiveStatus || JSON.stringify(editorState) === JSON.stringify(NOTE_INITIAL_STATE)}
                      data-testid='save-note'>
                      <SaveIcon />
                    </IconButton>

                    <IconButton color='error' sx={{ borderRadius: 0 }} onClick={cancelNote} disabled={JSON.stringify(editorState) === JSON.stringify(NOTE_INITIAL_STATE)}>
                      <CancelIcon />
                    </IconButton>
                  </Box>
                )}
              </>
            </Box>
          )}

          <Box
            sx={{
              overflowX: 'hidden',
              height: 'min-content',
            }}>
            <Editable
              renderElement={renderElement}
              renderLeaf={renderLeaf}
              placeholder='Add a note'
              spellCheck
              autoFocus={autoFocus}
              readOnly={readonly || deactiveStatus}
              onKeyDown={(event) => {
                for (const hotkey in HOTKEYS) {
                  if (isHotkey(hotkey, event as any)) {
                    event.preventDefault();
                    const mark = HOTKEYS[hotkey as keyof typeof HOTKEYS];
                    toggleMark(editor, mark);
                  }
                }
              }}
              className='editor'
              style={{ padding: '2px 0', width: `${Math.floor(noteRef.current?.getBoundingClientRect().width! - 50)}px`, outline: 'none' }}
            />
          </Box>
        </Box>
      </Slate>
    </ErrorBoundary>
  );
};

const toggleBlock = (editor: BaseEditor, format: string) => {
  const isActive = isBlockActive(editor, format, TEXT_ALIGN_TYPES.includes(format) ? 'align' : 'type');
  const isList = LIST_TYPES.includes(format);

  Transforms.unwrapNodes(editor, {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    match: (n) => !Editor.isEditor(n) && SlateElement.isElement(n) && LIST_TYPES.includes(n.type) && !TEXT_ALIGN_TYPES.includes(format),
    split: true,
  });

  let newProperties: any;
  if (TEXT_ALIGN_TYPES.includes(format)) {
    newProperties = {
      align: isActive ? undefined : format,
    };
  } else {
    newProperties = {
      type: isActive ? 'paragraph' : isList ? 'list-item' : format,
    };
  }
  Transforms.setNodes<SlateElement>(editor, newProperties);

  if (!isActive && isList) {
    const block = { type: format, children: [] };
    Transforms.wrapNodes(editor, block);
  }
};

const toggleMark = (editor: BaseEditor, format: string) => {
  const isActive = isMarkActive(editor, format);

  if (isActive) {
    Editor.removeMark(editor, format);
  } else {
    Editor.addMark(editor, format, true);
  }
};

const isBlockActive = (editor: BaseEditor, format: any, blockType = 'type') => {
  const { selection } = editor;
  if (!selection) return false;

  const [match] = Array.from(
    Editor.nodes(editor, {
      at: Editor.unhangRange(editor, selection),
      match: (n) => !Editor.isEditor(n) && SlateElement.isElement(n) && n[blockType as keyof typeof n] === format,
    }),
  );

  return !!match;
};

// const addMarkData = (editor: BaseEditor, data: any) => {
//   console.log(data);
//   Editor.addMark(editor, data.format, data.value);
// };

const isMarkActive = (editor: BaseEditor, format: any) => {
  const marks = Editor.marks(editor);
  return marks ? marks[format as keyof typeof marks] === true : false;
};

const Element = ({ attributes, children, element }: any) => {
  // const Element = (props: any) => {
  const style = { textAlign: element.align };
  switch (element.type) {
    case 'block-quote':
      return (
        <blockquote style={{ ...style, backgroundColor: '#EAEAEA', fontSize: '14px' }} {...attributes}>
          {children}
        </blockquote>
      );

    case 'bulleted-list':
      return (
        <ul
          style={{
            ...style,
            color: 'inherit',
            paddingLeft: '30px',
            fontSize: '14px',
          }}
          {...attributes}>
          {children}
        </ul>
      );

    case 'heading-one':
      return (
        <h1 style={style} {...attributes}>
          {children}
        </h1>
      );

    case 'heading-two':
      return (
        <h2 style={style} {...attributes}>
          {children}
        </h2>
      );

    case 'list-item':
      return (
        <li
          style={{
            margin: 0,
            ...style,
            fontSize: '14px',
          }}
          {...attributes}>
          {children}
        </li>
      );

    case 'numbered-list':
      return (
        <ol
          style={{
            ...style,
            color: 'inherit',
            paddingLeft: '30px',
            fontWeight: 'normal',
            fontSize: '14px',
            fontFamily: 'Roboto, Helvetica,Arial,sans-serif',
          }}
          {...attributes}>
          {children}
        </ol>
      );

    default:
      return (
        <p
          style={{
            ...style,
            margin: 0,
            fontSize: '14px',
          }}
          {...attributes}>
          {children}
        </p>
      );
  }
};

const Leaf = ({ attributes, children, leaf }: any) => {
  if (leaf.bold) {
    children = <strong>{children}</strong>;
  }

  if (leaf.code) {
    children = <code>{children}</code>;
  }

  if (leaf.italic) {
    children = <em>{children}</em>;
  }

  if (leaf.underline) {
    children = <u>{children}</u>;
  }

  return <span {...attributes}>{children}</span>;
};

const BlockButton = ({ format, icon }: { format: string; icon: JSX.Element }) => {
  const editor = useSlate();
  const isActive = isBlockActive(editor, format);

  return (
    <IconButton
      onMouseDown={(event: any) => {
        event.preventDefault();
        toggleBlock(editor, format);
      }}
      sx={{
        border: '1px solid #EAEAEA',
        backgroundColor: isActive ? '#EAEAEA' : '',
        marginRight: '3px',
        padding: '4px',
        borderRadius: 0,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }}>
      {icon}
    </IconButton>
  );
};

const MarkButton = ({ format, icon }: { format: string; icon: JSX.Element }) => {
  const editor = useSlate();
  const isActive = isMarkActive(editor, format);

  return (
    <IconButton
      sx={{
        backgroundColor: isActive ? '#EAEAEA' : '',
        border: '1px solid #EAEAEA',
        marginRight: '3px',
        padding: '4px',
        borderRadius: 0,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }}
      onMouseDown={(event) => {
        event.preventDefault();
        toggleMark(editor, format);
      }}>
      {icon}
    </IconButton>
  );
};
