/** @format */

interface VirtualAppointmentIconProps {
  fill: string;
}

export const VirtualAppointmentIcon: React.FC<VirtualAppointmentIconProps> = ({ fill }) => {
  return (
    <svg width='18' height='18' viewBox='0 0 12 12' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <rect width='12' height='12' rx='6' fill={fill} />
      <path
        d='M2.7998 4.4C2.7998 4.18783 2.88409 3.98434 3.03412 3.83431C3.18415 3.68428 3.38763 3.6 3.5998 3.6H5.9998C6.21198 3.6 6.41546 3.68428 6.56549 3.83431C6.71552 3.98434 6.7998 4.18783 6.7998 4.4V7.6C6.7998 7.81217 6.71552 8.01565 6.56549 8.16568C6.41546 8.31571 6.21198 8.4 5.9998 8.4H3.5998C3.38763 8.4 3.18415 8.31571 3.03412 8.16568C2.88409 8.01565 2.7998 7.81217 2.7998 7.6V4.4ZM7.821 4.8424C7.75457 4.87559 7.69869 4.92663 7.65963 4.98979C7.62056 5.05295 7.59984 5.12573 7.5998 5.2V6.8C7.59984 6.87426 7.62056 6.94705 7.65963 7.01021C7.69869 7.07337 7.75457 7.1244 7.821 7.1576L8.621 7.5576C8.68197 7.58806 8.74971 7.60245 8.8178 7.59938C8.88589 7.59631 8.95206 7.5759 9.01005 7.54008C9.06803 7.50425 9.1159 7.45421 9.14911 7.39469C9.18232 7.33517 9.19977 7.26815 9.1998 7.2V4.8C9.19977 4.73184 9.18232 4.66483 9.14911 4.60531C9.1159 4.54579 9.06803 4.49574 9.01005 4.45992C8.95206 4.4241 8.88589 4.40368 8.8178 4.40062C8.74971 4.39755 8.68197 4.41193 8.621 4.4424L7.821 4.8424Z'
        fill='#E0F2FE'
      />
    </svg>
  );
};

interface AppointmentsCalendarIconProps {
  fill: string;
}

export const AppointmentsCalendarIcon: React.FC<AppointmentsCalendarIconProps> = ({ fill }) => {
  return (
    <svg width='25' height='26' viewBox='0 0 19 20' fill={fill} xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M18.125 2.3125H14.1875V0.8125C14.1875 0.709375 14.1031 0.625 14 0.625H12.6875C12.5844 0.625 12.5 0.709375 12.5 0.8125V2.3125H6.5V0.8125C6.5 0.709375 6.41563 0.625 6.3125 0.625H5C4.89687 0.625 4.8125 0.709375 4.8125 0.8125V2.3125H0.875C0.460156 2.3125 0.125 2.64766 0.125 3.0625V18.625C0.125 19.0398 0.460156 19.375 0.875 19.375H18.125C18.5398 19.375 18.875 19.0398 18.875 18.625V3.0625C18.875 2.64766 18.5398 2.3125 18.125 2.3125ZM17.1875 17.6875H1.8125V8.78125H17.1875V17.6875ZM17.1875 7.1875H1.8125V4H4.8125V5.125C4.8125 5.22813 4.89687 5.3125 5 5.3125H6.3125C6.41563 5.3125 6.5 5.22813 6.5 5.125V4H12.5V5.125C12.5 5.22813 12.5844 5.3125 12.6875 5.3125H14C14.1031 5.3125 14.1875 5.22813 14.1875 5.125V4H17.1875V7.1875Z'
        fill='white'
      />
    </svg>
  );
};
