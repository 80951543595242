/** @format */

import * as React from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import CustomLoading from '../../components/common/custom-loading/CustomLoading';
import { UserSessionContext } from '../../context/UserSession';
import { fetchEmergencyLogin, fetchUserSession } from '../../service/user-session';

const EmergencyLogin: React.FunctionComponent = () => {
  const { emergencyId } = useParams();
  const navigate = useNavigate();

  const { handleClearUserSession, handleUpdateUserSession } = React.useContext(UserSessionContext);

  React.useEffect(() => {
    getUserSession(emergencyId!);
  }, [emergencyId]);

  const getUserSession = async (emergencyId: string) => {
    try {
      sessionStorage.setItem('last-active-time', new Date().getTime().toString());

      const { sessionId } = await fetchEmergencyLogin(emergencyId);
      const user = await fetchUserSession(sessionId);
      localStorage.setItem('userSession', JSON.stringify({ ...user, sessionId }));
      handleUpdateUserSession({ ...user, sessionId });
      navigate('/workspace');
    } catch (err) {
      console.error('Error while fetching the user session', err);
      handleClearUserSession();
      localStorage.setItem('userSession', JSON.stringify({}));
      navigate('/login');
    }
  };

  return <CustomLoading />;
};

export default EmergencyLogin;
