/** @format */

import { AxiosRequestConfig } from 'axios';

import AxiosInstance from './index';

import config from '../config/config';

export const fetchUserSession = async (sessionId: string) => {
  const params: AxiosRequestConfig = {
    baseURL: config.careWebAppApiUrl,
    url: '/loggedIn',
    headers: {
      'x-api-key': sessionId,
    },
  };

  const res = await AxiosInstance(params);
  return res.data || {};
};

export const updateUserPasswordByResetToken = async (resetToken: string, password: string) => {
  const params: AxiosRequestConfig = {
    method: 'patch',
    baseURL: config.careWebAppApiUrl,
    url: `/login/reset/${resetToken}/password`,
    data: {
      password,
    },
  };

  const res = await AxiosInstance(params);
  return res.data || {};
};

export const verifyUserPasswordByResetToken = async (resetToken: string) => {
  const params: AxiosRequestConfig = {
    baseURL: config.careWebAppApiUrl,
    url: `/login/reset/${resetToken}/password/verify`,
  };

  const res = await AxiosInstance(params);
  return res.data || {};
};

export const fetchEmergencyLogin = async (emergencyId: string) => {
  const params: AxiosRequestConfig = {
    baseURL: config.careWebAppApiUrl,
    url: `organizations/users/login/emergency/${emergencyId}`,
    method: 'GET',
  };

  const res = await AxiosInstance(params);
  return res.data || {};
};
