/** @format */

import _ from 'underscore';
import { DateTime } from 'luxon';

import { CriticalAlertReasonType, PatientTasksItemType } from '../types/PatientTasks.type';

import { MAPPING_ALGO_FORMATTED_VITALS_TEXT } from '../constants/alert';
import { TASKS_CATEGORY_MEDICATION_TASKS, TASKS_TYPE_MEDICATION_CHANGE, TASKS_TYPE_MEDICATION_CRITICAL_ALERTS } from '../constants/workspace-tasks';

export const getUniqTasksAlertByCauses = (alerts: any) => {
  // group the causes and get unique causes and severity

  const uniqueReason = _.groupBy(alerts, (item) => item?.payload?.inputPayload?.causes?.join('-'));

  let uniqAlerts: any[] = [];

  Object.keys(uniqueReason).forEach((key) => {
    if (uniqueReason[key].length <= 1) {
      uniqAlerts = uniqAlerts.concat(uniqueReason[key]);
    } else {
      const alerts = uniqueReason[key];
      const maxSeverity = alerts.reduce((acc, item) => {
        return Math.max(acc, item.severity);
      }, 0);

      const criticalAlerts = alerts.filter((item) => item.category === TASKS_CATEGORY_MEDICATION_TASKS && item.type === TASKS_TYPE_MEDICATION_CRITICAL_ALERTS && item.severity === maxSeverity);

      if (criticalAlerts.length > 0) {
        uniqAlerts = uniqAlerts.concat(criticalAlerts);
      } else {
        uniqAlerts = uniqAlerts.concat(uniqueReason[key]);
      }
    }
  });

  return uniqAlerts;
};

export const getCriticalAlertReasonFromTasks = (tasks?: PatientTasksItemType<any, any>[]): CriticalAlertReasonType[] => {
  let alerts: CriticalAlertReasonType[] = [];
  if (tasks) {
    const uniqTasks = getUniqTasksAlertByCauses(tasks);
    alerts = uniqTasks?.reduce((acc: CriticalAlertReasonType[], item: PatientTasksItemType<any, any>) => {
      if (item.category === TASKS_CATEGORY_MEDICATION_TASKS && item.type !== TASKS_TYPE_MEDICATION_CHANGE) {
        acc.push({ reason: item?.payload?.inputPayload?.reason });
      }
      return acc;
    }, [] as CriticalAlertReasonType[]);
  }

  return alerts;
};

export const getAlertsForRca = (patientMedicationTask: PatientTasksItemType<any, any>[]) => {
  const recentAlerts: PatientTasksItemType<any, any>[] = [];
  const previousAlerts: PatientTasksItemType<any, any>[] = [];

  const filteredPatientAlerts = patientMedicationTask?.filter((ele) => ele.type.match('CRITICAL_ALERTS') || ele.type.match('WARNING_ALERTS')) || [];

  const recentDate: any =
    !_.isEmpty(filteredPatientAlerts) &&
    filteredPatientAlerts?.reduce((a, b) => {
      const aDate = DateTime.fromJSDate(new Date(a.timestamp!)).startOf('day');
      const bDate = DateTime.fromJSDate(new Date(b.timestamp!)).startOf('day');

      return aDate.toMillis() > bDate.toMillis() ? a : b;
    });

  filteredPatientAlerts?.filter((ele) => {
    const aDate = DateTime.fromJSDate(new Date(ele.timestamp!)).startOf('day');
    const recentDateDate = DateTime.fromJSDate(new Date(recentDate.timestamp!)).startOf('day');

    if (recentDateDate.toMillis() === aDate.toMillis()) {
      recentAlerts.push(ele);
    } else {
      previousAlerts.push(ele);
    }

    return ele;
  });

  return { recentAlerts, previousAlerts };
};

export const getFormattedTextForRcaAlerts = (payload: string) => {
  const formattedString = MAPPING_ALGO_FORMATTED_VITALS_TEXT[payload as keyof typeof MAPPING_ALGO_FORMATTED_VITALS_TEXT];
  return formattedString;
};
