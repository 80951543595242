/** @format */

import * as React from 'react';

import Paper, { PaperProps } from '@mui/material/Paper';
import Popper, { PopperProps } from '@mui/material/Popper';
import { ClickAwayListener, useTheme } from '@mui/material';

interface CustomPopperProps {
  popperProps: PopperProps;

  paperProps?: PaperProps;

  children: React.ReactNode | JSX.Element;
  onClose?: () => void;
}

const CustomPopper: React.FunctionComponent<CustomPopperProps> = (props) => {
  const appTheme = useTheme();

  const handleClickAway = () => {
    if (props.onClose) {
      props.onClose();
    }
  };

  return (
    <ClickAwayListener onClickAway={handleClickAway}>
      <Popper {...props.popperProps}>
        <Paper
          sx={{
            bgColor: appTheme.palette.customColor.card,
            p: '6px 9px',
            border: '0.5px solid #D5D5D6',
            boxShadow: '0px 20px 25px -5px  rgba(51, 65, 85, 0.1), 0px 10px 10px -5px rgba(51, 65, 85, 0.04)',
            borderRadius: '5px',
          }}
          {...props.paperProps}>
          {props.children}
        </Paper>
      </Popper>
    </ClickAwayListener>
  );
};

export default CustomPopper;
