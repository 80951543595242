/** @format */

import { createSlice, PayloadAction } from '@reduxjs/toolkit';

// Types and interfaces.
import { RootState } from '../../app/store';
import { PatientListNewType } from '../../types/PatientListPage.types';

export interface ApprovalPatientListState {
  limit: number;
  pageOffset: number;

  count: number;
  listData: PatientListNewType[];

  showAlerts: boolean;
}

export const approvalInitialState: ApprovalPatientListState = {
  count: 0,
  listData: [],

  limit: 20,
  pageOffset: 0,

  showAlerts: true,
};

export const approvalPatientListReducer = createSlice({
  name: 'Approval patient list reducer',
  initialState: approvalInitialState,
  reducers: {
    toggleApprovalAlert: (state) => {
      state.showAlerts = !state.showAlerts;
    },

    setPageOffset: (state, { payload }: PayloadAction<number>) => {
      state.pageOffset = Number(payload);
    },

    setLimit: (state, { payload }: PayloadAction<number>) => {
      state.pageOffset = 0;
      state.limit = Number(payload);
    },

    changeDoctorFilters: (state) => {
      state.listData = [];
      state.count = 0;
      state.pageOffset = 0;
    },

    setApprovalPatientList: (state, { payload }: PayloadAction<{ data: PatientListNewType[]; count: number }>) => {
      state.listData = payload.data;
      state.listData.sort((a, b) => parseInt(a.basicDetails?.recordId!) - parseInt(b.basicDetails?.recordId!));
      state.count = payload.count;
    },

    updateApprovalPatientInfo: (state, { payload }: PayloadAction<PatientListNewType>) => {
      state.listData = state.listData.map((item) => {
        if (item?.basicDetails?.patientSourceId === payload?.basicDetails?.patientSourceId) {
          return { ...payload };
        }
        return item;
      });
    },

    clearPatientListStore: (state) => {
      state = {
        count: 0,
        listData: [],

        limit: 20,
        pageOffset: 0,

        showAlerts: true,
      };
      return state;
    },
  },
});

export const {
  clearPatientListStore,

  setApprovalPatientList,
  updateApprovalPatientInfo,

  toggleApprovalAlert,

  changeDoctorFilters,

  setPageOffset,
  setLimit,
} = approvalPatientListReducer.actions;

export const ApprovalPatientListSelector = (state: RootState) => state.approvalPatientList;
