/** @format */

import * as React from 'react';
import * as _ from 'underscore';

import Grid from '@mui/material/Grid';
import { useTheme } from '@mui/material';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import LoadingButton from '@mui/lab/LoadingButton';

// Icons
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';

// Type
import { CustomFormType, DropdownOptionType } from '../../../types/CustomForm.types';

// Constants
import { DROPDOWN_INPUT_COMPONENT } from '../../../constants/PatientEnrollment';
import FormFields from '../../common/form-fields/FormFields';
import PatientDeviceRemoveConfirmModal from './PatientDeviceRemoveConfirmModal';
import { PatientDevice } from '../../../types/Patients.types';

import { PatientProfileContext } from '../../../pages/patient-profile/PatientProfileContext';
import { PatientEnrolmentContext } from '../../../pages/patient-enrolment/PatientEnrolmentContext';

interface PatientDeviceManagementProps {
  isEnrolmentContext?: boolean;
}

const PatientDeviceManagement: React.FunctionComponent<PatientDeviceManagementProps> = (props) => {
  const appTheme = useTheme();

  let contextData: any = React.useContext(PatientProfileContext);

  if (props.isEnrolmentContext) {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    contextData = React.useContext(PatientEnrolmentContext);
  }

  const { patientInfo, unmappedDevices, mappedDevices, isDeviceInfoUpdating, handleSubmitPatientDeviceInfo, handleSubmitDeletePatientDeviceInfo }: any = contextData;

  const ASSEST_TYPE = [
    {
      label: 'Omron Weighing Scale',
      value: 'OMRON_WEIGHING_SCALE',
      hidetab: true,
    },
    {
      label: 'Omron BP machine',
      value: 'OMRON_BP_MACHINE',
      hidetab: true,
    },
    {
      label: 'Apple Phone',
      value: 'APPLE_PHONE',
      hidetab: localStorage.getItem('show-apple-phone') === '1',
    },
    {
      label: 'Apple Watch',
      value: 'APPLE_WATCH',
    },
    {
      label: 'Wearables',
      value: 'WEARABLES',
      hidetab: true,
    },
    {
      label: 'Accessories',
      value: 'ACCESSORIES',
      hidetab: true,
    },
    {
      label: 'RxCAP ID',
      value: 'RXCAP_ID',
      hidetab: localStorage.getItem('show-rx-cap-id') === '1',
    },
    {
      label: 'Apple Phone',
      value: 'APPLE_PHONE',
    },
    {
      label: 'RxCAP ID',
      value: 'RXCAP_ID',
    },
  ];

  const [isEdit, setIsEdit] = React.useState<{ [k: string]: boolean }>({});
  const [mappedDevicesInfo, setMappedDevicesInfo] = React.useState<PatientDevice[]>([]);

  const [isOpenRemoveDeviceModal, setIsOpenRemoveDeviceModal] = React.useState<boolean>(false);
  const [removeDeviceInfo, setRemoveDeviceInfo] = React.useState<PatientDevice | null>(null);

  const [errorMessage] = React.useState<any>({});

  const toggleEditMode = (deviceType: string) => {
    setIsEdit((prev) => ({ [deviceType]: !prev[deviceType] }));
  };

  const getMappedDeviceForPatient = () => {
    return mappedDevices.filter((item: any) => item.patientSourceId === patientInfo?.basicDetails?.patientSourceId);
  };

  const handleClickReset = (deviceType: string) => {
    if (!_.isEmpty(mappedDevices)) {
      setMappedDevicesInfo(JSON.parse(JSON.stringify(getMappedDeviceForPatient())));
    } else {
      setMappedDevicesInfo([]);
    }
    toggleEditMode(deviceType);
  };

  const handleClickSave = async (deviceInfo: PatientDevice) => {
    if (_.isEmpty(deviceInfo)) {
      return;
    }
    await handleSubmitPatientDeviceInfo(deviceInfo?.deviceId!);
    toggleEditMode(deviceInfo?.device?.deviceType);
  };

  const toggleRemoveDeviceModal = (deviceInfo: PatientDevice | null = null) => {
    setIsOpenRemoveDeviceModal((prev) => !prev);
    setRemoveDeviceInfo(deviceInfo);
  };

  const handleClickDeletePatientDeviceMapping = async () => {
    await handleSubmitDeletePatientDeviceInfo(removeDeviceInfo!);
    toggleRemoveDeviceModal(null);
  };

  const handleChangeDevice = (deviceType: string, deviceId: string) => {
    setMappedDevicesInfo((prev) => {
      if (prev.some((item) => item.device?.deviceType === deviceType)) {
        prev = prev.map((ele) => {
          if (ele?.device?.deviceType === deviceType) {
            return { ...ele, deviceId };
          }
          return ele;
        });
      } else {
        prev.push({ device: { deviceType }, deviceId } as any);
      }
      return [...prev];
    });
  };

  React.useEffect(() => {
    if (mappedDevices && mappedDevices.length > 0) {
      setMappedDevicesInfo(JSON.parse(JSON.stringify(getMappedDeviceForPatient())));
    } else {
      setMappedDevicesInfo([]);
    }
  }, [mappedDevices]);

  const _renderDevice = (item: any, index: number) => {
    const deviceInfo = mappedDevicesInfo.find((ele) => ele?.device?.deviceType === item.value);
    const unmappedDevicesOptions = unmappedDevices?.filter((ele: any) => ele.device?.deviceType === item.value).map((ele: any) => ({ label: ele.deviceId, value: ele.deviceId }));

    const formFields: CustomFormType = {
      id: 'devices',
      label: '',
      componentType: DROPDOWN_INPUT_COMPONENT,
      input: {
        value: deviceInfo?.deviceId ? { label: deviceInfo?.deviceId, value: deviceInfo?.deviceId } : null,
        onChange: (event: DropdownOptionType) => handleChangeDevice(item.value, event.value),
        errorMessage: errorMessage?.devices,
        placeholder: 'Select Device',
        required: true,
        options: unmappedDevicesOptions,
        disabled: !isEdit[item.value],
      },
    };

    if (!item.hidetab) {
      return null;
    } else {
      return (
        <Grid container my={1}>
          <Grid key={index} item container alignItems='center' justifyContent='space-between' mb={1}>
            <Grid item xs>
              <Typography variant='fontSemiBold16' component='div' color={appTheme.palette.customColor.primary}>
                {item.label}
              </Typography>
            </Grid>

            <Grid item xs='auto'>
              {!isEdit[item.value] && !_.isEmpty(deviceInfo) && (
                <Button color='error' aria-label='Edit' variant='contained' startIcon={<DeleteIcon />} onClick={() => toggleRemoveDeviceModal(deviceInfo)} disabled={isDeviceInfoUpdating}>
                  Remove
                </Button>
              )}

              {!isEdit[item.value] && _.isEmpty(deviceInfo) && (
                <Button aria-label='Edit' sx={{ ml: 1.5 }} variant='outlined' startIcon={<EditIcon />} onClick={() => handleClickReset(item.value)} disabled={isDeviceInfoUpdating}>
                  Edit
                </Button>
              )}

              {isEdit[item.value] && (
                <div>
                  <Button color='error' onClick={() => handleClickReset(item.value)}>
                    Cancel
                  </Button>

                  <LoadingButton
                    loading={isDeviceInfoUpdating}
                    sx={{ ml: 1 }}
                    color='primary'
                    disabled={_.isEmpty(deviceInfo?.deviceId)}
                    variant='contained'
                    onClick={() => handleClickSave(deviceInfo!)}>
                    Save
                  </LoadingButton>
                </div>
              )}
            </Grid>
          </Grid>
          <FormFields {...formFields} />
        </Grid>
      );
    }
  };

  return (
    <Grid container direction='column' gap={2}>
      <PatientDeviceRemoveConfirmModal isLoading={isDeviceInfoUpdating} open={isOpenRemoveDeviceModal} handleClose={toggleRemoveDeviceModal} handleSubmit={handleClickDeletePatientDeviceMapping} />

      {ASSEST_TYPE.map(_renderDevice)}
    </Grid>
  );
};

export default PatientDeviceManagement;
