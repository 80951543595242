/** @format */

export const MEDICATION_DUREATION_UNITS_OPTIONS = [
  { label: 'Days', value: 'Days' },
  { label: 'Weeks', value: 'Weeks' },
  { label: 'Months', value: 'Months' },
];

export const COMPLAINTS_DURATION_OPTIONS = [
  { label: 'Hours', value: 'Hours' },
  { label: 'Days', value: 'Days' },
  { label: 'Weeks', value: 'Weeks' },
  { label: 'Months', value: 'Months' },
];

export const MEDICATION_DOSAGE_UNIT_OPTIONS = [
  { label: 'Tabs', value: 'tabs' },
  { label: 'Caps', value: 'caps' },
  { label: 'tsp', value: 'tsp' },
  { label: 'ml', value: 'ml' },
];

export const MEDICATION_FREQUENCIES = [
  { label: '0 - 0 - 1', value: '0 - 0 - 1', pattern: 1 },
  { label: '1 - 0 - 1', value: '1 - 0 - 1', pattern: 2 },
  { label: '1 - 1 - 1', value: '1 - 1 - 1', pattern: 3 },
  { label: '0 - 1 - 1', value: '0 - 1 - 1', pattern: 4 },
  { label: '0 - 1 - 0', value: '0 - 1 - 0', pattern: 5 },
  { label: '1 - 1 - 0', value: '1 - 1 - 0', pattern: 6 },
  { label: '1 - 0 - 0', value: '1 - 0 - 0', pattern: 8 },
];

export const DURATION = [
  { label: '1', value: '1' },
  { label: '2', value: '2' },
  { label: '3', value: '3' },
  { label: '5', value: '5' },
  { label: '7', value: '7' },
  { label: '10', value: '10' },
  { label: '15', value: '15' },
  { label: '20', value: '20' },
];

export const DIRECTIONS = [
  'Before meal 3 times a day',
  'After meal 3 times a day',
  'Before meal once daily',
  'After meal once daily',
  'As needed',
  'Before sleep',
  'With food',
  'Empty stomach',
  'Every 6 hours',
  'Every 8 hours',
  'Every 12 hours',
].map((item) => ({ label: item, value: item }));

export const MEDICATION_DOSAGE_OPTIONS = [
  { label: '1/2', value: 0.5 },
  { label: '1/4', value: 0.25 },
  { label: '1', value: 1 },
  { label: '2', value: 2 },
  { label: '3', value: 3 },
  { label: '4', value: 4 },
  { label: '5', value: 5 },
];
