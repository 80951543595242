/** @format */

import * as React from 'react';
import * as _ from 'underscore';
import { DateTime } from 'luxon';
import { Form } from '@rjsf/mui';
import validator from '@rjsf/validator-ajv8';

import { Divider, Grid, Dialog, DialogTitle, DialogContent, Typography, IconButton, useTheme } from '@mui/material';

// ICONS
import CloseIcon from '@mui/icons-material/Close';

import type { RJSFSchema } from '@rjsf/utils';
import { ReadmissionDatesHistoryType, PatientMedicalHistoryPayloadType } from '../../../types/PatientMedicalHistory.types';

import { GridInputField } from '../../common/react-json-schema';
import { GridObjectFieldTemplateWithoutCard } from '../../common/react-json-schema/GridObjectFieldTemplate';

import { GridCheckBoxField } from '../../common/react-json-schema/GridCheckBoxField';
import { PatientInfoType } from '../../../types/Patients.types';

interface EditLastHospitalizationProps {
  isOpen: boolean;
  isUpdatingAPICalling: boolean;

  patientInfo: PatientInfoType;

  handleSubmit: (payload: Partial<PatientMedicalHistoryPayloadType>) => void;

  handleClose: () => void;
}

const EditLastHospitalization: React.FunctionComponent<EditLastHospitalizationProps> = (props) => {
  const appTheme = useTheme();

  const schema: RJSFSchema = {
    title: 'Patient Re-admission Date',
    type: 'object',
    required: ['admissionDate', 'dischargeDate'],
    properties: {
      admissionDate: { type: 'string', title: 'Admission Date' },
      dischargeDate: { type: 'string', title: 'Discharge Date' },
      isCardiacVisit: { type: 'boolean', title: 'Cardiac Admission', default: true },
      remark: { type: 'string', title: 'Remark' },
    },
  };

  const uiSchema: any = {
    'ui:ObjectFieldTemplate': GridObjectFieldTemplateWithoutCard,

    admissionDate: {
      'ui:widget': GridInputField,
      'ui:disabled': false,
      'ui:type': 'date',
      'ui:grid': {
        xs: 4,
      },
    },

    dischargeDate: {
      'ui:widget': GridInputField,
      'ui:disabled': false,
      'ui:type': 'date',
      'ui:grid': {
        xs: 4,
      },
    },

    isCardiacVisit: {
      'ui:widget': GridCheckBoxField,
      'ui:disabled': false,
      'ui:grid': {
        xs: 4,
      },
    },

    remark: {
      'ui:widget': GridInputField,
      'ui:disabled': false,
      'ui:grid': {
        xs: 12,
      },
    },
  };

  const customValidate = (formData: any, errors: any) => {
    if (new Date(formData?.admissionDate).getTime() > new Date(formData?.dischargeDate).getTime()) {
      errors.admissionDate.addError('Admission Date should less than Discharge date');
    }

    if (formData?.remark && formData?.remark.length > 100) {
      errors.remark.addError("Remark should be less than 100 Char's");
    }
    return errors;
  };

  const handleClickUpdateFormData = async (formData: any) => {
    const newReadmissionDatesHistory: ReadmissionDatesHistoryType = {
      admissionDate: formData.admissionDate,
      dischargeDate: formData.dischargeDate,
      remark: formData.remark,
      isCardiacVisit: formData.isCardiacVisit,
      createdAt: new Date().toISOString(),
    };

    const payload: Partial<PatientMedicalHistoryPayloadType> = {
      readmissionDatesHistory: [...(props.patientInfo?.medicalHistory?.readmissionDatesHistory || []), newReadmissionDatesHistory],
    };

    props.handleSubmit(payload);
  };

  let readmissionDatesHistory = props.patientInfo?.medicalHistory?.readmissionDatesHistory || [];

  readmissionDatesHistory = readmissionDatesHistory.sort((a, b) => new Date(b.admissionDate).getTime() - new Date(a.admissionDate).getTime());

  return (
    <Dialog open={props.isOpen} onClose={props.handleClose} maxWidth={'sm'} fullWidth>
      <DialogTitle sx={{ p: '10px 15px', display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
        <Typography variant='fontBold16' color={appTheme.palette.customColor.text}>
          Patient Hospitalization (Readmission) History
        </Typography>
        <IconButton size='small' onClick={props.handleClose}>
          <CloseIcon />
        </IconButton>
      </DialogTitle>

      <DialogContent sx={{ '& .MuiBox-root:has(button[type=submit])': { textAlign: 'right' }, '& .rjsf > .form-group > .MuiFormControl-root > .MuiGrid-root': { alignItems: 'center' } }}>
        <Form
          customValidate={customValidate}
          schema={schema}
          uiSchema={uiSchema}
          validator={validator}
          onSubmit={(formData) => handleClickUpdateFormData(formData.formData)}
          disabled={props.isUpdatingAPICalling}
          showErrorList={false}
        />

        <Divider sx={{ my: 2 }} />

        <Grid item xs={12} container justifyContent='center' sx={{ mb: 2, py: 2, mt: 4 }} gap={3}>
          {_.isEmpty(readmissionDatesHistory) && <Typography variant='fontSemiBold14'>Please Enter the Readmission Dates to view the history</Typography>}

          {!_.isEmpty(readmissionDatesHistory) &&
            readmissionDatesHistory?.map((item: ReadmissionDatesHistoryType, index: number) => (
              <Grid
                item
                xs={12}
                container
                key={index}
                gap={1}
                sx={{
                  p: 1,
                  background: appTheme.palette.customColor.chipBgColor,
                  border: '1px solid',
                  borderColor: appTheme.palette.customColor.stroke,
                  borderRadius: '4px',
                }}>
                <Grid item xs={12}>
                  <Typography variant='fontReg14'>
                    {item.isCardiacVisit ? 'Cardiac Admission' : 'Non-Cardiac Admission'} from
                    {'  '}
                    <b>{item.admissionDate ? DateTime.fromJSDate(new Date(item.admissionDate)).toFormat('dd MMM yyyy') : 'NA'}</b>
                    {'  '}
                    to
                    {'  '}
                    <b>{item.dischargeDate ? DateTime.fromJSDate(new Date(item.dischargeDate)).toFormat('dd MMM yyyy') : 'NA'}</b>
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <Typography variant='fontReg14'>
                    Remark:{'  '}
                    {item.remark || 'NA'}
                  </Typography>
                </Grid>
              </Grid>
            ))}
        </Grid>
      </DialogContent>
    </Dialog>
  );
};

export default EditLastHospitalization;
