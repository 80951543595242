/** @format */

import { styled } from '@mui/material/styles';

import CloseIcon from '@mui/icons-material/Close';

const ZoomDeleteIcon = styled(CloseIcon)(({ theme }) => ({
  fontSize: '1.5rem',
  transition: 'transform 0.1s ease-in-out, color 0.1s ease-in-out',
  transform: 'scale(1)',
  color: 'inherit',
  '&:hover': {
    transform: 'scale(1.1)',
    color: 'black !important',
  },
}));

export default ZoomDeleteIcon;
