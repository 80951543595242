/** @format */

import * as React from 'react';

import Grid from '@mui/material/Grid';
import { useTheme } from '@mui/material';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';

// Icons
import TaskAltIcon from '@mui/icons-material/TaskAlt';
import MedicationIcon from '@mui/icons-material/Medication';
import VolunteerActivismIcon from '@mui/icons-material/VolunteerActivism';
import { DateTime } from 'luxon';
import FormFields from '../../common/form-fields/FormFields';
import { TEXT_INPUT_COMPONENT } from '../../../constants/PatientEnrollment';

export interface MedicationTeamTaskDateType {
  startDate?: string;
  endDate?: string;
}

interface PatientTasksCreationProps {
  medicationTeamTaskDate: MedicationTeamTaskDateType;

  handleOnClickCreateCareTreamTasks: (date: string) => void;
  handleOnClickCreateTasksMedicationChange: (startDate: string, endDate: string) => void;
  handleOnClickCreatePatientDailyTasks: (date: string) => void;
}

const PatientTasksCreation: React.FunctionComponent<PatientTasksCreationProps> = (props) => {
  const appTheme = useTheme();

  const [careTeamTaskDate, setCareTeamTaskDate] = React.useState<string>(DateTime.local().toFormat('yyyy-MM-dd'));

  const [patientDialyTaskDate, setPatientDialyTaskDate] = React.useState<string>(DateTime.local().toFormat('yyyy-MM-dd'));

  const [medicationTeamTaskDate, setMedicationTeamTaskDate] = React.useState<MedicationTeamTaskDateType>({
    startDate: DateTime.local().toFormat('yyyy-MM-dd'),
    endDate: DateTime.local().toFormat('yyyy-MM-dd'),
  });

  React.useEffect(() => {
    setMedicationTeamTaskDate(props.medicationTeamTaskDate);
  }, [props.medicationTeamTaskDate]);

  const taskCreationSettingsSidebar = [
    {
      icon: <MedicationIcon fontSize='small' htmlColor={appTheme.palette.customColor.primary} />,
      label: 'Medication Change',
      type: 'DATE_RANGE',
      value: medicationTeamTaskDate,
      onChangeStartDate: (event: any) => setMedicationTeamTaskDate((prev) => ({ ...prev, startDate: event.target.value })),
      onChangeEndDate: (event: any) => setMedicationTeamTaskDate((prev) => ({ ...prev, endDate: event.target.value })),
      onClickButton: () => props.handleOnClickCreateTasksMedicationChange(medicationTeamTaskDate.startDate!, medicationTeamTaskDate.endDate!),
    },
    {
      icon: <VolunteerActivismIcon fontSize='small' htmlColor={appTheme.palette.customColor.primary} />,
      label: 'Care Team',
      type: 'DATE',
      value: careTeamTaskDate,
      onChange: (event: any) => setCareTeamTaskDate(event?.target.value),
      onClickButton: () => props.handleOnClickCreateCareTreamTasks(careTeamTaskDate),
    },
    {
      icon: <TaskAltIcon fontSize='small' htmlColor={appTheme.palette.customColor.primary} />,
      type: 'DATE',
      label: 'Patient Daily Tasks',
      value: patientDialyTaskDate,
      onChange: (event: any) => setPatientDialyTaskDate(event?.target.value),
      onClickButton: () => props.handleOnClickCreatePatientDailyTasks(patientDialyTaskDate),
    },
  ];

  const _renderTaskCreationItems = (ele: any, index: number) => {
    const dateLabel = (
      <Grid container alignItems='center' pb={1}>
        {ele.icon}
        <Typography pl={0.6} component='span' variant='fontSemiBold16' color={appTheme.palette.customColor.primary}>
          {ele.label}
        </Typography>
      </Grid>
    );

    let dateComponent = (
      <FormFields
        label={dateLabel}
        id={ele.label}
        input={{
          value: ele.value,
          onChange: ele.onChange,
          type: 'date',
        }}
        componentType={TEXT_INPUT_COMPONENT}
      />
    );

    if (ele.type === 'DATE_RANGE') {
      dateComponent = (
        <Grid container justifyContent='space-between' alignItems='center'>
          {dateLabel}
          <Grid item xs={5.9} sx={{ '& .MuiFormLabel-root': { display: 'none' } }}>
            <FormFields
              label=''
              id={ele.label}
              input={{
                value: ele.value?.startDate,
                onChange: ele.onChangeStartDate,
                type: 'date',
              }}
              componentType={TEXT_INPUT_COMPONENT}
            />
          </Grid>
          <Grid item xs={5.9} sx={{ '& .MuiFormLabel-root': { display: 'none' } }}>
            <FormFields
              label=''
              id={ele.label}
              input={{
                value: ele.value?.endDate,
                onChange: ele.onChangeEndDate,
                type: 'date',
              }}
              componentType={TEXT_INPUT_COMPONENT}
            />
          </Grid>
        </Grid>
      );
    }

    return (
      <Grid item container alignItems='center' justifyContent='space-between' key={index}>
        <Grid item xs={9}>
          {dateComponent}
        </Grid>

        <Button variant='contained' onClick={ele.onClickButton}>
          Create
        </Button>
      </Grid>
    );
  };

  return (
    <Grid container direction='column'>
      <Typography variant='fontSemiBold16' component='div' pb={2} color={appTheme.palette.customColor.primary}>
        Tasks Creation
      </Typography>

      {taskCreationSettingsSidebar.map(_renderTaskCreationItems)}
    </Grid>
  );
};

export default PatientTasksCreation;
