/** @format */

import { createSlice, PayloadAction } from '@reduxjs/toolkit';

// Types and interfaces.
import { RootState } from '../../app/store';
import { PatientListNewType } from '../../types/PatientListPage.types';

export interface DeactivePatientListState {
  limit: number;
  pageOffset: number;

  count: number;
  listData: PatientListNewType[];
}

export const deactiveInitialState: DeactivePatientListState = {
  count: 0,
  listData: [],

  limit: 20,
  pageOffset: 0,
};

export const deactivePatientListReducer = createSlice({
  name: 'Deactive patient list reducer',
  initialState: deactiveInitialState,
  reducers: {
    setPageOffset: (state, { payload }: PayloadAction<number>) => {
      state.pageOffset = Number(payload);
    },

    setLimit: (state, { payload }: PayloadAction<number>) => {
      state.pageOffset = 0;
      state.limit = Number(payload);
    },

    changeDoctorFilters: (state) => {
      state.listData = [];
      state.count = 0;
      state.pageOffset = 0;
    },

    setDeactivePatientList: (state, { payload }: PayloadAction<{ data: PatientListNewType[]; count: number }>) => {
      state.listData = payload.data;
      state.listData.sort((a, b) => parseInt(a.basicDetails?.recordId!) - parseInt(b.basicDetails?.recordId!));
      state.count = payload.count;
    },

    updateDeactivePatientInfo: (state, { payload }: PayloadAction<PatientListNewType>) => {
      state.listData = [payload, ...state.listData];
    },

    clearPatientListStore: (state) => {
      state = {
        count: 0,
        listData: [],

        limit: 20,
        pageOffset: 0,
      };
      return state;
    },
  },
});

export const {
  clearPatientListStore,

  setDeactivePatientList,
  updateDeactivePatientInfo,

  setPageOffset,
  setLimit,

  changeDoctorFilters,
} = deactivePatientListReducer.actions;

export const deactivePatientListSelector = (state: RootState) => state.deactivePatientList;
