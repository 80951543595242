/** @format */

import React from 'react';
import * as _ from 'underscore';
import { DateTime } from 'luxon';

import { styled } from '@mui/material/styles';
import { ViewState } from '@devexpress/dx-react-scheduler';
import { Box, Button, IconButton, MenuItem, Select, Typography } from '@mui/material';
import { Scheduler, WeekView, Toolbar, DateNavigator, Appointments, MonthView, DayView, ViewSwitcher } from '@devexpress/dx-react-scheduler-material-ui';
import { useAppDispatch, useAppSelector } from '../../app/hooks';

// Icons.
import AccessibilityIcon from '@mui/icons-material/Accessibility';
import { VirtualAppointmentIcon } from '../../assets/AppointmentIcons';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';

// Components.
import PatientSearch from './PatientSearch';
import { ConfirmationDialog } from '../common/confirmation-dialog/ConfirmationDialog';
import SearchSchedulePatientAppointmentModal from './SearchSchedulePatientAppointmentModal';

import { AppointmentsType } from '../../types/Appointments';
import { getStartAndEndDateForAppoitmentWeekView } from '../../utils/functions/convert-date';
import FormFields, { FormFieldsProps } from '../common/form-fields/FormFields';
import { TEXT_SELECT_COMPONENT } from '../../constants/PatientEnrollment';
import { setDoctorName } from '../../reducers/patient-list-reducer/PatientListSlice';
import { convertToPascalCase } from '../../utils/functions/case-change';

const PREFIX = 'appointments';

const classes = {
  todayCell: `${PREFIX}-todayCell`,
  weekendCell: `${PREFIX}-weekendCell`,
  today: `${PREFIX}-today`,
  weekend: `${PREFIX}-weekend`,
  day: `${PREFIX}-day`,
  dayCell: `${PREFIX}-dayCell`,
};

const StyledSwitcher = styled(ViewSwitcher.Switcher)(({ theme }) => ({
  '&:active': {
    border: 'none',
  },
  '&:hover': {
    border: 'none',
  },

  '&:focus': {
    border: 'none',
  },

  svg: {
    // display: 'none',
  },
  color: '#2B315D',
  border: 'none !important',
  minWidth: '120px',
  textTransform: 'capitalize',
}));

const StyledMonthViewDayScaleRowComponent = styled(MonthView.DayScaleRow)(({ theme }) => ({
  padding: '10px',
  height: '60px',
  backgroundColor: '#F1F2F3',

  '.Cell-dayOfWeek': {
    fontSize: '16px !important',
    color: '#3E4685 !important',
    textAlign: 'left !important',
    padding: '0 0 0 5px !important',
    margin: '0 0 20px 0 !important',
  },
}));

const StyledMonthViewTimeTableCell = styled(MonthView.TimeTableCell)(({ theme }) => ({
  '.Cell-text': {
    textAlign: 'left !important',
    color: '#3E4685 !important',
    fontSize: '14px',
    padding: '5px',
  },

  '.Cell-today': {
    textAlign: 'left !important',
    color: 'white !important',
    fontSize: '14px',
    fontWeight: 700,
    padding: '0px 5px 5px 5px',
    background: 'white',
    margin: 0,
    borderRaidus: '10px',

    width: 'min-content',
    backgroundColor: '#3E4685',

    '&:hover': {
      // backgroundColor: 'inherit',
    },
  },

  '&:hover': {
    backgroundColor: 'white',
  },

  borderBottom: '1px solid #2B315D',
}));

const StyledWeekViewTimeTableCell = styled(WeekView.TimeTableCell)(({ theme }) => ({
  [`&.${classes.todayCell}`]: {
    borderBottom: 'none',
    borderRight: '1px solid #9BB4CC',
    padding: '10px',
    textAlign: 'left',
  },

  [`&.${classes.weekendCell}`]: {
    borderBottom: 'none',
    borderRight: '1px solid #9BB4CC',
    padding: '10px',
  },

  [`&.${classes.dayCell}`]: {
    borderBottom: 'none',
    borderRight: '1px solid #9BB4CC',
    padding: '10px',
  },

  '&:first-of-type': {},

  // backgroundColor: 'yellow',
}));

const StyledWeekViewDayScaleCell = styled(WeekView.DayScaleCell)(({ theme }) => ({
  borderLeft: '1px solid #9BB4CC',
  borderRight: '1px solid #9BB4CC',
  backgroundColor: '#F1F2F3',
  '&:last-of-type': {
    borderRight: 'none',
  },
  '&:first-of-type': {
    borderLeft: 'none',
  },

  [`&.${classes.day}`]: {
    '.Cell-dayOfMonth': {
      textAlign: 'left',
      marginLeft: '5px',
      fontFamily: 'Lato',
      fontStyle: 'normal',
      fontWeight: 700,
      fontSize: '20px',
      lineHeight: '24px',
      color: '#3E4685',
      paddingBottom: '15px',
    },

    '.Cell-dayOfWeek': {
      textTransform: 'uppercase',
      textAlign: 'left',
      marginLeft: '5px',
      marginBottom: '3px',
      fontFamily: 'Lato',
      fontStyle: 'normal',
      fontWeight: 700,
      fontSize: '12px',
      lineHeight: '14px',
      color: '#3E4685',
    },
  },

  [`&.${classes.today}`]: {
    backgroundColor: '#F6F8FC',

    '.Cell-dayOfMonth': {
      textAlign: 'left',
      marginLeft: '5px',
      fontFamily: 'Lato',
      fontStyle: 'normal',
      fontWeight: 700,
      fontSize: '20px',
      lineHeight: '24px',
      color: '#3E4685',
      paddingBottom: '15px',
    },

    '.Cell-dayOfWeek': {
      textTransform: 'uppercase',
      textAlign: 'left',
      marginLeft: '5px',
      marginBottom: '3px',
      fontFamily: 'Lato',
      fontStyle: 'normal',
      fontWeight: 700,
      fontSize: '12px',
      lineHeight: '14px',
      color: '#3E4685',
    },
  },

  [`&.${classes.weekend}`]: {
    '.Cell-dayOfMonth': {
      textAlign: 'left',
      marginLeft: '5px',
      fontFamily: 'Lato',
      fontStyle: 'normal',
      fontWeight: 700,
      fontSize: '20px',
      lineHeight: '24px',
      color: '#3E4685',
      paddingBottom: '15px',
    },

    '.Cell-dayOfWeek': {
      textTransform: 'uppercase',
      textAlign: 'left',
      marginLeft: '5px',
      marginBottom: '3px',
      fontFamily: 'Lato',
      fontStyle: 'normal',
      fontWeight: 700,
      fontSize: '12px',
      lineHeight: '14px',
      color: '#3E4685',
    },
  },
}));

const StyledLayout = styled(WeekView.Layout)(({ theme }) => ({
  border: '1px solid #9BB4CC',
}));

const StyledMonthViewLayout = styled(WeekView.Layout)(({ theme }) => ({
  border: '1px solid #9BB4CC',
  overflow: 'hidden',
}));

const StyledDayViewDayScaleCell = styled(DayView.DayScaleCell)(({ theme }) => ({
  // border: '1px solid #9BB4CC',
  // overflow: 'hidden',
  padding: '5px !important',
  backgroundColor: '#F1F2F3',

  '.Cell-dayOfWeek': {
    textTransform: 'uppercase',
    textAlign: 'left',
    marginBottom: '3px',
    fontFamily: 'Lato',
    fontStyle: 'normal',
    fontWeight: 700,
    fontSize: '12px',
    lineHeight: '14px',
    color: '#3E4685',
  },

  '.Cell-dayOfMonth': {
    textAlign: 'left',
    fontFamily: 'Lato',
    fontStyle: 'normal',
    fontWeight: 700,
    fontSize: '20px',
    lineHeight: '24px',
    color: '#3E4685',
    paddingBottom: '15px',
  },
}));

const StyledTicks: any = styled(WeekView.TimeScaleTickCell)(({ theme }) => ({
  display: 'none',
}));

const StyledTimescaleLabel = styled(WeekView.TimeScaleLabel)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-end',

  '.Label-text': {
    color: '#5C6A90',
    fontWeight: 400,
    fontSize: '14px',
  },
}));

const StyledTimescaleLayout = styled(WeekView.TimeScaleLayout)(({ theme }) => ({
  paddingLeft: '10px',
}));

// Component props.
interface AppointmentsCalendarProps {
  appointmentList: AppointmentsType[];
  isAppointmentUpdating: boolean;
  handleSubmitNewPatientAppointment: (payload: AppointmentsType) => void;
  handleSubmitUpdateAppointment: (payload: AppointmentsType) => void;
  handleSubmitDeleteAppointment: (payload: AppointmentsType) => void;
  appointmentFilters: any;
  handleChangeAppointmentFilters: (filters: any) => void;
  hidePatientSearchFilter?: boolean;
}

const AppointmentsCalendarComponent: React.FunctionComponent<AppointmentsCalendarProps> = (props) => {
  const [view, setView] = React.useState<string>(props.appointmentFilters.view);

  const [currentDate, setCurrentDate] = React.useState(new Date());

  const [selectedPatientInfo, setSelectedPatientInfo] = React.useState((!_.isEmpty(props.appointmentFilters?.patientDetails) && props.appointmentFilters?.patientDetails) || null);

  const [isOpenAppointmentModal, setIsOpenAppointmentModal] = React.useState<boolean>(false);
  const [appointmentInfo, setAppointmentInfo] = React.useState<AppointmentsType | null>(null);

  const [isOpenDeleteAppointmentModal, setIsOpenDeleteAppointmentModal] = React.useState<boolean>(false);

  const handleChangePatientInfo = (payload: any) => {
    props.handleChangeAppointmentFilters({ patientSourceId: payload.patientSourceId, patientDetails: { ...payload } });
    setSelectedPatientInfo(payload);
  };

  const state = useAppSelector((state) => state.patientList);
  const doctors = useAppSelector((state) => state.userRegion.orgUsers);

  const handleClickClearPatientSearchFilter = () => {
    props.handleChangeAppointmentFilters({ patientSourceId: '', patientDetails: {} });
    setSelectedPatientInfo(null);
  };

  const onCurrentViewNameChange = (viewName: string) => {
    setView(viewName);
  };

  const handleChangeCurrentDate = (currentDate: Date) => {
    setCurrentDate(currentDate);
  };

  const handleClickShowDeleteConfirmationDialog = () => {
    setIsOpenDeleteAppointmentModal(true);
  };

  const handleClickHideDeleteConfirmationDialog = () => {
    setIsOpenDeleteAppointmentModal(false);
  };

  const handleClickShowAddEditAppointmentDialog = (payload: AppointmentsType | null, startDate?: Date, endDate?: Date) => {
    setIsOpenAppointmentModal(true);
    if (payload) {
      setAppointmentInfo(payload);
    } else {
      setAppointmentInfo({ startDate, endDate } as AppointmentsType);
    }
  };

  const reduxDispatch = useAppDispatch();

  const handleSubmitAppointment = async (appointment: AppointmentsType) => {
    if (!_.isEmpty(appointmentInfo?.appointmentId)) {
      await props.handleSubmitUpdateAppointment(appointment);
    } else {
      await props.handleSubmitNewPatientAppointment(appointment);
    }

    setIsOpenAppointmentModal(false);
    setAppointmentInfo(null);
  };

  const handleSubmitDeleteAppointment = () => {
    if (!appointmentInfo) {
      return;
    }

    props.handleSubmitDeleteAppointment(appointmentInfo);

    setIsOpenDeleteAppointmentModal(false);
    setIsOpenAppointmentModal(false);
  };

  const handleClickHideAddEditAppointmentDialog = () => {
    setIsOpenAppointmentModal(false);
    setAppointmentInfo(null);
  };

  const TimeTableCell = (props: WeekView.TimeTableCellProps) => {
    const { startDate } = props;
    const date = new Date(startDate!);

    if (date.getDate() === new Date().getDate()) {
      return (
        <StyledWeekViewTimeTableCell
          {...props}
          // onClick={props.onDoubleClick}
          className={classes.todayCell}
          onClick={() => handleClickShowAddEditAppointmentDialog(null, props.startDate!, props.endDate!)}
        />
      );
    }

    if (date.getDay() === 0 || date.getDay() === 6) {
      return (
        <StyledWeekViewTimeTableCell
          {...props}
          // onClick={props.onDoubleClick}
          className={classes.weekendCell}
          onClick={() => handleClickShowAddEditAppointmentDialog(null, props.startDate!, props.endDate!)}
        />
      );
    }

    return (
      <StyledWeekViewTimeTableCell
        {...props}
        className={classes.dayCell}
        // onClick={props.onDoubleClick}
        onClick={() => handleClickShowAddEditAppointmentDialog(null, props.startDate!, props.endDate!)}
      />
    );
  };

  const DayScaleCell = (props: WeekView.DayScaleCellProps) => {
    const { startDate, today } = props;

    if (today) {
      return <StyledWeekViewDayScaleCell {...props} className={classes.today} />;
    }

    if (startDate.getDay() === 0 || startDate.getDay() === 6) {
      return <StyledWeekViewDayScaleCell {...props} className={classes.weekend} />;
    }

    if (startDate.getDay() > 0 && startDate.getDay() < 6) {
      return <StyledWeekViewDayScaleCell {...props} className={classes.day} />;
    }

    return <StyledWeekViewDayScaleCell {...props} />;
  };

  const DayViewDayScaleCell = (props: DayView.DayScaleCellProps) => {
    return <StyledDayViewDayScaleCell {...props}></StyledDayViewDayScaleCell>;
  };

  const CustomAppointment = (props: Appointments.AppointmentProps) => {
    const { children, data } = props;

    if (data.status === 'UPCOMING') {
      return (
        <Appointments.Appointment
          {...props}
          onClick={() => handleClickShowAddEditAppointmentDialog(data as AppointmentsType)}
          style={{
            borderRadius: '4px',
            backgroundColor: data.appointmentType === 'CONSULTATION' ? '#F3E5E5' : '#00a5f0',
            borderLeft: data.appointmentType === 'CONSULTATION' ? '5px solid #E9430E' : '5px solid #006190',
            cursor: 'pointer',
          }}>
          {children}
        </Appointments.Appointment>
      );
    } else if (data.status === 'COMPLETED') {
      return (
        <Appointments.Appointment
          {...props}
          // onClick={() => handleClickShowAddEditAppointmentDialog(data as AppointmentsType)}
          style={{
            borderRadius: '4px',
            backgroundColor: 'white',
            borderLeft: data.appointmentType === 'CONSULTATION' ? '5px solid #E9430E' : '5px solid #0EA5E9',
            textDecoration: 'line-through',
          }}>
          {children}
        </Appointments.Appointment>
      );
    } else {
      return (
        <Appointments.Appointment {...props} onClick={() => handleClickShowAddEditAppointmentDialog(data as AppointmentsType)}>
          {children}
        </Appointments.Appointment>
      );
    }
  };

  const CustomAppointmentContent = (props: Appointments.AppointmentContentProps) => {
    const { data } = props;

    let name = '';

    if (data.patientDetails?.firstName && data.patientDetails?.lastName) {
      name = data.patientDetails?.firstName + ' ' + data.patientDetails?.lastName;
    }

    const _renderIcons = () => {
      if (data.appointmentPlace === 'VIRTUAL') {
        return (
          <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', marginTop: '2px' }}>
            <VirtualAppointmentIcon fill={data.appointmentType === 'CONSULTATION' ? '#E9430E' : '#0369A1'} />
          </Box>
        );
      } else if (data.appointmentPlace === 'PHYSICAL') {
        return (
          <Box
            sx={{
              borderRadius: '50%',
              padding: '2px',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              backgroundColor: data.appointmentType === 'CONSULTATION' ? '#E9430E' : '#0369A1',
              height: '18px',
              width: '18px',
              marginTop: '2px',
            }}>
            <AccessibilityIcon color='info' fontSize='small' sx={{ height: '18px', width: '18px' }} />
          </Box>
        );
      } else {
        return null;
      }
    };

    return (
      <Appointments.AppointmentContent
        {...props}
        style={{
          color: data.appointmentType === 'CONSULTATION' ? '#E9430E' : '#0369A1',
        }}>
        <Box>
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <Typography variant='fontReg14'>{DateTime.fromJSDate(new Date(data.startDate)).toFormat('hh:mm a')}</Typography>

            <Box sx={{ margin: '0 0 5px 4px' }}>{_renderIcons()}</Box>
          </Box>

          <Box>
            <Typography variant='fontSemiBold12'>
              {name.length === 0 ? data.patientDetails?.patientId : name} - {data.appointmentType === 'CONSULTATION' ? 'Consultation' : 'Lab Test'}
            </Typography>
          </Box>
        </Box>
      </Appointments.AppointmentContent>
    );
  };

  const CustomToolbar = (props: Toolbar.FlexibleSpaceProps) => {
    const doctorsTypeFormField: FormFieldsProps = {
      label: '',
      id: 'doctor-list',
      componentType: TEXT_SELECT_COMPONENT,
      width: '200px',
      input: {
        value: state.doctorName,
        type: 'text',
        maxMenuHeight: 400,
        onChange: (event) => {
          const doctorName = event.target.value;
          localStorage.setItem('selectedCardiologistName', doctorName);
          reduxDispatch(setDoctorName(doctorName));
        },
        options: [{ label: 'All  Cardiologists', value: 'ALL' }].concat(doctors?.map((e) => ({ ...e, label: convertToPascalCase(e.label, ' ', ' ') }))),
      },
      style: {
        padding: 0,
        border: '1px solid #9BB4CC',
        ':hover': {
          borderRadius: '4px',
          border: '1px solid #9BB4CC',
          background: '#EEE',
          cursor: 'default',
        },
        ':active': {
          border: 'none',
        },
        '.MuiSelect-select': {
          padding: '5px 10px',
          fontSize: '14px',
          border: '1px solid #9BB4CC',
        },
        '.MuiInputBase-root': {
          border: 'none',
        },
        'MuiSelect-nativeInput': {
          padding: 0,
          border: 'none',
        },
      },
    };
    return (
      <Toolbar.FlexibleSpace>
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <Button variant='contained' sx={{ minWidth: '200px', position: 'relative', minHeight: '43px', marginRight: '10px' }} onClick={() => handleClickShowAddEditAppointmentDialog(null)}>
            <Typography variant='fontReg16'>Schedule Appointment</Typography>
          </Button>
          <Box mr='16px' sx={{ minWidth: '200px' }}>
            <FormFields {...doctorsTypeFormField} />
          </Box>
        </Box>
      </Toolbar.FlexibleSpace>
    );
  };

  const CustomToolbarRootComponent = (props: Toolbar.RootProps, hidePatientSearchFilter?: boolean) => {
    return (
      <Toolbar.Root {...props} style={{ marginTop: '20px', padding: '15px 0' }}>
        {hidePatientSearchFilter ? null : (
          <PatientSearch userDetails={selectedPatientInfo} disabled={false} handleChange={handleChangePatientInfo} handleClickClearSearchFilter={handleClickClearPatientSearchFilter} />
        )}

        {props.children}
      </Toolbar.Root>
    );
  };

  const CustomOpenButton = (props: DateNavigator.OpenButtonProps) => {
    return <></>;
  };

  const handleDateNavigate = (direction: 'forward' | 'back' | undefined, nextDate: string | Date | number) => {
    let startDate = props.appointmentFilters.startDate;
    let endDate = props.appointmentFilters.endDate;
    if (view === 'Week' && direction === 'back') {
      startDate = DateTime.fromISO(startDate).minus({ week: 1 }).toFormat('yyyy-MM-dd');
      endDate = DateTime.fromISO(endDate).minus({ week: 1 }).toFormat('yyyy-MM-dd');
    }

    if (view === 'Week' && direction === 'forward') {
      startDate = DateTime.fromISO(startDate).plus({ week: 1 }).toFormat('yyyy-MM-dd');
      endDate = DateTime.fromISO(endDate).plus({ week: 1 }).toFormat('yyyy-MM-dd');
    }

    if (view === 'Month' && direction === 'back') {
      startDate = DateTime.fromISO(startDate).minus({ month: 1 }).toFormat('yyyy-MM-dd');
      endDate = DateTime.fromISO(endDate).minus({ month: 1 }).toFormat('yyyy-MM-dd');
    }

    if (view === 'Month' && direction === 'forward') {
      startDate = DateTime.fromISO(DateTime.fromJSDate(new Date(startDate)).toFormat('yyyy-MM-dd'))
        .plus({ month: 1 })
        .toFormat('yyyy-MM-dd');
      endDate = DateTime.fromISO(DateTime.fromJSDate(new Date(endDate)).toFormat('yyyy-MM-dd'))
        .plus({ month: 1 })
        .toFormat('yyyy-MM-dd');
    }

    if (view === 'Day' && direction === 'forward') {
      startDate = DateTime.fromJSDate(new Date(startDate)).plus({ day: 1 }).toFormat('yyyy-MM-dd');
      endDate = DateTime.fromJSDate(new Date(endDate)).plus({ day: 1 }).toFormat('yyyy-MM-dd');
    }

    if (view === 'Day' && direction === 'back') {
      startDate = DateTime.fromJSDate(new Date(startDate)).minus({ day: 1 }).toFormat('yyyy-MM-dd');
      endDate = DateTime.fromJSDate(new Date(endDate)).minus({ day: 1 }).toFormat('yyyy-MM-dd');
    }

    props.handleChangeAppointmentFilters({ startDate, endDate });
  };

  const CustomDateNavigator = (navProps: DateNavigator.RootProps, hidePatientSearchFilter?: boolean) => {
    const yearPattern = /\b(19|20)\d{2}\b/;

    return (
      <Box sx={{ position: 'relative', display: 'flex', alignItems: 'center', marginLeft: hidePatientSearchFilter ? 'none' : 'auto' }}>
        <Typography sx={{ marginRight: '10px', minWidth: '100px' }} variant='fontBold20' color='primary'>
          {DateTime.fromJSDate(new Date(props.appointmentFilters.startDate)).toFormat('MMM y')}
        </Typography>

        <Box sx={{ backgroundColor: '#5C6A90', borderTopLeftRadius: '10px', borderBottomLeftRadius: '10px', borderTopRightRadius: '10px', borderBottomRightRadius: '10px' }}>
          <DateNavigator.Root
            {...navProps}
            onNavigate={(direction: 'forward' | 'back' | undefined, nextDate: string | Date | number) => {
              handleDateNavigate(direction, nextDate);
              navProps.onNavigate(direction, nextDate);
            }}
          />

          <Box
            sx={{
              borderRadius: 0,
              height: '35px',
              boxShadow: 'none',
              paddingInline: '4px',
              position: 'absolute',
              top: 0,
              left: '146px',
              cursor: 'default',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              width: '40%',
            }}>
            <Typography variant='fontReg14' sx={{ color: 'white', textAlign: 'center', width: '100%' }}>
              {navProps.navigatorText?.replace(yearPattern, '')}
            </Typography>
          </Box>
        </Box>

        {/* <IconButton
          onClick={() => setCurrentDate(new Date())}
          sx={{ backgroundColor: '#5C6A90', borderRadius: '10px', marginLeft: '10px', maxHeight: '35px', '&:hover': { backgroundColor: '#5C6A90', opacity: 0.7 } }}
        >
          <EventIcon sx={{ color: 'white' }} />
        </IconButton> */}
      </Box>
    );
  };

  const CustomViewSwticher = (props: ViewSwitcher.SwitcherProps) => {
    setView(props.currentView.name);
    return (
      <Box sx={{ display: 'flex', alignItems: 'center', border: '1px solid #2B315D', paddingRight: '10px', borderRadius: '4px' }}>
        <StyledSwitcher {...props} className='switcher'>
          <Select variant='standard'>
            {props.availableViews.map((e) => {
              return (
                <MenuItem value={e.name} key={e.name}>
                  {e.displayName}
                </MenuItem>
              );
            })}
          </Select>
        </StyledSwitcher>
      </Box>
    );
  };

  const CustomNavigator = (props: DateNavigator.NavigationButtonProps) => {
    if (props.type === 'back') {
      return (
        <IconButton
          onClick={(e) => {
            props.onClick!(e);
          }}
          sx={{
            padding: 0,
            borderRadius: 0,

            '&:hover': { backgroundColor: 'inherit' },
            borderRight: '1px solid white',
          }}>
          <KeyboardArrowLeftIcon fontSize='large' color='info' />
        </IconButton>
      );
    } else {
      return (
        <>
          <IconButton
            onClick={(e) => {
              props.onClick!(e);
            }}
            sx={{
              marginLeft: '122px',
              padding: 0,
              borderRadius: 0,

              '&:hover': { backgroundColor: 'inherit' },
              borderLeft: '1px solid white',
            }}>
            <KeyboardArrowRightIcon fontSize='large' color='info' />
          </IconButton>
        </>
      );
    }
  };

  const CustomMonthViewDayScaleRowComponent = (props: MonthView.RowProps) => {
    return <StyledMonthViewDayScaleRowComponent>{props.children}</StyledMonthViewDayScaleRowComponent>;
  };

  const CustomMonthViewTimeTableCellComponent = (props: MonthView.TimeTableCellProps) => {
    return <StyledMonthViewTimeTableCell {...props} onClick={() => handleClickShowAddEditAppointmentDialog(null, props.startDate!, props.endDate!)} />;
  };

  React.useEffect(() => {
    let startDate = null;
    let endDate = null;

    if (view === props.appointmentFilters.view) {
      return;
    }

    if (view === 'Week') {
      const result = getStartAndEndDateForAppoitmentWeekView();
      startDate = DateTime.fromISO(result.startDate).minus({ day: 1 }).toFormat('yyyy-MM-dd');
      endDate = DateTime.fromISO(result.endDate).minus({ day: 1 }).toFormat('yyyy-MM-dd');
    }

    if (view === 'Month') {
      const date = new Date();
      const calStartDate = DateTime.fromJSDate(new Date(date.getFullYear(), date.getMonth(), 1)).toISO();
      startDate = DateTime.fromISO(calStartDate as string).toFormat('yyyy-MM-dd') as string;
      const calEndDate = DateTime.fromJSDate(new Date(date.getFullYear(), date.getMonth() + 1)).toISO();
      endDate = DateTime.fromISO(calEndDate as string).toFormat('yyyy-MM-dd') as string;
    }

    if (view === 'Day') {
      const date = new Date();
      startDate = DateTime.fromJSDate(date).toFormat('yyyy-MM-dd');
      endDate = DateTime.fromJSDate(date).toFormat('yyyy-MM-dd');
    }

    props.handleChangeAppointmentFilters({ startDate, endDate, view });
  }, [view]);

  return (
    <>
      <Scheduler data={props.appointmentList} height='auto'>
        <ViewState
          currentDate={props.appointmentFilters.startDate || new Date()}
          onCurrentDateChange={handleChangeCurrentDate}
          defaultCurrentDate={currentDate}
          currentViewName={view}
          onCurrentViewNameChange={onCurrentViewNameChange}
        />

        <Toolbar flexibleSpaceComponent={CustomToolbar} rootComponent={(rootProps) => CustomToolbarRootComponent(rootProps, props.hidePatientSearchFilter)} />

        <DateNavigator
          rootComponent={(rootProps) => CustomDateNavigator(rootProps, props.hidePatientSearchFilter)}
          navigationButtonComponent={CustomNavigator}
          openButtonComponent={CustomOpenButton}
        />

        <WeekView
          startDayHour={0}
          endDayHour={24}
          intervalCount={1}
          cellDuration={60}
          timeTableCellComponent={TimeTableCell}
          dayScaleCellComponent={DayScaleCell}
          timeScaleTickCellComponent={StyledTicks}
          timeScaleLabelComponent={StyledTimescaleLabel}
          timeScaleLayoutComponent={StyledTimescaleLayout}
          layoutComponent={StyledLayout}
        />

        <MonthView dayScaleRowComponent={CustomMonthViewDayScaleRowComponent} layoutComponent={StyledMonthViewLayout} timeTableCellComponent={CustomMonthViewTimeTableCellComponent} />

        <DayView
          startDayHour={0}
          endDayHour={24}
          intervalCount={1}
          cellDuration={60}
          dayScaleCellComponent={DayViewDayScaleCell}
          timeTableCellComponent={TimeTableCell}
          layoutComponent={StyledLayout}
          timeScaleLabelComponent={StyledTimescaleLabel}
          timeScaleLayoutComponent={StyledTimescaleLayout}
          timeScaleTickCellComponent={StyledTicks}
        />

        <ViewSwitcher switcherComponent={CustomViewSwticher} />

        <Appointments appointmentComponent={CustomAppointment} appointmentContentComponent={CustomAppointmentContent} />
      </Scheduler>

      {isOpenAppointmentModal && (
        <SearchSchedulePatientAppointmentModal
          open={isOpenAppointmentModal}
          disableButtons={false}
          isNewAppointment={_.isEmpty(appointmentInfo?.appointmentId)}
          handleClose={handleClickHideAddEditAppointmentDialog}
          appointment={appointmentInfo!}
          handleSubmitAppointment={handleSubmitAppointment}
          handleDeleteAppointment={handleClickShowDeleteConfirmationDialog}
          isUpdatingAppointment={props.isAppointmentUpdating}
          selectedPatientInfo={(appointmentInfo as any)?.patientDetails}
        />
      )}

      {isOpenDeleteAppointmentModal && (
        <ConfirmationDialog
          description='Once deleted, the action cannot be undo.'
          title='Are you sure you want to delete this appointment?'
          onClickConfirm={handleSubmitDeleteAppointment}
          isOpen={isOpenDeleteAppointmentModal}
          onClose={handleClickHideDeleteConfirmationDialog}
        />
      )}
    </>
  );
};

export const AppointmentsCalendar = AppointmentsCalendarComponent;
