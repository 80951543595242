/** @format */

import { Box, Tooltip, TooltipProps, Typography, styled, tooltipClasses } from '@mui/material';
import React from 'react';

const LightTooltip = styled(({ className, ...props }: TooltipProps) => <Tooltip {...props} classes={{ popper: className }} />)(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.common.white,
    color: 'rgba(0, 0, 0, 0.87)',
    boxShadow: theme.shadows[1],
    fontSize: 11,
  },
}));

interface InfoIconProps {
  title: string | JSX.Element;
}

export const InfoIcon: React.FC<InfoIconProps> = ({ title }) => {
  return (
    <LightTooltip
      title={
        <Box>
          <Typography color='#9B9A9A' variant='fontReg16'>
            {title}
          </Typography>
        </Box>
      }
      placement='right-end'>
      <svg width='20' height='20' viewBox='0 0 17 16' fill='none' xmlns='http://www.w3.org/2000/svg'>
        <path
          d='M8.56348 12C8.79014 12 8.98028 11.9232 9.13388 11.7696C9.28748 11.616 9.36401 11.4261 9.36348 11.2V7.98C9.36348 7.75333 9.28668 7.56667 9.13308 7.42C8.97948 7.27333 8.78961 7.2 8.56348 7.2C8.33681 7.2 8.14668 7.2768 7.99308 7.4304C7.83948 7.584 7.76294 7.77387 7.76348 8V11.22C7.76348 11.4467 7.84028 11.6333 7.99388 11.78C8.14748 11.9267 8.33734 12 8.56348 12ZM8.56348 5.6C8.79014 5.6 8.98028 5.5232 9.13388 5.3696C9.28748 5.216 9.36401 5.02613 9.36348 4.8C9.36348 4.57333 9.28668 4.3832 9.13308 4.2296C8.97948 4.076 8.78961 3.99947 8.56348 4C8.33681 4 8.14668 4.0768 7.99308 4.2304C7.83948 4.384 7.76294 4.57387 7.76348 4.8C7.76348 5.02667 7.84028 5.2168 7.99388 5.3704C8.14748 5.524 8.33734 5.60053 8.56348 5.6ZM8.56348 16C7.45681 16 6.41681 15.7899 5.44348 15.3696C4.47014 14.9493 3.62348 14.3795 2.90348 13.66C2.18348 12.94 1.61361 12.0933 1.19388 11.12C0.774143 10.1467 0.56401 9.10667 0.563477 8C0.563477 6.89333 0.77361 5.85333 1.19388 4.88C1.61414 3.90667 2.18401 3.06 2.90348 2.34C3.62348 1.62 4.47014 1.05013 5.44348 0.6304C6.41681 0.210667 7.45681 0.000533333 8.56348 0C9.67014 0 10.7101 0.210133 11.6835 0.6304C12.6568 1.05067 13.5035 1.62053 14.2235 2.34C14.9435 3.06 15.5136 3.90667 15.9339 4.88C16.3541 5.85333 16.564 6.89333 16.5635 8C16.5635 9.10667 16.3533 10.1467 15.9331 11.12C15.5128 12.0933 14.9429 12.94 14.2235 13.66C13.5035 14.38 12.6568 14.9501 11.6835 15.3704C10.7101 15.7907 9.67014 16.0005 8.56348 16ZM8.56348 14.4C10.3368 14.4 11.8469 13.7768 13.0939 12.5304C14.3408 11.284 14.964 9.77387 14.9635 8C14.9635 6.22667 14.3403 4.71653 13.0939 3.4696C11.8475 2.22267 10.3373 1.59947 8.56348 1.6C6.79014 1.6 5.28001 2.2232 4.03308 3.4696C2.78614 4.716 2.16294 6.22613 2.16348 8C2.16348 9.77333 2.78668 11.2835 4.03308 12.5304C5.27948 13.7773 6.78961 14.4005 8.56348 14.4Z'
          fill='#9B9A9A'
        />
      </svg>
    </LightTooltip>
  );
};
