/** @format */

import * as React from 'react';
import * as _ from 'underscore';
import { DateTime } from 'luxon';

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

import { PatientPrescriptionReviewListType } from '../../types/PrescriptionsGenerator.types';

interface PrescriptionReviewDateTimeCellProps {
  rowData: PatientPrescriptionReviewListType;
}

const PrescriptionReviewDateTimeCell: React.FunctionComponent<PrescriptionReviewDateTimeCellProps> = (props) => {
  const rowData = props.rowData;

  let reviewDateTime = null;
  let reviewDate = '-';
  let reviewDateColor = '#33425B';
  let reviewDateVariant = 'fontSemiBold14';

  if (rowData && rowData.timestamp) {
    reviewDate = DateTime.fromJSDate(new Date(rowData.timestamp)).toFormat('MMM dd, yyyy');
  }

  if (rowData && !_.isEmpty(rowData.appointmentInfo)) {
    const startDate = DateTime.fromJSDate(new Date(rowData.appointmentInfo?.startDate!)).toFormat('hh:mm a');
    const endDate = DateTime.fromJSDate(new Date(rowData.appointmentInfo?.endDate!)).toFormat('hh:mm a');
    reviewDateTime = startDate + ' - ' + endDate;

    reviewDateColor = 'fontReg14';
    reviewDateVariant = '#5C6A90';
  }

  return (
    <Box>
      {reviewDateTime && (
        <Typography component='div' variant='fontSemiBold14' color='#33425B' sx={{ pb: 0.7 }}>
          {reviewDateTime}
        </Typography>
      )}
      <Typography component='div' variant={reviewDateVariant as any} color={reviewDateColor}>
        {reviewDate}
      </Typography>
    </Box>
  );
};

export default PrescriptionReviewDateTimeCell;
