/** @format */

import axios from 'axios';
import React from 'react';
import SearchIcon from '@mui/icons-material/Search';
import { searchPatient } from '../../service/patients';
import { Button, Typography } from '@mui/material';
import _ from 'underscore';
import ClearIcon from '@mui/icons-material/Clear';
import CustomAsyncSelectWithDynamicDimensions from '../common/custom-select/CustomAsyncSelectWithDynamicDimensions';
import { FormatOptionLabelMeta } from 'react-select';

// Component props.
interface PatientSearchProps {
  error?: string;
  handleChange?: (payload: any) => void;
  userDetails?: {
    firstName: string;
    lastName: string;
    patientId: string;
    patientSourceId: string;
    age: string;
    gender: string;
  } | null;
  disabled: boolean;
  handleClickClearSearchFilter?: () => void;

  minWidth?: number;
  minHeight?: number;

  maxWidth?: number;
  maxHeight?: number;

  width?: number;
  height?: number;
  isNewAppointment: boolean;
}

const PatientSearchDynamicDimensions: React.FunctionComponent<PatientSearchProps> = (props) => {
  const { error, handleChange, userDetails, disabled, handleClickClearSearchFilter, isNewAppointment } = props;

  const handleClickNavigate = (patientSourceId: string) => {
    const newTab: any = window.open('', '_blank');
    newTab.location.href = `#/patients/${patientSourceId}`;
  };

  const [inputvalue, setInputvalue] = React.useState<string>('');
  const axiosSourceRef: any = React.useRef();
  let name: any = userDetails?.patientId;

  if (userDetails && userDetails?.firstName && userDetails?.lastName && userDetails.patientId) {
    const gender = userDetails.gender === 'FEMALE' ? 'F' : 'M';
    name = (
      <Typography component='div'>
        <Typography
          component='div'
          variant='fontSemiBold14'
          sx={{
            color: '#33425B',
            fontWeight: '700',
            fontSize: '14px',
            lineHeight: '16.8px',
            letterSpacing: '1%',
          }}>
          {userDetails.patientId}
        </Typography>
        <Typography component='div' variant='fontSemiBold14' sx={{ color: '#33425B', fontWeight: '700', fontSize: '14px' }}>
          {userDetails.firstName} {userDetails.lastName}
        </Typography>
        <Typography component='div' variant='fontSemiBold14' sx={{ fontSize: '12px' }}>
          {`(${userDetails.age} / ${gender})`}
        </Typography>
      </Typography>
    );
  }

  const handleInputChange = (value: string) => {
    setInputvalue(value);
  };

  const formatOptionLabel = (userDetails: any, _: FormatOptionLabelMeta<any>) => {
    let gender;
    if (userDetails && userDetails?.firstName && userDetails?.lastName && userDetails.patientId && userDetails.age && userDetails?.gender) {
      gender = userDetails.gender === 'FEMALE' ? 'F' : 'M';
    }

    return (
      <Typography component='div'>
        <Typography component='div' variant='fontSemiBold14'>
          {userDetails.patientId}
        </Typography>
        <Typography component='div' variant='fontReg14'>
          {userDetails.firstName} {userDetails.lastName}
        </Typography>
        <Typography component='div' variant='fontSemiBold14'>
          {`(${userDetails.age} / ${gender})`}
        </Typography>
      </Typography>
    );
  };

  const handleClickClearSearch = () => {
    setInputvalue('');
    if (handleClickClearSearchFilter) {
      handleClickClearSearchFilter();
    }
  };

  const fetchPatients = (inputValue: string, cb: any) => {
    if (axiosSourceRef.current && axiosSourceRef.current.token) {
      axiosSourceRef.current.cancel();
    }
    axiosSourceRef.current = axios.CancelToken.source();
    if (inputValue && !_.isEmpty(inputValue.trim())) {
      searchPatient(inputValue, axiosSourceRef.current)
        .then((data) => {
          cb(data);
        })
        .catch((err) => {
          console.error('err', err);
        });
    } else {
      cb();
    }
  };

  return (
    <>
      <CustomAsyncSelectWithDynamicDimensions
        value={inputvalue}
        placeholder={userDetails ? name : 'Search'}
        loadOptions={fetchPatients}
        components={{
          DropdownIndicator: () => null,
          IndicatorSeparator: () => (userDetails && handleClickClearSearchFilter ? <ClearIcon color='secondary' onClick={handleClickClearSearch} /> : <SearchIcon color='secondary' />),
        }}
        onInputChange={handleInputChange}
        onChange={handleChange}
        getOptionLabel={(e: any) => (e.firstName ? `${e.firstName} ${e.lastName}` + ' - ' + e.patientId : e.patientId)}
        getOptionValue={(e: any) => e.patientSourceId}
        isDisabled={disabled}
        minWidth={props.minWidth}
        minHeight={props.minHeight}
        maxWidth={props.maxWidth}
        maxHeight={props.maxHeight}
        width={props.width}
        height={props.height}
        formatOptionLabel={formatOptionLabel}
      />
      {!isNewAppointment && userDetails && (
        <Button
          sx={{
            width: '195px',
            height: '30px',
            p: 1,
            mt: 1,
            border: '1px solid   #9BB4CC',
            borderRadius: '5px',
            fontFamily: "'DM Sans', sans-serif;",
            fontStyle: 'normal',
            fontWeight: '700',
            fontSize: '14px',
            lineHeight: '19px',
            letterSpacing: '0.01em',
            color: '#33425B',
            transform: 'rotate(-0.06deg)',
          }}
          onClick={() => handleClickNavigate(userDetails.patientSourceId)}>
          Go to Patient
        </Button>
      )}

      <Typography>{error}</Typography>
    </>
  );
};

export default PatientSearchDynamicDimensions;
