/** @format */

export const DEVICE_MAPPING = {
  'All Devices': 'ALL',
  'Apple Watch': 'APPLE_WATCH',
  Omron: 'OMRON',
};

export const DIAGNOSIS_MAPPING = {
  'All Diagnosis': 'ALL',
  'Sinus Rhythm': 'sinusRhythm',
  'Atrial Fibrillation': 'atrialFibrillation',
  'Low Heart Rate': 'inconclusiveLowHeartRate',
  'High Heart Rate': 'inconclusiveHighHeartRate',
  Inconclusive: 'inconclusiveOther',
  'Poor Reading': 'inconclusivePoorReading',
};

export const ECG_DEVICES_LIST = [
  {
    label: 'All Devices',
    value: 'All Devices',
  },
  {
    label: 'Apple Watch',
    value: 'Apple Watch',
  },
  {
    label: 'Omron',
    value: 'Omron',
  },
];

export const ECG_DIAGNOSIS_LIST = [
  {
    label: 'All Diagnosis',
    value: 'All Diagnosis',
  },
  {
    label: 'Sinus Rhythm',
    value: 'Sinus Rhythm',
  },

  {
    label: 'Atrial Fibrillation',
    value: 'Atrial Fibrillation',
  },

  {
    label: 'Low Heart Rate',
    value: 'Low Heart Rate',
  },

  {
    label: 'High Heart Rate',
    value: 'High Heart Rate',
  },

  {
    label: 'Inconclusive',
    value: 'inconclusive_other',
  },

  {
    label: 'Poor Reading',
    value: 'poor_reading',
  },
];
