/** @format */

import { createSlice, PayloadAction } from '@reduxjs/toolkit';

// Types and interfaces.
import { RootState } from '../../app/store';

export interface ToastInterface {
  message: string;
  id: number;
  code: string;
  timeout?: number;
  noTimeout?: boolean;
  html?: any;
}

export const initialState: ToastInterface[] = [];

export const toastReducer = createSlice({
  name: 'toast reducer',
  initialState,
  reducers: {
    setToast: (state, { payload }: PayloadAction<{ message: string; html?: any; code: string; id?: number; isOpen?: boolean; noTimeout?: boolean }>) => {
      if (!payload.id) {
        payload.id = new Date().getTime();
      }
      state.push(payload as ToastInterface);
    },

    clearToast: (state, { payload }: PayloadAction<{ message?: string; code?: string; id: number }>) => {
      return state.filter((item) => item.id !== payload.id);
    },
  },
});

export const { setToast, clearToast } = toastReducer.actions;

export const toastSelector = (state: RootState) => state.toast;
