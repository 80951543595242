/** @format */

import React from 'react';
import * as _ from 'underscore';
import { Form } from '@rjsf/mui';
import { RJSFSchema } from '@rjsf/utils';
import validator from '@rjsf/validator-ajv8';

import Dialog from '@mui/material/Dialog';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';

import CloseIcon from '@mui/icons-material/Close';

// Types.
import { PatientInfoType } from '../../../types/Patients.types';
import { EditMedicalHistoryFormDataPayloadType, HeartFailureMedicalHistoryType, PatientMedicalHistoryPayloadType } from '../../../types/PatientMedicalHistory.types';

// Components
import { GridCheckBoxField } from '../../common/react-json-schema/GridCheckBoxField';
import { GridObjectFieldTemplateWithoutCard } from '../../common/react-json-schema/GridObjectFieldTemplate';
import { GridInputField, GridSelectInputField, TitleFieldTemplate, FieldErrorTemplate, GridTextareaField, FlexVitalsObjectFieldTemplate } from '../../common/react-json-schema';

// Constants.
import {
  CARDIOVASULCAR_DISEASES,
  CARDIOVASULCAR_DISEASES_REVERSE_MAP,
  NON_CARDIOVASCULAR_DISEASES,
  NON_CARDIOVASULCAR_DISEASES_REVERSE_MAP,
  HF_TYPES_OPTIONS,
  NYHA_OPTIONS,
  CARDIOVASULCAR_DISEASES_KEY_MAP,
  NON_CARDIOVASULCAR_DISEASES_KEY_MAP,
} from '../../../constants/PatientEnrollment';

// Component props.
interface EditMedicalHistoryDialogProps {
  isOpen: boolean;
  isUpdatingAPICalling: boolean;

  handleClose: () => void;
  handleSubmit: (payload: Partial<PatientMedicalHistoryPayloadType>) => void;

  patientInfo: PatientInfoType;
}

const EditMedicalHistoryDialog: React.FunctionComponent<EditMedicalHistoryDialogProps> = (props) => {
  const deactiveStatus = props.patientInfo.basicDetails?.patientStatus === 'DEACTIVE' || false;

  const [formData, setFormData] = React.useState<EditMedicalHistoryFormDataPayloadType>({} as EditMedicalHistoryFormDataPayloadType);

  React.useEffect(() => {
    const medicalHistory: PatientMedicalHistoryPayloadType = props.patientInfo?.medicalHistory || ({} as PatientMedicalHistoryPayloadType);

    let otherNonCardiovascularDiseases = '';
    const nonCardiovascularDiseases = medicalHistory?.nonCardiovascularDiseases?.reduce((acc: any, curr: any) => {
      if (!NON_CARDIOVASULCAR_DISEASES_KEY_MAP[curr]) {
        otherNonCardiovascularDiseases = curr;
      }
      return {
        ...acc,
        [NON_CARDIOVASULCAR_DISEASES_KEY_MAP[curr]]: true,
      };
    }, {});

    let otherCardiovascularDiseases = '';
    const cardiovascularDiseases = medicalHistory?.cardiovascularDiseases?.reduce((acc: any, curr: any) => {
      if (!CARDIOVASULCAR_DISEASES_KEY_MAP[curr]) {
        otherCardiovascularDiseases = curr;
      }
      return {
        ...acc,
        [CARDIOVASULCAR_DISEASES_KEY_MAP[curr]]: true,
      };
    }, {});

    const formData: EditMedicalHistoryFormDataPayloadType = {
      heartFailureMedicalHistory: {
        hfType: medicalHistory?.heartFailureMedicalHistory?.hfType || '',
        lvef: medicalHistory.heartFailureMedicalHistory?.lvef || '',
        nyhaClass: medicalHistory.heartFailureMedicalHistory?.nyhaClass || '',
      },

      nonCardiovascularDiseases: {
        ...nonCardiovascularDiseases,
        others: otherNonCardiovascularDiseases.length !== 0,
        otherNonCardiovascularDiseases,
      },
      cardiovascularDiseases: {
        ...cardiovascularDiseases,
        others: otherCardiovascularDiseases.length !== 0,
        otherCardiovascularDiseases,
      },

      allergies: medicalHistory.allergies || '',
    };

    setFormData(formData);
  }, [props.patientInfo]);

  const schema: RJSFSchema = {
    title: 'Medical History',
    type: 'object',
    properties: {
      heartFailureMedicalHistory: {
        title: '',
        type: 'object',
        required: ['hfType', 'nyhaClass', 'lvef'],
        properties: {
          hfType: {
            type: 'string',
            title: 'HF Category',
          },
          nyhaClass: {
            type: 'string',
            title: 'NYHA Class',
          },

          lvef: { type: 'number', title: 'LVEF (%)', minimum: 0, maximum: 100 },
        },
      },

      cardiovascularDiseases: {
        title: 'Cardiovascular Diseases',
        type: 'object',
        properties: {
          hypertension: { title: 'Hypertension', type: 'boolean', default: false },
          coronaryHeartDisease: { title: 'Coronary Heart Disease', type: 'boolean', default: false },
          priorMyocardialInfarction: { title: 'Prior Myocardial Infarction (MI)', type: 'boolean', default: false },
          valvularHeartDisease: { title: 'Valvular Heart Disease', type: 'boolean', default: false },
          atrialFibrillation: { title: 'Atrial Fibrillation / Flutter', type: 'boolean', default: false },
          stroke: { title: 'Stroke / Transient Ischemic Attack (TIA) / Thromboembolism', type: 'boolean', default: false },
          aorticPlaque: { title: 'Aortic Plaque', type: 'boolean', default: false },
          peripheralArteryDisease: { title: 'Peripheral Artery Disease ', type: 'boolean', default: false },
          typeIDiabetesMellitus: { title: 'Type I Diabetes Mellitus', type: 'boolean', default: false },
          typeIIDiabetesMellitus: { title: 'Type II Diabetes Mellitus', type: 'boolean', default: false },
          chronicKidneyDisease: { title: 'Chronic Kidney Disease', type: 'boolean', default: false },
          others: { title: 'Others', type: 'boolean', default: false },
        },

        allOf: [
          {
            if: {
              type: 'object',
              properties: {
                others: {
                  const: true,
                },
              },
            },

            then: {
              type: 'object',
              // required: ['otherCardiovascularDiseases'],
              properties: {
                otherCardiovascularDiseases: {
                  type: 'string',
                  title: 'Other Cardiovascular Diseases',
                },
              },
            },
          },
        ],
      },
      nonCardiovascularDiseases: {
        title: 'Non-Cardiovascular Diseases',
        type: 'object',
        properties: {
          copd: { title: 'Chronic Obstructive Pulmonary Disease (COPD)', type: 'boolean', default: false },
          hypothyroidism: { title: 'Hypothyroidism', type: 'boolean', default: false },
          anaemia: { title: 'Anaemia', type: 'boolean', default: false },
          ironDeficiency: { title: 'Iron Deficiency', type: 'boolean', default: false },
          obesity: { title: 'Obesity', type: 'boolean', default: false },
          smoking: { title: 'Smoking History (Vaping, Chewing Tobacco, etc )', type: 'boolean', default: false },
          alcohol: { title: 'Alcohol Consumption History', type: 'boolean', default: false },
          dementia: { title: 'Dementia', type: 'boolean', default: false },
          others: { title: 'Others', type: 'boolean', default: false },
        },

        allOf: [
          {
            if: {
              type: 'object',
              properties: {
                others: {
                  const: true,
                },
              },
            },

            then: {
              type: 'object',
              properties: {
                otherNonCardiovascularDiseases: {
                  type: 'string',
                  title: 'Other Non Cardiovascular Diseases',
                },
              },
            },
          },
        ],
      },

      allergies: { type: 'string', title: 'Allergies' },
    },
  };

  const uiSchema: any = {
    'ui:ObjectFieldTemplate': GridObjectFieldTemplateWithoutCard,
    'ui:noErrors': true,

    heartFailureMedicalHistory: {
      'ui:ObjectFieldTemplate': GridObjectFieldTemplateWithoutCard,
      'ui:noErrors': true,

      'ui:grid': {
        xs: 12,
      },

      'ui:fields': {
        className: 'medical-history',
      },

      hfType: {
        'ui:widget': GridSelectInputField,
        'ui:disabled': false,
        'ui:customOptions': HF_TYPES_OPTIONS,
        'ui:grid': {
          xs: 6,
          lg: 3,
        },
        'ui:grid-area': 'hf-type',
      },

      nyhaClass: {
        'ui:widget': GridSelectInputField,
        'ui:disabled': false,
        'ui:customOptions': NYHA_OPTIONS,
        'ui:grid': {
          xs: 6,
          lg: 3,
        },
        'ui:grid-area': 'nyha-class',
      },

      lvef: {
        'ui:widget': GridInputField,
        'ui:disabled': false,
        'ui:type': 'number',
        'ui:endAdornment': '%',
        'ui:grid': {
          xs: 6,
          lg: 3,
        },
        'ui:grid-area': 'lvef',
      },
    },

    cardiovascularDiseases: {
      'ui:ObjectFieldTemplate': FlexVitalsObjectFieldTemplate,
      'ui:noErrors': true,
      'ui:grid': {
        xs: 5.5,
      },

      coronaryHeartDisease: {
        'ui:widget': GridCheckBoxField,
        'ui:noErrors': true,
        'ui:grid': {
          xs: 12,
        },
      },

      valvularHeartDisease: {
        'ui:widget': GridCheckBoxField,
        'ui:noErrors': true,
        'ui:grid': {
          xs: 12,
        },
      },

      typeIDiabetesMellitus: {
        'ui:widget': GridCheckBoxField,
        'ui:noErrors': true,
        'ui:grid': {
          xs: 12,
        },
      },

      typeIIDiabetesMellitus: {
        'ui:widget': GridCheckBoxField,
        'ui:noErrors': true,
        'ui:grid': {
          xs: 12,
        },
      },

      chronicKidneyDisease: {
        'ui:widget': GridCheckBoxField,
        'ui:noErrors': true,
        'ui:grid': {
          xs: 12,
        },
      },

      atrialFibrillation: {
        'ui:widget': GridCheckBoxField,
        'ui:noErrors': true,
        'ui:grid': {
          xs: 12,
        },
      },

      hypertension: {
        'ui:widget': GridCheckBoxField,
        'ui:noErrors': true,
        'ui:grid': {
          xs: 12,
        },
      },

      peripheralArteryDisease: {
        'ui:widget': GridCheckBoxField,
        'ui:noErrors': true,
        'ui:grid': {
          xs: 12,
        },
      },

      priorMyocardialInfarction: {
        'ui:widget': GridCheckBoxField,
        'ui:noErrors': true,
        'ui:grid': {
          xs: 12,
        },
      },

      aorticPlaque: {
        'ui:widget': GridCheckBoxField,
        'ui:noErrors': true,
        'ui:grid': {
          xs: 12,
        },
      },

      stroke: {
        'ui:widget': GridCheckBoxField,
        'ui:noErrors': true,
        'ui:grid': {
          xs: 12,
        },
      },

      others: {
        'ui:widget': GridCheckBoxField,
        'ui:noErrors': true,
        'ui:grid': {
          xs: 12,
        },
      },

      otherCardiovascularDiseases: {
        'ui:widget': GridTextareaField,
        // 'ui:noErrors': true,
        'ui:grid': {
          xs: 12,
        },
        'ui:fields': {
          minRows: 5,
          maxRows: 5,
        },
      },
    },

    nonCardiovascularDiseases: {
      'ui:ObjectFieldTemplate': FlexVitalsObjectFieldTemplate,

      'ui:noErrors': true,
      'ui:grid': {
        xs: 5.5,
      },

      copd: {
        'ui:widget': GridCheckBoxField,
        'ui:noErrors': true,
        'ui:grid': {
          xs: 12,
        },
      },

      hypothyroidism: {
        'ui:widget': GridCheckBoxField,
        'ui:noErrors': true,
        'ui:grid': {
          xs: 12,
        },
      },

      anaemia: {
        'ui:widget': GridCheckBoxField,
        'ui:noErrors': true,
        'ui:grid': {
          xs: 12,
        },
      },

      ironDeficiency: {
        'ui:widget': GridCheckBoxField,
        'ui:noErrors': true,
        'ui:grid': {
          xs: 12,
        },
      },

      obesity: {
        'ui:widget': GridCheckBoxField,
        'ui:noErrors': true,
        'ui:grid': {
          xs: 12,
        },
      },

      alcohol: {
        'ui:widget': GridCheckBoxField,
        'ui:noErrors': true,
        'ui:grid': {
          xs: 12,
        },
      },

      dementia: {
        'ui:widget': GridCheckBoxField,
        'ui:noErrors': true,
        'ui:grid': {
          xs: 12,
        },
      },

      smoking: {
        'ui:widget': GridCheckBoxField,
        'ui:noErrors': true,
        'ui:grid': {
          xs: 12,
        },
      },

      others: {
        'ui:widget': GridCheckBoxField,
        'ui:noErrors': true,
        'ui:grid': {
          xs: 12,
        },
      },

      otherNonCardiovascularDiseases: {
        'ui:widget': GridTextareaField,
        'ui:grid': {
          xs: 12,
        },
        'ui:fields': {
          minRows: 5,
          maxRows: 5,
        },
      },
    },

    allergies: {
      'ui:widget': GridTextareaField,
      'ui:disabled': false,
      'ui:grid': {
        xs: 12,
      },
      'ui:mandatory': false,
      'ui:grid-area': 'allergies',
      'ui:fields': {
        minRows: 4,
        maxRows: 4,
      },
    },
  };

  const transformErrors = (errors: any[]) => {
    return errors.map((error) => {
      const path = error.property.split('.');
      const fieldName = path[path?.length - 1] || '';

      const formatFieldName: string = fieldName.replace(/([A-Z])/g, ' $1').replace(/^./, (str: string) => str.toUpperCase());

      if (error.name === 'type') {
        error.message = `${formatFieldName} cannot be empty`;
      }

      if (error.name === 'pattern') {
        error.message = `Please enter valid ${formatFieldName}`;
      }

      if (error.name === 'minLength') {
        error.message = `${formatFieldName} should be minimum ${error?.params?.limit} char`;
      }

      if (error.name === 'maxLength') {
        error.message = `${formatFieldName} should not exceed ${error?.params?.limit} char`;
      }

      if (error.name === 'maximum') {
        error.message = `${formatFieldName} should be less or equal to ${error?.params?.limit}`;
      }

      if (error.name === 'minimum') {
        error.message = `${formatFieldName} should be  greater or equal to ${error?.params?.limit}`;
      }

      if (error.name === 'required') {
        error.message = `Other ${formatFieldName} is required`;
      }

      return error;
    });
  };

  const customValidate = (formData: any, errors: any) => {
    if (formData?.cardiovascularDiseases?.others && formData?.cardiovascularDiseases?.otherCardiovascularDiseases?.length === 0) {
      errors.cardiovascularDiseases?.otherCardiovascularDiseases.addError('Other cardiovascular diseases cannot be empty if others is checked');
    }

    if (formData?.nonCardiovascularDiseases?.others && formData?.nonCardiovascularDiseases?.otherNonCardiovascularDiseases?.length === 0) {
      errors.nonCardiovascularDiseases?.otherNonCardiovascularDiseases.addError('Other Non cardiovascular diseases cannot be empty if others is checked');
    }

    return errors;
  };

  const handleClickUpdatePatientMedicalHistory = async (formData: any) => {
    const medicalHistory: PatientMedicalHistoryPayloadType = props.patientInfo?.medicalHistory || ({} as PatientMedicalHistoryPayloadType);

    let cardiovascularDiseases: string[] = [];
    let nonCardiovascularDiseases: string[] = [];
    if (!_.isEmpty(formData.cardiovascularDiseases)) {
      for (const key in formData.cardiovascularDiseases) {
        if (key === 'others') {
          continue;
        } else {
          if (key === 'otherCardiovascularDiseases' && formData.cardiovascularDiseases.otherCardiovascularDiseases) {
            cardiovascularDiseases.push(formData.cardiovascularDiseases[key]);
          }
        }

        if (formData.cardiovascularDiseases[key] && CARDIOVASULCAR_DISEASES[key]) {
          cardiovascularDiseases = [...cardiovascularDiseases, CARDIOVASULCAR_DISEASES_REVERSE_MAP[key]];
        }
      }
    }

    if (!_.isEmpty(formData.nonCardiovascularDiseases)) {
      for (const key in formData.nonCardiovascularDiseases) {
        if (key === 'others') {
          continue;
        } else {
          if (key === 'otherNonCardiovascularDiseases' && formData.nonCardiovascularDiseases.otherNonCardiovascularDiseases) {
            nonCardiovascularDiseases.push(formData.nonCardiovascularDiseases[key]);
          }
        }

        if (formData.nonCardiovascularDiseases[key] && NON_CARDIOVASCULAR_DISEASES[key]) {
          nonCardiovascularDiseases = [...nonCardiovascularDiseases, NON_CARDIOVASULCAR_DISEASES_REVERSE_MAP[key]];
        }
      }
    }

    const heartFailureMedicalHistory: HeartFailureMedicalHistoryType = {
      ...medicalHistory?.heartFailureMedicalHistory,
      ...formData.heartFailureMedicalHistory,
    };

    if (heartFailureMedicalHistory && heartFailureMedicalHistory.lvef && heartFailureMedicalHistory.lvef !== medicalHistory?.heartFailureMedicalHistory?.lvef) {
      const lvefHistory = medicalHistory?.heartFailureMedicalHistory?.lvefHistory || [];

      if (_.isEmpty(lvefHistory) && props.patientInfo?.medicalHistory?.heartFailureMedicalHistory.lvef) {
        lvefHistory.push({ lvef: props.patientInfo?.medicalHistory?.heartFailureMedicalHistory.lvef, date: props.patientInfo?.basicDetails?.enrollmentDate! });
      }

      heartFailureMedicalHistory.lvefHistory = lvefHistory.concat([{ lvef: heartFailureMedicalHistory?.lvef, date: new Date().toISOString() }]);
    }

    if (heartFailureMedicalHistory && heartFailureMedicalHistory.lvef && heartFailureMedicalHistory.hfType !== medicalHistory?.heartFailureMedicalHistory?.hfType) {
      const hfTypeHistory = medicalHistory?.heartFailureMedicalHistory?.hfTypeHistory || [];

      if (_.isEmpty(hfTypeHistory) && props.patientInfo?.medicalHistory?.heartFailureMedicalHistory.hfType) {
        hfTypeHistory.push({ hfType: props.patientInfo?.medicalHistory?.heartFailureMedicalHistory.hfType, date: props.patientInfo?.basicDetails?.enrollmentDate! });
      }

      heartFailureMedicalHistory.hfTypeHistory = hfTypeHistory.concat([{ hfType: heartFailureMedicalHistory?.hfType, date: new Date().toISOString() }]);
    }

    if (heartFailureMedicalHistory && heartFailureMedicalHistory.lvef && heartFailureMedicalHistory.nyhaClass !== medicalHistory?.heartFailureMedicalHistory?.nyhaClass) {
      const nyhaClassHistory = medicalHistory?.heartFailureMedicalHistory?.nyhaClassHistory || [];

      if (_.isEmpty(nyhaClassHistory) && props.patientInfo?.medicalHistory?.heartFailureMedicalHistory.nyhaClass) {
        nyhaClassHistory.push({ nyhaClass: props.patientInfo?.medicalHistory?.heartFailureMedicalHistory.nyhaClass, date: props.patientInfo?.basicDetails?.enrollmentDate! });
      }

      heartFailureMedicalHistory.nyhaClassHistory = nyhaClassHistory.concat([{ nyhaClass: heartFailureMedicalHistory?.nyhaClass, date: new Date().toISOString() }]);
    }

    const cardiovascularDiseasesHistory = medicalHistory.cardiovascularDiseasesHistory || [];
    const nonCardiovascularDiseasesHistory = medicalHistory.nonCardiovascularDiseasesHistory || [];
    const allergiesHistory = medicalHistory.allergiesHistory || [];

    if (_.isEmpty(cardiovascularDiseasesHistory) && props.patientInfo?.medicalHistory?.cardiovascularDiseases) {
      cardiovascularDiseasesHistory.push({ cardiovascularDiseases: props.patientInfo?.medicalHistory?.cardiovascularDiseases, date: props.patientInfo?.basicDetails?.enrollmentDate! });
    }

    if (_.isEmpty(nonCardiovascularDiseasesHistory) && props.patientInfo?.medicalHistory?.nonCardiovascularDiseases) {
      nonCardiovascularDiseasesHistory.push({ nonCardiovascularDiseases: props.patientInfo?.medicalHistory?.nonCardiovascularDiseases, date: props.patientInfo?.basicDetails?.enrollmentDate! });
    }

    if (_.isEmpty(allergiesHistory) && props.patientInfo?.medicalHistory?.allergies) {
      allergiesHistory.push({ allergies: props.patientInfo?.medicalHistory?.allergies, date: props.patientInfo?.basicDetails?.enrollmentDate! });
    }

    if (_.difference(nonCardiovascularDiseases, medicalHistory.nonCardiovascularDiseases || [])) {
      nonCardiovascularDiseasesHistory.push({ nonCardiovascularDiseases, date: new Date().toISOString() });
    }

    if (_.difference(cardiovascularDiseases, medicalHistory.cardiovascularDiseases || [])) {
      cardiovascularDiseasesHistory.push({ cardiovascularDiseases, date: new Date().toISOString() });
    }

    if (medicalHistory.allergies !== formData.allergies) {
      allergiesHistory.push({ allergies: formData.allergies, date: new Date().toISOString() });
    }

    const payload: Partial<PatientMedicalHistoryPayloadType> = {
      heartFailureMedicalHistory,

      nonCardiovascularDiseases,
      nonCardiovascularDiseasesHistory,

      cardiovascularDiseases,
      cardiovascularDiseasesHistory,

      allergies: formData.allergies,
      allergiesHistory,
    };

    props.handleSubmit(payload);
  };

  return (
    <Dialog onClose={props.handleClose} open={props.isOpen} maxWidth='lg' fullWidth>
      <DialogTitle sx={{ display: 'flex' }}>
        <Typography color='primary' variant='fontSemiBold18' sx={{ flex: 1 }}>
          Edit Medical History
        </Typography>

        <IconButton onClick={props.handleClose}>
          <CloseIcon />
        </IconButton>
      </DialogTitle>

      <DialogContent
        id='medical-history-edit-profile'
        sx={{
          minWidth: '500px',
        }}>
        <Form
          liveValidate
          formData={formData}
          onError={console.error}
          validator={validator}
          onSubmit={(formData) => handleClickUpdatePatientMedicalHistory(formData.formData)}
          templates={{ TitleFieldTemplate, FieldErrorTemplate }}
          uiSchema={uiSchema}
          schema={schema}
          showErrorList={false}
          disabled={props.isUpdatingAPICalling || deactiveStatus}
          transformErrors={transformErrors}
          customValidate={customValidate}
        />
      </DialogContent>
    </Dialog>
  );
};

export default EditMedicalHistoryDialog;
