/** @format */

import React, { FC } from 'react';

interface SpbIconInterface {
  width?: number | string;
  height?: number | string;
  fill?: string;
}

const SpbIcon: FC<SpbIconInterface> = ({ width, height, fill }) => {
  return (
    <svg width={width} height={height} viewBox='0 0 18 17' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M17.5645 3.77888C17.2852 3.13724 16.8825 2.5558 16.379 2.06709C15.875 1.57693 15.2808 1.1874 14.6288 0.919693C13.9526 0.640996 13.2274 0.498342 12.4952 0.500015C11.468 0.500015 10.4658 0.779111 9.59485 1.30629C9.38649 1.4324 9.18855 1.57092 9.00103 1.72184C8.81351 1.57092 8.61557 1.4324 8.40721 1.30629C7.53628 0.779111 6.53408 0.500015 5.50688 0.500015C4.76721 0.500015 4.05046 0.640597 3.3733 0.919693C2.71906 1.18845 2.12941 1.57505 1.6231 2.06709C1.11887 2.55524 0.716078 3.13683 0.43755 3.77888C0.147934 4.44665 0 5.15576 0 5.88554C0 6.57398 0.141683 7.29136 0.422965 8.02115C0.658409 8.63103 0.995947 9.26365 1.42725 9.90247C2.11066 10.9134 3.05035 11.9678 4.21715 13.0366C6.1507 14.8084 8.06551 16.0323 8.14677 16.0819L8.64057 16.3961C8.85935 16.5346 9.14063 16.5346 9.35941 16.3961L9.85321 16.0819C9.93447 16.0302 11.8472 14.8084 13.7828 13.0366C14.9496 11.9678 15.8893 10.9134 16.5727 9.90247C17.004 9.26365 17.3437 8.63103 17.577 8.02115C17.8583 7.29136 18 6.57398 18 5.88554C18.0021 5.15576 17.8541 4.44665 17.5645 3.77888V3.77888Z'
        fill={fill}
      />
    </svg>
  );
};

SpbIcon.defaultProps = {
  width: 17,
  height: 18,
  fill: '#5C6A90',
};

export default SpbIcon;
