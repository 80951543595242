/** @format */

import * as React from 'react';
import * as _ from 'underscore';
import { DateTime } from 'luxon';
import Box from '@mui/material/Box';
import Tabs from '@mui/material/Tabs';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import AppBar from '@mui/material/AppBar';
import InputBase from '@mui/material/InputBase';
import Typography from '@mui/material/Typography';
import Tab, { TabProps } from '@mui/material/Tab';

import TreatmentAdvice from './TreatmentAdvice';
import ObservationsAssessments from './ObservationsAssessments';
import InvestigationsNextReview from './InvestigationsNextReview';
import PrescriptionConfirmationModal from './PrescriptionConfirmationModal';

import { PrescriptionGeneratorContext } from './PrescriptionGeneratorContext';
import { PrescriptionGeneratorTabsType } from '../../types/PrescriptionsGenerator.types';
import { LoadingButton } from '@mui/lab';
import Scrollbars from 'react-custom-scrollbars-2';
import { ConfirmationDialog } from '../common/confirmation-dialog/ConfirmationDialog';

interface PrescriptionsGeneratorProps {}

const PrescriptionsGenerator: React.FunctionComponent<PrescriptionsGeneratorProps> = () => {
  const {
    containerHeight,

    tabValue,
    handleChangeTabs,

    prescriptionDate,
    prescriptionDateErrors,
    handleChangePrescriptionDate,

    isOpenPreviewModal,
    prescriptionsPreviewReportURL,
    prescriptionsPreviewReportURLIsLoading,
    togglePatientPreviewModal,
    handleClickOnPreviewBtn,

    isDraftPrescriptionUpdating,
    handleClickOnDraftBtn,

    isSubmitApprovalPrescriptionUpdating,
    handleSubmitApprovalPrescriptions,

    patientPrescriptionMedications,

    isPatientPrescriptionUpdating,
    handleSubmitPatientPrescription,

    patientReviewPrescriptions,

    handleClickRejectPrescriptions,

    prescriptionNotes,

    labInvestigationTest,
    imageInvestigationTest,

    isOpenReviewDateWarningModal,
    handleClickOnSubmitReviewDateWarning,
    toggleReviewDateWarningModal,
  } = React.useContext(PrescriptionGeneratorContext);

  const handleClickOnNextBtn = () => {
    const tabs: Array<PrescriptionGeneratorTabsType> = ['OBSERVATION_ASSESSMENTS', 'TREATMENT_ADVICE', 'INVESTIGATIONS_NEXT_REVIEW'];
    const currentTabIndex = tabs.findIndex((item) => tabValue === item);

    const nextTabIndex = currentTabIndex + 1;

    if (nextTabIndex < tabs.length) {
      handleChangeTabs(undefined as any, tabs[nextTabIndex]);
    }
  };

  const _renderTabs = (tab: TabProps) => {
    return (
      <Tab
        key={tab.id}
        id={tab.id}
        label={
          <Typography color='#5C6A90' variant={tabValue === tab.value ? 'fontSemiBold14' : 'fontReg14'} sx={{ textTransform: 'none' }}>
            {tab.label}
          </Typography>
        }
        value={tab.value}
      />
    );
  };

  const _renderTabsValue = React.useMemo(() => {
    if (tabValue === 'TREATMENT_ADVICE') {
      return <TreatmentAdvice />;
    }

    if (tabValue === 'INVESTIGATIONS_NEXT_REVIEW') {
      return <InvestigationsNextReview />;
    }
    return <ObservationsAssessments />;
  }, [tabValue]);

  const prescriptionsGeneratorTabs: TabProps[] = [
    {
      id: 'observation-assessments',
      value: 'OBSERVATION_ASSESSMENTS',
      label: 'Observations & Assessments',
    },

    {
      id: 'treatment-advice',
      value: 'TREATMENT_ADVICE',
      label: 'Treatment & Advice',
    },

    {
      id: 'investigations-next-review',
      value: 'INVESTIGATIONS_NEXT_REVIEW',
      label: 'Investigations & Next Review',
    },
  ];

  const disabledActionButton = ![!_.isEmpty(patientPrescriptionMedications), !_.isEmpty(prescriptionNotes), !_.isEmpty(labInvestigationTest), !_.isEmpty(imageInvestigationTest)].some((item) => item);

  return (
    <Box sx={{ background: '#fff' }}>
      {isOpenReviewDateWarningModal && (
        <ConfirmationDialog
          description='Please ensure that your consultation date aligns with your scheduled investigation or lab test dates.'
          title={<Box sx={{ color: '#d32f2f' }}>Advisory!</Box>}
          onClickConfirm={handleClickOnSubmitReviewDateWarning}
          isOpen={isOpenReviewDateWarningModal}
          onClose={toggleReviewDateWarningModal}
          submitText='Finalize'
          cancelText='Update Consultation'
        />
      )}

      {isOpenPreviewModal && (
        <PrescriptionConfirmationModal
          isOpen={isOpenPreviewModal}
          isUpdatingAPICalling={isPatientPrescriptionUpdating}
          previewPdfUrl={prescriptionsPreviewReportURL?.attamentUrl || ''}
          previewPdfUrlLoading={prescriptionsPreviewReportURLIsLoading}
          handleClose={togglePatientPreviewModal}
          handleSubmit={handleSubmitPatientPrescription}
          isUpdatingPrescriptionApprovalAPICalling={isSubmitApprovalPrescriptionUpdating}
          handleSubmitPrescriptionApproval={handleSubmitApprovalPrescriptions}
        />
      )}

      <AppBar elevation={0} position='static' sx={{ borderBottom: '.6px solid #d5d5d6', padding: 0, margin: 0, background: '#fff', borderTopLeftRadius: '5px', borderTopRightRadius: '5px' }}>
        <Tabs
          value={tabValue}
          onChange={handleChangeTabs}
          sx={{ '> .MuiButtonBase-root': { color: '#3E4685 !important' }, '> .MuiTabs-scrollButtons': { display: 'inherit !important' } }}
          aria-label='full width tabs'>
          {prescriptionsGeneratorTabs.map(_renderTabs)}
        </Tabs>
      </AppBar>

      <Box sx={{ p: 1.5 }}>
        <Box sx={{ overflow: 'auto', height: `calc(${containerHeight} - 150px)` }}>
          <Scrollbars autoHide>{_renderTabsValue}</Scrollbars>
        </Box>

        <Box sx={{ mt: 2 }}>
          <Grid
            item
            xs={12}
            container
            justifyContent='space-between'
            alignItems='center'
            // gap={3}
            mt={1}
            sx={{ backgroundColor: '#EFF1FF', border: '.6px solid #D5D5D6', paddingInline: '8px', borderRadius: '8px' }}>
            <Grid item xs='auto'>
              <Button size='small' variant='outlined' sx={{ backgroundColor: '#FFF' }} onClick={handleClickOnNextBtn} disabled={tabValue === 'INVESTIGATIONS_NEXT_REVIEW'}>
                Next
              </Button>
            </Grid>

            <Grid item xs='auto'>
              <Typography variant='fontSemiBold14' color='#3E4685'>
                Prescription Date:
              </Typography>
              <InputBase
                type='date'
                value={prescriptionDate ? DateTime.fromJSDate(new Date(prescriptionDate)).toFormat('yyyy-MM-dd') : prescriptionDate}
                onChange={(event) => handleChangePrescriptionDate(event.target.value)}
                sx={{
                  '&.MuiInputBase-root': {
                    background: 'transparent !important',
                    p: 0,
                  },
                }}
                error={!!prescriptionDateErrors}
              />
            </Grid>

            <Grid item xs='auto' container gap={2}>
              {patientReviewPrescriptions && ['SUBMITTED_APPROVAL'].includes(patientReviewPrescriptions.status) && (
                <Button
                  sx={{ backgroundColor: '#FFF' }}
                  disabled={_.isEmpty(patientPrescriptionMedications) || isDraftPrescriptionUpdating}
                  variant='outlined'
                  size='small'
                  onClick={handleClickRejectPrescriptions}
                  color='error'>
                  Reject
                </Button>
              )}

              {(_.isEmpty(patientReviewPrescriptions) || (patientReviewPrescriptions && ['UPCOMING', 'DRAFTED'].includes(patientReviewPrescriptions.status))) && (
                <LoadingButton
                  sx={{ backgroundColor: '#FFF' }}
                  disabled={disabledActionButton || isDraftPrescriptionUpdating}
                  variant='outlined'
                  onClick={handleClickOnDraftBtn}
                  loading={isDraftPrescriptionUpdating}
                  size='small'>
                  Draft
                </LoadingButton>
              )}

              <Button
                sx={{ backgroundColor: '#FFF' }}
                disabled={disabledActionButton || isDraftPrescriptionUpdating || patientReviewPrescriptions?.status === 'APPROVED'}
                variant='outlined'
                onClick={handleClickOnPreviewBtn}
                size='small'>
                Finalize
              </Button>
            </Grid>
          </Grid>
        </Box>
      </Box>
    </Box>
  );
};

export default PrescriptionsGenerator;
