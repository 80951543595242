/** @format */

import axios, { CancelTokenSource } from 'axios';
import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';

import { PatientTasksType } from '../../types/PatientTasks.type';
import { PatientPrescriptionTodayReviewListType } from '../../types/PatientListPage.types';

import { RootState } from '../../app/store';
import { fetchPatientPrescriptionReviewPatient, fetchPatientTasksByCategory } from '../../service/workspace';

import { TASKS_CATEGORY_MEDICATION_TASKS, TASKS_TYPE_MEDICATION_CRITICAL_ALERTS, TASKS_TYPE_MEDICATION_WARNING_ALERTS } from '../../constants/workspace-tasks';

let reviewAxiosCancelToken: CancelTokenSource | null = null;
let alertsAxiosCancelToken: CancelTokenSource | null = null;

export interface UpcomingEventsState {
  adjustMedicationTasksIsLoading: boolean;
  patientPrescriptionReviewIsLoading: boolean;

  alertLimit: number;
  reviewLimit: number;

  alertPageOffset: number;
  reviewPageOffset: number;

  adjustMedicationTasksData: { count: number; data: PatientTasksType<any, any>[] };
  patientPrescriptionReviewPatientData: { count: number; data: PatientPrescriptionTodayReviewListType[] };
}

export const activeInitialState: UpcomingEventsState = {
  adjustMedicationTasksIsLoading: false,
  patientPrescriptionReviewIsLoading: false,

  alertLimit: 20,
  reviewLimit: 20,

  alertPageOffset: 0,
  reviewPageOffset: 0,

  adjustMedicationTasksData: { count: 0, data: [] },
  patientPrescriptionReviewPatientData: { count: 0, data: [] },
};

export const FetchAdjustMedicationTasks = createAsyncThunk(
  'FetchAdjustMedicationTasks',
  async ({ alertLimit, alertPageOffset, cardiologistId }: { alertLimit: number; alertPageOffset: number; cardiologistId: string | undefined }) => {
    try {
      if (alertsAxiosCancelToken) {
        alertsAxiosCancelToken.cancel();
      }

      alertsAxiosCancelToken = axios.CancelToken.source();

      const data = await fetchPatientTasksByCategory(
        TASKS_CATEGORY_MEDICATION_TASKS,
        alertLimit,
        alertPageOffset * alertLimit,
        { severity: 'MAX' },
        [TASKS_TYPE_MEDICATION_CRITICAL_ALERTS, TASKS_TYPE_MEDICATION_WARNING_ALERTS],
        cardiologistId || 'ALL',
        undefined,
        { isMedicalAdhrence: false },
        alertsAxiosCancelToken,
      );
      return data;
    } catch (err) {
      if (axios.isCancel(err)) {
        throw err;
      }
      console.error(err);
    }
  },
);

export const FetchPatientPrescriptionReviewPatient = createAsyncThunk(
  'FetchPatientPrescriptionReviewPatient',
  async ({ reviewLimit, reviewPageOffset, cardiologistId }: { reviewLimit: number; reviewPageOffset: number; cardiologistId: string }) => {
    try {
      if (reviewAxiosCancelToken) {
        reviewAxiosCancelToken.cancel();
      }

      reviewAxiosCancelToken = axios.CancelToken.source();

      const data = await fetchPatientPrescriptionReviewPatient(reviewLimit, reviewPageOffset * reviewLimit, cardiologistId, reviewAxiosCancelToken.token);
      return data;
    } catch (err) {
      if (axios.isCancel(err)) {
        throw err;
      }
      console.error(err);
    }
  },
);

export const upcomingEventsReducer = createSlice({
  name: 'Upcoming events reducer',
  initialState: activeInitialState,
  reducers: {
    setAlertLimit: (state, { payload }: PayloadAction<number>) => {
      state.alertLimit = Number(payload);
    },

    setReviewLimit: (state, { payload }: PayloadAction<number>) => {
      state.reviewLimit = Number(payload);
    },

    setAlertPageOffset: (state, { payload }: PayloadAction<number>) => {
      state.alertPageOffset = Number(payload);
    },

    setReviewPageOffset: (state, { payload }: PayloadAction<number>) => {
      state.reviewPageOffset = Number(payload);
    },

    setAdjustMedicationTasksData: (state, { payload }: PayloadAction<{ count: number; data: PatientTasksType<any, any>[] }>) => {
      state.adjustMedicationTasksData.data = payload.data;
      state.adjustMedicationTasksData.count = payload.count;
    },

    setPatientPrescriptionReviewPatientData: (state, { payload }: PayloadAction<{ count: number; data: PatientPrescriptionTodayReviewListType[] }>) => {
      state.patientPrescriptionReviewPatientData.data = payload.data;
      state.patientPrescriptionReviewPatientData.count = payload.count;
    },

    clearUpcomingEventsStore: (state) => {
      state = {
        ...activeInitialState,
      };

      return state;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(FetchAdjustMedicationTasks.pending, (state: UpcomingEventsState, action) => {
      state.adjustMedicationTasksIsLoading = true;
    });

    builder.addCase(FetchAdjustMedicationTasks.fulfilled, (state: UpcomingEventsState, action) => {
      state.adjustMedicationTasksData.data = action.payload?.data;
      state.adjustMedicationTasksData.count = action.payload?.count;

      state.adjustMedicationTasksIsLoading = false;
    });

    builder.addCase(FetchAdjustMedicationTasks.rejected, (state: UpcomingEventsState, action) => {
      state.adjustMedicationTasksIsLoading = true;
    });

    builder.addCase(FetchPatientPrescriptionReviewPatient.pending, (state: UpcomingEventsState, action) => {
      state.patientPrescriptionReviewIsLoading = true;
    });

    builder.addCase(FetchPatientPrescriptionReviewPatient.fulfilled, (state: UpcomingEventsState, action) => {
      state.patientPrescriptionReviewPatientData.data = action.payload?.data;
      state.patientPrescriptionReviewPatientData.count = action.payload?.count;

      state.patientPrescriptionReviewIsLoading = false;
    });

    builder.addCase(FetchPatientPrescriptionReviewPatient.rejected, (state: UpcomingEventsState, action) => {
      state.patientPrescriptionReviewIsLoading = true;
    });
  },
});

export const {
  setAlertLimit,
  setReviewLimit,

  setAlertPageOffset,
  setReviewPageOffset,

  setAdjustMedicationTasksData,
  setPatientPrescriptionReviewPatientData,

  clearUpcomingEventsStore,
} = upcomingEventsReducer.actions;

export const upcomingEventsSelector = (state: RootState) => state.upcomingEvents;
