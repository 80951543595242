/** @format */

import { useTheme, Typography } from '@mui/material';
import { TitleFieldProps } from '@rjsf/utils';

export const TitleFieldTemplate = (props: TitleFieldProps) => {
  const appTheme = useTheme();

  const { required, title } = props;

  return (
    <Typography component='div' variant='fontBold28' color={appTheme.palette.customColor.primary} marginLeft='15px'>
      {title}
      {required && <mark>*</mark>}
    </Typography>
  );
};
