/** @format */

import { createSlice, PayloadAction } from '@reduxjs/toolkit';

// Types and interfaces.
import { RootState } from '../../app/store';
import { OrganizationMedicationType } from '../../types/SystemMedications.types';

export interface OrgMedicationsError {
  message: string;
}

export interface OrgMedicationsState {
  medications: Array<OrganizationMedicationType>;
  error: OrgMedicationsError;
}

export const initialState: OrgMedicationsState = {
  medications: [],
  error: { message: '' },
};

export const orgMedicationsReducer = createSlice({
  name: 'org medications',
  initialState,
  reducers: {
    setOrgMedications: (state, { payload }: PayloadAction<Array<OrganizationMedicationType>>) => {
      state.medications = payload;
    },
  },
});

export const orgMedicationsSelector = (state: RootState) => state.orgMedications;

export const { setOrgMedications } = orgMedicationsReducer.actions;
