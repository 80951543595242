/** @format */

export const SESSION_KEY_PATIENT_DOCTORS_FILTER = 'doctor-filter';

export const SESSION_KEY_ACTIVE_PAGE_DOCTOR = 'active-page-doctor';
export const SESSION_KEY_ACTIVE_PAGE_NO = 'active-page-no';
export const SESSION_KEY_ACTIVE_PAGE_ROWS = 'active-page-rows';

export const SESSION_KEY_ADJUST_PAGE_DOCTOR = 'adjust-page-doctor';
export const SESSION_KEY_ADJUST_PAGE_NO = 'adjust-page-no';
export const SESSION_KEY_ADJUST_PAGE_ROWS = 'adjust-page-rows';
