/** @format */

import React from 'react';
import { DateTime } from 'luxon';
import CloseIcon from '@mui/icons-material/Close';
import { TransitionProps } from '@mui/material/transitions';
import { Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, Grid, IconButton, Slide, Typography, useTheme } from '@mui/material';

// Components.
import { Notes } from '../patient-profile/patient-notes/Notes';
import FormFields, { FormFieldsProps } from '../common/form-fields/FormFields';

// Types.
import type { Descendant } from 'slate';
import { BasicDetailsType } from '../../types/Patients.types';
import { PatientTasksItemType } from '../../types/PatientTasks.type';

import { convertToRawText } from '../../utils/functions/notes';

// Contstants.
import { NOTE_INITIAL_STATE } from '../../constants/notes';
import { TEXT_INPUT_COMPONENT, TEXT_SELECT_COMPONENT } from '../../constants/PatientEnrollment';

const Transition = React.forwardRef(function Transition (
  props: TransitionProps & {
    children: React.ReactElement<any, any>;
  },
  ref: React.Ref<unknown>,
) {
  return <Slide direction='down' ref={ref} {...props} />;
});

interface MedicationAdherenceStatusModalProps {
  isOpen: boolean;
  isUpdatingAPICalling: boolean;

  onClose: () => void;
  handleSubmit: (medicalAdherencePayload: any, notes: any) => void;

  patientBasicDetails: BasicDetailsType;

  tasksDetails?: PatientTasksItemType<any, any>;
  isManualUpdate?: boolean;

  hideDialogBehaviour?: boolean;
  disabled?: boolean;
}

const MedicationAdherenceStatusModal: React.FunctionComponent<MedicationAdherenceStatusModalProps> = (props) => {
  const appTheme = useTheme();

  const [medicalAdherence, setMedicalAdherence] = React.useState<{ isPrescribeDosage?: string; medicationDuration?: string }>({});
  const [entryDate, setEntryDate] = React.useState<string>(DateTime.now().toFormat('yyyy-MM-dd'));
  const [editorState, setEditorState] = React.useState<Descendant[]>(NOTE_INITIAL_STATE);

  const prescribedDosageOptions = [
    { label: 'Yes', value: 'YES' },
    { label: 'No', value: 'NO' },
  ];

  let name = '';
  if (props.patientBasicDetails?.firstName && props.patientBasicDetails?.lastName) {
    name = props.patientBasicDetails?.firstName + ' ' + props.patientBasicDetails?.lastName;
  }

  const medicationDurationOptions = [
    { label: 'Mostly', value: 'MOSTLY', totalValue: 90 },
    { label: 'Usually', value: 'USUALLY', totalValue: 70 },
    { label: 'Sometimes', value: 'SOMETIMES', totalValue: 50 },
    { label: 'Rarely', value: 'RARELY', totalValue: 30 },
  ];

  const dateOfMedicalAdherenceEntryFormField: FormFieldsProps = {
    id: 'date-of-entry',
    label: 'Entry Date',
    componentType: TEXT_INPUT_COMPONENT,
    input: {
      placeholder: 'Date',
      type: 'date',
      value: entryDate,
      onChange: (event: any) => setEntryDate(event.target.value),
      disabled: props.disabled,
    },
  };

  const medicalAdherenceQes1Field: FormFieldsProps = {
    id: 'symptoms',
    label: 'Have you been taking all the medication classes at the prescribed dosage(s)?',
    componentType: TEXT_SELECT_COMPONENT,
    input: {
      placeholder: 'Select',
      options: prescribedDosageOptions,
      onChange: (event: any) => setMedicalAdherence((prev) => ({ ...prev, isPrescribeDosage: event.target.value })),
      value: prescribedDosageOptions.find((item) => item.value === medicalAdherence.isPrescribeDosage),
      disabled: props.disabled,
    },
  };

  const medicalAdherenceQes2Field: FormFieldsProps = {
    id: 'symptoms',
    label: 'How often have you been taking these medications?',
    componentType: TEXT_SELECT_COMPONENT,
    input: {
      placeholder: 'Select',
      options: medicationDurationOptions,
      onChange: (event: any) => setMedicalAdherence((prev) => ({ ...prev, medicationDuration: event.target.value })),
      value: medicationDurationOptions.find((item) => item.value === medicalAdherence.medicationDuration),
      disabled: props.disabled,
    },
  };

  const handleSubmit = () => {
    const details = medicationDurationOptions.find((item) => item.value === medicalAdherence.medicationDuration);
    const rawText = convertToRawText(editorState);

    const startDate = props.tasksDetails?.payload?.inputPayload?.lastEntryDate ? props.tasksDetails?.payload?.inputPayload?.lastEntryDate : new Date();

    const payload = {
      endDate: DateTime.local().toFormat('yyyy-MM-dd'),
      isWrongDosage: medicalAdherence.isPrescribeDosage?.toUpperCase() === 'NO',
      questionnaireResponse: details?.value,
      startDate: !props.isManualUpdate ? DateTime.fromJSDate(new Date(startDate)).toFormat('yyyy-MM-dd') : entryDate,
      totalValue: details?.totalValue,
      notes: {
        payload: editorState,
        rawText,
      },
    };

    props.handleSubmit(payload, editorState);
  };

  const disableSubmitButton = props.isUpdatingAPICalling || !medicalAdherence.isPrescribeDosage || !medicalAdherence.medicationDuration;

  const _renderDialogContext = () => {
    return (
      <>
        <DialogTitle sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', p: '24px' }}>
          <Typography variant='fontBold20' color={appTheme.palette.customColor.primary}>
            Medications ({name.length !== 0 ? `${name} / ${props.patientBasicDetails?.patientId}` : `${props.patientBasicDetails?.patientId}`})
          </Typography>

          <IconButton size='small' color='info' className='button' onClick={props.onClose}>
            <CloseIcon color='primary' />
          </IconButton>
        </DialogTitle>

        <DialogContent>
          {props.isManualUpdate && (
            <Grid item>
              <FormFields fullWidth={true} {...dateOfMedicalAdherenceEntryFormField} />
            </Grid>
          )}
          <Grid item>
            <FormFields fullWidth={true} {...medicalAdherenceQes1Field} />
          </Grid>

          <Grid item>
            <FormFields fullWidth={true} {...medicalAdherenceQes2Field} />
          </Grid>

          <Grid item>
            <Notes editorState={editorState} setEditorState={setEditorState} showToolbar={true} readonly={false} />
          </Grid>
        </DialogContent>

        <DialogActions sx={{ px: '24px' }}>
          <Button disabled={props.isUpdatingAPICalling} variant='outlined' color='primary' onClick={props.onClose}>
            Cancel
          </Button>
          <Button disabled={disableSubmitButton || props.disabled} variant='contained' color='primary' onClick={handleSubmit} data-testid='medication-status-update'>
            {!props.isUpdatingAPICalling && 'Update'}
            {props.isUpdatingAPICalling && (
              <>
                <CircularProgress size='small' sx={{ mr: '10px', width: '20px' }} />
                Updating...
              </>
            )}
          </Button>
        </DialogActions>
      </>
    );
  };

  if (props.hideDialogBehaviour) {
    return _renderDialogContext();
  }

  return (
    <Dialog keepMounted maxWidth='sm' fullWidth open={props.isOpen} TransitionComponent={Transition} onClose={props.onClose}>
      {_renderDialogContext()}
    </Dialog>
  );
};

export default MedicationAdherenceStatusModal;
