/** @format */

import * as React from 'react';
import * as _ from 'underscore';

import Grid from '@mui/material/Grid';
import { useTheme } from '@mui/material';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';

// Icons
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';

import FormFields from '../../common/form-fields/FormFields';

import { CustomFormType } from '../../../types/CustomForm.types';

import { TEXT_INPUT_COMPONENT } from '../../../constants/PatientEnrollment';
import { LoadingButton } from '@mui/lab';
import { ConfirmationDialog } from '../../common/confirmation-dialog/ConfirmationDialog';

interface OmronSettingPannelProps {
  isUpdating: boolean;
  omronDetails: any;
  handleSubmit: (omronConnectId: string, omronGroupId: string) => void;

  handleSubmitDeleteOmronCreds: () => void;
}

const OmronSettingPannel: React.FunctionComponent<OmronSettingPannelProps> = (props) => {
  const appTheme = useTheme();
  const [isEdit, setIsEdit] = React.useState<boolean>(false);

  const [isOpenDeleteOmronModal, setIsOpenDeleteOmronModal] = React.useState<boolean>(false);

  const [omronConnectId, setOmronConnectId] = React.useState<any>('');
  const [omronGroupId, setOmronGroupId] = React.useState<any>('');
  const [errorMessage] = React.useState<any>({});

  const toggleEditMode = () => {
    setIsEdit((prev) => !prev);
  };

  const toggleDeleteOmronModal = () => {
    setIsOpenDeleteOmronModal((prev) => !prev);
  };

  React.useEffect(() => {
    setOmronConnectId(props.omronDetails?.omronCloudId || '');
    setOmronGroupId(props.omronDetails?.groupId || '');
  }, [props.omronDetails]);

  const handleClickReset = () => {
    setOmronConnectId(props.omronDetails?.omronCloudId || '');
    setOmronGroupId(props.omronDetails?.groupId || '');
    toggleEditMode();
  };

  const handleClickSave = async () => {
    await props.handleSubmit(omronConnectId, omronGroupId);
    toggleEditMode();
  };

  const handleSubmitDeleteOmronCreds = async () => {
    await props.handleSubmitDeleteOmronCreds();
    toggleDeleteOmronModal();
  };

  const formFields: CustomFormType[] = [
    {
      id: 'omronConnectId',
      label: 'Omron Connect Id',
      componentType: TEXT_INPUT_COMPONENT,
      input: {
        value: omronConnectId,
        isCreateableSelect: true,
        onChange: (event: any) => setOmronConnectId(event.target.value),
        errorMessage: errorMessage?.OmronConnectId,
        placeholder: 'Please enter omron connect id',
        required: true,
        disabled: !isEdit || props.isUpdating,
      },
    },
    {
      id: 'OmronGroupId',
      label: 'Omron Group Id',
      componentType: TEXT_INPUT_COMPONENT,
      input: {
        value: omronGroupId,
        isCreateableSelect: true,
        disabled: !isEdit || props.isUpdating,
        onChange: (event: any) => setOmronGroupId(event.target.value),
        errorMessage: errorMessage?.omronGroupId,
        placeholder: 'Please enter omron group id',
        required: true,
      },
    },
  ];

  return (
    <Grid container direction='column' gap={2}>
      <Grid container alignItems='center' justifyContent='space-between'>
        <Typography variant='fontSemiBold16' component='div' color={appTheme.palette.customColor.primary}>
          Omron Management
        </Typography>

        <Grid item xs='auto' gap={2}>
          {!isEdit && !_.isEmpty(omronConnectId) && (
            <Button sx={{ mr: 2 }} startIcon={<DeleteIcon />} aria-label='Edit' color='error' variant='outlined' onClick={toggleDeleteOmronModal}>
              Delete
            </Button>
          )}

          {!isEdit && (
            <Button startIcon={<EditIcon />} aria-label='Edit' onClick={toggleEditMode} variant='outlined'>
              Edit
            </Button>
          )}
        </Grid>

        {isEdit && (
          <div>
            <Button disabled={props.isUpdating} color='error' onClick={handleClickReset}>
              Cancel
            </Button>

            <LoadingButton sx={{ ml: 1 }} color='primary' variant='contained' onClick={handleClickSave} loading={props.isUpdating}>
              Save
            </LoadingButton>
          </div>
        )}
      </Grid>

      {formFields.map((item) => (
        <Grid key={item.id} container item xs={3} {...item.grid}>
          <FormFields {...item} />
        </Grid>
      ))}

      {isOpenDeleteOmronModal && (
        <ConfirmationDialog
          title={'Are you sure you want to delete this OMRON Creds?'}
          description={'Once deleted, the action cannot be undo.'}
          isOpen={isOpenDeleteOmronModal}
          onClickConfirm={handleSubmitDeleteOmronCreds}
          onClose={toggleDeleteOmronModal}
        />
      )}
    </Grid>
  );
};

export default OmronSettingPannel;
