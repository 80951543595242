/** @format */

import * as React from 'react';
import { DateTime } from 'luxon';
import { useNavigate } from 'react-router-dom';
import { useQuery } from '@tanstack/react-query';
import { ErrorBoundary } from 'react-error-boundary';

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

import CustomTable from '../../components/common/custom-table/CustomTable';
import CustomLoading from '../../components/common/custom-loading/CustomLoading';
import PatientNameIdCell from '../../components/table-cell-components/PatientNameIdCell';
import { PageErrorBoundary } from '../../components/common/error/PageErrorBoundary';
import { ComponentErrorBoundary } from '../../components/common/error/ComponentErrorBoundary';

import { useAppDispatch, useAppSelector } from '../../app/hooks';
import { CustomTableColumnType } from '../../types/CustomTable.types';
import { PatientListNewType } from '../../types/PatientListPage.types';
import { setDeactivePatientList, setPageOffset, setLimit } from '../../reducers/patient-list-reducer/DeactivePatientListSlice';

import { fetchPatientsList } from '../../service/patient-list';
import { Tooltip } from '@mui/material';
import axios, { CancelTokenSource } from 'axios';

interface DeactivePatientListProps {}

const customStyles = {
  backgroundColor: '#F1F2F3',
  color: '#667185',
};

const DeactivePatientList: React.FunctionComponent<DeactivePatientListProps> = (props) => {
  const navigate = useNavigate();
  const doctors = useAppSelector((state) => state.userRegion.orgUsers);

  const axiosApiRef = React.useRef<CancelTokenSource | null>(null);

  const reduxDispatch = useAppDispatch();

  const state = useAppSelector((state) => state.deactivePatientList);
  const cardiologistId = useAppSelector((state) => state.patientList.doctorName || 'ALL');

  const deactivePatientQuery = useQuery({
    queryKey: ['fetchPatientsList', 'DEACTIVE', state.limit, state.pageOffset, cardiologistId],
    queryFn: async ({ signal }) => {
      const CancelToken = axios.CancelToken;

      if (axiosApiRef.current) {
        axiosApiRef.current.cancel();
      }

      axiosApiRef.current = CancelToken.source();

      signal?.addEventListener('abort', () => {
        axiosApiRef.current?.cancel('Query was cancelled by TanStack Query');
      });

      const payload = await fetchPatientsList(
        {
          status: 'DEACTIVE',
          limit: state.limit,
          offset: state.pageOffset * state.limit,
          cardiologistId,
          isEnrolmentDetails: 'false',
          isMedicalAdhrence: 'false',
          isPatientSymtoms: 'false',
          isPatientVitals: 'false',
        },
        axiosApiRef.current.token,
      );

      reduxDispatch(setDeactivePatientList(payload));

      return payload;
    },
    refetchIntervalInBackground: false,
    refetchOnWindowFocus: false,
  });

  const handleChangePageOffset = (event: React.MouseEvent<HTMLButtonElement> | null, newPage: number) => {
    reduxDispatch(setPageOffset(Number(newPage)));
  };

  const handleChangeLimit = (event: React.ChangeEvent<HTMLInputElement>) => {
    reduxDispatch(setLimit(Number(event.target.value)));
  };

  const columnDefs: CustomTableColumnType[] = [
    {
      minWidth: 250,
      width: '25%',
      id: 'patientId',
      label: 'Patient Details',
      cellFormatter: (data: PatientListNewType, index: number) => {
        const handleClickPatientId = () => {
          navigate(`/patients/${data?.basicDetails?.patientSourceId}`);
        };

        return (
          <ErrorBoundary key={index} fallbackRender={ComponentErrorBoundary}>
            <PatientNameIdCell data={data} handleClickPatientId={handleClickPatientId} showAlertBtn={false} />
          </ErrorBoundary>
        );
      },
    },
    {
      id: 'hf-type',
      // minWidth: 150,
      // width: '15%',
      label: 'Hf-Type',
      cellFormatter: (data: PatientListNewType, index: number) => (
        <Typography variant='fontSemiBold14' color='#33425B' component='div' sx={{ maxWidth: 'fit-content' }}>
          {data?.medicalHistory?.heartFailureMedicalHistory?.hfType
            ? `${data?.medicalHistory?.heartFailureMedicalHistory?.hfType} (${data?.medicalHistory?.heartFailureMedicalHistory?.nyhaClass})`
            : 'NA'}
        </Typography>
      ),
    },
    {
      id: 'doctor-name',
      label: 'Cardiologist',
      // minWidth: 180,
      // width: '18%',
      cellFormatter: (columnValue: PatientListNewType, index: number) => {
        const patientDoctor = doctors?.find((e: any) => e.value === columnValue.basicDetails?.cardiologistId!);
        if (doctors && patientDoctor) {
          return (
            <Typography
              key={index}
              textTransform='capitalize'
              color='#5C6A90'
              variant='fontSemiBold14'
              component='div'
              sx={{
                marginRight: '5px',
                display: 'inline-block',
                maxWidth: '200px',
                whiteSpace: 'nowrap',
                overflow: 'hidden !important',
                textOverflow: 'ellipsis',
                padding: '0 10px',
              }}>
              {patientDoctor.label.toLowerCase()}
            </Typography>
          );
        } else {
          return null;
        }
      },
    },
    {
      id: 'enrolment-date',
      // minWidth: 150,
      // width: '15%',
      label: 'Enrolment Date',
      cellFormatter: (data: PatientListNewType, index: number) => (
        <Typography variant='fontSemiBold14' color='#33425B' component='div' sx={{ maxWidth: 'fit-content' }}>
          {data?.basicDetails?.enrollmentDate ? DateTime.fromJSDate(new Date(data?.basicDetails?.enrollmentDate)).toFormat('MMM dd yyyy') : 'NA'}
        </Typography>
      ),
    },
    {
      id: 'last-review',
      // minWidth: 150,
      // width: '15%',
      label: 'Last Review',
      cellFormatter: (data: PatientListNewType, index: number) => (
        <Typography variant='fontSemiBold14' color='#33425B' component='div' sx={{ maxWidth: 'fit-content' }}>
          {data?.prescriptions?.prescriptions?.prescriptionDate ? DateTime.fromJSDate(new Date(data?.prescriptions?.prescriptions?.prescriptionDate)).toFormat('MMM dd yyyy') : 'NA'}
        </Typography>
      ),
    },
    {
      id: 'date',
      // minWidth: 150,
      // width: '20%',
      label: 'Date of Deactivation',
      cellFormatter: (data: PatientListNewType, index: number) => (
        <Typography variant='fontSemiBold14' color='#33425B' component='div' sx={{ maxWidth: 'fit-content' }}>
          {data?.basicDetails?.deactivationDate ? DateTime.fromJSDate(new Date(data?.basicDetails?.deactivationDate)).toFormat('MMM dd yyyy') : 'NA'}
        </Typography>
      ),
    },
    {
      id: 'date',
      // minWidth: 150,
      // width: '20%',
      label: 'Reason for Deactivation',
      cellFormatter: (data: PatientListNewType, index: number) => {
        if (data.basicDetails?.deactivationDate) {
          const deactivationReason =
            data.basicDetails?.deactivationReason?.reasonForDeactivation && ['Patient deceased', 'Others'].includes(data.basicDetails?.deactivationReason?.reasonForDeactivation)
              ? data.basicDetails?.deactivationReason?.others
              : '';
          return (
            <Box>
              <Typography variant='fontReg14' color='primary'>
                {data.basicDetails?.deactivationReason?.reasonForDeactivation}
                {data.basicDetails?.deactivationReason?.reasonForDeactivation &&
                ['Patient deceased'].includes(data.basicDetails?.deactivationReason?.reasonForDeactivation) &&
                data.basicDetails?.deactivationReason?.cardiacArrest
                  ? data.basicDetails?.deactivationReason?.cardiacArrest === 'Yes'
                    ? ':    Cardiac Death'
                    : ':    Non-Cardiac Death'
                  : ''}
                <br />
                <Tooltip title={deactivationReason}>
                  <Typography noWrap={false} sx={{ textOverflow: 'ellipsis', whiteSpace: 'nowrap', overflow: 'hidden', width: '20rem' }}>
                    {deactivationReason}
                  </Typography>
                </Tooltip>
              </Typography>
            </Box>
          );
        } else {
          return null;
        }
      },
    },
  ];

  if (deactivePatientQuery.isPending) {
    return <CustomLoading />;
  }

  return (
    <ErrorBoundary FallbackComponent={PageErrorBoundary}>
      <Box id='deactive-patient-list' padding='0px 15px 0px 15px'>
        <CustomTable
          customStyles={customStyles}
          count={state.count}
          data={state.listData}
          columns={columnDefs}
          tablePagination
          rowsPerPage={state.limit}
          handleChangeRowsPerPage={handleChangeLimit}
          page={state.pageOffset}
          handleChangePage={handleChangePageOffset}
        />
      </Box>
    </ErrorBoundary>
  );
};

export default DeactivePatientList;
