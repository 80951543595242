/** @format */

import config from '../config/config';
import AxiosInstance from './index';

export const getLogsHistoryByFilters = async (patientSourceId: string, limit = 10, offset = 0, category?: string) => {
  const res = await AxiosInstance({
    method: 'get',
    baseURL: config.logsApiUrl,
    url: '/logs/all/filters',
    params: {
      patientSourceId,
      category,
      limit,
      offset,
    },
  });

  return res.data || {};
};

export const getPatientLogs = async (patientSourceId: string, limit = 10, offset = 0) => {
  const res = await AxiosInstance({
    method: 'get',
    baseURL: config.logsApiUrl,
    url: `/logs/patients/${patientSourceId}`,
    params: {
      limit,
      offset,
    },
  });

  return res.data || {};
};
