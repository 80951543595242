/** @format */

// Lib
import * as React from 'react';
import * as _ from 'underscore';

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import TableBody from '@mui/material/TableBody';
import TableHead from '@mui/material/TableHead';
import Typography from '@mui/material/Typography';
import TableContainer from '@mui/material/TableContainer';
import TablePagination from '@mui/material/TablePagination';
import Table, { TablePropsSizeOverrides } from '@mui/material/Table';

import AddIcon from '@mui/icons-material/Add';

// Types
import type { OverridableStringUnion } from '@mui/types';
import { CustomTableColumnType } from '../../../types/CustomTable.types';
import { CustomTableRow } from './CustomTableRow';
interface CustomStyles {
  color?: string;
  backgroundColor?: string;
  padding?: string;
}

interface CustomTableProps {
  tablePagination?: boolean;
  count?: number;
  page?: number;
  handleChangePage?: (event: React.MouseEvent<HTMLButtonElement> | null, newPage: number) => void;
  columns: CustomTableColumnType[];
  rowsPerPage?: number;
  handleChangeRowsPerPage?: (event: React.ChangeEvent<HTMLInputElement>) => void;

  data: unknown[];
  rowsPerPageOptions?: number[];

  handleClickDataRow?: (rowData: any, index: number) => void;
  getSelectedRow?: (rowData: any) => boolean;

  noDataMessage?: string;

  dataRowHover?: boolean;
  stickyHeader?: boolean;
  tableSize?: OverridableStringUnion<'small' | 'medium', TablePropsSizeOverrides>;

  minHeightFullRow?: boolean;
  showAddRow?: boolean;
  addRowBtnLabel?: string;
  addRowBtnHoverEffect?: boolean;
  handleAddNewRow?: () => void;
  customActions?: JSX.Element;

  customStyles?: CustomStyles;

  isDisabled?: boolean;

  actions?: JSX.Element;
  hoverEffect?: boolean;

  enableBorderHoverAndFocusEffect?: boolean;
}

const CustomTable: React.FunctionComponent<CustomTableProps> = (props) => {
  const cssForCustomBorderHoverAndFocusEffect = {
    // Apply hover effect to the 1st row
    '&:first-child:hover > td:first-child': {
      borderTop: '2px solid #9BB4CC !important',
    },
    '&:first-child:hover > td:last-child': {
      borderTop: '2px solid #9BB4CC !important',
    },
    '&:first-child:hover > :not(:first-child):not(:last-child)': {
      borderTop: '2px solid #9BB4CC !important',
    },

    // Focus effect for 1st row with a focused cell (td with focus)
    '&:first-child:has(td *:focus)': {
      '& > td:first-child': {
        borderTop: '2px solid #9BB4CC !important',
      },
      '& > td:last-child': {
        borderTop: '2px solid #9BB4CC !important',
      },
      '& > :not(:first-child):not(:last-child)': {
        borderTop: '2px solid #9BB4CC !important',
      },
    },

    // Apply hover effect to all rows
    '&:hover > td:first-child': {
      borderTop: '1px solid #9BB4CC !important',
      borderLeft: '2px solid #9BB4CC !important',
      borderBottom: '2px solid #9BB4CC !important',
    },
    '&:hover > td:last-child': {
      borderTop: '1px solid #9BB4CC !important',
      borderRight: '2px solid #9BB4CC !important',
      borderBottom: '2px solid #9BB4CC !important',
    },
    '&:hover > :not(:first-child):not(:last-child)': {
      borderTop: '1px solid #9BB4CC !important',
      borderBottom: '2px solid #9BB4CC !important',
    },

    // Focus effect for rows with a focused cell (td with focus)
    '&:has(td *:focus)': {
      '& > td:first-child': {
        borderTop: '1px solid #9BB4CC !important',
        borderLeft: '2px solid #9BB4CC !important',
        borderBottom: '2px solid #9BB4CC !important',
      },
      '& > td:last-child': {
        borderTop: '1px solid #9BB4CC !important',
        borderRight: '2px solid #9BB4CC !important',
        borderBottom: '2px solid #9BB4CC !important',
      },
      '& > :not(:first-child):not(:last-child)': {
        borderTop: '1px solid #9BB4CC !important',
        borderBottom: '2px solid #9BB4CC !important',
      },
    },
  };

  const _renderTableHeadColumn = (column: CustomTableColumnType) => {
    if (column.hiddenColumn || column.hiddenHeader) {
      return null;
    }

    return (
      <TableCell
        sx={{
          zIndex: 1,
        }}
        style={props.customStyles}
        colSpan={column.headerColSpan || 1}
        key={column.id}
        className={`table-head-cell-${column.id}`}
        variant='head'>
        {column.label}
      </TableCell>
    );
  };

  const tableRowDataColDef = {
    fullWidth: true,
  };

  let customTableAddRow: any = props.data && props.data.length > 0 ? props.data : [];

  if (props.showAddRow) {
    customTableAddRow = customTableAddRow.concat(tableRowDataColDef);
  }

  return (
    <Box className='custom-table'>
      <TableContainer className='table-container'>
        <Table stickyHeader={props.stickyHeader} size={props.tableSize} aria-labelledby='tableTitle' className='table'>
          <TableHead className='table-head'>
            <TableRow className='table-head-row'>{props.columns.map(_renderTableHeadColumn)}</TableRow>
          </TableHead>
          {
            <TableBody className='table-body'>
              {_.isEmpty(customTableAddRow) && (
                <TableCell className='table-body-cell-no-data' colSpan={props.columns?.length}>
                  <Typography fontFamily='Lato' sx={{ fontSize: '14px' }} align='center'>
                    {props.noDataMessage}
                  </Typography>
                </TableCell>
              )}

              {!_.isEmpty(customTableAddRow) &&
                customTableAddRow?.map((rowData: any, index: number) => {
                  if (rowData.fullWidth) {
                    return (
                      <TableRow hover={props.dataRowHover || props.addRowBtnHoverEffect} key={index} className='table-body-row full-row'>
                        <TableCell colSpan={props.columns?.length}>
                          {props.customActions ? (
                            props.customActions
                          ) : (
                            <Button startIcon={<AddIcon />} disabled={props.isDisabled} onClick={props.handleAddNewRow} sx={{ padding: '0 5px 0 15px' }}>
                              <Typography align='center'>{props.addRowBtnLabel}</Typography>
                            </Button>
                          )}
                        </TableCell>
                      </TableRow>
                    );
                  }

                  if (props.hoverEffect) {
                    return (
                      <CustomTableRow
                        columns={props.columns}
                        rowData={rowData}
                        index={index}
                        dataRowHover={props.dataRowHover}
                        getSelectedRow={props.getSelectedRow}
                        handleClickDataRow={props.handleClickDataRow}
                        children={props.actions}
                      />
                    );
                  }

                  return (
                    <TableRow
                      selected={props.getSelectedRow?.(rowData)}
                      hover={props.dataRowHover}
                      key={index}
                      className='table-body-row'
                      onClick={() => props.handleClickDataRow?.(rowData, index)}
                      sx={{
                        ...(props.enableBorderHoverAndFocusEffect && cssForCustomBorderHoverAndFocusEffect),
                      }}>
                      {props.columns.map((column: any) => _renderTableBodyColumn(rowData, column, index))}
                    </TableRow>
                  );
                })}
            </TableBody>
          }
        </Table>
      </TableContainer>

      <Box display='flex' alignItems='center' justifyContent='flex-end' bgcolor='#fafbfc'>
        {props?.tablePagination && props.rowsPerPage && (
          <TablePagination
            className='table-pagination'
            classes={{ actions: 'pagination-actions', displayedRows: 'pagination-text' }}
            rowsPerPageOptions={props.rowsPerPageOptions}
            component='div'
            count={props.count!}
            rowsPerPage={props.rowsPerPage}
            page={props.page!}
            onPageChange={props.handleChangePage!}
            onRowsPerPageChange={props.handleChangeRowsPerPage!}
          />
        )}
      </Box>
    </Box>
  );
};

CustomTable.defaultProps = {
  noDataMessage: 'No Data to Display',
  rowsPerPage: 20,
  rowsPerPageOptions: [10, 15, 20],

  dataRowHover: true,
  stickyHeader: true,
};

export default CustomTable;

export const _renderTableBodyColumn = (rowData: any, column: CustomTableColumnType, index: number) => {
  if (!rowData || column.hiddenColumn) return null;

  let value: JSX.Element | null = null;

  if (column.value) {
    value = rowData[column.value];
  }

  if (column.cellFormatter) {
    value = column.cellFormatter(rowData, index);
  }

  if (column.valueFormatter) {
    value = column.valueFormatter(rowData);
  }

  return (
    <TableCell
      key={column.id}
      style={{ minWidth: column.minWidth, width: column.width, maxWidth: column.maxWidth }}
      className={`table-body-cell-${column.id}`}
      align={column.cellAlignment}
      rowSpan={column.rowSpan}
      valign={column.valign}>
      {value}
    </TableCell>
  );
};
