/** @format */

import * as React from 'react';
import * as _ from 'underscore';

import Grid from '@mui/material/Grid';
import List from '@mui/material/List';
import { styled } from '@mui/material/styles';
import ListItem from '@mui/material/ListItem';
import Typography from '@mui/material/Typography';
import ListSubheader from '@mui/material/ListSubheader';
import Tooltip, { TooltipProps, tooltipClasses } from '@mui/material/Tooltip';

import { AlgoSuggestionOutputPayloadItemV2Dto, PatientAlreadyPrescribedMedicationsType, PatientPrescriptionReviewListType } from '../../types/PrescriptionsGenerator.types';

const LightTooltip = styled(({ className, ...props }: TooltipProps) => <Tooltip {...props} classes={{ popper: className }} />)(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.common.white,
    color: 'rgba(0, 0, 0, 0.87)',
    boxShadow: theme.shadows[1],
    fontSize: 11,
  },
}));

export function getAlgoSuggestionBasedOnNewMedication (
  patientReviewPrescriptions: PatientPrescriptionReviewListType | null,
  patientAlreadyPrescribedMedications: PatientAlreadyPrescribedMedicationsType[] | null,
) {
  if (!_.isEmpty(patientReviewPrescriptions) && !_.isEmpty(patientReviewPrescriptions?.algoSuggestion)) {
    return Object.keys(patientReviewPrescriptions?.algoSuggestion!).map((objectKey, index) => {
      const isPrecribedMedication = patientAlreadyPrescribedMedications?.find((item) => item.medicationName === objectKey);

      if (_.isEmpty(isPrecribedMedication)) {
        const algoSuggestion: AlgoSuggestionOutputPayloadItemV2Dto[] = patientReviewPrescriptions?.algoSuggestion?.[objectKey]!;
        let reviewSuggestion: string | null | undefined = null;

        if (!_.isEmpty(algoSuggestion)) {
          reviewSuggestion = algoSuggestion?.sort((a, b) => b.severity - a.severity)[0].summary;
        }

        return { reviewSuggestion, algoSuggestion };
      }

      return null;
    });
  }
  return null;
}

export function getAlgoSuggestionBasedOnSelected (selectedMedications: PatientAlreadyPrescribedMedicationsType, patientReviewPrescriptions: PatientPrescriptionReviewListType | null) {
  let algoSuggestion: AlgoSuggestionOutputPayloadItemV2Dto[] | null | undefined = null;
  let reviewSuggestion: string | null | undefined = null;

  if (selectedMedications.medicationName && !_.isEmpty(patientReviewPrescriptions?.algoSuggestion) && !_.isEmpty(patientReviewPrescriptions?.algoSuggestion?.[selectedMedications.medicationName!])) {
    algoSuggestion = patientReviewPrescriptions?.algoSuggestion?.[selectedMedications.medicationName!];

    if (!_.isEmpty(algoSuggestion)) {
      reviewSuggestion = algoSuggestion?.sort((a, b) => b.severity - a.severity)[0].summary;
    }
  }

  return { algoSuggestion, reviewSuggestion };
}

export function getAlgoSuggestionBasedOnOverview (patientReviewPrescriptions: PatientPrescriptionReviewListType | null) {
  if (!_.isEmpty(patientReviewPrescriptions) && !_.isEmpty(patientReviewPrescriptions?.algoSuggestion)) {
    let algoSuggestion: AlgoSuggestionOutputPayloadItemV2Dto[] = [];
    let severity = -1;
    let reviewSuggestion: string | null | undefined = null;
    Object.keys(patientReviewPrescriptions?.algoSuggestion!).forEach((objectKey) => {
      algoSuggestion = algoSuggestion.concat(patientReviewPrescriptions?.algoSuggestion?.[objectKey]!);

      if (!_.isEmpty(algoSuggestion)) {
        algoSuggestion = algoSuggestion?.sort((a, b) => b.severity - a.severity);

        if (severity < algoSuggestion?.[0]?.severity!) {
          reviewSuggestion = algoSuggestion?.[0]?.summary!;
          severity = algoSuggestion?.[0]?.severity!;
        }
      }
    });

    return { reviewSuggestion, algoSuggestion };
  }

  return null;
}

interface AlgoSuggestionViewToolTipProps {
  algoSuggestion?: AlgoSuggestionOutputPayloadItemV2Dto[] | null;

  children: JSX.Element;
}

const AlgoSuggestionViewToolTip: React.FunctionComponent<AlgoSuggestionViewToolTipProps> = (props) => {
  const _renderAlgoSuggestion = (): JSX.Element => {
    return (
      <List sx={{ p: 0, maxHeight: '200px', overflow: 'auto' }}>
        {props.algoSuggestion?.map((item, index) => (
          <React.Fragment key={index}>
            <ListSubheader sx={{ lineHeight: '21px', px: 0.5 }}>
              <Typography variant='fontBold14' color='#646464'>
                {item.summary}:
              </Typography>
            </ListSubheader>
            {item.reason.map((ele, index) => (
              <ListItem sx={{ px: 0.5 }} key={index}>
                <Grid container alignItems='flex-start' gap={0.6}>
                  <Grid item xs='auto'>
                    <Typography variant='fontReg14' color='#646464'>
                      {index + 1}.
                    </Typography>
                  </Grid>
                  <Grid item xs>
                    <Typography variant='fontReg14' color='#646464'>
                      {ele}
                    </Typography>
                  </Grid>
                </Grid>
              </ListItem>
            ))}
          </React.Fragment>
        ))}
      </List>
    );
  };

  if (_.isEmpty(props.algoSuggestion)) {
    return (
      <Grid item xs='auto' container alignItems='center' gap={1}>
        {props.children}
      </Grid>
    );
  }

  return (
    <LightTooltip title={_renderAlgoSuggestion()} placement='bottom-end'>
      <Grid item xs='auto' container alignItems='center' gap={1}>
        {props.children}
      </Grid>
    </LightTooltip>
  );
};

export default AlgoSuggestionViewToolTip;
