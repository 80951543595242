/** @format */

import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';

// Types and interfaces.
import { RootState } from '../../app/store';
import { fetchPatientVitalsByRange } from '../../service/patient-vitals';
import { PatientVitalsReadingType } from '../../types/PatientVitals.types';

export interface PatientVitalsError {
  message: string;
}

export interface OrgMedicationsState {
  isLoading: boolean;
  patientVitals: Array<PatientVitalsReadingType>;
  error: PatientVitalsError;
  allPatientVitals: {
    [sourceId: string]: { vitals: any; vitalsReference: any; lastestVitals: any; medicalAdherence: any };
  };
  allPatientVitalsReference: { [sourceId: string]: PatientVitalsReadingType[] };
  allPatientVitalsLatestReadings: { [sourceId: string]: PatientVitalsReadingType[] };
}

export const initialState: OrgMedicationsState = {
  isLoading: false,
  patientVitals: [],
  error: { message: '' },
  allPatientVitals: {},
  allPatientVitalsReference: {},
  allPatientVitalsLatestReadings: {},
};

export const FetchPatientVitalsByRange = createAsyncThunk(
  'vitals/FetchPatientVitalsByRange',
  async ({ patientSourceId, startDate, endDate }: { patientSourceId: string; startDate: string; endDate: string }) => {
    try {
      // API call.
      const response = await fetchPatientVitalsByRange(patientSourceId, startDate, endDate);

      return response;
    } catch (error) {
      console.error(error);
    }
  },
);

export const patientVitalsReducer = createSlice({
  name: 'patient vitals reducer',
  initialState,
  reducers: {
    setLoading: (state, { payload }: PayloadAction<boolean>) => {
      state.isLoading = payload;
    },

    setPatientVitalsRedux: (
      state,
      {
        payload,
      }: PayloadAction<{
        sourceId: string;
        vitals: PatientVitalsReadingType[];
        vitalsReference: PatientVitalsReadingType[];
        lastestVitals: PatientVitalsReadingType[];
        medicalAdherence?: any;
      }>,
    ) => {
      state.allPatientVitals = {
        ...state.allPatientVitals,
        [payload.sourceId]: { vitals: payload.vitals, vitalsReference: payload.vitalsReference, lastestVitals: payload.lastestVitals, medicalAdherence: payload.medicalAdherence },
      };
    },
  },

  extraReducers: (builder: any) => {
    // Sign in with email fulfilled case.
    builder.addCase(FetchPatientVitalsByRange.fulfilled, (state: any, action: any) => {
      if (action.payload) {
        state.patientVitals = action.payload;
        // state.allPatientVitals = [...action.payload];
      }
    });

    // Sign in with email rejected case.
    builder.addCase(FetchPatientVitalsByRange.rejected);

    // Sign in with email pending case.
    builder.addCase(FetchPatientVitalsByRange.pending, (state: any) => {
      state.isLoading = true;
    });
  },
});

export const { setPatientVitalsRedux, setLoading } = patientVitalsReducer.actions;

export const patientVitalsSelector = (state: RootState) => state.patientVitals;
