/** @format */

import _ from 'underscore';
import * as React from 'react';
import List from '@mui/material/List';
import Menu from '@mui/material/Menu';
import Button from '@mui/material/Button';
import ListItem from '@mui/material/ListItem';
import MenuItem from '@mui/material/MenuItem';
import Typography from '@mui/material/Typography';
import LoadingButton from '@mui/lab/LoadingButton';

import AddIcon from '@mui/icons-material/Add';

import { DropdownOptionType } from '../../types/CustomForm.types';
import { PatientRuleItemConditionType, PatientRuleItemErrorType, PatientRuleItemType } from '../../types/PatientRule.types';

import { PATIENT_RULE_ACTION_KIND_MEDICATION_CHANGE, PATIENT_RULE_DEFAULT_RULE_ID, PATIENT_RULE_SUGESSTION_TYPE_IMMEDIATE } from '../../constants/PatientRules';
import PatientRuleActionGroup from './PatientRuleActionGroup';

interface PatientRuleListProps {
  showDefaultRules?: boolean;
  hideSaveBtnRuleItem?: boolean;
  isForceEdit?: boolean;
  itemControlEdit?: boolean;

  patientRules: PatientRuleItemType[];
  patientRulesErrors?: PatientRuleItemErrorType[];

  isUpdatingPatientRule: boolean;
  handleSubmitNewPatientRule: (rule: PatientRuleItemType) => void;
  handleSubmitUpdatePatientRule: (ruleId: string, rule: PatientRuleItemType) => void;
  handleSubmitDeletePatientRule: (ruleId: string) => void;

  handleChangeRules?: (rules: PatientRuleItemType[]) => void;

  handleClickChangeRules: () => void;

  onChange?: (payload: any, id: string) => void;

  isRestrictColumnWidth?: boolean;
}

const PatientRuleList: React.FunctionComponent<PatientRuleListProps> = (props) => {
  const [addRuleMenu, setAddRuleMenu] = React.useState<null | HTMLElement>(null);
  const [patientRules, setPatientRules] = React.useState<PatientRuleItemType[]>([]);

  React.useEffect(() => {
    if (props.patientRules) {
      setPatientRules(JSON.parse(JSON.stringify(props.patientRules)));
    }
  }, [props.patientRules]);

  const handleClickAddRuleMenu = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAddRuleMenu(event.currentTarget);
  };

  const handleCloseAddRuleMenu = () => {
    setAddRuleMenu(null);
  };

  const handleAddNewRule = (action: string) => {
    setPatientRules((prev) => {
      const suggestionType = prev.find((item) => item?.ruleAction === action)?.suggestionType || PATIENT_RULE_SUGESSTION_TYPE_IMMEDIATE;

      const newRule: PatientRuleItemType = {
        ruleId: PATIENT_RULE_DEFAULT_RULE_ID + '_' + new Date().getTime(),
        suggestionType,
        conditions: {
          all: [{} as PatientRuleItemConditionType],
        },
        ruleAction: action,
        ruleKind: PATIENT_RULE_ACTION_KIND_MEDICATION_CHANGE,
        medications: [],
        notificationPreferences: {
          sms: true,
          email: true,
        },
      };

      if (props.onChange) {
        props.onChange(newRule, 'add_new_medication_rule');
      }

      prev = prev.concat(newRule);

      if (props.handleChangeRules) {
        props.handleChangeRules(prev);
      }
      return prev;
    });
  };

  const handleRemoveNewRule = (ruleId: string) => {
    setPatientRules((prev) => {
      prev = prev.filter((item) => item.ruleId !== ruleId);
      if (props.handleChangeRules) {
        props.handleChangeRules(prev);
      }
      return prev;
    });
  };

  const handleSubmitDuplicateRule = (rule: PatientRuleItemType) => {
    setPatientRules((prev) => {
      prev = prev.concat({ ...rule, ruleId: PATIENT_RULE_DEFAULT_RULE_ID + '_' + new Date().getTime() });
      if (props.handleChangeRules) {
        props.handleChangeRules(prev);
      }
      return prev;
    });
  };

  const handleClickMenuAction = (item: DropdownOptionType) => {
    handleAddNewRule(item.value);
    setAddRuleMenu(null);
  };

  const handleChageRuleItem = (ruleId: string, rule: PatientRuleItemType) => {
    if (props.handleChangeRules) {
      const newRules: PatientRuleItemType[] | any = patientRules?.map((item) => {
        if (item.ruleId === ruleId) {
          return rule;
        }
        return item;
      });
      props.handleChangeRules(newRules);
    }
  };

  const addRuleMenuOptions: DropdownOptionType[] = [
    {
      label: 'Stop',
      value: 'stop',
    },
    {
      label: 'Uptitrate',
      value: 'uptitrate',
    },
  ].filter((item) => !patientRules.some((rule) => rule?.ruleAction === item.value));

  const _renderList = () => {
    if (patientRules?.length <= 0 && props.showDefaultRules) {
      return (
        <ListItem sx={{ textAlign: 'center', width: '100%', p: 0 }}>
          <Typography variant='fontReg16'>System Default Rule is active. Would you like to change Default Rule</Typography>
          <LoadingButton loading={props.isUpdatingPatientRule} sx={{ ml: 2 }} onClick={props.handleClickChangeRules} variant='contained'>
            Change
          </LoadingButton>
        </ListItem>
      );
    }

    const ruleGroupByAction = _.groupBy(patientRules, (item) => item?.ruleAction);

    return Object.keys(ruleGroupByAction).map((key, index) => (
      <ListItem key={index} sx={{ px: 0 }}>
        <PatientRuleActionGroup
          isRestrictColumnWidth={props.isRestrictColumnWidth}
          isForceEdit={props.isForceEdit}
          itemControlEdit={props.itemControlEdit}
          hideSaveBtnRuleItem={props.hideSaveBtnRuleItem}
          rules={ruleGroupByAction[key]}
          handleAddNewRule={handleAddNewRule}
          handleRemoveNewRule={handleRemoveNewRule}
          handleSubmitDuplicateRule={handleSubmitDuplicateRule}
          isUpdatingPatientRule={props.isUpdatingPatientRule}
          handleSubmitNewPatientRule={props.handleSubmitNewPatientRule}
          handleSubmitUpdatePatientRule={props.handleSubmitUpdatePatientRule}
          handleSubmitDeletePatientRule={props.handleSubmitDeletePatientRule}
          patientRulesErrors={props.patientRulesErrors}
          handleChangeRules={handleChageRuleItem}
        />
      </ListItem>
    ));
  };

  return (
    <>
      {addRuleMenuOptions?.length > 0 && props.isForceEdit && !(patientRules?.length <= 0 && props.showDefaultRules) && (
        <Button id='addRuleBtn' onClick={handleClickAddRuleMenu} variant='contained' sx={{ ml: 2, position: 'absolute', top: '10%', left: '45%' }} startIcon={<AddIcon />}>
          Add Rules
        </Button>
      )}

      <Menu id='basic-menu' anchorEl={addRuleMenu} open={Boolean(addRuleMenu)} onClose={handleCloseAddRuleMenu}>
        {addRuleMenuOptions.map((item, index) => (
          <MenuItem key={index} sx={{ minWidth: '150px' }} onClick={() => handleClickMenuAction(item)}>
            {item.label}
          </MenuItem>
        ))}
      </Menu>
      <List>{_renderList()}</List>
    </>
  );
};

PatientRuleList.defaultProps = {
  hideSaveBtnRuleItem: false,
  showDefaultRules: true,
  isForceEdit: true,
  itemControlEdit: false,
};

export default PatientRuleList;
