/** @format */

import axios, { CancelTokenSource } from 'axios';
import { PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { RootState } from '../../app/store';
import { PatientTasksType } from '../../types/PatientTasks.type';
import { fetchPatientsApprovalsList } from '../../service/patient-list';
import { PatientListNewType } from '../../types/PatientListPage.types';

let axiosCancelToken: CancelTokenSource | null = null;

export interface PatientApprovalState {
  patientApprovalListIsLoading: boolean;

  limit: number;
  pageOffset: number;

  patientApprovalList: { count: number; data: PatientListNewType[] };
}

export const activeInitialState: PatientApprovalState = {
  patientApprovalListIsLoading: false,

  limit: 20,
  pageOffset: 0,

  patientApprovalList: { count: 0, data: [] },
};

export const FetchPatientApprovalList = createAsyncThunk(
  'FetchPatientApprovalList',
  async ({ limit, pageOffset, cardiologistId }: { limit: number; pageOffset: number; cardiologistId: string | undefined }) => {
    try {
      if (axiosCancelToken) {
        axiosCancelToken.cancel();
      }

      axiosCancelToken = axios.CancelToken.source();

      const data = await fetchPatientsApprovalsList({ limit, offset: pageOffset * limit, cardiologistId }, axiosCancelToken.token);

      return data;
    } catch (err) {
      if (axios.isCancel(err)) {
        throw err;
      }
      console.error(err);
    }
  },
);

export const patientApprovalReducer = createSlice({
  name: 'Patient approval reducer',
  initialState: activeInitialState,
  reducers: {
    setLimit: (state, { payload }: PayloadAction<number>) => {
      state.limit = payload;
    },

    setPageOffset: (state, { payload }: PayloadAction<number>) => {
      state.pageOffset = payload;
    },

    setPatientApprovalList: (state, { payload }: PayloadAction<{ count: number; data: PatientTasksType<any, any>[] }>) => {
      state.patientApprovalList.data = payload.data;
      state.patientApprovalList.count = payload.count;
    },

    updatePatientApprovalList: (state, { payload }: PayloadAction<{ payload: PatientListNewType }>) => {
      state.patientApprovalList.data = state.patientApprovalList.data.filter((e) => e.basicDetails?.patientSourceId !== payload.payload.basicDetails?.patientSourceId);
    },
  },
  extraReducers: (builder) => {
    builder.addCase(FetchPatientApprovalList.pending, (state: PatientApprovalState, action) => {
      state.patientApprovalListIsLoading = true;
    });

    builder.addCase(FetchPatientApprovalList.fulfilled, (state: PatientApprovalState, action) => {
      state.patientApprovalList.data = action.payload?.data;
      state.patientApprovalList.count = action.payload?.count;

      state.patientApprovalListIsLoading = false;
    });

    builder.addCase(FetchPatientApprovalList.rejected, (state: PatientApprovalState, action) => {
      state.patientApprovalListIsLoading = true;
    });
  },
});

export const { setPatientApprovalList, setLimit, setPageOffset, updatePatientApprovalList } = patientApprovalReducer.actions;

export const patientApprovalSelector = (state: RootState) => state.patientApproval;
