/** @format */

import * as React from 'react';
import * as _ from 'underscore';

import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';

import AddIcon from '@mui/icons-material/Add';

import Grow from '@mui/material/Grow';

import AddReport from './AddReport';
import { V2PatientAttachmentReportType } from '../../../types/PatientAttachments.types';
import { NEW_ATTACHMENT_REPORT_ID } from '../../../constants/PatientAttachments';
import { PatientProfileContext } from '../../../pages/patient-profile/PatientProfileContext';
import CustomLoading from '../../common/custom-loading/CustomLoading';
import { ClickAwayListener, MenuItem, MenuList, Paper, Popper } from '@mui/material';

const Attachments: React.FunctionComponent = () => {
  const { patientAttachmentsReportsData, handleDeletePatientReport: handleDeleteReport, refetchPatientDetails, isLoadingPatientAttachmentsReports } = React.useContext(PatientProfileContext);
  const [attachmentReports, setAttachmnetReports] = React.useState<V2PatientAttachmentReportType[]>([]);
  const anchorRef = React.useRef<HTMLButtonElement>(null);
  const [open, setOpen] = React.useState(false);

  const handleClickDeleteAttachmentReport = async (attachmentReport: V2PatientAttachmentReportType) => {
    if (attachmentReport.attachmentReportId === NEW_ATTACHMENT_REPORT_ID) {
      setAttachmnetReports((item) => item.filter((ele) => ele.attachmentReportId !== NEW_ATTACHMENT_REPORT_ID));
    }

    await handleDeleteReport(attachmentReport.reportId);
  };

  const handleClickNewReportType = (type: string) => {
    setAttachmnetReports((prev) => {
      const isNew = prev.find((item) => item.attachmentReportId === NEW_ATTACHMENT_REPORT_ID);
      if (!_.isEmpty(isNew)) {
        return prev.map((item) => {
          if (item.attachmentReportId === NEW_ATTACHMENT_REPORT_ID) {
            return { ...item, type } as V2PatientAttachmentReportType;
          }
          return item;
        });
      }

      return [{ type, attachmentReportId: NEW_ATTACHMENT_REPORT_ID } as V2PatientAttachmentReportType].concat(prev);
    });
    setOpen(false);
  };

  const handleClickCancelAttachmentReport = (payload: V2PatientAttachmentReportType) => {
    const filterItems = attachmentReports.filter((e, i) => e.id !== payload.id);
    setAttachmnetReports(filterItems);
    refetchPatientDetails.refetch();
  };

  if (isLoadingPatientAttachmentsReports) {
    return <CustomLoading />;
  }

  const reportActionOption = [
    {
      title: 'Add Report',
      onClick: () => {
        handleClickNewReportType('REPORTS');
      },
    },
    {
      title: 'Add Prescription',
      onClick: () => {
        handleClickNewReportType('PRESCRIPTIONS');
      },
    },
    {
      title: 'Add Hospitalisation/Discharge details',
      onClick: () => {
        handleClickNewReportType('HOSTPITALISATION');
      },
    },
    {
      title: 'Add other',
      onClick: () => {
        handleClickNewReportType('OTHERS');
      },
    },
  ];

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };
  const handleClose = (event: Event | React.SyntheticEvent) => {
    if (anchorRef.current && anchorRef.current.contains(event.target as HTMLElement)) {
      return;
    }

    setOpen(false);
  };

  const _renderReportActionButtons = (items: any, index: number, array: any[]) => {
    return (
      <MenuItem
        key={index}
        sx={{
          border: '1px solid #9BB4CC',
          // height: '32px',
          width: '300px',
        }}
        onClick={items.onClick}>
        <Typography>{items.title}</Typography>
      </MenuItem>
    );
  };

  return (
    <Box>
      <Grid item xs={12} gap={3} sx={{ marginBottom: '15px' }} justifyContent='space-between'>
        <Button
          ref={anchorRef}
          id='composition-button'
          onClick={handleToggle}
          startIcon={<AddIcon sx={{ color: 'grey' }} />}
          sx={{
            boxShadow: 'none',
            border: '1px solid #9BB4CC',
            borderRadius: '5px',
            height: '32px',
            padding: '0 10px',
            '.MuiInput-input': {
              padding: 0,
            },
            marginTop: '10px',
          }}>
          <Typography>Add Attachments</Typography>
        </Button>
        <Popper open={open} anchorEl={anchorRef.current} role={undefined} placement='bottom-start' transition disablePortal sx={{ zIndex: '10' }}>
          {({ TransitionProps, placement }) => (
            <Grow
              {...TransitionProps}
              style={{
                transformOrigin: placement === 'bottom-start' ? 'left top' : 'left bottom',
              }}>
              <Paper>
                <ClickAwayListener onClickAway={handleClose}>
                  <MenuList
                    autoFocusItem={open}
                    sx={{
                      pb: 0,
                      pt: '5px',
                    }}>
                    {reportActionOption.map(_renderReportActionButtons)}
                  </MenuList>
                </ClickAwayListener>
              </Paper>
            </Grow>
          )}
        </Popper>
      </Grid>

      <Grid item xs={12} container gap={1}>
        {attachmentReports &&
          attachmentReports.map((item, index) => (
            <Grid item xs={12}>
              <AddReport
                key={index}
                handleClickCancelAttachmentReport={handleClickCancelAttachmentReport}
                attachmentReport={item}
                setAttachmnetReports={setAttachmnetReports}
                attachmentReports={attachmentReports}
                handleClickDeleteAttachmentReport={handleClickDeleteAttachmentReport}
              />
            </Grid>
          ))}

        {patientAttachmentsReportsData &&
          patientAttachmentsReportsData.map((item, index) => (
            <Grid item xs={12}>
              <AddReport
                key={index}
                attachmentReport={item}
                setAttachmnetReports={setAttachmnetReports}
                attachmentReports={attachmentReports}
                handleClickCancelAttachmentReport={handleClickCancelAttachmentReport}
                handleClickDeleteAttachmentReport={handleClickDeleteAttachmentReport}
              />
            </Grid>
          ))}
      </Grid>

      {attachmentReports?.length <= 0 && patientAttachmentsReportsData?.length <= 0 && (
        <Typography component='div' textAlign='center'>
          No Attachments
        </Typography>
      )}
    </Box>
  );
};
export default Attachments;
