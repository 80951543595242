/** @format */

export const NOTE_TYPES = [
  {
    label: 'General Note',
    value: 'GENERAL_NOTE',
  },
  {
    label: 'Medication Change',
    value: 'MEDICATION_CHANGE',
  },
  {
    label: 'Review',
    value: 'REVIEW',
  },
  {
    label: 'Symptoms Missing',
    value: 'MISSING_SYMPTOMS',
  },
  {
    label: 'Vitals Missing',
    value: 'MISSING_VITALS',
  },
  {
    label: 'Medication Adherence Missing',
    value: 'MISSING_MEDICATION_ADHERENCE',
  },
  {
    label: 'Missing Apple Watch data',
    value: 'MISSING_APPLE_WATCH_DATA',
  },

  {
    label: 'Medication Adherence Update',
    value: 'MEDICATION_ADHERENCE_UPDATE',
  },

  {
    label: 'Appointment',
    value: 'APPOINTMENT',
  },
];

export const NOTE_INITIAL_STATE = [
  {
    type: 'paragaph',
    children: [{ text: '' }],
  } as any,
];

export const NOTES_TYPE_MAPPING: { [m: string]: string } = {
  GENERAL_NOTE: 'General',
  MEDICATION_CHANGE: 'Medication Change',
  REVIEW_ALERTS: 'Review Alerts',
  MISSING_SYMPTOMS: 'Symptoms Missing',
  MISSING_VITALS: 'Vitals Missing',
  MISSING_TASK: 'Missing Task',
  MEDICATION_ADHERENCE: 'Medication Adherence',
  MISSING_APPLE_WATCH_DATA: 'Missign Apple Watch Data',
  MEDICATION_ADHERENCE_UPDATE: 'Medication Adherence Update',
  APPOINTMENT: 'Appointment',
};
