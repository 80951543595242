/** @format */

export const REASONS_FOR_VISIT_OPTIONS = [
  {
    label: 'Lab Test',
    value: 'LAB_TEST',
  },
  {
    label: 'Consultation',
    value: 'CONSULTATION',
  },
];

export const REPEAT_OPTIONS = [
  {
    label: 'Repeat',
    value: 'REPEAT',
  },

  {
    label: 'Does not repeat',
    value: 'DOES_NOT_REPEAT',
  },
];

export const APPOINTMENT_TYPE_MAP: { [m: string]: string } = {
  CONSULTATION: 'Consultation',
  LAB_TEST: 'Lab Test',
};

export const APPOINTMENT_PLACES_LIST = [
  {
    label: 'Virtual',
    value: 'VIRTUAL',
  },

  {
    label: 'Physical',
    value: 'PHYSICAL',
  },
];

export const DAYS_BEFORE_CONSULTATION_OPTIONS = [
  {
    label: '1',
    value: '1',
  },
  {
    label: '2',
    value: '2',
  },

  {
    label: '3',
    value: '3',
  },
];
