/** @format */

import AxiosInstance from './index';
import CONFIG from '../config/config';
import { PatientMedicalHistoryPayloadType } from '../types/PatientMedicalHistory.types';
import { PatientDeactivationReasonType } from '../types/Patients.types';

export const fetchPatientInfo = async (sourceId: string, query?: any) => {
  const res = await AxiosInstance({
    method: 'GET',
    baseURL: CONFIG.patientApiUrl,
    url: `patients/${sourceId}/info`,
    params: query,
  });

  return res.data;
};

export const updatePatientMedicalHistory = async (sourceId: string, payload: Partial<PatientMedicalHistoryPayloadType>) => {
  const res = await AxiosInstance({
    method: 'PATCH',
    baseURL: CONFIG.patientApiUrl,
    url: `patients/${sourceId}/medical-history`,
    data: {
      ...payload,
    },
  });

  return res.data || {};
};

export const deactivatePatient = async (sourceId: string, deactivationReason: PatientDeactivationReasonType) => {
  const res = await AxiosInstance({
    method: 'DELETE',
    baseURL: CONFIG.patientApiUrl,
    url: `patients/${sourceId}`,
    data: {
      ...deactivationReason,
    },
  });

  return res.data || {};
};

export const activatePatient = async (sourceId: string) => {
  const res = await AxiosInstance({
    method: 'PATCH',
    baseURL: CONFIG.patientApiUrl,
    url: `patients/${sourceId}/activate`,
  });

  return res.data || {};
};
