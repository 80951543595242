/** @format */

import * as React from 'react';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import MoreVertIcon from '@mui/icons-material/MoreVert';

interface LongMenuProps {
  menuItems: { id: number; title: string; value: string; onClick: (payload?: any) => void }[];
}

const ThreeDotsMenu: React.FC<LongMenuProps> = ({ menuItems }) => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div>
      <IconButton aria-label='more' id='long-button' aria-controls={open ? 'long-menu' : undefined} aria-expanded={open ? 'true' : undefined} aria-haspopup='true' onClick={handleClick}>
        <MoreVertIcon />
      </IconButton>
      <Menu
        id='long-menu'
        MenuListProps={{
          'aria-labelledby': 'long-button',
        }}
        anchorEl={anchorEl}
        open={open}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        onClose={handleClose}
        PaperProps={{
          style: {
            maxHeight: 4.5 * 48,
            width: '20ch',
          },
        }}>
        {menuItems.map((menu) => (
          <MenuItem
            key={menu.id}
            onClick={() => {
              menu.onClick();
              handleClose();
            }}>
            {menu.title}
          </MenuItem>
        ))}
      </Menu>
    </div>
  );
};

export default ThreeDotsMenu;
