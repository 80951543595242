/** @format */

import AxiosInstance from './index';
import CONFIG from '../config/config';

export const fetchUnmappedDevices = async () => {
  const res = await AxiosInstance({
    method: 'GET',
    baseURL: CONFIG.patientApiUrl,
    url: '/patients/devices',
    params: {
      type: 'UNMAPPED',
    },
  });

  return res.data || {};
};

export const fetchAllMappedDevices = async () => {
  const res = await AxiosInstance({
    method: 'GET',
    baseURL: CONFIG.patientApiUrl,
    url: '/patients/devices',
    params: {
      type: 'MAPPED',
    },
  });

  return res.data || {};
};

export const updatePatientDeviceInfo = async (patientSourceId: string, deviceId: string) => {
  const res = await AxiosInstance({
    method: 'PATCH',
    baseURL: CONFIG.patientApiUrl,
    url: `/patients/${patientSourceId}/devices/${deviceId}`,
  });

  return res.data || {};
};

export const deletePatientDeviceInfo = async (patientSourceId: string, deviceId: string) => {
  const res = await AxiosInstance({
    method: 'DELETE',
    baseURL: CONFIG.patientApiUrl,
    url: `/patients/${patientSourceId}/devices/${deviceId}`,
  });

  return res.data || {};
};

export const generatePatientAppUsername = async (patientSourceId: string) => {
  const res = await AxiosInstance({
    method: 'POST',
    baseURL: CONFIG.patientApiUrl,
    url: `/patients/${patientSourceId}/app/credentials`,
  });

  return res.data || {};
};
