/** @format */

import { toastReducer } from './../reducers/toast-reducer/ToastReducer';
import { alertReducer } from './../reducers/alert-reducer/AlertSlice';
import { userRegionReducer } from './../reducers/user-region-reducer/UserRegionSlice';
import { Action, configureStore, ThunkAction } from '@reduxjs/toolkit';

// Reducers.
import { configReducer } from '../reducers/config-reducer/ConfigSlice';
import { patientVitalsReducer } from './../reducers/vitals-reducer/PatientVitalsSlice';
import { orgMedicationsReducer } from './../reducers/org-medications/OrgMedicationsSlice';
import { patientSymptomsReducer } from '../reducers/patient-symptoms-reducer/PatientSymptomsSlice';

import { patientListReducer } from '../reducers/patient-list-reducer/PatientListSlice';
import { draftPatientListReducer } from '../reducers/patient-list-reducer/DraftPatientListSlice';
import { activePatientListReducer } from '../reducers/patient-list-reducer/ActivePatientListSlice';
import { approvalPatientListReducer } from '../reducers/patient-list-reducer/ApprovalPatientListSlice';
import { deactivePatientListReducer } from '../reducers/patient-list-reducer/DeactivePatientListSlice';

import { upcomingEventsReducer } from '../reducers/workspace-reducer/UpcomingEventsSlice';
import { monitorPatientsReducer } from '../reducers/workspace-reducer/MonitorPatientsSlice';
import { patientApprovalReducer } from '../reducers/workspace-reducer/PatientApprovalSlice';

export const store = configureStore({
  reducer: {
    // Add other reducers here.
    config: configReducer.reducer,
    alert: alertReducer.reducer,
    orgMedications: orgMedicationsReducer.reducer,
    patientSymptoms: patientSymptomsReducer.reducer,
    patientVitals: patientVitalsReducer.reducer,
    toast: toastReducer.reducer,
    patientList: patientListReducer.reducer,
    activePatientList: activePatientListReducer.reducer,
    draftPatientList: draftPatientListReducer.reducer,
    deactivePatientList: deactivePatientListReducer.reducer,
    approvalPatientList: approvalPatientListReducer.reducer,
    userRegion: userRegionReducer.reducer,

    upcomingEvents: upcomingEventsReducer.reducer,
    monitorPatients: monitorPatientsReducer.reducer,
    patientApproval: patientApprovalReducer.reducer,
  },

  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        // Ignore these action types.
        ignoredActions: ['auth/setProfile', 'toast reducer/clearToast'],
        // Ignore these field paths in all actions.
        ignoredActionPaths: ['meta.arg', 'payload.timestamp'],
        // Ignore these paths in the state.
        ignoredPaths: ['items.dates', 'items.html'],
      },
    }),
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<ReturnType, RootState, unknown, Action<string>>;
