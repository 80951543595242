/** @format */

import AxiosInstance from './index';
import CONFIG from '../config/config';
import { TASKS_CATEGORY_MEDICATION_TASKS, TASKS_TYPE_MEDICATION_CHANGE } from '../constants/workspace-tasks';
import { PatientPrescriptionsLogsType, PatientPrescriptionsReportType } from '../types/PrescriptionsGenerator.types';

export const fetchPatientTitrationsPlan = async (patientSourceId: string) => {
  const res = await AxiosInstance({
    method: 'GET',
    baseURL: CONFIG.patientApiUrl,
    url: `/patients/${patientSourceId}/care-plan`,
  });
  return res.data || {};
};

export const fetchPatientPrescriptionLogs = async (patientSouceId: string, limit: number, offset: number): Promise<{ count: number; prescriptionsLogs: PatientPrescriptionsLogsType[] }> => {
  const res = await AxiosInstance({
    method: 'GET',
    baseURL: CONFIG.patientApiUrl,
    url: `/patients/${patientSouceId}/prescriptions/logs`,
    params: {
      limit,
      offset,
    },
  });
  return res.data || {};
};

export const fetchPatientAlgoSuggestions = async (sourceId: string) => {
  const res = await AxiosInstance({
    method: 'GET',
    baseURL: CONFIG.careWebAppApiUrl,
    url: `/patients/${sourceId}/tasks`,
    params: {
      category: TASKS_CATEGORY_MEDICATION_TASKS,
      type: TASKS_TYPE_MEDICATION_CHANGE,
    },
  });

  return res.data || {};
};

export const forceGeneratePatientAlgoSuggestions = async (sourceId: string, currentDate: string | Date, lastTitrationDate: string | Date) => {
  const res = await AxiosInstance({
    method: 'POST',
    baseURL: CONFIG.patientApiUrl,
    url: `/patients/${sourceId}/tasks/medical-change/force`,
    params: {
      lastTitrationDate,
      currentDate,
    },
  });

  return res.data || {};
};

export const forceTriggerOmronDataSync = async (sourceId: string, currentDate: string | Date) => {
  const res = await AxiosInstance({
    baseURL: CONFIG.careWebAppApiUrl,
    url: `/patients/${sourceId}/omron/sync`,
    params: {
      startDate: new Date(currentDate).getTime(),
    },
  });

  return res.data || {};
};

export const forceTriggerFitbitSync = async (sourceId: string, currentDate: string | Date) => {
  const res = await AxiosInstance({
    baseURL: CONFIG.careWebAppApiUrl,
    url: `/patients/${sourceId}/fitbit/sync`,
    params: {
      startDate: new Date(currentDate),
    },
  });

  return res.data || {};
};

export const updatePatientPrescriptions = async (patientSourceId: string, payload: PatientPrescriptionsReportType) => {
  const res = await AxiosInstance({
    method: 'PUT',
    baseURL: CONFIG.patientApiUrl,
    url: `/patients/${patientSourceId}/prescriptions`,
    data: payload,
  });

  return res.data || {};
};

export const getPatientPrescriptionReviewList = async (patientSourceId: string, status: string[] = [], limit = 30, offset = 0) => {
  const res = await AxiosInstance({
    method: 'GET',
    baseURL: CONFIG.patientApiUrl,
    url: `/patients/${patientSourceId}/prescriptions/reviews/list`,
    params: {
      status,
      limit,
      offset,
    },
  });

  return res.data || {};
};

export const createPatientPrescriptionReviewDraft = async (patientSourceId: string, prescriptions: PatientPrescriptionsReportType) => {
  const res = await AxiosInstance({
    method: 'Post',
    baseURL: CONFIG.patientApiUrl,
    url: `/patients/${patientSourceId}/prescriptions/reviews`,
    data: {
      prescriptions,
      status: 'DRAFTED',
    },
  });

  return res.data || {};
};

export const updatePatientPrescriptionReviewDraft = async (patientSourceId: string, reviewId: string, prescriptions: PatientPrescriptionsReportType) => {
  const res = await AxiosInstance({
    method: 'PATCH',
    baseURL: CONFIG.patientApiUrl,
    url: `/patients/${patientSourceId}/prescriptions/reviews/${reviewId}`,
    data: {
      prescriptions,
      status: 'DRAFTED',
    },
  });

  return res.data || {};
};

export const createPatientPrescriptionReviewSubmitApproval = async (patientSourceId: string, prescriptions: PatientPrescriptionsReportType) => {
  const res = await AxiosInstance({
    method: 'Post',
    baseURL: CONFIG.patientApiUrl,
    url: `/patients/${patientSourceId}/prescriptions/reviews`,
    data: {
      prescriptions,
      status: 'SUBMITTED_APPROVAL',
    },
  });

  return res.data || {};
};

export const updatePatientPrescriptionReviewSubmitApproval = async (patientSourceId: string, reviewId: string, prescriptions: PatientPrescriptionsReportType) => {
  const res = await AxiosInstance({
    method: 'PATCH',
    baseURL: CONFIG.patientApiUrl,
    url: `/patients/${patientSourceId}/prescriptions/reviews/${reviewId}`,
    data: {
      prescriptions,
      status: 'SUBMITTED_APPROVAL',
    },
  });

  return res.data || {};
};

export const deletePatientPrescriptionReview = async (patientSourceId: string, reviewId: string) => {
  const res = await AxiosInstance({
    method: 'DELETE',
    baseURL: CONFIG.patientApiUrl,
    url: `/patients/${patientSourceId}/prescriptions/reviews/${reviewId}`,
  });

  return res.data || {};
};

export const rejectPatientPrescriptionReview = async (patientSourceId: string, reviewId: string, prescriptions: PatientPrescriptionsReportType, notes: string) => {
  const res = await AxiosInstance({
    method: 'Patch',
    baseURL: CONFIG.patientApiUrl,
    url: `/patients/${patientSourceId}/prescriptions/reviews/${reviewId}`,
    data: {
      notes,
      prescriptions,
      status: 'REJECTED',
    },
  });

  return res.data || {};
};
