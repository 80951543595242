/** @format */

import React, { FC } from 'react';

interface HrIconProps {
  width?: number | string;
  height?: number | string;
  fill?: string;
}

const HrIcon: FC<HrIconProps> = ({ width, height, fill }) => {
  return (
    <svg width={width} height={height} viewBox='0 0 17 16' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M1.56714 9.56258C2.87082 11.5176 5.07764 13.6755 8.49995 15.9376C11.9223 13.6755 14.1291 11.5176 15.4328 9.56258H12.7499C12.6439 9.56255 12.5402 9.53076 12.4523 9.4713C12.3645 9.41184 12.2964 9.32743 12.2569 9.22895L10.7089 5.35933L9.01101 11.3019C8.98065 11.4085 8.91773 11.5029 8.83105 11.5719C8.74436 11.641 8.63826 11.6812 8.52759 11.6869C8.41693 11.6926 8.30724 11.6636 8.21388 11.6039C8.12052 11.5442 8.04817 11.4568 8.00695 11.354L6.25701 6.97858L4.69195 9.3267C4.64336 9.39934 4.5776 9.45887 4.5005 9.50002C4.4234 9.54116 4.33734 9.56265 4.24995 9.56258H1.56714ZM0.933887 8.50008C-2.57768 1.78508 4.68557 -2.12492 8.31295 1.21452C8.3767 1.27295 8.43939 1.33352 8.49995 1.3962C8.5599 1.33357 8.62228 1.27332 8.68695 1.21558C12.3143 -2.12492 19.5776 1.78508 16.0649 8.50008H13.108L11.1169 3.5212C11.0757 3.41834 11.0033 3.33094 10.91 3.27124C10.8166 3.21154 10.7069 3.18252 10.5962 3.18826C10.4856 3.194 10.3795 3.2342 10.2928 3.30324C10.2061 3.37227 10.1432 3.46669 10.1128 3.57327L8.41601 9.51583L6.86795 5.6462C6.8319 5.55644 6.77202 5.47822 6.69477 5.41999C6.61752 5.36175 6.52584 5.32572 6.42962 5.31577C6.3334 5.30582 6.23628 5.32233 6.14876 5.36352C6.06123 5.40472 5.98661 5.46903 5.93295 5.54952L3.9652 8.50008H0.934949H0.933887Z'
        fill={fill}
      />
    </svg>
  );
};

HrIcon.defaultProps = {
  width: 17,
  height: 16,
  fill: '#33425B',
};

export default HrIcon;
