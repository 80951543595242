/** @format */

import React from 'react';

// Component props.
interface BmiEquationWithKgsProps {}

const BmiEquationWithKgsComponent: React.FunctionComponent<BmiEquationWithKgsProps> = () => {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' width='250px' height='50px' viewBox='0 -1370 17498.6 2413.9' xmlnsXlink='http://www.w3.org/1999/xlink'>
      <defs>
        <path
          id='MJX-44-TEX-I-42'
          d='M231 637Q204 637 199 638T194 649Q194 676 205 682Q206 683 335 683Q594 683 608 681Q671 671 713 636T756 544Q756 480 698 429T565 360L555 357Q619 348 660 311T702 219Q702 146 630 78T453 1Q446 0 242 0Q42 0 39 2Q35 5 35 10Q35 17 37 24Q42 43 47 45Q51 46 62 46H68Q95 46 128 49Q142 52 147 61Q150 65 219 339T288 628Q288 635 231 637ZM649 544Q649 574 634 600T585 634Q578 636 493 637Q473 637 451 637T416 636H403Q388 635 384 626Q382 622 352 506Q352 503 351 500L320 374H401Q482 374 494 376Q554 386 601 434T649 544ZM595 229Q595 273 572 302T512 336Q506 337 429 337Q311 337 310 336Q310 334 293 263T258 122L240 52Q240 48 252 48T333 46Q422 46 429 47Q491 54 543 105T595 229Z'></path>
        <path
          id='MJX-44-TEX-I-4D'
          d='M289 629Q289 635 232 637Q208 637 201 638T194 648Q194 649 196 659Q197 662 198 666T199 671T201 676T203 679T207 681T212 683T220 683T232 684Q238 684 262 684T307 683Q386 683 398 683T414 678Q415 674 451 396L487 117L510 154Q534 190 574 254T662 394Q837 673 839 675Q840 676 842 678T846 681L852 683H948Q965 683 988 683T1017 684Q1051 684 1051 673Q1051 668 1048 656T1045 643Q1041 637 1008 637Q968 636 957 634T939 623Q936 618 867 340T797 59Q797 55 798 54T805 50T822 48T855 46H886Q892 37 892 35Q892 19 885 5Q880 0 869 0Q864 0 828 1T736 2Q675 2 644 2T609 1Q592 1 592 11Q592 13 594 25Q598 41 602 43T625 46Q652 46 685 49Q699 52 704 61Q706 65 742 207T813 490T848 631L654 322Q458 10 453 5Q451 4 449 3Q444 0 433 0Q418 0 415 7Q413 11 374 317L335 624L267 354Q200 88 200 79Q206 46 272 46H282Q288 41 289 37T286 19Q282 3 278 1Q274 0 267 0Q265 0 255 0T221 1T157 2Q127 2 95 1T58 0Q43 0 39 2T35 11Q35 13 38 25T43 40Q45 46 65 46Q135 46 154 86Q158 92 223 354T289 629Z'></path>
        <path
          id='MJX-44-TEX-I-49'
          d='M43 1Q26 1 26 10Q26 12 29 24Q34 43 39 45Q42 46 54 46H60Q120 46 136 53Q137 53 138 54Q143 56 149 77T198 273Q210 318 216 344Q286 624 286 626Q284 630 284 631Q274 637 213 637H193Q184 643 189 662Q193 677 195 680T209 683H213Q285 681 359 681Q481 681 487 683H497Q504 676 504 672T501 655T494 639Q491 637 471 637Q440 637 407 634Q393 631 388 623Q381 609 337 432Q326 385 315 341Q245 65 245 59Q245 52 255 50T307 46H339Q345 38 345 37T342 19Q338 6 332 0H316Q279 2 179 2Q143 2 113 2T65 2T43 1Z'></path>
        <path
          id='MJX-44-TEX-N-3D'
          d='M56 347Q56 360 70 367H707Q722 359 722 347Q722 336 708 328L390 327H72Q56 332 56 347ZM56 153Q56 168 72 173H708Q722 163 722 153Q722 140 707 133H70Q56 140 56 153Z'></path>
        <path
          id='MJX-44-TEX-N-77'
          d='M90 368Q84 378 76 380T40 385H18V431H24L43 430Q62 430 84 429T116 428Q206 428 221 431H229V385H215Q177 383 177 368Q177 367 221 239L265 113L339 328L333 345Q323 374 316 379Q308 384 278 385H258V431H264Q270 428 348 428Q439 428 454 431H461V385H452Q404 385 404 369Q404 366 418 324T449 234T481 143L496 100L537 219Q579 341 579 347Q579 363 564 373T530 385H522V431H529Q541 428 624 428Q692 428 698 431H703V385H697Q696 385 691 385T682 384Q635 377 619 334L559 161Q546 124 528 71Q508 12 503 1T487 -11H479Q460 -11 456 -4Q455 -3 407 133L361 267Q359 263 266 -4Q261 -11 243 -11H238Q225 -11 220 -3L90 368Z'></path>
        <path
          id='MJX-44-TEX-N-65'
          d='M28 218Q28 273 48 318T98 391T163 433T229 448Q282 448 320 430T378 380T406 316T415 245Q415 238 408 231H126V216Q126 68 226 36Q246 30 270 30Q312 30 342 62Q359 79 369 104L379 128Q382 131 395 131H398Q415 131 415 121Q415 117 412 108Q393 53 349 21T250 -11Q155 -11 92 58T28 218ZM333 275Q322 403 238 411H236Q228 411 220 410T195 402T166 381T143 340T127 274V267H333V275Z'></path>
        <path
          id='MJX-44-TEX-N-69'
          d='M69 609Q69 637 87 653T131 669Q154 667 171 652T188 609Q188 579 171 564T129 549Q104 549 87 564T69 609ZM247 0Q232 3 143 3Q132 3 106 3T56 1L34 0H26V46H42Q70 46 91 49Q100 53 102 60T104 102V205V293Q104 345 102 359T88 378Q74 385 41 385H30V408Q30 431 32 431L42 432Q52 433 70 434T106 436Q123 437 142 438T171 441T182 442H185V62Q190 52 197 50T232 46H255V0H247Z'></path>
        <path
          id='MJX-44-TEX-N-67'
          d='M329 409Q373 453 429 453Q459 453 472 434T485 396Q485 382 476 371T449 360Q416 360 412 390Q410 404 415 411Q415 412 416 414V415Q388 412 363 393Q355 388 355 386Q355 385 359 381T368 369T379 351T388 325T392 292Q392 230 343 187T222 143Q172 143 123 171Q112 153 112 133Q112 98 138 81Q147 75 155 75T227 73Q311 72 335 67Q396 58 431 26Q470 -13 470 -72Q470 -139 392 -175Q332 -206 250 -206Q167 -206 107 -175Q29 -140 29 -75Q29 -39 50 -15T92 18L103 24Q67 55 67 108Q67 155 96 193Q52 237 52 292Q52 355 102 398T223 442Q274 442 318 416L329 409ZM299 343Q294 371 273 387T221 404Q192 404 171 388T145 343Q142 326 142 292Q142 248 149 227T179 192Q196 182 222 182Q244 182 260 189T283 207T294 227T299 242Q302 258 302 292T299 343ZM403 -75Q403 -50 389 -34T348 -11T299 -2T245 0H218Q151 0 138 -6Q118 -15 107 -34T95 -74Q95 -84 101 -97T122 -127T170 -155T250 -167Q319 -167 361 -139T403 -75Z'></path>
        <path
          id='MJX-44-TEX-N-68'
          d='M41 46H55Q94 46 102 60V68Q102 77 102 91T102 124T102 167T103 217T103 272T103 329Q103 366 103 407T103 482T102 542T102 586T102 603Q99 622 88 628T43 637H25V660Q25 683 27 683L37 684Q47 685 66 686T103 688Q120 689 140 690T170 693T181 694H184V367Q244 442 328 442Q451 442 463 329Q464 322 464 190V104Q464 66 466 59T477 49Q498 46 526 46H542V0H534L510 1Q487 2 460 2T422 3Q319 3 310 0H302V46H318Q379 46 379 62Q380 64 380 200Q379 335 378 343Q372 371 358 385T334 402T308 404Q263 404 229 370Q202 343 195 315T187 232V168V108Q187 78 188 68T191 55T200 49Q221 46 249 46H265V0H257L234 1Q210 2 183 2T145 3Q42 3 33 0H25V46H41Z'></path>
        <path
          id='MJX-44-TEX-N-74'
          d='M27 422Q80 426 109 478T141 600V615H181V431H316V385H181V241Q182 116 182 100T189 68Q203 29 238 29Q282 29 292 100Q293 108 293 146V181H333V146V134Q333 57 291 17Q264 -10 221 -10Q187 -10 162 2T124 33T105 68T98 100Q97 107 97 248V385H18V422H27Z'></path>
        <path id='MJX-44-TEX-N-20' d=''></path>
        <path
          id='MJX-44-TEX-N-6E'
          d='M41 46H55Q94 46 102 60V68Q102 77 102 91T102 122T103 161T103 203Q103 234 103 269T102 328V351Q99 370 88 376T43 385H25V408Q25 431 27 431L37 432Q47 433 65 434T102 436Q119 437 138 438T167 441T178 442H181V402Q181 364 182 364T187 369T199 384T218 402T247 421T285 437Q305 442 336 442Q450 438 463 329Q464 322 464 190V104Q464 66 466 59T477 49Q498 46 526 46H542V0H534L510 1Q487 2 460 2T422 3Q319 3 310 0H302V46H318Q379 46 379 62Q380 64 380 200Q379 335 378 343Q372 371 358 385T334 402T308 404Q263 404 229 370Q202 343 195 315T187 232V168V108Q187 78 188 68T191 55T200 49Q221 46 249 46H265V0H257L234 1Q210 2 183 2T145 3Q42 3 33 0H25V46H41Z'></path>
        <path
          id='MJX-44-TEX-N-6B'
          d='M36 46H50Q89 46 97 60V68Q97 77 97 91T97 124T98 167T98 217T98 272T98 329Q98 366 98 407T98 482T98 542T97 586T97 603Q94 622 83 628T38 637H20V660Q20 683 22 683L32 684Q42 685 61 686T98 688Q115 689 135 690T165 693T176 694H179V463L180 233L240 287Q300 341 304 347Q310 356 310 364Q310 383 289 385H284V431H293Q308 428 412 428Q475 428 484 431H489V385H476Q407 380 360 341Q286 278 286 274Q286 273 349 181T420 79Q434 60 451 53T500 46H511V0H505Q496 3 418 3Q322 3 307 0H299V46H306Q330 48 330 65Q330 72 326 79Q323 84 276 153T228 222L176 176V120V84Q176 65 178 59T189 49Q210 46 238 46H254V0H246Q231 3 137 3T28 0H20V46H36Z'></path>
        <path
          id='MJX-44-TEX-N-28'
          d='M94 250Q94 319 104 381T127 488T164 576T202 643T244 695T277 729T302 750H315H319Q333 750 333 741Q333 738 316 720T275 667T226 581T184 443T167 250T184 58T225 -81T274 -167T316 -220T333 -241Q333 -250 318 -250H315H302L274 -226Q180 -141 137 -14T94 250Z'></path>
        <path
          id='MJX-44-TEX-N-63'
          d='M370 305T349 305T313 320T297 358Q297 381 312 396Q317 401 317 402T307 404Q281 408 258 408Q209 408 178 376Q131 329 131 219Q131 137 162 90Q203 29 272 29Q313 29 338 55T374 117Q376 125 379 127T395 129H409Q415 123 415 120Q415 116 411 104T395 71T366 33T318 2T249 -11Q163 -11 99 53T34 214Q34 318 99 383T250 448T370 421T404 357Q404 334 387 320Z'></path>
        <path
          id='MJX-44-TEX-N-73'
          d='M295 316Q295 356 268 385T190 414Q154 414 128 401Q98 382 98 349Q97 344 98 336T114 312T157 287Q175 282 201 278T245 269T277 256Q294 248 310 236T342 195T359 133Q359 71 321 31T198 -10H190Q138 -10 94 26L86 19L77 10Q71 4 65 -1L54 -11H46H42Q39 -11 33 -5V74V132Q33 153 35 157T45 162H54Q66 162 70 158T75 146T82 119T101 77Q136 26 198 26Q295 26 295 104Q295 133 277 151Q257 175 194 187T111 210Q75 227 54 256T33 318Q33 357 50 384T93 424T143 442T187 447H198Q238 447 268 432L283 424L292 431Q302 440 314 448H322H326Q329 448 335 442V310L329 304H301Q295 310 295 316Z'></path>
        <path
          id='MJX-44-TEX-N-29'
          d='M60 749L64 750Q69 750 74 750H86L114 726Q208 641 251 514T294 250Q294 182 284 119T261 12T224 -76T186 -143T145 -194T113 -227T90 -246Q87 -249 86 -250H74Q66 -250 63 -250T58 -247T55 -238Q56 -237 66 -225Q221 -64 221 250T66 725Q56 737 55 738Q55 746 60 749Z'></path>
        <path
          id='MJX-44-TEX-N-32'
          d='M109 429Q82 429 66 447T50 491Q50 562 103 614T235 666Q326 666 387 610T449 465Q449 422 429 383T381 315T301 241Q265 210 201 149L142 93L218 92Q375 92 385 97Q392 99 409 186V189H449V186Q448 183 436 95T421 3V0H50V19V31Q50 38 56 46T86 81Q115 113 136 137Q145 147 170 174T204 211T233 244T261 278T284 308T305 340T320 369T333 401T340 431T343 464Q343 527 309 573T212 619Q179 619 154 602T119 569T109 550Q109 549 114 549Q132 549 151 535T170 489Q170 464 154 447T109 429Z'></path>
        <path
          id='MJX-44-TEX-N-D7'
          d='M630 29Q630 9 609 9Q604 9 587 25T493 118L389 222L284 117Q178 13 175 11Q171 9 168 9Q160 9 154 15T147 29Q147 36 161 51T255 146L359 250L255 354Q174 435 161 449T147 471Q147 480 153 485T168 490Q173 490 175 489Q178 487 284 383L389 278L493 382Q570 459 587 475T609 491Q630 491 630 471Q630 464 620 453T522 355L418 250L522 145Q606 61 618 48T630 29Z'></path>
        <path
          id='MJX-44-TEX-N-31'
          d='M213 578L200 573Q186 568 160 563T102 556H83V602H102Q149 604 189 617T245 641T273 663Q275 666 285 666Q294 666 302 660V361L303 61Q310 54 315 52T339 48T401 46H427V0H416Q395 3 257 3Q121 3 100 0H88V46H114Q136 46 152 46T177 47T193 50T201 52T207 57T213 61V578Z'></path>
        <path
          id='MJX-44-TEX-N-35'
          d='M164 157Q164 133 148 117T109 101H102Q148 22 224 22Q294 22 326 82Q345 115 345 210Q345 313 318 349Q292 382 260 382H254Q176 382 136 314Q132 307 129 306T114 304Q97 304 95 310Q93 314 93 485V614Q93 664 98 664Q100 666 102 666Q103 666 123 658T178 642T253 634Q324 634 389 662Q397 666 402 666Q410 666 410 648V635Q328 538 205 538Q174 538 149 544L139 546V374Q158 388 169 396T205 412T256 420Q337 420 393 355T449 201Q449 109 385 44T229 -22Q148 -22 99 32T50 154Q50 178 61 192T84 210T107 214Q132 214 148 197T164 157Z'></path>
        <path
          id='MJX-44-TEX-N-34'
          d='M462 0Q444 3 333 3Q217 3 199 0H190V46H221Q241 46 248 46T265 48T279 53T286 61Q287 63 287 115V165H28V211L179 442Q332 674 334 675Q336 677 355 677H373L379 671V211H471V165H379V114Q379 73 379 66T385 54Q393 47 442 46H471V0H462ZM293 211V545L74 212L183 211H293Z'></path>
        <path
          id='MJX-44-TEX-N-39'
          d='M352 287Q304 211 232 211Q154 211 104 270T44 396Q42 412 42 436V444Q42 537 111 606Q171 666 243 666Q245 666 249 666T257 665H261Q273 665 286 663T323 651T370 619T413 560Q456 472 456 334Q456 194 396 97Q361 41 312 10T208 -22Q147 -22 108 7T68 93T121 149Q143 149 158 135T173 96Q173 78 164 65T148 49T135 44L131 43Q131 41 138 37T164 27T206 22H212Q272 22 313 86Q352 142 352 280V287ZM244 248Q292 248 321 297T351 430Q351 508 343 542Q341 552 337 562T323 588T293 615T246 625Q208 625 181 598Q160 576 154 546T147 441Q147 358 152 329T172 282Q197 248 244 248Z'></path>
        <path id='MJX-44-TEX-N-2E' d='M78 60Q78 84 95 102T138 120Q162 120 180 104T199 61Q199 36 182 18T139 0T96 17T78 60Z'></path>
        <path
          id='MJX-44-TEX-N-36'
          d='M42 313Q42 476 123 571T303 666Q372 666 402 630T432 550Q432 525 418 510T379 495Q356 495 341 509T326 548Q326 592 373 601Q351 623 311 626Q240 626 194 566Q147 500 147 364L148 360Q153 366 156 373Q197 433 263 433H267Q313 433 348 414Q372 400 396 374T435 317Q456 268 456 210V192Q456 169 451 149Q440 90 387 34T253 -22Q225 -22 199 -14T143 16T92 75T56 172T42 313ZM257 397Q227 397 205 380T171 335T154 278T148 216Q148 133 160 97T198 39Q222 21 251 21Q302 21 329 59Q342 77 347 104T352 209Q352 289 347 316T329 361Q302 397 257 397Z'></path>
      </defs>
      <g stroke='currentColor' fill='currentColor' stroke-width='0' transform='matrix(1 0 0 -1 0 0)'>
        <g data-mml-node='math'>
          <g data-mml-node='mi'>
            <use xlinkHref='#MJX-44-TEX-I-42'></use>
          </g>
          <g data-mml-node='mi' transform='translate(759, 0)'>
            <use xlinkHref='#MJX-44-TEX-I-4D'></use>
          </g>
          <g data-mml-node='mi' transform='translate(1810, 0)'>
            <use xlinkHref='#MJX-44-TEX-I-49'></use>
          </g>
          <g data-mml-node='mo' transform='translate(2591.8, 0)'>
            <use xlinkHref='#MJX-44-TEX-N-3D'></use>
          </g>
          <g data-mml-node='mfrac' transform='translate(3647.6, 0)'>
            <g data-mml-node='mtext' transform='translate(1549.8, 676)'>
              <use xlinkHref='#MJX-44-TEX-N-77'></use>
              <use xlinkHref='#MJX-44-TEX-N-65' transform='translate(722, 0)'></use>
              <use xlinkHref='#MJX-44-TEX-N-69' transform='translate(1166, 0)'></use>
              <use xlinkHref='#MJX-44-TEX-N-67' transform='translate(1444, 0)'></use>
              <use xlinkHref='#MJX-44-TEX-N-68' transform='translate(1944, 0)'></use>
              <use xlinkHref='#MJX-44-TEX-N-74' transform='translate(2500, 0)'></use>
              <use xlinkHref='#MJX-44-TEX-N-20' transform='translate(2889, 0)'></use>
              <use xlinkHref='#MJX-44-TEX-N-69' transform='translate(3139, 0)'></use>
              <use xlinkHref='#MJX-44-TEX-N-6E' transform='translate(3417, 0)'></use>
              <use xlinkHref='#MJX-44-TEX-N-20' transform='translate(3973, 0)'></use>
              <use xlinkHref='#MJX-44-TEX-N-6B' transform='translate(4223, 0)'></use>
              <use xlinkHref='#MJX-44-TEX-N-67' transform='translate(4751, 0)'></use>
            </g>
            <g data-mml-node='mrow' transform='translate(220, -793.9)'>
              <g data-mml-node='mo'>
                <use xlinkHref='#MJX-44-TEX-N-28'></use>
              </g>
              <g data-mml-node='mtext' transform='translate(389, 0)'>
                <use xlinkHref='#MJX-44-TEX-N-68'></use>
                <use xlinkHref='#MJX-44-TEX-N-65' transform='translate(556, 0)'></use>
                <use xlinkHref='#MJX-44-TEX-N-69' transform='translate(1000, 0)'></use>
                <use xlinkHref='#MJX-44-TEX-N-67' transform='translate(1278, 0)'></use>
                <use xlinkHref='#MJX-44-TEX-N-68' transform='translate(1778, 0)'></use>
                <use xlinkHref='#MJX-44-TEX-N-74' transform='translate(2334, 0)'></use>
                <use xlinkHref='#MJX-44-TEX-N-20' transform='translate(2723, 0)'></use>
                <use xlinkHref='#MJX-44-TEX-N-69' transform='translate(2973, 0)'></use>
                <use xlinkHref='#MJX-44-TEX-N-6E' transform='translate(3251, 0)'></use>
                <use xlinkHref='#MJX-44-TEX-N-20' transform='translate(3807, 0)'></use>
                <use xlinkHref='#MJX-44-TEX-N-69' transform='translate(4057, 0)'></use>
                <use xlinkHref='#MJX-44-TEX-N-6E' transform='translate(4335, 0)'></use>
                <use xlinkHref='#MJX-44-TEX-N-63' transform='translate(4891, 0)'></use>
                <use xlinkHref='#MJX-44-TEX-N-68' transform='translate(5335, 0)'></use>
                <use xlinkHref='#MJX-44-TEX-N-65' transform='translate(5891, 0)'></use>
                <use xlinkHref='#MJX-44-TEX-N-73' transform='translate(6335, 0)'></use>
              </g>
              <g data-mml-node='msup' transform='translate(7118, 0)'>
                <g data-mml-node='mo'>
                  <use xlinkHref='#MJX-44-TEX-N-29'></use>
                </g>
                <g data-mml-node='mn' transform='translate(389, 363) scale(0.707)'>
                  <use xlinkHref='#MJX-44-TEX-N-32'></use>
                </g>
              </g>
            </g>
            <rect width='8110.6' height='60' x='120' y='220'></rect>
          </g>
          <g data-mml-node='mo' transform='translate(12220.3, 0)'>
            <use xlinkHref='#MJX-44-TEX-N-D7'></use>
          </g>
          <g data-mml-node='mn' transform='translate(13220.6, 0)'>
            <use xlinkHref='#MJX-44-TEX-N-31'></use>
            <use xlinkHref='#MJX-44-TEX-N-35' transform='translate(500, 0)'></use>
            <use xlinkHref='#MJX-44-TEX-N-34' transform='translate(1000, 0)'></use>
            <use xlinkHref='#MJX-44-TEX-N-39' transform='translate(1500, 0)'></use>
            <use xlinkHref='#MJX-44-TEX-N-2E' transform='translate(2000, 0)'></use>
            <use xlinkHref='#MJX-44-TEX-N-39' transform='translate(2278, 0)'></use>
            <use xlinkHref='#MJX-44-TEX-N-39' transform='translate(2778, 0)'></use>
            <use xlinkHref='#MJX-44-TEX-N-36' transform='translate(3278, 0)'></use>
            <use xlinkHref='#MJX-44-TEX-N-39' transform='translate(3778, 0)'></use>
          </g>
        </g>
      </g>
    </svg>
  );
};

export const BmiEquationWithKgs = BmiEquationWithKgsComponent;
