/** @format */

import React from 'react';

const WeightIcon = () => {
  return (
    <svg width='18' height='17' viewBox='0 0 18 17' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M15.75 2.75H14.8366C15.4139 3.74422 15.75 4.89488 15.75 6.125C15.75 9.84699 12.722 12.875 9 12.875C5.27801 12.875 2.25 9.84699 2.25 6.125C2.25 4.89488 2.58609 3.74422 3.16336 2.75H2.25C1.00934 2.75 0 3.75934 0 5V16.25C0 17.4907 1.00934 18.5 2.25 18.5H15.75C16.9907 18.5 18 17.4907 18 16.25V5C18 3.75934 16.9907 2.75 15.75 2.75ZM9 11.75C12.1068 11.75 14.625 9.23176 14.625 6.125C14.625 3.01824 12.1068 0.5 9 0.5C5.89324 0.5 3.375 3.01824 3.375 6.125C3.375 9.23176 5.89324 11.75 9 11.75ZM8.98945 6.40836L10.17 3.65352C10.293 3.36629 10.6249 3.23445 10.9093 3.35785C11.1948 3.4802 11.327 3.81102 11.205 4.09613L10.0213 6.85766C10.2561 7.10938 10.4062 7.4416 10.4062 7.8125C10.4062 8.5891 9.7766 9.21875 9 9.21875C8.2234 9.21875 7.59375 8.5891 7.59375 7.8125C7.59375 7.03977 8.21813 6.41434 8.98945 6.40836Z'
        fill='#5C6A90'
      />
    </svg>
  );
};

export default WeightIcon;
