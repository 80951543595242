/** @format */

export const NotesUndoIcon = () => {
  return (
    <svg width='20' height='20' viewBox='0 0 15 14' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path d='M0.666748 1.66675V5.66675H4.66675' stroke='#404681' strokeWidth='1.2' strokeLinecap='round' strokeLinejoin='round' />
      <path
        d='M2.34008 8.99989C2.77234 10.2268 3.59164 11.28 4.67452 12.0008C5.7574 12.7216 7.0452 13.0709 8.34389 12.9962C9.64258 12.9215 10.8818 12.4267 11.8748 11.5864C12.8679 10.7461 13.5609 9.60587 13.8496 8.33746C14.1382 7.06905 14.0068 5.74119 13.4752 4.55395C12.9435 3.36671 12.0404 2.38441 10.902 1.75506C9.76351 1.12571 8.45135 0.883393 7.1632 1.06463C5.87505 1.24586 4.68069 1.84083 3.76008 2.75988L0.666748 5.66655'
        stroke='#404681'
        strokeWidth='1.2'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  );
};

export const NotesRedoIcon = () => {
  return (
    <svg width='20' height='20' viewBox='0 0 15 14' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path d='M14.3333 1.66675V5.66675H10.3333' stroke='#404681' strokeWidth='1.2' strokeLinecap='round' strokeLinejoin='round' />
      <path
        d='M12.66 8.99993C12.2266 10.2265 11.4064 11.2791 10.3228 11.9989C9.23918 12.7188 7.95097 13.067 6.65228 12.991C5.35359 12.915 4.11477 12.419 3.1225 11.5777C2.13024 10.7364 1.43828 9.59541 1.15091 8.32663C0.863539 7.05786 0.996319 5.73005 1.52924 4.54331C2.06217 3.35656 2.96636 2.37517 4.10557 1.74702C5.24478 1.11887 6.55729 0.877995 7.84531 1.06069C9.13333 1.24339 10.3271 1.83975 11.2467 2.75993L14.3333 5.66659'
        stroke='#404681'
        strokeWidth='1.2'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  );
};

export const NotesPrintIcon = () => {
  return (
    <svg width='20' height='20' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path d='M4 5.99992V1.33325H12V5.99992' stroke='#404681' strokeWidth='1.2' strokeLinecap='round' strokeLinejoin='round' />
      <path
        d='M3.99992 12H2.66659C2.31296 12 1.97382 11.8595 1.72378 11.6095C1.47373 11.3594 1.33325 11.0203 1.33325 10.6667V7.33333C1.33325 6.97971 1.47373 6.64057 1.72378 6.39052C1.97382 6.14048 2.31296 6 2.66659 6H13.3333C13.6869 6 14.026 6.14048 14.2761 6.39052C14.5261 6.64057 14.6666 6.97971 14.6666 7.33333V10.6667C14.6666 11.0203 14.5261 11.3594 14.2761 11.6095C14.026 11.8595 13.6869 12 13.3333 12H11.9999'
        stroke='#404681'
        strokeWidth='1.2'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path d='M12 9.33325H4V14.6666H12V9.33325Z' stroke='#404681' strokeWidth='1.2' strokeLinecap='round' strokeLinejoin='round' />
    </svg>
  );
};

export const NotesEditIcon = () => {
  return (
    <svg width='20' height='20' viewBox='0 0 14 13' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path d='M7 12.3333H13' stroke='#404681' strokeWidth='1.2' strokeLinecap='round' strokeLinejoin='round' />
      <path
        d='M10 1.3334C10.2652 1.06819 10.6249 0.919189 11 0.919189C11.1857 0.919189 11.3696 0.955769 11.5412 1.02684C11.7128 1.09791 11.8687 1.20208 12 1.3334C12.1313 1.46472 12.2355 1.62063 12.3066 1.79221C12.3776 1.96379 12.4142 2.14769 12.4142 2.3334C12.4142 2.51912 12.3776 2.70302 12.3066 2.8746C12.2355 3.04618 12.1313 3.20208 12 3.3334L3.66667 11.6667L1 12.3334L1.66667 9.66674L10 1.3334Z'
        stroke='#404681'
        strokeWidth='1.2'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  );
};

export const NotesDeleteIcon = () => {
  return (
    <svg width='20' height='20' viewBox='0 0 14 16' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path d='M1 4H2.33333H13' stroke='#404681' strokeWidth='1.2' strokeLinecap='round' strokeLinejoin='round' />
      <path
        d='M11.6666 3.99992V13.3333C11.6666 13.6869 11.5261 14.026 11.2761 14.2761C11.026 14.5261 10.6869 14.6666 10.3333 14.6666H3.66659C3.31296 14.6666 2.97382 14.5261 2.72378 14.2761C2.47373 14.026 2.33325 13.6869 2.33325 13.3333V3.99992M4.33325 3.99992V2.66659C4.33325 2.31296 4.47373 1.97382 4.72378 1.72378C4.97382 1.47373 5.31296 1.33325 5.66659 1.33325H8.33325C8.68687 1.33325 9.02601 1.47373 9.27606 1.72378C9.52611 1.97382 9.66658 2.31296 9.66658 2.66659V3.99992'
        stroke='#404681'
        strokeWidth='1.2'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path d='M5.66675 7.33325V11.3333' stroke='#404681' strokeWidth='1.2' strokeLinecap='round' strokeLinejoin='round' />
      <path d='M8.33325 7.33325V11.3333' stroke='#404681' strokeWidth='1.2' strokeLinecap='round' strokeLinejoin='round' />
    </svg>
  );
};

export const NotesEmojiIcon = () => {
  return (
    <svg width='22' height='22' viewBox='0 0 14 14' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M7.00033 12.8333C10.222 12.8333 12.8337 10.2216 12.8337 6.99996C12.8337 3.7783 10.222 1.16663 7.00033 1.16663C3.77866 1.16663 1.16699 3.7783 1.16699 6.99996C1.16699 10.2216 3.77866 12.8333 7.00033 12.8333Z'
        stroke='#404681'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path d='M4.66699 8.16663C4.66699 8.16663 5.54199 9.33329 7.00033 9.33329C8.45866 9.33329 9.33366 8.16663 9.33366 8.16663' stroke='#404681' strokeLinecap='round' strokeLinejoin='round' />
      <path d='M5.25 5.25H5.25559' stroke='#404681' strokeLinecap='round' strokeLinejoin='round' />
      <path d='M8.75 5.25H8.75559' stroke='#404681' strokeLinecap='round' strokeLinejoin='round' />
    </svg>
  );
};
