/** @format */

import { createSlice, PayloadAction } from '@reduxjs/toolkit';

// Types and interfaces.
import { RootState } from '../../app/store';

export interface AlertError {
  message: string;
}

export interface AlertInterface {
  id: string;
  value: string;
}

export interface AlertState {
  isLoading: boolean;
  error: AlertError;
  alerts: Array<AlertInterface> | null;
}

export const initialState: AlertState = {
  alerts: null,
  isLoading: false,
  error: { message: '' },
};

export const alertReducer = createSlice({
  name: 'alert',
  initialState,
  reducers: {
    setLoading: (state, { payload }: PayloadAction<boolean>) => {
      state.isLoading = payload;
    },

    setAlerts: (state, { payload }: PayloadAction<Array<AlertInterface>>) => {
      state.alerts = payload;
    },
  },
});

export const { setAlerts, setLoading } = alertReducer.actions;

export const alertSelector = (state: RootState) => state.alert;
