/** @format */

import React from 'react';
import { CustomObservations, MultiInputInterface, ObservationInterface } from '../common/custom-observations/CustomObservations';
import { COMPLAINTS_DURATION_OPTIONS } from '../../constants/PrescriptionGenerator';

interface ComplaintsInterface {
  handleChangeComplaints: (payload: ObservationInterface[]) => void;
  complaints: ObservationInterface[];

  contextStateName?: string;
  saveToContext?: (a: string, b: 'COMPLAINTS' | 'EXAMINATIONS' | 'DIAGNOSIS' | 'NOTES' | 'REASON_FOR_CHANGE' | 'PROCEDURES') => void;
}

const Complaints: React.FC<ComplaintsInterface> = ({ handleChangeComplaints, complaints, contextStateName, saveToContext }) => {
  const [since, setSince] = React.useState<string>('');

  const [duration, setDuration] = React.useState<string>('Days');

  const EXTENDED_OBSERVATIONS: MultiInputInterface[] = [
    { type: 'input', label: 'Since', value: since, onChange: (payload: string) => setSince(payload), inputType: 'number' },
    { type: 'select', label: '', value: duration, onChange: (payload: string) => setDuration(payload), options: COMPLAINTS_DURATION_OPTIONS },
  ];

  return (
    <CustomObservations
      observationActionTitle='Add Complaints'
      observationTitle='Complaints'
      options={[
        { label: 'Fever', value: 'FEVER' },
        { label: 'Headache', value: 'HEADACHE' },
        { label: 'Cough', value: 'COUGH' },
      ]}
      handleChangeObservations={handleChangeComplaints}
      inputs={EXTENDED_OBSERVATIONS}
      bufferText='since'
      existingObservations={complaints}
      contextStateName={contextStateName}
      saveToContext={saveToContext}
      showHoverEffectBorderOnInputField
    />
  );
};

export default Complaints;
