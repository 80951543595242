/** @format */

import React from 'react';
import { Descendant } from 'slate';

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
// Components.
import { Notes } from './Notes';
import { PatientNoteItem } from './PatientNoteItem';
import { ErrorBoundary } from 'react-error-boundary';
import { ComponentErrorBoundary } from '../../common/error/ComponentErrorBoundary';
import { ConfirmationDialog } from '../../common/confirmation-dialog/ConfirmationDialog';
import { PatientProfileContext } from '../../../pages/patient-profile/PatientProfileContext';

// Types.
import { AllSavedNotesType } from '../../../types/Notes';

import { NOTE_INITIAL_STATE } from '../../../constants/notes';

import './Notes.scss';

interface AllPatientNotesProps {
  allSavedNotes: AllSavedNotesType[];
  handleClickSaveGeneralNote: (payload: AllSavedNotesType | any) => void;
  handleClickDeleteNote: (payload: string) => void;
  inModal?: boolean;
  handleClickShowMoreNotes: () => void;
  noteCount: number;
  allSavedNotesIsLoading: boolean;
  disabled: boolean;
  handleClickEditPatientNote: (payload: AllSavedNotesType) => void;
}

export const AllPatientNotes: React.FunctionComponent<AllPatientNotesProps> = ({
  allSavedNotes,
  handleClickSaveGeneralNote,
  handleClickDeleteNote,
  handleClickShowMoreNotes,
  noteCount,
  disabled,
  handleClickEditPatientNote,
}) => {
  const [showDeleteNoteConfirmationModal, setShowDeleteNoteConfirmationModal] = React.useState<boolean>(false);
  const [noteId, setNoteId] = React.useState<string>('');

  const handleClickShowDeleteConfirmationModal = (id: string) => {
    setShowDeleteNoteConfirmationModal(true);
    setNoteId(id);
  };

  const handleClickHideDeleteConfirmationModal = () => {
    setShowDeleteNoteConfirmationModal(false);
    setNoteId('');
  };

  const deleteNote = () => {
    handleClickDeleteNote(noteId);
    setNoteId('');
    setShowDeleteNoteConfirmationModal(false);
  };

  if (allSavedNotes.length === 0) {
    return (
      <Box p={5} sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
        <Typography color='primary' variant='fontReg14' fontFamily='Lato' textAlign='center'>
          No data to display
        </Typography>
      </Box>
    );
  }

  return (
    <ErrorBoundary fallbackRender={ComponentErrorBoundary}>
      <Box>
        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end', margin: '15px 5px 10px 10px' }}>
          <Button size='small' disabled={noteCount === allSavedNotes.length} variant='outlined' color='primary' onClick={handleClickShowMoreNotes}>
            Show More ({noteCount - allSavedNotes.length})
          </Button>
        </Box>

        <Box>
          {allSavedNotes?.map((e) => {
            return (
              <PatientNoteItem
                handleClickEditPatientNote={handleClickEditPatientNote}
                note={e}
                handleClickShowDeleteConfirmationModal={() => handleClickShowDeleteConfirmationModal(e.noteId)}
                handleClickSaveGeneralNote={handleClickSaveGeneralNote}
              />
            );
          })}
        </Box>

        {showDeleteNoteConfirmationModal && (
          <ConfirmationDialog
            title='Are you sure you want to delete this note?'
            description='Once deleted this note cannot be recovered'
            isOpen={showDeleteNoteConfirmationModal}
            onClose={handleClickHideDeleteConfirmationModal}
            onClickConfirm={deleteNote}
            disabled={disabled}
          />
        )}
      </Box>
    </ErrorBoundary>
  );
};

interface AllPatientNotesDialogProps {
  disabled: boolean;
}

export const AllPatientNotesDialog: React.FunctionComponent<AllPatientNotesDialogProps> = ({ disabled }) => {
  const { handleClickSaveGeneralNote, allSavedNotes, handleClickDeleteNote, handleClickShowMoreNotes, noteCount, handleClickEditPatientNote, note, allSavedNotesIsLoading } =
    React.useContext(PatientProfileContext);

  const [editorState, setEditorState] = React.useState<Descendant[]>(NOTE_INITIAL_STATE);

  const handleClickCancelNotes = () => {
    handleClickEditPatientNote(null as any);
  };

  React.useEffect(() => {
    if (note) {
      setEditorState(note.notesPayload);
    }
  }, [note]);

  return (
    <Box>
      <Box sx={{ display: 'flex', border: '1px solid #b4c7d9', flex: 1 }}>
        <Box sx={{ minWidth: '450px', maxWidth: '470px' }}>
          <AllPatientNotes
            inModal={true}
            allSavedNotes={allSavedNotes}
            handleClickSaveGeneralNote={handleClickSaveGeneralNote}
            handleClickDeleteNote={handleClickDeleteNote}
            handleClickShowMoreNotes={handleClickShowMoreNotes}
            noteCount={noteCount}
            allSavedNotesIsLoading={allSavedNotesIsLoading}
            disabled={disabled}
            handleClickEditPatientNote={handleClickEditPatientNote}
          />
        </Box>

        <Box sx={{ borderLeft: '1px solid #b4c7d9', flex: 1, overflowX: 'hidden' }}>
          <Notes
            readonly={false}
            showToolbar={true}
            handleClickCancel={handleClickCancelNotes}
            handleClickSaveNote={handleClickSaveGeneralNote}
            updatedNote={note}
            editorState={editorState}
            setEditorState={setEditorState}
            isGeneralNote={true}
          />
        </Box>
      </Box>
    </Box>
  );
};
