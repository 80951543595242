/** @format */

import { Box, Typography } from '@mui/material';
import React from 'react';
import Alert from '@mui/material/Alert';

// Component props.
interface ComponentErrorBoundaryProps {
  error: any;
}

export const ComponentErrorBoundary: React.FunctionComponent<ComponentErrorBoundaryProps> = () => {
  return (
    <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', padding: '30px', margin: '20px auto' }}>
      <Alert severity='error' sx={{ maxHeight: '120px' }}>
        <Typography color='error' variant='fontBold18' sx={{ textAlign: 'center' }}>
          Something went wrong, please try again later.
        </Typography>
      </Alert>
    </Box>
  );
};

export default ComponentErrorBoundary;
