/** @format */

// Types
import { CustomTableColumnType } from '../../types/CustomTable.types';
import { UptitratePatientMedicaitonPayloadType } from '../../types/PatientTCPMedications.types';

// Icons
import { OrganizationMedicationType } from '../../types/SystemMedications.types';

interface ApprovalMedicationTableProps {
  appTheme: any;
  organizationMedications: OrganizationMedicationType[];
}

const getApprovalMedicationTable = (props: ApprovalMedicationTableProps) => {
  const { appTheme } = props;

  const medicationColumns: CustomTableColumnType[] = [
    {
      id: 'medication-group-and-name',
      label: '',
      hiddenHeader: true,
      cellAlignment: 'left',
      valign: 'top',
      minWidth: '5rem',
      maxWidth: '9rem',
      cellFormatter: (medication: UptitratePatientMedicaitonPayloadType, index: number) => {
        return (
          <>
            <div style={{ ...appTheme.typography?.fontBold14, color: appTheme.palette.text.primary, textAlign: 'left' }}>{medication?.brandName}</div>
            <div style={{ ...appTheme.typography?.fontReg14, color: appTheme.palette.text.primary, textAlign: 'left' }}>{medication?.medicationName}</div>
          </>
        );
      },
    },
    {
      id: 'medication-quantity-and-dosage',
      label: '',
      hiddenHeader: true,
      cellAlignment: 'left',
      valign: 'top',
      minWidth: '5rem',
      maxWidth: '9rem',
      cellFormatter: (medication: UptitratePatientMedicaitonPayloadType, index: number) => {
        return (
          <>
            <div style={{ ...appTheme.typography?.fontBold14, color: appTheme.palette.text.primary, textAlign: 'left' }}>
              {medication?.dosageQuantity} {medication?.dosageQuantity && 'dosage'}
            </div>
            <div style={{ ...appTheme.typography.fontBold14, color: appTheme.palette.text.primary, textAlign: 'left' }}>{medication?.frequencyRegime}</div>
          </>
        );
      },
    },
    {
      id: 'medication-duration',
      label: '',
      hiddenHeader: true,
      cellAlignment: 'left',
      valign: 'top',
      minWidth: '5rem',
      maxWidth: '8rem',
      cellFormatter: (medication: any, index: number) => {
        return (
          <>
            <div style={{ ...appTheme.typography.fontBold14, color: appTheme.palette.text.primary, textAlign: 'left' }}>{medication?.duration}</div>
            <div style={{ ...appTheme.typography.fontBold14, color: appTheme.palette.text.primary, textAlign: 'left' }}>{medication?.durationUnits}</div>
          </>
        );
      },
    },
  ];

  const medicationNotesColumns: CustomTableColumnType[] = [
    {
      id: 'medication-notes',
      label: '',
      hiddenHeader: true,
      cellAlignment: 'left',
      valign: 'top',
      minWidth: '10rem',
      maxWidth: '16rem',
      cellFormatter: (medication: any, index: number) => {
        return (
          <>
            <div style={{ ...appTheme.typography.fontBold14, color: appTheme.palette.text.primary, textAlign: 'left' }}>{medication?.notes}</div>
          </>
        );
      },
    },
  ];

  return { medicationColumns, medicationNotesColumns };
};

export default getApprovalMedicationTable;
