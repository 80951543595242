/** @format */

import * as React from 'react';
import { useQuery } from '@tanstack/react-query';

import Grid from '@mui/material/Grid';
import { useTheme } from '@mui/material/styles';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';

import { PatientListNewType } from '../../types/PatientListPage.types';

import PatientHealthOverviewModal from './PatientHealthOverviewModal';
import { VitalsGraphIcon } from '../../assets/VitalsGraphIcon';
import { fetchPatientAlertNotification } from '../../service/patientAlertNotification';
import { fetchPatientInfo } from '../../service/patient-info';

interface PatientNameIdCellProps {
  handleClickPatientId: () => void;
  data: PatientListNewType;

  showAlertBtn?: boolean;
}

export const PatientNameIdCellALertHoc: React.FunctionComponent<PatientNameIdCellProps> = (props) => {
  const fetchPatientMedicationTasksQuery = useQuery({
    queryKey: ['fetchPatientMedicationTasks', props.data?.basicDetails?.patientSourceId!],
    queryFn: async () => fetchPatientAlertNotification(props.data?.basicDetails?.patientSourceId!, 'MEDICATION_TASKS', '50', '0', 'MAX'),
  });

  const fetchPatientInfoQuery = useQuery({
    queryKey: ['fetchPatientInfo', props.data?.basicDetails?.patientSourceId!],
    queryFn: async () => fetchPatientInfo(props.data?.basicDetails?.patientSourceId!, { isMedicalAdhrence: false }),
  });

  const patientInfo: any = {
    ...props.data,
    ...fetchPatientInfoQuery.data,
    tasks: fetchPatientMedicationTasksQuery.data,
  };

  return <PatientNameIdCell {...props} data={{ ...patientInfo }} />;
};

const PatientNameIdCell: React.FunctionComponent<PatientNameIdCellProps> = (props) => {
  const appTheme = useTheme();

  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);

  const handleClickChartIcon = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.currentTarget.parentElement?.parentElement?.parentElement?.classList.add('patient-name-id-cell-selection-border');
    setAnchorEl(event.currentTarget.parentElement?.parentElement?.parentElement as HTMLButtonElement);
  };

  const handleCloseHealthOverview = () => {
    const patientIdCells = document.getElementsByClassName('table-body-cell-patientId') as HTMLCollectionOf<HTMLElement>;
    Array.from(patientIdCells).forEach((ele) => {
      ele.classList.remove('patient-name-id-cell-selection-border');
    });

    setAnchorEl(null);
  };

  const patientData = props.data;

  let name = '';

  if (patientData?.basicDetails?.firstName && patientData?.basicDetails?.lastName) {
    name = patientData?.basicDetails?.firstName + ' ' + patientData?.basicDetails?.lastName;
  }

  const patientId = patientData?.basicDetails?.patientId;

  return (
    <Grid item xs={12} container alignItems='center' gap={3}>
      <Grid item xs={9} sx={{ cursor: 'pointer' }} onClick={props.handleClickPatientId}>
        <Typography variant='fontSemiBold14' color='#33425B' component='div' sx={{ maxWidth: 'fit-content' }}>
          {patientId}
        </Typography>
        <Grid item xs={12} container>
          <Typography
            variant='fontSemiBold14'
            color='#33425B'
            component='div'
            sx={{
              marginRight: '5px',
              display: 'inline-block',
              maxWidth: '70%',
              width: 'auto',
              whiteSpace: 'break-spaces',
              overflow: 'auto !important',
              textOverflow: 'ellipsis',
            }}>
            {name}
          </Typography>
          <Typography sx={{ color: appTheme.palette.customColor.netural, pl: 1 }} component='span'>
            ({patientData?.basicDetails?.age || '-'} / {patientData?.basicDetails?.gender ? patientData?.basicDetails?.gender.slice(0, 1) : '-'})
          </Typography>
        </Grid>
      </Grid>

      {props.showAlertBtn && (
        <Grid item xs='auto'>
          <IconButton
            size='small'
            sx={{
              backgroundColor: anchorEl ? '#C8C8C8' : 'inherit',
              borderColor: '#9BB4CC',
              borderRadius: '4px',
              padding: '8px',
              border: '1px solid #9BB4CC',
              '&hover': {
                backgroundColor: anchorEl ? '#C8C8C8' : 'inherit',
              },
            }}
            onClick={handleClickChartIcon}>
            <VitalsGraphIcon />
          </IconButton>
        </Grid>
      )}

      {Boolean(anchorEl) && <PatientHealthOverviewModal anchorEl={anchorEl} handleClose={handleCloseHealthOverview} patientData={patientData} />}
    </Grid>
  );
};

export default PatientNameIdCell;
