/** @format */

import * as React from 'react';

import Dialog from '@mui/material/Dialog';
import { useTheme } from '@mui/material/styles';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';

import CloseIcon from '@mui/icons-material/Close';

interface ViewPrescriptionReportModalProps {
  isOpen: boolean;

  handleClose: () => void;

  previewPdfUrl?: string | null;
}

const ViewPrescriptionReportModal: React.FunctionComponent<ViewPrescriptionReportModalProps> = (props) => {
  const appTheme = useTheme();

  return (
    <Dialog id='medication-critical-alerts' open={props.isOpen} maxWidth='md' fullWidth onClose={props.handleClose}>
      <DialogTitle sx={{ p: '10px 15px', display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
        <Typography variant='fontBold16' color={appTheme.palette.customColor.text}>
          Review Patient Report
        </Typography>
        <IconButton size='small' onClick={props.handleClose}>
          <CloseIcon />
        </IconButton>
      </DialogTitle>

      <DialogContent sx={{ '& .custom-titration-calender': { ml: 2, pr: 3 }, '& #scroll-today': { ml: 2 }, minHeight: '30rem' }}>
        <iframe src={props.previewPdfUrl!} title='Patient Prescriptions' style={{ width: '100%', height: '50rem' }} />
      </DialogContent>
    </Dialog>
  );
};

export default ViewPrescriptionReportModal;
