/** @format */

import AxiosInstance from './index';

import { AxiosRequestConfig, CancelToken, CancelTokenSource } from 'axios';
import { UpdateTasksByIdPropsType } from '../types/Workspace.types';

import config from '../config/config';

export const fetchPatientPrescriptionReviewPatient = async (limit: number, offset: number, cardiologistId: string, reviewAxiosCancelToken?: CancelToken) => {
  const params: AxiosRequestConfig = {
    method: 'GET',
    baseURL: config.patientApiUrl,
    url: 'patients/prescriptions/reviews/list',
    params: {
      limit,
      offset,
      cardiologistId,
    },
    cancelToken: reviewAxiosCancelToken,
  };

  const res = await AxiosInstance(params);

  return res.data || {};
};

export const updateTasksById = async ({ patientSourceId, taskId, payload }: UpdateTasksByIdPropsType) => {
  const params: AxiosRequestConfig = {
    method: 'PATCH',
    baseURL: config.careWebAppApiUrl,
    url: `patients/${patientSourceId}/tasks/${taskId}`,
    data: payload,
  };

  const res = await AxiosInstance(params);

  return res.data || {};
};

export const fetchPatientTasksByCategory = async (
  category: string,
  limit: number,
  offset: number,
  groupSortBy: any = { severity: 'SUM' },
  groupMainType?: string[],
  cardiologistId?: string,
  status?: string,
  extrasParams?: any,
  axiosCancelToken?: CancelTokenSource,
) => {
  const params: AxiosRequestConfig = {
    baseURL: config.careWebAppApiUrl,
    url: 'patients/tasks',
    params: {
      category,
      limit,
      offset,
      groupSortBy,
      groupMainType,
      cardiologistId,
      status,
      ...extrasParams,
    },
  };

  if (axiosCancelToken) {
    params.cancelToken = axiosCancelToken.token;
  }

  const res = await AxiosInstance(params);

  return res.data || {};
};
