/** @format */
import * as React from 'react';
import * as _ from 'underscore';

import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import { useTheme } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import LoadingButton from '@mui/lab/LoadingButton';

// Icons
import EditIcon from '@mui/icons-material/Edit';

// Components
import FormFields from '../../common/form-fields/FormFields';

// Types
import { CustomFormType } from '../../../types/CustomForm.types';

// Constants
import { TEXT_SELECT_CHECKBOX_MULTI_COMPONENT } from '../../../constants/PatientEnrollment';

export interface PatientTreatmentDevicesPreferencesType {
  createdAt: string;
  organizationId: string;
  patientSourceId: string;
  preferences: { preferences: string[] };
  recordId: number;
  type: string;
}

interface PatientTrementPreferncesProps {
  isPatientTreatmentDevicesPreferencesUpdating: boolean;
  handleSubmitPatientTreatmentDevicePreferences: (preferences: any) => void;

  patientTreatmentDevicesPreferences?: PatientTreatmentDevicesPreferencesType | null;
}

const PatientTrementPrefernces: React.FunctionComponent<PatientTrementPreferncesProps> = (props) => {
  const appTheme = useTheme();

  const [isEdit, setIsEdit] = React.useState<boolean>(false);

  const [devicesPreferences, setDevicesPreferences] = React.useState<any>(['BP_WEIGHT_DEVICES']);

  const toggleEditMode = () => {
    setIsEdit((prev) => !prev);
  };

  const handleClickSave = async () => {
    await props.handleSubmitPatientTreatmentDevicePreferences(devicesPreferences);
    toggleEditMode();
  };

  const handleClickReset = () => {
    toggleEditMode();
    if (props.patientTreatmentDevicesPreferences?.preferences?.preferences) {
      setDevicesPreferences(JSON.parse(JSON.stringify(props.patientTreatmentDevicesPreferences?.preferences?.preferences)));
    }
  };

  const handleChangeValue = (value: any) => {
    setDevicesPreferences(typeof value === 'string' ? value.split(',') : value);
  };

  React.useEffect(() => {
    if (props.patientTreatmentDevicesPreferences && props.patientTreatmentDevicesPreferences?.preferences) {
      setDevicesPreferences(JSON.parse(JSON.stringify(props.patientTreatmentDevicesPreferences?.preferences?.preferences)));
    }
  }, [props.patientTreatmentDevicesPreferences]);

  const treatmentOptions = [
    {
      label: 'BP monitor and Weighing scale',
      value: 'BP_WEIGHT_DEVICES',
    },

    {
      label: "Wearable Devices without ECG's",
      value: 'WEARABLE_DEVICES_WITHOUT_ECGS',
    },

    {
      label: "Wearable Devices with ECG's",
      value: 'WEARABLE_DEVICES_WITH_ECGS',
    },
  ];

  const formFields: CustomFormType = {
    id: 'select-operator',
    label: '',
    dataTestIdPrefix: 'Patient-Treatment',
    componentType: TEXT_SELECT_CHECKBOX_MULTI_COMPONENT,
    fullWidth: true,
    input: {
      disabled: !isEdit,
      options: treatmentOptions,
      value: Array.isArray(devicesPreferences) ? devicesPreferences : [],
      errorMessage: '',
      placeholder: '',
      onChange: (event: any) => handleChangeValue(event.target.value),
      renderValue: (selectedValue: any) => {
        const selectedLabel: string[] = [];

        if (Array.isArray(selectedValue)) {
          selectedValue.forEach((item: string) => {
            const optionLabel = treatmentOptions.find((ele) => ele.value === item);
            if (!_.isEmpty(optionLabel)) {
              selectedLabel.push(optionLabel?.label!);
            } else {
              selectedLabel.push(item);
            }
          });
        } else {
          console.info('errrrorooror>>>', selectedValue);
        }

        return selectedLabel.join(', ');
      },
    },
    grid: {
      md: 6,
      lg: 3,
    },
    noError: true,
  };

  return (
    <Grid container direction='column' gap={2}>
      <Grid container alignItems='center' justifyContent='space-between'>
        <Typography variant='fontSemiBold16' component='div' color={appTheme.palette.customColor.primary}>
          Treatment
        </Typography>

        {!isEdit && (
          <Button startIcon={<EditIcon />} aria-label='Edit' onClick={toggleEditMode}>
            Edit
          </Button>
        )}

        {isEdit && (
          <div>
            <Button color='error' onClick={handleClickReset}>
              Cancel
            </Button>

            <LoadingButton sx={{ ml: 1 }} color='primary' variant='contained' onClick={handleClickSave}>
              Save
            </LoadingButton>
          </div>
        )}
      </Grid>
      <FormFields {...formFields} />
    </Grid>
  );
};

export default PatientTrementPrefernces;
