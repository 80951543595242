/** @format */

export const FILE_TYPE_OPTIONS = [
  {
    label: 'Prescriptions',
    value: 'PRESCRIPTIONS',
  },
  {
    label: 'Medical History',
    value: 'MEDICAL_HISTORY',
  },
];

export const NEW_ATTACHMENT_REPORT_ID = 'NEW_ATTACHMENT_REPORT_ID';
