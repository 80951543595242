/** @format */

import * as React from 'react';

import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import { LoadingButton } from '@mui/lab';

interface PatientDeviceRemoveConfirmModalProps {
  open: boolean;
  isLoading: boolean;
  handleClose: () => void;
  handleSubmit: () => void;
}

const PatientDeviceRemoveConfirmModal: React.FunctionComponent<PatientDeviceRemoveConfirmModalProps> = (props) => {
  return (
    <Dialog open={props.open} onClose={props.handleClose} aria-labelledby='alert-dialog-title' aria-describedby='alert-dialog-description'>
      <DialogTitle id='alert-dialog-title'>Remove Device</DialogTitle>
      <DialogContent>
        <DialogContentText id='alert-dialog-description'>Are you sure, you want to unmapp the device from the patient ?</DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button variant='outlined' color='error' onClick={props.handleClose}>
          Cancel
        </Button>
        <LoadingButton loading={props.isLoading} variant='contained' onClick={props.handleSubmit} autoFocus>
          Submit
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};

export default PatientDeviceRemoveConfirmModal;
