/** @format */

import React from 'react';
import { Grid, Button, Dialog, Slide, Checkbox, useTheme, IconButton, Typography, DialogTitle, DialogActions, DialogContent, CircularProgress, FormControlLabel } from '@mui/material';
import { DateTime } from 'luxon';
import CloseIcon from '@mui/icons-material/Close';
import { TransitionProps } from '@mui/material/transitions';

// Components.
import { Notes } from '../patient-profile/patient-notes/Notes';
import FormFields, { FormFieldsProps } from '../common/form-fields/FormFields';

// Hooks.
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import { setToast } from '../../reducers/toast-reducer/ToastReducer';

// Types.
import { BasicDetailsType } from '../../types/Patients.types';
import type { Descendant } from 'slate';
import { PatientSymptomsPayloadType } from '../../types/PatientSymptoms.types';

import { convertToRawText } from '../../utils/functions/notes';

// Constants.
import { NOTE_INITIAL_STATE } from '../../constants/notes';
import { DROPDOWN_INPUT_COMPONENT, TEXT_INPUT_COMPONENT } from '../../constants/PatientEnrollment';
import _ from 'underscore';

export const DialogModalTransition = React.forwardRef(function Transition (
  props: TransitionProps & {
    children: React.ReactElement<any, any>;
  },
  ref: React.Ref<unknown>,
) {
  return <Slide direction='down' ref={ref} {...props} />;
});

interface SymptomsReportModalProps {
  isOpen: boolean;
  isUpdatingAPICalling: boolean;

  hideDialogBehaviour?: boolean;

  onClose: () => void;

  handleSubmit: (symptomsPayload: Omit<PatientSymptomsPayloadType, 'patientSourceId' | 'recordId' | 'sourceId'>, notes: any) => void;

  patientBasicDetails: BasicDetailsType;

  disabled?: boolean;
}

const SymptomsReportModal: React.FunctionComponent<SymptomsReportModalProps> = (props) => {
  const appTheme = useTheme();

  const reduxDispatch = useAppDispatch();
  const [symptoms, setSymptoms] = React.useState<{ timestamp?: string; symptoms?: any }>({ timestamp: DateTime.local().toISO() as string });
  const [symptomsErrors, setSymptomsErrors] = React.useState<{ timestamp?: string; symptoms?: any }>({});

  const [disabled, setDisabled] = React.useState<boolean>(false);
  const [noSymptoms, setNoSymptoms] = React.useState<boolean>(false);

  const [editorState, setEditorState] = React.useState<Descendant[]>(NOTE_INITIAL_STATE);
  const symtomsState = useAppSelector((state) => state.patientSymptoms);

  let name = '';
  if (props.patientBasicDetails?.firstName && props.patientBasicDetails?.lastName) {
    name = props.patientBasicDetails?.firstName + ' ' + props.patientBasicDetails?.lastName;
  }

  const symptomsOptions = symtomsState.patientSymptoms
    .map((e) => {
      return {
        label: e.description,
        value: e.shortCode,
        description: e.description,
        category: e.category,
        shortCode: e.shortCode,
      };
    })
    .filter((e) => e.value !== 'OTHERS' && e.value !== 'NONE');

  const symptomsTimestampFormField: FormFieldsProps = {
    id: 'date',
    label: 'Date',
    componentType: TEXT_INPUT_COMPONENT,
    input: {
      onChange: (event: any) => setSymptoms((prev) => ({ ...prev, timestamp: event.target.value })),
      value: symptoms?.timestamp ? DateTime.fromISO(symptoms?.timestamp).toFormat('yyyy-MM-dd hh:mm') : DateTime.fromJSDate(new Date()).toFormat('yyyy-MM-dd hh:mm'),
      type: 'datetime-local',
      disabled: props.disabled,
      errorMessage: symptomsErrors?.timestamp,
    },
  };

  const symptomsFormField: FormFieldsProps = {
    id: 'symptoms',
    label: 'Symptoms',
    componentType: DROPDOWN_INPUT_COMPONENT,
    input: {
      options: symptomsOptions,
      // onChange: (event: any) => setSymptoms((prev) => ({ ...prev, symptoms: [...(prev.symptoms as string[]), event.label] })),
      onChange: (event: any) => {
        setSymptoms((prev) => {
          return {
            ...prev,
            symptoms: event,
          };
        });
      },
      disabled: disabled || props.disabled,
      value: symptoms?.symptoms,
      isCreateableSelect: true,
      isMulti: true,
      maxMenuHeight: 120,
    },
  };

  const handleSubmit = () => {
    if (!symptoms?.symptoms && !noSymptoms) {
      reduxDispatch(
        setToast({
          message: 'Please select atleast one symptom.',
          code: 'error',
        }),
      );

      return;
    }

    let isError = false;

    if (_.isEmpty(symptoms?.timestamp)) {
      setSymptomsErrors({ timestamp: 'Please enter date of Symptoms' });
    } else if (!(new Date(symptoms?.timestamp!).getTime() < DateTime.local().plus({ days: 0 }).toMillis())) {
      setSymptomsErrors({ timestamp: 'Date cannot be future date' });
      isError = true;
    } else if (!(new Date(symptoms?.timestamp!).getTime() > DateTime.local().minus({ months: 3 }).toMillis())) {
      setSymptomsErrors({ timestamp: 'Date cannot be less than 3 months' });
      isError = true;
    } else {
      setSymptomsErrors({});
    }

    if (isError) {
      return;
    }

    const rawText = convertToRawText(editorState);

    const payload: Omit<PatientSymptomsPayloadType, 'patientSourceId' | 'recordId' | 'sourceId'> = {
      symptoms: noSymptoms
        ? [{ category: 'NO_SYMPTOMS', shortcode: 'NONE', description: 'No Symptoms' }]
        : symptoms?.symptoms?.map((e: any) => {
          if (symptomsOptions.find((f) => f.value === e.value)) {
            return {
              category: e.category,
              shortcode: e.shortCode,
              description: e.description,
            };
          } else {
            return {
              category: 'OTHERS',
              shortcode: 'OTHERS',
              description: e.value,
            };
          }
        }),
      timestamp: DateTime.fromJSDate(new Date(symptoms.timestamp!)).toISO()!,
      notes: {
        payload: editorState,
        rawText,
      },
    };

    props.handleSubmit(payload, editorState);
  };

  const disableSubmitButton = props.isUpdatingAPICalling || !symptoms.timestamp;

  const _renderDialogContext = () => {
    return (
      <>
        <DialogTitle sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', p: '24px' }}>
          <Typography variant='fontBold20' color={appTheme.palette.customColor.primary}>
            Add Symptoms ({name.length !== 0 ? `${name} / ${props.patientBasicDetails?.patientId}` : `${props.patientBasicDetails?.patientId}`})
          </Typography>

          <IconButton sx={{ marginLeft: 'auto' }} size='small' color='info' className='button' onClick={props.onClose}>
            <CloseIcon color='primary' />
          </IconButton>
        </DialogTitle>

        <DialogContent>
          <Grid container direction='column' gap={2}>
            <Grid item>
              <FormFields {...symptomsTimestampFormField} />
            </Grid>

            <Grid item>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={noSymptoms}
                    disabled={props.disabled}
                    onChange={(event: any) => {
                      if (event.target.checked) {
                        setDisabled(true);
                      } else {
                        setDisabled(false);
                      }

                      setNoSymptoms(event.target.checked);
                    }}
                  />
                }
                label={<Typography variant='fontSemiBold14'>No Symptoms</Typography>}
              />
            </Grid>

            <Grid item>
              <Typography variant='fontSemiBold14' align='center'>
                --OR--
              </Typography>
            </Grid>

            <Grid item>
              <FormFields {...symptomsFormField} />
            </Grid>

            <Grid item>
              <Notes editorState={editorState} setEditorState={setEditorState} showToolbar={true} readonly={false} />
            </Grid>
          </Grid>
        </DialogContent>

        <DialogActions sx={{ px: '24px' }}>
          <Button disabled={props.isUpdatingAPICalling} variant='outlined' color='primary' onClick={props.onClose}>
            Cancel
          </Button>
          <Button disabled={disableSubmitButton || props.disabled} variant='contained' color='primary' onClick={handleSubmit} data-testid='symptoms-update'>
            {!props.isUpdatingAPICalling && 'Add'}
            {props.isUpdatingAPICalling && (
              <>
                <CircularProgress size='small' sx={{ width: '20px', mr: '10px' }} />
                Updating...
              </>
            )}
          </Button>
        </DialogActions>
      </>
    );
  };

  if (props.hideDialogBehaviour) {
    return _renderDialogContext();
  }

  return (
    <Dialog keepMounted maxWidth='sm' fullWidth open={props.isOpen} TransitionComponent={DialogModalTransition} onClose={props.onClose}>
      {_renderDialogContext()}
    </Dialog>
  );
};

export default SymptomsReportModal;
