/** @format */

import AxiosInstance from './index';
import CONFIG from '../config/config';

import { DEVICE_MAPPING, DIAGNOSIS_MAPPING } from '../constants/PatientEcgMapping';
import { CancelTokenSource } from 'axios';

export const fetchPatientEcg = async (
  sourceId: string,
  startDate: string | Date,
  endDate: string | Date,
  acquisitionDevice: string,
  diagnosisCodes: string,
  limit: number,
  offset: number,
  axiosCancelToken?: CancelTokenSource,
) => {
  acquisitionDevice = DEVICE_MAPPING[acquisitionDevice as keyof typeof DEVICE_MAPPING];
  diagnosisCodes = DIAGNOSIS_MAPPING[diagnosisCodes as keyof typeof DIAGNOSIS_MAPPING];

  const res = await AxiosInstance({
    method: 'GET',
    baseURL: CONFIG.patientApiUrl,
    url: `/patients/${sourceId}/ecg?startDate=${startDate}&endDate=${endDate}&acquisitionDevice=${acquisitionDevice}&diagnosisCodes=${diagnosisCodes}&limit=${limit}&offset=${offset}`,
    cancelToken: axiosCancelToken?.token,
  });

  return res.data || {};
};
