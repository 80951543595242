/** @format */

import { Notes } from '../../patient-profile/patient-notes/Notes';

import { NOTE_INITIAL_STATE } from '../../../constants/notes';
import _ from 'underscore';
import { Box, FormControl, FormLabel, Typography, useTheme } from '@mui/material';

export const GridCustomNotes = (props: any) => {
  const appTheme = useTheme();

  const required = props.uiSchema['ui:required'] || false;
  const mandatory = props.uiSchema['ui:mandatory'] || false;

  return (
    <FormControl required={props.required} disabled={props.disabled} sx={{ display: 'flex' }} id={props.id}>
      <FormLabel sx={{ pb: '8px', display: 'flex', alignItems: 'center' }}>
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <Typography variant='fontSemiBold14' color={appTheme.palette.customColor.pageTitle} component='div' sx={{ marginLeft: '5px', display: 'flex' }}>
            {props.schema.title} {mandatory && <span style={{ marginLeft: '3px' }}>*</span>}
          </Typography>

          {required && (
            <Typography color='error' sx={{ marginLeft: '3px' }}>
              *
            </Typography>
          )}
        </Box>
      </FormLabel>

      <div className={`${props.uiSchema['ui:fields']?.className}`}>
        <Notes editorState={!_.isEmpty(props.formData) ? props.formData : NOTE_INITIAL_STATE} setEditorState={props.onChange} showToolbar={!props.disabled} readonly={props.disabled} />
      </div>
    </FormControl>
  );
};
