/** @format */

import CONFIG from '../config/config';
import AxiosInstance from './index';

export const fetchPatientAlertNotification = async (patientSourceId: string, category: string, limit: string, offset: string, severitySortBy: string) => {
  const res = await AxiosInstance({
    method: 'GET',
    baseURL: CONFIG.careWebAppApiUrl,
    url: `patients/${patientSourceId}/tasks`,
    params: {
      category,
      limit,
      offset,
      severitySortBy,
    },
  });
  return res.data || {};
};
