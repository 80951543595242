/** @format */

import axios, { CancelTokenSource } from 'axios';
import { PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { PatientTasksType } from '../../types/PatientTasks.type';

import { RootState } from '../../app/store';
import { fetchPatientTasksByCategory } from '../../service/workspace';
import { TASKS_CATEGORY_CARE_TEAM_ALERTS } from '../../constants/workspace-tasks';

let axiosCancelToken: CancelTokenSource | null = null;

export interface MonitorPatientsSliceState {
  patientMonitoryIsLoading: boolean;

  limit: number;
  pageOffset: number;

  patientMonitoryData: { count: number; data: PatientTasksType<any, any>[] };
}

export const activeInitialState: MonitorPatientsSliceState = {
  patientMonitoryIsLoading: false,

  limit: 20,
  pageOffset: 0,

  patientMonitoryData: { count: 0, data: [] },
};

export const FetchPatientMonitory = createAsyncThunk(
  'FetchPatientMonitory',
  async ({ limit, pageOffset, cardiologistId }: { limit: number; pageOffset: number; cardiologistId: string | undefined }) => {
    try {
      if (axiosCancelToken) {
        axiosCancelToken.cancel();
      }

      axiosCancelToken = axios.CancelToken.source();

      const data = await fetchPatientTasksByCategory(
        TASKS_CATEGORY_CARE_TEAM_ALERTS,
        limit,
        pageOffset * limit,
        undefined,
        undefined,
        cardiologistId,
        undefined,
        {
          isPatientSymtoms: false,
          isPatientVitals: false,
          isMedicalAdhrence: false,
        },
        axiosCancelToken,
      );

      return data;
    } catch (err) {
      if (axios.isCancel(err)) {
        throw err;
      }
    }
  },
);

export const monitorPatientsReducer = createSlice({
  name: 'Monitor patients reducer',
  initialState: activeInitialState,
  reducers: {
    setLimit: (state, { payload }: PayloadAction<number>) => {
      state.limit = payload;
    },
    setPageOffset: (state, { payload }: PayloadAction<number>) => {
      state.pageOffset = payload;
    },

    setPatientMonitoryData: (state, { payload }: PayloadAction<{ count: number; data: PatientTasksType<any, any>[] }>) => {
      state.patientMonitoryData.data = payload.data;
      state.patientMonitoryData.count = payload.count;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(FetchPatientMonitory.pending, (state: MonitorPatientsSliceState, action) => {
      state.patientMonitoryIsLoading = true;
    });

    builder.addCase(FetchPatientMonitory.fulfilled, (state: MonitorPatientsSliceState, action) => {
      state.patientMonitoryData.data = action.payload?.data;
      state.patientMonitoryData.count = action.payload?.count;

      state.patientMonitoryIsLoading = false;
    });

    builder.addCase(FetchPatientMonitory.rejected, (state: MonitorPatientsSliceState, action) => {
      state.patientMonitoryIsLoading = true;
    });
  },
});

export const { setPatientMonitoryData, setLimit, setPageOffset } = monitorPatientsReducer.actions;

export const monitorPatientsSelector = (state: RootState) => state.monitorPatients;
