/** @format */

import { FormControl, FormLabel, OutlinedInput, Typography, useTheme } from '@mui/material';

export const GridTextareaField = (props: any) => {
  const appTheme = useTheme();
  const mandatory = props.uiSchema['ui:mandatory'] || false;

  const defaultValue = props.schema?.default || null;

  return (
    <FormControl id={props.id} required={props.required} disabled={props.disabled}>
      <FormLabel sx={{ pb: '8px' }}>
        {props.label && (
          <Typography variant='fontSemiBold14' color={appTheme.palette.customColor.pageTitle} component='div' sx={{ marginLeft: '5px', display: 'flex', minHeight: '31px', alignItems: 'center' }}>
            {props.label} {mandatory && <span style={{ marginLeft: '3px' }}>*</span>}
            {props.schema.required && (
              <Typography color='error' sx={{ marginLeft: '3px' }}>
                *
              </Typography>
            )}
          </Typography>
        )}
      </FormLabel>

      <OutlinedInput
        fullWidth
        disabled={props.disabled}
        type={props.uiSchema?.['ui:type'] || 'text'}
        value={props.value}
        defaultValue={defaultValue}
        multiline
        minRows={props.uiSchema?.['ui:fields'].minRows}
        maxRows={props.uiSchema?.['ui:fields'].maxRows}
        onChange={(event) => props.onChange(event.target.value)}
        placeholder={props.placeholder}
        sx={{ minHeight: '58px' }}
      />
    </FormControl>
  );
};
