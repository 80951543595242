/** @format */

import { Box, FormControl, FormControlLabel, FormLabel, Radio, RadioGroup, Typography, useTheme } from '@mui/material';

import { DropdownOptionType } from '../../../types/CustomForm.types';

export const GridInputRadioField = (props: any) => {
  const appTheme = useTheme();

  const required = props.uiSchema['ui:required'] || false;
  const mandatory = props.uiSchema['ui:mandatory'] || false;

  let options: DropdownOptionType[] = [];

  if (props.uiSchema['ui:customOptions']) {
    options = props.uiSchema['ui:customOptions'];
  } else {
    options = props.options.enumOptions;
  }

  return (
    <FormControl required={props.required} disabled={props.disabled} sx={{ display: 'flex' }} id={props.id}>
      <FormLabel sx={{ pb: '8px', display: 'flex', alignItems: 'center' }}>
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <Typography variant='fontSemiBold14' color={appTheme.palette.customColor.pageTitle} component='div' sx={{ marginLeft: '5px', display: 'flex' }}>
            {props.label} {mandatory && <span style={{ marginLeft: '3px' }}>*</span>}
          </Typography>

          {required && (
            <Typography color='error' sx={{ marginLeft: '3px' }}>
              *
            </Typography>
          )}
        </Box>
      </FormLabel>

      <RadioGroup
        row={props.uiSchema['ui:raidoDisplayRow']}
        aria-labelledby='demo-radio-buttons-group-label'
        name='radio-buttons-group'
        value={props.value}
        onChange={(event) => props.onChange(event.target.value)}>
        {options.map((item) => (
          <FormControlLabel value={item.value} control={<Radio />} label={item.label} />
        ))}
      </RadioGroup>
    </FormControl>
  );
};
