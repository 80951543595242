/** @format */

import * as React from 'react';

import { useTheme } from '@mui/material';
import Grid from '@mui/material/Grid';
// import Paper from '@mui/material/Paper';
import Button from '@mui/material/Button';
import Collapse from '@mui/material/Collapse';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';

import AddIcon from '@mui/icons-material/Add';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import PatientRuleItem from './PatientRuleItem';
import FormFields from '../common/form-fields/FormFields';

import { PatientRuleItemErrorType, PatientRuleItemType } from '../../types/PatientRule.types';

import { PatientRuleSuggestionTypeOptions, PATIENT_RULE_SUGESSTION_TYPE_IMMEDIATE } from '../../constants/PatientRules';

interface PatientRuleActionGroupProps {
  hideSaveBtnRuleItem?: boolean;
  isForceEdit?: boolean;
  itemControlEdit?: boolean;

  rules: PatientRuleItemType[];
  patientRulesErrors?: PatientRuleItemErrorType[];

  handleAddNewRule: (action: string) => void;
  handleRemoveNewRule: (ruleId: string) => void;
  handleSubmitDuplicateRule: (rule: PatientRuleItemType) => void;

  isUpdatingPatientRule: boolean;
  handleSubmitNewPatientRule: (rule: PatientRuleItemType) => void;
  handleSubmitUpdatePatientRule: (ruleId: string, rule: PatientRuleItemType) => void;
  handleSubmitDeletePatientRule: (ruleId: string) => void;

  handleChangeRules?: (ruleId: string, rule: PatientRuleItemType) => void;
  isRestrictColumnWidth?: boolean;
}

const PatientRuleActionGroup: React.FunctionComponent<PatientRuleActionGroupProps> = (props) => {
  const appTheme = useTheme();

  const [suggestionType, setSuggestionType] = React.useState<string>(PATIENT_RULE_SUGESSTION_TYPE_IMMEDIATE);
  const [expandedRule, setExpandedRule] = React.useState<boolean>(false);

  const getRuleAction = () => {
    return (props.rules && props.rules[0] ? props.rules[0]?.ruleAction : '') || '';
  };

  const toggleExpandRule = () => {
    setExpandedRule((prev) => !prev);
  };

  const handleAddNewRule = () => {
    const action = getRuleAction();
    props.handleAddNewRule(action);
  };

  React.useEffect(() => {
    let suggestionType: string;
    props.rules.forEach((item) => {
      suggestionType = item.suggestionType;
    });

    setSuggestionType(suggestionType!);
  }, [props.rules]);

  React.useEffect(() => {
    if (props.isForceEdit) {
      setExpandedRule(true);
    }
  }, [props.isForceEdit]);

  const handleChangeSuggestionType = (event: any) => {
    setSuggestionType(event.target.value);
  };

  return (
    <Grid sx={{ background: appTheme.palette.customColor.card, width: '100%', px: 0, py: 2 }}>
      <Grid item xs={12} container gap={2} alignItems='center' justifyContent='space-between'>
        <Typography variant='fontBold20' color={appTheme.palette.customColor.primary} textTransform='capitalize'>
          {getRuleAction()} rules
        </Typography>
        <Grid
          item
          sx={{
            display: 'none',
            '& .MuiFormLabel-root, .MuiFormHelperText-root': { display: 'none' },
            '& .MuiSelect-select': { pt: '5px', pb: '0px' },
            '& .MuiInputBase-root': { borderRadius: '14px' },
          }}>
          <FormFields
            label=''
            id='suggestion-type'
            componentType='TEXT_SELECT_COMPONENT'
            input={{
              value: suggestionType,
              onChange: handleChangeSuggestionType,
              options: PatientRuleSuggestionTypeOptions,
            }}
          />
        </Grid>
        <Grid item>
          <IconButton onClick={toggleExpandRule} sx={{ transform: !expandedRule ? 'rotate(0deg)' : 'rotate(180deg)' }}>
            <ExpandMoreIcon />
          </IconButton>
        </Grid>
      </Grid>
      <Collapse in={expandedRule} timeout='auto' unmountOnExit>
        <Grid container gap={2} mt={2}>
          {props.rules?.map((item, index) => (
            <Grid item xs={12}>
              <PatientRuleItem
                key={index}
                rule={item}
                isRestrictColumnWidth={props.isRestrictColumnWidth}
                isForceEdit={props.isForceEdit}
                itemControlEdit={props.itemControlEdit}
                hideSaveBtnRuleItem={props.hideSaveBtnRuleItem}
                handleSubmitDuplicateRule={props.handleSubmitDuplicateRule}
                isUpdatingPatientRule={props.isUpdatingPatientRule}
                handleDeleteRule={props.handleSubmitDeletePatientRule}
                handleRemoveNewRule={props.handleRemoveNewRule}
                handleSubmitNewPatientRule={props.handleSubmitNewPatientRule}
                handleSubmitUpdatePatientRule={props.handleSubmitUpdatePatientRule}
                patientRulesErrors={props.patientRulesErrors}
                handleChangeRules={props.handleChangeRules}
              />
            </Grid>
          ))}

          <Grid item xs={12} textAlign='center'>
            {props.isForceEdit && (
              <Button startIcon={<AddIcon />} variant='contained' onClick={handleAddNewRule} disabled={props.isUpdatingPatientRule}>
                Add new rule
              </Button>
            )}
          </Grid>
        </Grid>
      </Collapse>
    </Grid>
  );
};

PatientRuleActionGroup.defaultProps = {
  isForceEdit: true,
  itemControlEdit: false,
};

export default PatientRuleActionGroup;
