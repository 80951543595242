/** @format */

import { Box, Checkbox, FormControl, FormLabel, Typography } from '@mui/material';
import { InfoIcon } from '../../../assets/InfoIcon';

export const GridCheckBoxField = (props: any) => {
  return (
    <FormControl required={props.required} disabled={props.disabled || props.schema.readOnly} sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }} id={props.id}>
      <Checkbox
        autoFocus={props.uiSchema?.['ui:autoFocus']}
        disabled={props.disabled || props.schema.readOnly}
        onChange={(event) => {
          props.onChange(event.target.checked);
        }}
        sx={{ color: '#BDBDBD' }}
        checked={props.value}
      />

      <FormLabel sx={{ display: 'flex', alignItems: 'center' }}>
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <Typography variant='fontReg16' color='#676767' component='div' sx={{ marginLeft: '5px' }}>
            {props.label}
          </Typography>
        </Box>
      </FormLabel>

      {props.uiSchema['ui:infoIcon'] && (
        <Box sx={{ marginLeft: '5px', padding: '5px', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
          <InfoIcon title={props.uiSchema['ui:info']} />
        </Box>
      )}
    </FormControl>
  );
};
