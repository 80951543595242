/** @format */

import AxiosInstance from './index';
import CONFIG from '../config/config';
import { PatientRuleItemThresholdType, PatientRuleItemType } from '../types/PatientRule.types';

export const getPatientRulesThreshold = async (patientSourceId: string) => {
  const res = await AxiosInstance({
    method: 'GET',
    baseURL: CONFIG.patientApiUrl,
    url: `/patients/${patientSourceId}/rules/threshold`,
  });

  return res.data || {};
};

export const updatePatientRuleThreshold = async (patientSourceId: string, rule: PatientRuleItemThresholdType[]) => {
  const res = await AxiosInstance({
    method: 'POST',
    baseURL: CONFIG.patientApiUrl,
    url: `/patients/${patientSourceId}/rules/threshold`,
    data: rule,
  });
  return res.data || {};
};

export const getPatientRules = async (patientSourceId: string) => {
  const res = await AxiosInstance({
    method: 'GET',
    baseURL: CONFIG.patientApiUrl,
    url: `/patients/${patientSourceId}/rules`,
  });

  return res.data || {};
};

export const updatePatientRuleDefaultRule = async (patientSourceId: string) => {
  const res = await AxiosInstance({
    method: 'POST',
    baseURL: CONFIG.patientApiUrl,
    url: `/patients/${patientSourceId}/rules/default`,
  });

  return res.data || {};
};

export const createNewPatientRule = async (patientSourceId: string, rules: PatientRuleItemType) => {
  const res = await AxiosInstance({
    method: 'POST',
    baseURL: CONFIG.patientApiUrl,
    url: `/patients/${patientSourceId}/rules`,
    data: rules,
  });

  return res.data || {};
};

export const updatePatientRuleById = async (patientSourceId: string, ruleId: string, rules: PatientRuleItemType) => {
  const res = await AxiosInstance({
    method: 'PATCH',
    baseURL: CONFIG.patientApiUrl,
    url: `/patients/${patientSourceId}/rules/${ruleId}`,
    data: rules,
  });

  return res.data || {};
};

export const deletePatientRuleById = async (patientSourceId: string, ruleId: string) => {
  const res = await AxiosInstance({
    method: 'DELETE',
    baseURL: CONFIG.patientApiUrl,
    url: `/patients/${patientSourceId}/rules/${ruleId}`,
  });

  return res.data || {};
};

export const getPatientActiveVideoCallStatus = async (patientSourceId: string) => {
  const res = await AxiosInstance({
    method: 'GET',
    baseURL: CONFIG.patientApiUrl,
    url: `/patients/${patientSourceId}/call-status`,
  });

  return res.data || {};
};
