/** @format */

export const VITALS_MAPPING = {
  POTASSIUM: 'potassium',
  EGFR: 'eGFR',
  HEART_RATE: 'hr',
  BLOOD_PRESSURE: 'bp',
  WEIGHT: 'weight',
};

export const LAB_REPORTS_MAP_LABEL: { [m: string]: string } = {
  SYSTOLIC: 'Systolic',
  DIASTOLIC: 'Diastolic',
  HEART_RATE: 'Pulse Rate',
  WEIGHT: 'Weight',
  FEET: 'Feet',
  INCHES: 'Inches',
  BMI: 'BMI',
  CHLORIDE: 'Chloride',
  BNP: 'BNP',
  NTPROBNP: 'NT-proBNP',
  SCR: 'sCr',
  EGFR: 'eGFR',
  POTASSIUM: 'K',
  CALCIUM: 'Calcium',
  SODIUM: 'Sodium',
  GLUCOSE: 'Glucose',
  BLOOD_UREA_NITROGEN: 'Blood Urea Nitrogen',
  HEMOGLOBIN: 'Hemoglobin',
  HEMATOCRIT: 'Hematocrit',
  HBA1c: 'HBA1c',
  BICARBONATE: 'Bi-Carbonate',

  HEIGHT: 'Height',
};
