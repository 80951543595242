/** @format */

import React, { FC } from 'react';

interface SettingsIconInterface {
  width?: number | string;
  height?: number | string;
  fill?: string;
}

const SettingsIcon: FC<SettingsIconInterface> = ({ width, height, fill }) => {
  return (
    <svg width={width} height={height} viewBox='0 0 19 16' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M3.59375 0.125H2.28125V6.6875H3.59375V0.125ZM11.6 6.6875H7.4L6.875 5.96562V4.65312L7.4 4.0625H11.6L12.125 4.71875V6.03125L11.6 6.6875ZM5.0375 10.625H0.8375L0.3125 9.96875V8.65625L0.8375 8H5.0375L5.5625 8.65625V9.96875L5.0375 10.625ZM10.1563 0.125H8.84375V2.75H10.1563V0.125ZM8.84375 8H10.1563V15.875H8.84375V8ZM3.59375 11.9375H2.28125V15.875H3.59375V11.9375ZM13.9625 11.9375H18.1494L18.6744 11.2812V10.0344L18.1494 9.37813H13.9625L13.4375 10.0344V11.2812L13.9625 11.9375ZM16.7188 0.125H15.4063V8H16.7188V0.125ZM15.4063 13.25H16.7188V15.875H15.4063V13.25Z'
        fill={fill}
      />
    </svg>
  );
};

SettingsIcon.defaultProps = {
  width: 30,
  height: 30,
  fill: '#9BB4CC',
};

export default SettingsIcon;
