/** @format */

import * as React from 'react';
import { DateTime } from 'luxon';
import { useQuery } from '@tanstack/react-query';
import { ErrorBoundary } from 'react-error-boundary';
import { useLocation, useNavigate } from 'react-router-dom';

import Box from '@mui/material/Box';
import Chip from '@mui/material/Chip';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';

// Components.
// import CustomLoading from '../../components/common/custom-loading/CustomLoading';
import { PageErrorBoundary } from '../../components/common/error/PageErrorBoundary';
import FormFields, { FormFieldsProps } from '../../components/common/form-fields/FormFields';
import CustomRoutingTabs, { CustomRoutingTabsTabsType } from '../../components/common/custom-tabs/CustomRoutingTab';

// Contexts.
import { appTheme } from '../../context/AppTheme';
// import { getOrgUsers } from '../../service/notes';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import { setDoctorName } from '../../reducers/patient-list-reducer/PatientListSlice';
import { changeDoctorFilters as approvalChangeDoctorFilters } from '../../reducers/patient-list-reducer/ApprovalPatientListSlice';
import { changeDoctorFilters as draftChangeDoctorFilters, setDraftPatientList } from '../../reducers/patient-list-reducer/DraftPatientListSlice';
import { changeDoctorFilters as activeChangeDoctorFilters, setActivePatientList } from '../../reducers/patient-list-reducer/ActivePatientListSlice';
import { changeDoctorFilters as deactiveChangeDoctorFilters, setDeactivePatientList } from '../../reducers/patient-list-reducer/DeactivePatientListSlice';

import { fetchPatientsList } from '../../service/patient-list';

// SCSS.
import './PatientList.scss';

import { convertToPascalCase } from '../../utils/functions/case-change';
import { TEXT_SELECT_COMPONENT } from '../../constants/PatientEnrollment';
import { APP_ROLES_CARE_TEAM, APP_ROLES_DOCTORS } from '../../constants/user-role';
import { SESSION_KEY_PATIENT_DOCTORS_FILTER } from '../../constants';
import { PersonIcon } from '../../assets/Person';

// Component props.
interface PatientListProps {}

const PatientList: React.FunctionComponent<PatientListProps> = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const reduxDispatch = useAppDispatch();

  const state = useAppSelector((state) => state.patientList);
  const doctors = useAppSelector((state) => state.userRegion.orgUsers);
  const activeStateCount = useAppSelector((state) => state.activePatientList.count);
  const deactiveStateCount = useAppSelector((state) => state.deactivePatientList.count);
  const draftStateCount = useAppSelector((state) => state.draftPatientList.count);

  const PATIENT_LIST_INDEX_ROUTE = './active';

  useQuery({
    queryKey: ['fetchActivePatientsList', 10, 0, state.doctorName],
    queryFn: async () => {
      const payload = await fetchPatientsList({
        status: 'ACTIVE',
        limit: 10,
        offset: 0,
        flag: true,
        startDate: DateTime.now().minus({ days: 7 }).toFormat('yyyy-MM-dd'),
        endDate: DateTime.now().toFormat('yyyy-MM-dd'),
        cardiologistId: state.doctorName,
        isEnrolmentDetails: 'false',
        isMedicalAdhrence: 'false',
        isPatientSymtoms: 'false',
        isPatientVitals: 'false',
      });

      reduxDispatch(setActivePatientList(payload));
      return payload;
    },
    refetchIntervalInBackground: false,
    refetchOnWindowFocus: false,
  });

  useQuery({
    queryKey: ['fetchPatientsList', 'DEACTIVE', 10, 0, state.doctorName],
    queryFn: async () => {
      const payload = await fetchPatientsList({
        status: 'DEACTIVE',
        limit: 10,
        offset: 0,
        cardiologistId: state.doctorName,
        isEnrolmentDetails: 'false',
        isMedicalAdhrence: 'false',
        isPatientSymtoms: 'false',
        isPatientVitals: 'false',
      });
      reduxDispatch(setDeactivePatientList(payload));
      return payload;
    },
    refetchIntervalInBackground: false,
    refetchOnWindowFocus: false,
  });

  useQuery({
    queryKey: ['fetchPatientsList', 'DRAFT', 10, 0, state.doctorName],
    queryFn: async () => {
      const payload = await fetchPatientsList({
        status: 'DRAFT',
        limit: 10,
        offset: 0,
        cardiologistId: state.doctorName,
        isEnrolmentDetails: 'false',
        isMedicalAdhrence: 'false',
        isPatientSymtoms: 'false',
        isPatientVitals: 'false',
      });

      reduxDispatch(setDraftPatientList(payload));

      return payload;
    },
    refetchIntervalInBackground: false,
    refetchOnWindowFocus: false,
  });

  React.useEffect(() => {
    if (!location?.pathname?.endsWith('active') && !location?.pathname?.endsWith('deactive') && !location?.pathname?.endsWith('draft')) {
      navigate(PATIENT_LIST_INDEX_ROUTE);
    }
  }, [location.pathname]);

  React.useEffect(() => {
    const selectedCardiologist: string = localStorage.getItem('selectedCardiologistName') || '';
    if (selectedCardiologist.length === 0) {
      const userSession: any = localStorage.getItem('userSession');
      if (JSON.parse(userSession).productRole.roles.find((el: any) => el === 'DOCTORS') === 'DOCTORS') {
        reduxDispatch(setDoctorName(JSON.parse(userSession).userId));
      }
    } else {
      reduxDispatch(setDoctorName(selectedCardiologist));
    }
  }, []);

  const workspaceTabs: CustomRoutingTabsTabsType[] = [
    {
      label: (
        <>
          Active
          {activeStateCount > 0 && <Chip size='small' color='primary' label={activeStateCount} sx={{ ml: 2 }} />}
        </>
      ),
      value: 'ACTIVE',
      path: './active',
      activePathPatter: (path: string) => path.startsWith('/patients') && path.endsWith('/active'),
      userRoles: [APP_ROLES_DOCTORS, APP_ROLES_CARE_TEAM],
    },
    {
      label: (
        <>
          Deactivated
          {deactiveStateCount > 0 && <Chip size='small' color='primary' label={deactiveStateCount} sx={{ ml: 2 }} />}
        </>
      ),
      value: 'DEACTIVATED',
      path: './deactive',
      activePathPatter: (path: string) => path.startsWith('/patients') && path.endsWith('deactive'),
      userRoles: [APP_ROLES_CARE_TEAM, APP_ROLES_DOCTORS],
    },
    {
      label: (
        <>
          Draft
          {draftStateCount > 0 && <Chip size='small' color='primary' label={draftStateCount} sx={{ ml: 2 }} />}
        </>
      ),
      value: 'DRAFT',
      path: './draft',
      activePathPatter: (path: string) => path.startsWith('/patients') && path.endsWith('draft'),
      userRoles: [APP_ROLES_CARE_TEAM, APP_ROLES_DOCTORS],
    },
  ];

  const doctorsTypeFormField: FormFieldsProps = {
    label: '',
    id: 'doctor-list',
    width: '200px',
    componentType: TEXT_SELECT_COMPONENT,
    fullWidth: false,
    input: {
      value: state.doctorName,
      type: 'text',
      maxMenuHeight: 400,
      onChange: (event) => {
        sessionStorage.setItem(SESSION_KEY_PATIENT_DOCTORS_FILTER, event.target.value);
        localStorage.setItem('selectedCardiologistName', event.target.value);
        reduxDispatch(setDoctorName(event.target.value));
        reduxDispatch(draftChangeDoctorFilters());
        reduxDispatch(activeChangeDoctorFilters());
        reduxDispatch(deactiveChangeDoctorFilters());
        reduxDispatch(approvalChangeDoctorFilters());
      },
      options: [{ label: 'All  Cardiologists', value: 'ALL' }].concat(doctors?.map((e) => ({ ...e, label: convertToPascalCase(e.label, ' ', ' ') }))),
    },
    style: {
      padding: 0,
      border: '1px solid #9BB4CC',
      ':hover': {
        borderRadius: '4px',
        border: '1px solid #9BB4CC',
        background: '#EEE',
        cursor: 'default',
      },
      ':active': {
        border: 'none',
      },
      '.MuiSelect-select': {
        padding: '5px 10px',
        fontSize: '14px',
        border: '1px solid #9BB4CC',
      },
      '.MuiInputBase-root': {
        border: 'none',
      },
      'MuiSelect-nativeInput': {
        padding: 0,
        border: 'none',
      },
    },
  };

  return (
    <ErrorBoundary FallbackComponent={PageErrorBoundary}>
      <Grid container item xs={12} direction='row' sx={{ padding: '16px 16px 0 16px' }} alignItems='center' justifyContent='space-between'>
        <Grid item xs={12} sm={5} md>
          <Typography
            sx={{
              color: '#33425B',
              fontFamily: 'Lato',
              fontSize: '28px',
              fontStyle: 'normal',
              fontWeight: '700',
              lineHeight: 'normal',
              letterSpacing: '0.224px',
            }}>
            Patient List
          </Typography>
        </Grid>

        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <Typography
            sx={{
              fontFamily: 'Lato',
              fontSize: '14px',
              fontStyle: 'normal',
              fontWeight: '400',
              lineHeight: 'normal',
              letterSpacing: '0.14px',
              color: '#5C6A90',
              marginRight: '16px',
            }}>
            View patients assigned to
          </Typography>

          <Box mr='16px'>
            <FormFields {...doctorsTypeFormField} />
          </Box>

          <Button disableElevation variant='contained' onClick={() => navigate('/patients/enroll')} sx={{ padding: '7.5px' }}>
            <PersonIcon />
            <Typography component='div' variant='fontReg14' sx={{ fontWeight: '600' }} color={appTheme.palette.customColor.menuBar} ml='4px'>
              Enroll Patient
            </Typography>
          </Button>
        </Box>
      </Grid>

      <Box padding='0 16px 0 16px' mt='13px'>
        <CustomRoutingTabs tabs={workspaceTabs} />
      </Box>
    </ErrorBoundary>
  );
};

export default PatientList;
