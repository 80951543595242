/** @format */

import * as React from 'react';
import { useParams } from 'react-router-dom';

import Grid from '@mui/material/Grid';
import { useTheme } from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton';

import Typography from '@mui/material/Typography';

// Components
import FormFields from '../../common/form-fields/FormFields';

// Types
import { CustomFormType } from '../../../types/CustomForm.types';

// COnstants
import { useAppDispatch } from '../../../app/hooks';
import CustomLoading from '../../common/custom-loading/CustomLoading';
import { generatePatientAppUsername } from '../../../service/devices';
import { setToast } from '../../../reducers/toast-reducer/ToastReducer';
import { TEXT_INPUT_COMPONENT } from '../../../constants/PatientEnrollment';

interface PatientAppLoginProps {
  isLoading?: boolean;
  username?: string;
  password?: string;

  isResetPasswordLinkLoading?: boolean;

  refreshAppCreds: () => void;

  handleClickResetPasswordLink: () => void;
}

const PatientAppLogin: React.FunctionComponent<PatientAppLoginProps> = (props) => {
  const appTheme = useTheme();
  const { patientSourceId } = useParams();
  const [loading, setLoading] = React.useState<boolean>(false);
  const reduxDispatch = useAppDispatch();

  const [username, setUsername] = React.useState<string>(props.username || '');

  React.useEffect(() => {
    setUsername(props.username || '');
  }, [props.username]);

  const userNameFormFields: CustomFormType = {
    id: 'username',
    label: 'Username',
    componentType: TEXT_INPUT_COMPONENT,
    input: {
      value: username,
      disabled: true,
      onChange: (event: any) => setUsername(event.target.value),
      placeholder: 'Username',
      required: true,
    },
  };

  const handleClickGenerateUsername = React.useCallback(async () => {
    if (loading) {
      return;
    }

    setLoading(true);
    try {
      await generatePatientAppUsername(patientSourceId!);
      setLoading(false);
      reduxDispatch(setToast({ code: 'success', message: 'Successfully generated a username.' }));
      setTimeout(() => {
        props.refreshAppCreds();
      }, 10);
    } catch (error) {
      reduxDispatch(setToast({ code: 'error', message: 'Failed to generate a username.' }));
      setLoading(false);
    }
  }, [loading, patientSourceId]);

  if (props.isLoading) {
    return <CustomLoading />;
  }

  return (
    <Grid container direction='column' gap={2} mt={2}>
      <Grid container alignItems='center' justifyContent='space-between'>
        <Typography variant='fontSemiBold16' component='div' color={appTheme.palette.customColor.primary}>
          Login
        </Typography>
      </Grid>

      <Grid container item alignItems='center' justifyContent='space-between'>
        <Grid item xs={7}>
          <FormFields {...userNameFormFields} />
        </Grid>

        {username.length > 0 && (
          <Grid item xs={4}>
            <LoadingButton loading={props.isResetPasswordLinkLoading} onClick={props.handleClickResetPasswordLink} variant='outlined'>
              Reset Password
            </LoadingButton>
          </Grid>
        )}

        {username.length === 0 && (
          <Grid item>
            <LoadingButton loading={loading} variant='outlined' color='primary' onClick={handleClickGenerateUsername}>
              Generate Username
            </LoadingButton>
          </Grid>
        )}
      </Grid>
    </Grid>
  );
};

export default PatientAppLogin;
