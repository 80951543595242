/** @format */

export const LOGS_CATEGORY_OPTIONS = [
  { label: 'All', value: 'ALL' },
  { label: 'Enrolment', value: 'PATIENT_ENROLMENT' },
  { label: 'Medical History', value: 'MEDICAL_HISTORY' },
  { label: 'Medication Change', value: 'MEDICATION_CHANGE' },
  { label: 'Medical Adherence', value: 'PATIENT_MEDICAL_ADHERENCE' },
  { label: 'User Preset', value: 'ORGANIZATION_USER_PRESET' },
  { label: 'Rules', value: 'PATIENT_RULES' },
  { label: 'Vitals', value: 'PATIENT_VITALS' },
  { label: 'Appointments', value: 'APPOINTMENTS' },
  { label: 'Notes', value: 'PATIENT_NOTES' },
  { label: 'Care Plan', value: 'PATIENT_CARE_PLAN' },
  { label: 'Login', value: 'LOGIN' },
  { label: 'Logout', value: 'LOGOUT' },
  { label: 'User Inactivity', value: 'USER_INACTIVITY' },
  { label: 'User Pin', value: 'USER_PIN' },
  { label: 'Symptoms', value: 'PATIENT_SYMPTOMS' },
  { label: 'Patient Attachments', value: 'PATIENT_ATTACHMENTS' },
  { label: 'Tasks', value: 'TASKS' },
];

export const LOGS_CATEGORY_MAPPING: { [m: string]: string } = {
  ALL: 'All',
  PATIENT_ENROLMENT: 'Patient Enrolment',
  MEDICAL_HISTORY: 'Medical History',
  MEDICATION_CHANGE: 'Medication Change',
  PATIENT_MEDICAL_ADHERENCE: 'Patient Medical Adherence',
  ORGANIZATION_USER_PRESET: 'Organization User Preset',
  PATIENT_RULES: 'Rules',
  PATIENT_VITALS: 'Vitals',
  APPOINTMENTS: 'Appointments',
  PATIENT_NOTES: 'Patient Notes',
  PATIENT_CARE_PLAN: 'Patient Care Plan',
  LOGIN: 'Login',
  LOGOUT: 'Logout',
  USER_INACTIVITY: 'User Inactivity',
  USER_PIN: 'User Pin',
  PATIENT_SYMPTOMS: 'Symptoms',
  PATIENT_ATTACHMENTS: 'Attachments',
  TASKS: 'Task',
  PATIENT_PREFERENCES: 'Patient Preferences',
  PATIENT_APP_PASSWORD_REST: 'Patient App Password Reset',
  PATIENT_DEVICES: 'Patient Devices',
  PATIENT_PRESCRIPTIONS_APPROVALS: 'Patient Prescriptions Approvals',
};
