/** @format */

export const TASKS_CATEGORY_PATIENT_DAILY_TASK = 'PATIENT_DAILY_TASK';
export const TASKS_CATEGORY_CARE_TEAM_ALERTS = 'CARE_TEAM_ALERTS';
export const TASKS_CATEGORY_MEDICATION_TASKS = 'MEDICATION_TASKS';
export const TASKS_CATEGORY_PATIENT_NEW_PRESCRIPTIONS = 'PATIENT_NEW_PRESCRIPTIONS';
export const TASKS_CATEGORY_DEVICE_SETUP = 'DEVICE_SETUP';

export const TASKS_TYPE_VITALS_BP = 'VITALS_BP';
export const TASKS_TYPE_VITALS_HR = 'VITALS_HR';
export const TASKS_TYPE_ECG_READING = 'ECG_READING';
export const TASKS_TYPE_VITALS_WEIGHT = 'VITALS_WEIGHT';
export const TASKS_TYPE_SYMPTOMS = 'SYMPTOMS';
export const TASKS_TYPE_MEDICAL_ADHERENCE = 'MEDICAL_ADHERENCE';
export const TASKS_TYPE_LAB_REPORTS = 'LAB_REPORTS';
export const TASKS_TYPE_PATIENT_ENROLLMENT = 'PATIENT_ENROLLMENT';
export const TASKS_TYPE_APPLE_DEVICE = 'VITALS_APPLE_WATCH';
export const TASKS_TYPE_MEDICATION_CHANGE = 'MEDICATION_CHANGE';
export const TASKS_TYPE_MEDICATION_CRITICAL_ALERTS = 'MEDICATION_CRITICAL_ALERTS';
export const TASKS_TYPE_MEDICATION_WARNING_ALERTS = 'MEDICATION_WARNING_ALERTS';

export const TASKS_STATUS_TODO = 'TODO';
export const TASKS_STATUS_DONE = 'DONE';

export const TASKS_ALERT_SEVERITY_LOW = 'LOW';
export const TASKS_ALERT_SEVERITY_MEDIUM = 'MEDIUM';
export const TASKS_ALERT_SEVERITY_HIGH = 'HIGH';
