/** @format */

import * as React from 'react';
import { DateTime } from 'luxon';
import { useNavigate } from 'react-router-dom';
import { useQuery } from '@tanstack/react-query';
import { ErrorBoundary } from 'react-error-boundary';

import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import LinearProgress from '@mui/material/LinearProgress';

import CustomTable from '../../components/common/custom-table/CustomTable';
import PatientNameIdCell from '../../components/table-cell-components/PatientNameIdCell';
import CustomLoading from '../../components/common/custom-loading/CustomLoading';
import { PageErrorBoundary } from '../../components/common/error/PageErrorBoundary';
import { ComponentErrorBoundary } from '../../components/common/error/ComponentErrorBoundary';

import { useAppDispatch, useAppSelector } from '../../app/hooks';
import { CustomTableColumnType } from '../../types/CustomTable.types';
import { PatientListNewType } from '../../types/PatientListPage.types';
import { setDraftPatientList, setPageOffset, setLimit } from '../../reducers/patient-list-reducer/DraftPatientListSlice';

import { fetchPatientsList } from '../../service/patient-list';

import { calculateProgress } from '../../utils/validations';
import axios, { CancelTokenSource } from 'axios';

interface DraftPatientListProps {}

const customStyles = {
  backgroundColor: '#F1F2F3',
  color: '#667185',
};

const DraftPatientList: React.FunctionComponent<DraftPatientListProps> = (props) => {
  const reduxDispatch = useAppDispatch();

  const navigate = useNavigate();

  const axiosApiRef = React.useRef<CancelTokenSource | null>(null);

  const state = useAppSelector((state) => state.draftPatientList);
  const cardiologistId = useAppSelector((state) => state.patientList.doctorName || 'ALL');

  const draftPatientQuery = useQuery({
    queryKey: ['fetchPatientsList', 'DRAFT', state.limit, state.pageOffset, cardiologistId],
    queryFn: async ({ signal }) => {
      const CancelToken = axios.CancelToken;

      if (axiosApiRef.current) {
        axiosApiRef.current.cancel();
      }

      axiosApiRef.current = CancelToken.source();

      signal?.addEventListener('abort', () => {
        axiosApiRef.current?.cancel('Query was cancelled by TanStack Query');
      });

      const payload = await fetchPatientsList(
        {
          status: 'DRAFT',
          limit: state.limit,
          offset: state.pageOffset * state.limit,
          cardiologistId,
          isEnrolmentDetails: 'false',
          isMedicalAdhrence: 'false',
          isPatientSymtoms: 'false',
          isPatientVitals: 'false',
        },
        axiosApiRef.current.token,
      );

      reduxDispatch(setDraftPatientList(payload));

      return payload;
    },
    refetchIntervalInBackground: false,
    refetchOnWindowFocus: false,
  });

  const handleChangePageOffset = (event: React.MouseEvent<HTMLButtonElement> | null, newPage: number) => {
    reduxDispatch(setPageOffset(Number(newPage)));
  };

  const handleChangeLimit = (event: React.ChangeEvent<HTMLInputElement>) => {
    reduxDispatch(setLimit(Number(event.target.value)));
  };

  const columnDefs: CustomTableColumnType[] = [
    {
      // minWidth: 300,
      // width: '30%',
      id: 'patientId',
      label: 'Patient Details',
      cellFormatter: (data: PatientListNewType, index: number) => {
        const handleClickPatientId = () => {
          navigate(`/patients/${data?.basicDetails?.patientSourceId}/draft`);
        };

        return (
          <ErrorBoundary key={index} fallbackRender={ComponentErrorBoundary}>
            <PatientNameIdCell data={data} handleClickPatientId={handleClickPatientId} showAlertBtn={false} />
          </ErrorBoundary>
        );
      },
    },
    {
      id: 'complete',
      // minWidth: 300,
      // width: '30%',
      label: '% Complete',
      cellFormatter: (data: PatientListNewType, index: number) => {
        const percentage = calculateProgress(data);
        return (
          <ErrorBoundary key={index} fallbackRender={ComponentErrorBoundary}>
            <Grid container alignItems='center' gap={1}>
              <Grid item xs>
                <LinearProgress variant='determinate' value={Number(percentage)} sx={{ height: '10px', borderRadius: '5px' }} />
              </Grid>
              <Grid item xs='auto'>
                <Typography variant='fontReg16' color='text.secondary'>
                  {percentage} <Typography variant='fontReg14'>%</Typography>
                </Typography>
              </Grid>
            </Grid>
          </ErrorBoundary>
        );
      },
    },
    {
      id: 'extra-space',
      minWidth: 100,
      label: '',
    },
    {
      // minWidth: 200,
      // width: '20%',
      cellAlignment: 'left',
      id: 'profile-started-on',
      label: 'Profile Started On',
      cellFormatter: (data: PatientListNewType, index: number) => (
        <ErrorBoundary key={index} fallbackRender={ComponentErrorBoundary}>
          <Typography color='primary' variant='fontReg14'>
            {data?.basicDetails?.createdAt && DateTime.fromJSDate(new Date(data?.basicDetails?.createdAt)).toFormat('dd/MM/yyyy')}
          </Typography>
        </ErrorBoundary>
      ),
    },
    {
      // minWidth: 150,
      // width: '20%',
      hiddenColumn: true,
      id: 'action',
      label: '',
      cellFormatter: (data: PatientListNewType, index: number) => {
        return (
          <Box key={index} sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
            <Button variant='outlined' color='primary' onClick={() => navigate(`/patients/${data?.basicDetails?.patientSourceId}/draft`)}>
              <Typography variant='fontSemiBold14' color='primary'>
                Edit Now
              </Typography>
            </Button>
          </Box>
        );
      },
    },
  ];

  if (draftPatientQuery.isPending) {
    return <CustomLoading />;
  }

  return (
    <ErrorBoundary FallbackComponent={PageErrorBoundary}>
      <Box id='draft-patient-list' padding='0px 15px 0px 15px'>
        <CustomTable
          customStyles={customStyles}
          data={state.listData}
          columns={columnDefs}
          tablePagination
          count={state.count}
          rowsPerPage={state.limit}
          handleChangeRowsPerPage={handleChangeLimit}
          page={state.pageOffset}
          handleChangePage={handleChangePageOffset}
          hoverEffect={true}
        />
      </Box>
    </ErrorBoundary>
  );
};

export default DraftPatientList;
