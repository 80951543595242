/** @format */

import React from 'react';

import { MenuItem } from '@mui/material';
import KeyboardDoubleArrowLeftIcon from '@mui/icons-material/KeyboardDoubleArrowLeft';
import KeyboardDoubleArrowRightIcon from '@mui/icons-material/KeyboardDoubleArrowRight';

import { EcgClass } from 'tricog-d3';
import { ChartCoreOptions } from 'tricog-d3/dist/types/LineChart.types';

interface EcgType {
  id: string;
  data: any[];
  dimensions: { width: number; height: number };
  margin: { top: number; right: number; bottom: number; left: number };
  scale?: 'SCALE_TIME' | 'SCALE_POINT';
  xAxisPosition: 'BOTTOM' | 'TOP';
  timeFormat: string;
  rangeXAxisOffset: number;
  rangeYAxisOffset: number;
  rcaChartRef: React.MutableRefObject<HTMLDivElement | null>;
  ecgLineColor: string;
  recordingTime: number;
}

const Ecg: React.FunctionComponent<EcgType> = (props) => {
  const onScrollRef = React.useRef<any>(null);

  const width = props.dimensions!.width - props.margin!.left - props.margin!.right;
  const height = props.dimensions!.height - props.margin!.top - props.margin!.bottom;

  React.useEffect(() => {
    const options: ChartCoreOptions = {
      scale: props.scale,
      chartBackgroundColor: '',
      translateXBackgroundRect: 0,
      xAxisPosition: props.xAxisPosition,
      timeFormat: props.timeFormat,
      rangeXAxisOffset: props.rangeXAxisOffset,
      rangeYAxisOffset: props.rangeYAxisOffset,
    };

    const ecgCore = new EcgClass(`#${props.id}`, width, height, props.margin!, {} as any, props.data, options);

    let leadWidth;
    let leadHeight;
    const width1 = window.innerWidth;
    if (width1 <= 1399) {
      leadWidth = 936;
      leadHeight = 196;
    } else if (width1 >= 1400 && width1 <= 1440) {
      leadWidth = 966;
      leadHeight = 315;
    } else {
      leadWidth = 1202;
      leadHeight = 250;
    }

    ecgCore.core.deleteSvgElements();
    onScrollRef.current = ecgCore.core.createSvgElement({
      viewStartX: 0,
      viewStartY: true,
      graphYAxis: 250,
      leadWindowHeight: leadHeight,
      leadWindowWidth: leadWidth,
      xFactor: props.recordingTime / 10,
      zoomEnabled: false,
    });

    document.querySelector(`#${props.id} > svg`)?.setAttribute('width', '100%');
  }, [props.data, props.dimensions, props.id, props.margin, props.rangeXAxisOffset, props.rangeYAxisOffset, props.scale, props.timeFormat, props.xAxisPosition]);

  return (
    <div style={{ width: '100%', position: 'relative' }}>
      {props.recordingTime && props.recordingTime > 10 && (
        <div style={{ position: 'absolute', right: '0px', display: 'flex' }}>
          <MenuItem sx={{ backgroundColor: '#D5D5D6', border: '1px solid black', borderRadius: '5px' }} onClick={() => onScrollRef.current?.onScroll({ deltaY: 1 })}>
            {' '}
            <KeyboardDoubleArrowLeftIcon />{' '}
          </MenuItem>
          <MenuItem sx={{ backgroundColor: '#D5D5D6', border: '1px solid black', borderRadius: '5px' }} onClick={() => onScrollRef.current?.onScroll({ deltaY: -1 })}>
            {' '}
            <KeyboardDoubleArrowRightIcon />{' '}
          </MenuItem>
        </div>
      )}
      <div ref={props.rcaChartRef} id={props.id} style={{ width: '100%', height: '100%' }}></div>
    </div>
  );
};

export default Ecg;
