/** @format */

import * as React from 'react';
import { Box, Grid } from '@mui/material';
import { ObjectFieldTemplateProps } from 'react-jsonschema-form';
import CustomCard from '../custom-card';

const GridTemplateObjectFieldTemplate: React.FunctionComponent<ObjectFieldTemplateProps> = (props) => {
  const editMode: boolean = props.uiSchema['ui:fields']?.disabled;

  const editCard = props.uiSchema['ui:fields']?.handleClick;

  const customRef = props.uiSchema['ui:fields']?.customRef;

  return (
    <Box id={props.uiSchema['ui:fields']?.id} ref={customRef} sx={{ '& > div': { pb: '0px' } }}>
      <CustomCard title={props.title} editMode={editMode} handleClickEditCard={editCard}>
        <Grid id={`grid-template-areas-${props.uiSchema['ui:fields']?.className}`} container sx={{ display: 'grid', gridColumnGap: '0.625rem', gridRowGap: '0rem' }}>
          {props.properties.map((element, idx) => (
            <Grid item sx={{ gridArea: element.content?.props?.uiSchema?.['ui:grid-area'] }} key={idx}>
              {element.content}
            </Grid>
          ))}
        </Grid>
      </CustomCard>
    </Box>
  );
};

export default GridTemplateObjectFieldTemplate;

export const GridTemplateObjectWithoutCard: React.FunctionComponent<ObjectFieldTemplateProps> = (props) => {
  return (
    <Grid
      id={`grid-template-areas-${props.uiSchema['ui:fields']?.className}`}
      container
      sx={{ display: 'grid', gridColumnGap: '0.625rem', gridRowGap: '0rem', padding: '15px', border: '1px solid #D5D5D6', borderRadius: '5px' }}>
      {props.properties.map((element, idx) => (
        <Grid item sx={{ gridArea: element.content?.props?.uiSchema?.['ui:grid-area'] }} key={idx}>
          {element.content}
        </Grid>
      ))}
    </Grid>
  );
};
