/** @format */

import React from 'react';

export const PersonIcon = () => {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' width='16' height='16' viewBox='0 0 16 16' fill='none'>
      <path
        d='M14 4H13.3333V3.33333C13.3333 3.15652 13.2631 2.98695 13.1381 2.86193C13.013 2.73691 12.8435 2.66667 12.6667 2.66667C12.4899 2.66667 12.3203 2.73691 12.1953 2.86193C12.0702 2.98695 12 3.15652 12 3.33333V4H11.3333C11.1565 4 10.987 4.07024 10.8619 4.19526C10.7369 4.32029 10.6667 4.48986 10.6667 4.66667C10.6667 4.84348 10.7369 5.01305 10.8619 5.13807C10.987 5.2631 11.1565 5.33333 11.3333 5.33333H12V6C12 6.17681 12.0702 6.34638 12.1953 6.47141C12.3203 6.59643 12.4899 6.66667 12.6667 6.66667C12.8435 6.66667 13.013 6.59643 13.1381 6.47141C13.2631 6.34638 13.3333 6.17681 13.3333 6V5.33333H14C14.1768 5.33333 14.3464 5.2631 14.4714 5.13807C14.5964 5.01305 14.6667 4.84348 14.6667 4.66667C14.6667 4.48986 14.5964 4.32029 14.4714 4.19526C14.3464 4.07024 14.1768 4 14 4ZM6.66667 7.33333C7.19408 7.33333 7.70966 7.17694 8.14819 6.88392C8.58672 6.5909 8.92851 6.17443 9.13035 5.68716C9.33218 5.19989 9.38499 4.66371 9.28209 4.14643C9.1792 3.62914 8.92522 3.15399 8.55228 2.78105C8.17934 2.40811 7.70419 2.15413 7.18691 2.05124C6.66962 1.94835 6.13345 2.00116 5.64618 2.20299C5.15891 2.40482 4.74243 2.74662 4.44941 3.18515C4.1564 3.62368 4 4.13925 4 4.66667C4 5.37391 4.28095 6.05219 4.78105 6.55229C5.28115 7.05238 5.95942 7.33333 6.66667 7.33333ZM10.6667 14C10.8435 14 11.013 13.9298 11.1381 13.8047C11.2631 13.6797 11.3333 13.5101 11.3333 13.3333C11.3333 12.0957 10.8417 10.9087 9.9665 10.0335C9.09133 9.15833 7.90434 8.66667 6.66667 8.66667C5.42899 8.66667 4.242 9.15833 3.36683 10.0335C2.49167 10.9087 2 12.0957 2 13.3333C2 13.5101 2.07024 13.6797 2.19526 13.8047C2.32029 13.9298 2.48986 14 2.66667 14'
        fill='#F5F9FE'
      />
    </svg>
  );
};
